export const PUA_CONV_TABLE = {
    '\ue0bc': '\u115f\u1161\u11ae',                          // 'ᅟᅡᆮ'
    '\ue0bd': '\u115f\u1161\ud7cd',                          // 'ᅟᅡퟍ'
    '\ue0be': '\u115f\u1161\u11af',                          // 'ᅟᅡᆯ'
    '\ue0bf': '\u115f\u1161\u11b0',                          // 'ᅟᅡᆰ'
    '\ue0c0': '\u115f\u1161\u11b1',                          // 'ᅟᅡᆱ'
    '\ue0c1': '\u115f\u1161\u11b2',                          // 'ᅟᅡᆲ'
    '\ue0c2': '\u115f\u1161\u11ba',                          // 'ᅟᅡᆺ'
    '\ue0c3': '\u115f\u1161\u11bb',                          // 'ᅟᅡᆻ'
    '\ue0c4': '\u115f\u1161\u11bd',                          // 'ᅟᅡᆽ'
    '\ue0c5': '\u115f\u1161\ud7f9',                          // 'ᅟᅡퟹ'
    '\ue0c6': '\u115f\u11a3',                                // 'ᅟᆣ'
    '\ue0c7': '\u115f\u11a3\u11ae',                          // 'ᅟᆣᆮ'
    '\ue0c8': '\u115f\u1163\u11ab',                          // 'ᅟᅣᆫ'
    '\ue0c9': '\u115f\u1165\u11af',                          // 'ᅟᅥᆯ'
    '\ue0ca': '\u115f\u1165\u11b8',                          // 'ᅟᅥᆸ'
    '\ue0cb': '\u115f\u116e\u11ab',                          // 'ᅟᅮᆫ'
    '\ue0cc': '\u115f\u1175\u11a8',                          // 'ᅟᅵᆨ'
    '\ue0cd': '\u115f\u1175\u11ab',                          // 'ᅟᅵᆫ'
    '\ue0ce': '\u115f\u1175\u11af',                          // 'ᅟᅵᆯ'
    '\ue0cf': '\u115f\u1175\u11ba',                          // 'ᅟᅵᆺ'
    '\ue0d0': '\u115f\u1175\u11bc',                          // 'ᅟᅵᆼ'
    '\ue0d1': '\u115f\u119e\u11af',                          // 'ᅟᆞᆯ'
    '\ue0d2': '\u1100\u1161\u11ff',                          // '가ᇿ'
    '\ue0d3': '\u1100\u1161\u11c7',                          // '가ᇇ'
    '\ue0d4': '\u1100\u1161\u11ca',                          // '가ᇊ'
    '\ue0d5': '\u1100\u1161\u11cd',                          // '가ᇍ'
    '\ue0d6': '\u1100\u1161\u11ce',                          // '가ᇎ'
    '\ue0d7': '\u1100\u1161\u11d0',                          // '가ᇐ'
    '\ue0d8': '\u1100\u1161\ud7db',                          // '가ퟛ'
    '\ue0d9': '\u1100\u1161\u11d9',                          // '가ᇙ'
    '\ue0da': '\u1100\u1161\u11dd',                          // '가ᇝ'
    '\ue0db': '\u1100\u1161\u11e1',                          // '가ᇡ'
    '\ue0dc': '\u1100\u1161\u11e2',                          // '가ᇢ'
    '\ue0dd': '\u1100\u1161\u11e4',                          // '가ᇤ'
    '\ue0de': '\u1100\u1161\u11e5',                          // '가ᇥ'
    '\ue0df': '\u1100\u1161\u11e6',                          // '가ᇦ'
    '\ue0e0': '\u1100\u1161\u11e7',                          // '가ᇧ'
    '\ue0e1': '\u1100\u1161\u11ea',                          // '가ᇪ'
    '\ue0e2': '\u1100\u1161\ud7f0',                          // '가ퟰ'
    '\ue0e3': '\u1100\u1161\ud7f1',                          // '가ퟱ'
    '\ue0e4': '\u1100\u1161\u11eb',                          // '가ᇫ'
    '\ue0e5': '\u1100\u1161\u11f1',                          // '가ᇱ'
    '\ue0e6': '\u1100\u1161\u11f0',                          // '가ᇰ'
    '\ue0e7': '\u1100\u1161\u11f1',                          // '가ᇱ'
    '\ue0e8': '\u1100\u1161\u11f9',                          // '가ᇹ'
    '\ue0e9': '\u1100\u1176',                                // 'ᄀᅶ'
    '\ue0ea': '\u1100\u1177',                                // 'ᄀᅷ'
    '\ue0eb': '\u1100\u1162\u11cd',                          // '개ᇍ'
    '\ue0ec': '\u1100\u1162\ud7db',                          // '개ퟛ'
    '\ue0ed': '\u1100\u1162\u11f0',                          // '개ᇰ'
    '\ue0ee': '\u1100\u1163\u11e2',                          // '갸ᇢ'
    '\ue0ef': '\u1100\u1163\u11e6',                          // '갸ᇦ'
    '\ue0f0': '\u1100\u1163\u11f0',                          // '갸ᇰ'
    '\ue0f1': '\u1100\u1163\u11f9',                          // '갸ᇹ'
    '\ue0f2': '\u1100\u1178',                                // 'ᄀᅸ'
    '\ue0f3': '\u1100\u1165\u11c7',                          // '거ᇇ'
    '\ue0f4': '\u1100\u1165\u11d0',                          // '거ᇐ'
    '\ue0f5': '\u1100\u1165\u11d9',                          // '거ᇙ'
    '\ue0f6': '\u1100\u1165\u11dd',                          // '거ᇝ'
    '\ue0f7': '\u1100\u1165\u11e2',                          // '거ᇢ'
    '\ue0f8': '\u1100\u1165\u11e3',                          // '거ᇣ'
    '\ue0f9': '\u1100\u1165\u11e6',                          // '거ᇦ'
    '\ue0fa': '\u1100\u1165\u11e7',                          // '거ᇧ'
    '\ue0fb': '\u1100\u1165\ud7f0',                          // '거ퟰ'
    '\ue0fc': '\u1100\u1165\ud7f2',                          // '거ퟲ'
    '\ue0fd': '\u1100\u1165\u11eb',                          // '거ᇫ'
    '\ue0fe': '\u1100\u1165\u11f0',                          // '거ᇰ'
    '\ue0ff': '\u1100\u1165\u11f9',                          // '거ᇹ'
    '\ue100': '\u1100\u117c',                                // 'ᄀᅼ'
    '\ue101': '\u1100\u117c\u11ab',                          // 'ᄀᅼᆫ'
    '\ue102': '\u1100\u117c\u11af',                          // 'ᄀᅼᆯ'
    '\ue103': '\u1100\u117c\u11b7',                          // 'ᄀᅼᆷ'
    '\ue104': '\u1100\u1166\u11f0',                          // '게ᇰ'
    '\ue105': '\u1100\u1167\ud7d4',                          // '겨ퟔ'
    '\ue106': '\u1100\u1167\u11d9',                          // '겨ᇙ'
    '\ue107': '\u1100\u1167\u11e2',                          // '겨ᇢ'
    '\ue108': '\u1100\u1167\ud7e9',                          // '겨ퟩ'
    '\ue109': '\u1100\u1167\u11e7',                          // '겨ᇧ'
    '\ue10a': '\u1100\u1167\ud7f0',                          // '겨ퟰ'
    '\ue10b': '\u1100\u1167\ud7f1',                          // '겨ퟱ'
    '\ue10c': '\u1100\u1167\ud7f2',                          // '겨ퟲ'
    '\ue10d': '\u1100\u1167\u11eb',                          // '겨ᇫ'
    '\ue10e': '\u1100\u1167\u11f0',                          // '겨ᇰ'
    '\ue10f': '\u1100\u1167\u11f1',                          // '겨ᇱ'
    '\ue110': '\u1100\u1167\u11f9',                          // '겨ᇹ'
    '\ue111': '\u1100\u1168\u11f0',                          // '계ᇰ'
    '\ue112': '\u1100\u1169\u11d0',                          // '고ᇐ'
    '\ue113': '\u1100\u1169\ud7da',                          // '고ퟚ'
    '\ue114': '\u1100\u1169\u11d6',                          // '고ᇖ'
    '\ue115': '\u1100\u1169\u11d9',                          // '고ᇙ'
    '\ue116': '\u1100\u1169\u11da',                          // '고ᇚ'
    '\ue117': '\u1100\u1169\u11dd',                          // '고ᇝ'
    '\ue118': '\u1100\u1169\u11e2',                          // '고ᇢ'
    '\ue119': '\u1100\u1169\u11e6',                          // '고ᇦ'
    '\ue11a': '\u1100\u1169\ud7f0',                          // '고ퟰ'
    '\ue11b': '\u1100\u1169\u11eb',                          // '고ᇫ'
    '\ue11c': '\u1100\u1169\u11f0',                          // '고ᇰ'
    '\ue11d': '\u1100\u1169\u11f1',                          // '고ᇱ'
    '\ue11e': '\u1100\u1169\u11f7',                          // '고ᇷ'
    '\ue11f': '\u1100\u1169\u11f8',                          // '고ᇸ'
    '\ue120': '\u1100\u116a\u11c7',                          // '과ᇇ'
    '\ue121': '\u1100\u116a\u11d9',                          // '과ᇙ'
    '\ue122': '\u1100\u116a\u11f0',                          // '과ᇰ'
    '\ue123': '\u1100\u116a\u11f9',                          // '과ᇹ'
    '\ue124': '\u1100\u11a6',                                // 'ᄀᆦ'
    '\ue125': '\u1100\u11a6\u11bc',                          // 'ᄀᆦᆼ'
    '\ue126': '\u1100\u117f',                                // 'ᄀᅿ'
    '\ue127': '\u1100\u117f\u11ab',                          // 'ᄀᅿᆫ'
    '\ue128': '\u1100\u117f\u11af',                          // 'ᄀᅿᆯ'
    '\ue129': '\u1100\u1182',                                // 'ᄀᆂ'
    '\ue12a': '\u1100\u1182\u11af',                          // 'ᄀᆂᆯ'
    '\ue12b': '\u1100\u116c\u11f0',                          // '괴ᇰ'
    '\ue12c': '\u1100\u116d\u11d9',                          // '교ᇙ'
    '\ue12d': '\u1100\u116d\u11e2',                          // '교ᇢ'
    '\ue12e': '\u1100\u116d\u11f0',                          // '교ᇰ'
    '\ue12f': '\u1100\ud7b2',                                // 'ᄀힲ'
    '\ue130': '\u1100\ud7b3',                                // 'ᄀힳ'
    '\ue131': '\u1100\ud7b3\u11af',                          // 'ᄀힳᆯ'
    '\ue132': '\u1100\u1184',                                // 'ᄀᆄ'
    '\ue133': '\u1100\u1185',                                // 'ᄀᆅ'
    '\ue134': '\u1100\u1185\u11af',                          // 'ᄀᆅᆯ'
    '\ue135': '\u1100\u1185\u11b0',                          // 'ᄀᆅᆰ'
    '\ue136': '\u1100\u1188',                                // 'ᄀᆈ'
    '\ue137': '\u1100\u1188\u11ab',                          // 'ᄀᆈᆫ'
    '\ue138': '\u1100\u116e\u11fd',                          // '구ᇽ'
    '\ue139': '\u1100\u116e\u11c7',                          // '구ᇇ'
    '\ue13a': '\u1100\u116e\u11cd',                          // '구ᇍ'
    '\ue13b': '\u1100\u116e\u11d0',                          // '구ᇐ'
    '\ue13c': '\u1100\u116e\u11d9',                          // '구ᇙ'
    '\ue13d': '\u1100\u116e\u11da',                          // '구ᇚ'
    '\ue13e': '\u1100\u116e\u11dd',                          // '구ᇝ'
    '\ue13f': '\u1100\u116e\u11e2',                          // '구ᇢ'
    '\ue140': '\u1100\u116e\u11e6',                          // '구ᇦ'
    '\ue141': '\u1100\u116e\u11ec',                          // '구ᇬ'
    '\ue142': '\u1100\u116e\u11f0',                          // '구ᇰ'
    '\ue143': '\u1100\u116e\u11ec',                          // '구ᇬ'
    '\ue144': '\u1100\u116e\u11f6',                          // '구ᇶ'
    '\ue145': '\u1100\u116e\u11f9',                          // '구ᇹ'
    '\ue146': '\u1100\u1189',                                // 'ᄀᆉ'
    '\ue147': '\u1100\u1189\u11ab',                          // 'ᄀᆉᆫ'
    '\ue148': '\u1100\u1189\u11b7',                          // 'ᄀᆉᆷ'
    '\ue149': '\u1100\u1189\u11bc',                          // 'ᄀᆉᆼ'
    '\ue14a': '\u1100\u118a',                                // 'ᄀᆊ'
    '\ue14b': '\u1100\u116f\u11c7',                          // '궈ᇇ'
    '\ue14c': '\u1100\u116f\u11d9',                          // '궈ᇙ'
    '\ue14d': '\u1100\u116f\u11f9',                          // '궈ᇹ'
    '\ue14e': '\u1100\u118b',                                // 'ᄀᆋ'
    '\ue14f': '\u1100\u118b\u11ab',                          // 'ᄀᆋᆫ'
    '\ue150': '\u1100\ud7b5',                                // 'ᄀힵ'
    '\ue151': '\u1100\u118c',                                // 'ᄀᆌ'
    '\ue152': '\u1100\u118d',                                // 'ᄀᆍ'
    '\ue153': '\u1100\u118d\u11af',                          // 'ᄀᆍᆯ'
    '\ue154': '\u1100\u1171\u11f0',                          // '귀ᇰ'
    '\ue155': '\u1100\u1171\u11f9',                          // '귀ᇹ'
    '\ue156': '\u1100\u1172\u11d9',                          // '규ᇙ'
    '\ue157': '\u1100\u1172\u11e2',                          // '규ᇢ'
    '\ue158': '\u1100\u1172\u11f0',                          // '규ᇰ'
    '\ue159': '\u1100\u1172\u11f9',                          // '규ᇹ'
    '\ue15a': '\u1100\u118e',                                // 'ᄀᆎ'
    '\ue15b': '\u1100\u118e\u11ab',                          // 'ᄀᆎᆫ'
    '\ue15c': '\u1100\u118f',                                // 'ᄀᆏ'
    '\ue15d': '\u1100\u118f\u11ab',                          // 'ᄀᆏᆫ'
    '\ue15e': '\u1100\u1191',                                // 'ᄀᆑ'
    '\ue15f': '\u1100\u1191\u11a8',                          // 'ᄀᆑᆨ'
    '\ue160': '\u1100\u1191\u11ab',                          // 'ᄀᆑᆫ'
    '\ue161': '\u1100\u1191\u11af',                          // 'ᄀᆑᆯ'
    '\ue162': '\u1100\u1191\u11d9',                          // 'ᄀᆑᇙ'
    '\ue163': '\u1100\u1191\u11bc',                          // 'ᄀᆑᆼ'
    '\ue164': '\u1100\u1191\u11f0',                          // 'ᄀᆑᇰ'
    '\ue165': '\u1100\u1191\u11f9',                          // 'ᄀᆑᇹ'
    '\ue166': '\u1100\u1192',                                // 'ᄀᆒ'
    '\ue167': '\u1100\u1192\u11af',                          // 'ᄀᆒᆯ'
    '\ue168': '\u1100\u1192\u11b0',                          // 'ᄀᆒᆰ'
    '\ue169': '\u1100\u1192\u11bc',                          // 'ᄀᆒᆼ'
    '\ue16a': '\u1100\u1194',                                // 'ᄀᆔ'
    '\ue16b': '\u1100\u1194\u11a8',                          // 'ᄀᆔᆨ'
    '\ue16c': '\u1100\u1194\u11af',                          // 'ᄀᆔᆯ'
    '\ue16d': '\u1100\u1194\u11d9',                          // 'ᄀᆔᇙ'
    '\ue16e': '\u1100\u1194\u11bc',                          // 'ᄀᆔᆼ'
    '\ue16f': '\u1100\u1194\u11f0',                          // 'ᄀᆔᇰ'
    '\ue170': '\u1100\u1173\u11c7',                          // '그ᇇ'
    '\ue171': '\u1100\u1173\ud7cc',                          // '그ퟌ'
    '\ue172': '\u1100\u1173\u11c9',                          // '그ᇉ'
    '\ue173': '\u1100\u1173\ud7d3',                          // '그ퟓ'
    '\ue174': '\u1100\u1173\ud7d4',                          // '그ퟔ'
    '\ue175': '\u1100\u1173\u11cd',                          // '그ᇍ'
    '\ue176': '\u1100\u1173\u11d0',                          // '그ᇐ'
    '\ue177': '\u1100\u1173\u11d9',                          // '그ᇙ'
    '\ue178': '\u1100\u1173\u11da',                          // '그ᇚ'
    '\ue179': '\u1100\u1173\u11dd',                          // '그ᇝ'
    '\ue17a': '\u1100\u1173\u11df',                          // '그ᇟ'
    '\ue17b': '\u1100\u1173\u11e1',                          // '그ᇡ'
    '\ue17c': '\u1100\u1173\u11e2',                          // '그ᇢ'
    '\ue17d': '\u1100\u1173\ud7f0',                          // '그ퟰ'
    '\ue17e': '\u1100\u1173\u11eb',                          // '그ᇫ'
    '\ue17f': '\u1100\u1173\u11f0',                          // '그ᇰ'
    '\ue180': '\u1100\u1173\u11f9',                          // '그ᇹ'
    '\ue181': '\u1100\ud7ba',                                // 'ᄀힺ'
    '\ue182': '\u1100\ud7bb',                                // 'ᄀힻ'
    '\ue183': '\u1100\u1195',                                // 'ᄀᆕ'
    '\ue184': '\u1100\u1196',                                // 'ᄀᆖ'
    '\ue185': '\u1100\u1174\u11d9',                          // '긔ᇙ'
    '\ue186': '\u1100\u1174\u11f9',                          // '긔ᇹ'
    '\ue187': '\u1100\u1175\u11ca',                          // '기ᇊ'
    '\ue188': '\u1100\u1175\ud7cd',                          // '기ퟍ'
    '\ue189': '\u1100\u1175\u11cd',                          // '기ᇍ'
    '\ue18a': '\u1100\u1175\u11d0',                          // '기ᇐ'
    '\ue18b': '\u1100\u1175\u11d9',                          // '기ᇙ'
    '\ue18c': '\u1100\u1175\ud7dc',                          // '기ퟜ'
    '\ue18d': '\u1100\u1175\u11e0',                          // '기ᇠ'
    '\ue18e': '\u1100\u1175\u11e2',                          // '기ᇢ'
    '\ue18f': '\u1100\u1175\ud7e6',                          // '기ퟦ'
    '\ue190': '\u1100\u1175\ud7e8',                          // '기ퟨ'
    '\ue191': '\u1100\u1175\u11e4',                          // '기ᇤ'
    '\ue192': '\u1100\u1175\u11e5',                          // '기ᇥ'
    '\ue193': '\u1100\u1175\u11e6',                          // '기ᇦ'
    '\ue194': '\u1100\u1175\u11e7',                          // '기ᇧ'
    '\ue195': '\u1100\u1175\ud7ec',                          // '기ퟬ'
    '\ue196': '\u1100\u1175\ud7f0',                          // '기ퟰ'
    '\ue197': '\u1100\u1175\u11eb',                          // '기ᇫ'
    '\ue198': '\u1100\u1175\u11f0',                          // '기ᇰ'
    '\ue199': '\u1100\u1175\u11f9',                          // '기ᇹ'
    '\ue19a': '\u1100\u1198',                                // 'ᄀᆘ'
    '\ue19b': '\u1100\u1199',                                // 'ᄀᆙ'
    '\ue19c': '\u1100\u1199\u11ab',                          // 'ᄀᆙᆫ'
    '\ue19d': '\u1100\u1199\u11bc',                          // 'ᄀᆙᆼ'
    '\ue19e': '\u1100\ud7bf',                                // 'ᄀힿ'
    '\ue19f': '\u1100\ud7c0',                                // 'ᄀퟀ'
    '\ue1a0': '\u1100\ud7c2',                                // 'ᄀퟂ'
    '\ue1a1': '\u1100\u119b',                                // 'ᄀᆛ'
    '\ue1a2': '\u1100\ud7c3',                                // 'ᄀퟃ'
    '\ue1a3': '\u1100\ud7c3\u11ab',                          // 'ᄀퟃᆫ'
    '\ue1a4': '\u1100\u119c',                                // 'ᄀᆜ'
    '\ue1a5': '\u1100\ud7c4',                                // 'ᄀퟄ'
    '\ue1a6': '\u1100\u119d',                                // 'ᄀᆝ'
    '\ue1a7': '\u1100\u119e',                                // 'ᄀᆞ'
    '\ue1a8': '\u1100\u119e\u11a8',                          // 'ᄀᆞᆨ'
    '\ue1a9': '\u1100\u119e\u11a9',                          // 'ᄀᆞᆩ'
    '\ue1aa': '\u1100\u119e\u11ab',                          // 'ᄀᆞᆫ'
    '\ue1ab': '\u1100\u119e\u11ae',                          // 'ᄀᆞᆮ'
    '\ue1ac': '\u1100\u119e\ud7cf',                          // 'ᄀᆞퟏ'
    '\ue1ad': '\u1100\u119e\u11af',                          // 'ᄀᆞᆯ'
    '\ue1ae': '\u1100\u119e\u11b0',                          // 'ᄀᆞᆰ'
    '\ue1af': '\u1100\u119e\u11cd',                          // 'ᄀᆞᇍ'
    '\ue1b0': '\u1100\u119e\u11d0',                          // 'ᄀᆞᇐ'
    '\ue1b1': '\u1100\u119e\u11b1',                          // 'ᄀᆞᆱ'
    '\ue1b2': '\u1100\u119e\u11b2',                          // 'ᄀᆞᆲ'
    '\ue1b3': '\u1100\u119e\u11d3',                          // 'ᄀᆞᇓ'
    '\ue1b4': '\u1100\u119e\ud7da',                          // 'ᄀᆞퟚ'
    '\ue1b5': '\u1100\u119e\u11d5',                          // 'ᄀᆞᇕ'
    '\ue1b6': '\u1100\u119e\u11b3',                          // 'ᄀᆞᆳ'
    '\ue1b7': '\u1100\u119e\u11b5',                          // 'ᄀᆞᆵ'
    '\ue1b8': '\u1100\u119e\u11b6',                          // 'ᄀᆞᆶ'
    '\ue1b9': '\u1100\u119e\u11d9',                          // 'ᄀᆞᇙ'
    '\ue1ba': '\u1100\u119e\u11b7',                          // 'ᄀᆞᆷ'
    '\ue1bb': '\u1100\u119e\u11e2',                          // 'ᄀᆞᇢ'
    '\ue1bc': '\u1100\u119e\u11b8',                          // 'ᄀᆞᆸ'
    '\ue1bd': '\u1100\u119e\u11e3',                          // 'ᄀᆞᇣ'
    '\ue1be': '\u1100\u119e\u11e6',                          // 'ᄀᆞᇦ'
    '\ue1bf': '\u1100\u119e\u11ba',                          // 'ᄀᆞᆺ'
    '\ue1c0': '\u1100\u119e\u11e7',                          // 'ᄀᆞᇧ'
    '\ue1c1': '\u1100\u119e\u11ea',                          // 'ᄀᆞᇪ'
    '\ue1c2': '\u1100\u119e\u11eb',                          // 'ᄀᆞᇫ'
    '\ue1c3': '\u1100\u119e\u11bc',                          // 'ᄀᆞᆼ'
    '\ue1c4': '\u1100\u119e\u11f0',                          // 'ᄀᆞᇰ'
    '\ue1c5': '\u1100\u119e\u11f1',                          // 'ᄀᆞᇱ'
    '\ue1c6': '\u1100\u119e\u11bd',                          // 'ᄀᆞᆽ'
    '\ue1c7': '\u1100\u119e\u11be',                          // 'ᄀᆞᆾ'
    '\ue1c8': '\u1100\u119e\u11c0',                          // 'ᄀᆞᇀ'
    '\ue1c9': '\u1100\u119e\u11c2',                          // 'ᄀᆞᇂ'
    '\ue1ca': '\u1100\u119f',                                // 'ᄀᆟ'
    '\ue1cb': '\u1100\u119f\u11ab',                          // 'ᄀᆟᆫ'
    '\ue1cc': '\u1100\u119f\u11bc',                          // 'ᄀᆟᆼ'
    '\ue1cd': '\u1100\u11a1',                                // 'ᄀᆡ'
    '\ue1ce': '\u1100\u11a1\u11a8',                          // 'ᄀᆡᆨ'
    '\ue1cf': '\u1100\u11a1\u11ab',                          // 'ᄀᆡᆫ'
    '\ue1d0': '\u1100\u11a1\u11af',                          // 'ᄀᆡᆯ'
    '\ue1d1': '\u1100\u11a1\u11b7',                          // 'ᄀᆡᆷ'
    '\ue1d2': '\u1100\u11a1\u11b8',                          // 'ᄀᆡᆸ'
    '\ue1d3': '\u1100\u11a1\u11ba',                          // 'ᄀᆡᆺ'
    '\ue1d4': '\u1100\u11a1\u11bc',                          // 'ᄀᆡᆼ'
    '\ue1d5': '\u1100\u11a1\u11f0',                          // 'ᄀᆡᇰ'
    '\ue1d6': '\u1100\u11a1\u11f1',                          // 'ᄀᆡᇱ'
    '\ue1d7': '\u1101\u1161\u11f0',                          // '까ᇰ'
    '\ue1d8': '\u1101\u1176',                                // 'ᄁᅶ'
    '\ue1d9': '\u1101\u1163\u11e2',                          // '꺄ᇢ'
    '\ue1da': '\u1101\u1165\u11d9',                          // '꺼ᇙ'
    '\ue1db': '\u1101\u1165\u11e2',                          // '꺼ᇢ'
    '\ue1dc': '\u1101\u1165\u11e6',                          // '꺼ᇦ'
    '\ue1dd': '\u1101\u1165\u11ec',                          // '꺼ᇬ'
    '\ue1de': '\u1101\u1165\u11ed',                          // '꺼ᇭ'
    '\ue1df': '\u1101\u117b',                                // 'ᄁᅻ'
    '\ue1e0': '\u1101\u1167\u11f0',                          // '껴ᇰ'
    '\ue1e1': '\u1101\u1169\u11e2',                          // '꼬ᇢ'
    '\ue1e2': '\u1101\u1169\u11f0',                          // '꼬ᇰ'
    '\ue1e3': '\u1101\u116a\u11f0',                          // '꽈ᇰ'
    '\ue1e4': '\u1101\u116d\u11e2',                          // '꾜ᇢ'
    '\ue1e5': '\u1101\u116d\u11f0',                          // '꾜ᇰ'
    '\ue1e6': '\u1101\u1188',                                // 'ᄁᆈ'
    '\ue1e7': '\u1101\u116e\u11d9',                          // '꾸ᇙ'
    '\ue1e8': '\u1101\u116e\u11da',                          // '꾸ᇚ'
    '\ue1e9': '\u1101\u116e\u11e2',                          // '꾸ᇢ'
    '\ue1ea': '\u1101\u116e\u11f0',                          // '꾸ᇰ'
    '\ue1eb': '\u1101\u1189',                                // 'ᄁᆉ'
    '\ue1ec': '\u1101\u116f\u11d9',                          // '꿔ᇙ'
    '\ue1ed': '\u1101\u116f\u11f0',                          // '꿔ᇰ'
    '\ue1ee': '\u1101\ud7b5',                                // 'ᄁힵ'
    '\ue1ef': '\u1101\u118c',                                // 'ᄁᆌ'
    '\ue1f0': '\u1101\u1172\u11d9',                          // '뀨ᇙ'
    '\ue1f1': '\u1101\u1172\u11e2',                          // '뀨ᇢ'
    '\ue1f2': '\u1101\u1172\u11f0',                          // '뀨ᇰ'
    '\ue1f3': '\u1101\u1191',                                // 'ᄁᆑ'
    '\ue1f4': '\u1101\u1191\u11ab',                          // 'ᄁᆑᆫ'
    '\ue1f5': '\u1101\u1191\u11bc',                          // 'ᄁᆑᆼ'
    '\ue1f6': '\u1101\u1191\u11f0',                          // 'ᄁᆑᇰ'
    '\ue1f7': '\u1101\u1191\u11f9',                          // 'ᄁᆑᇹ'
    '\ue1f8': '\u1101\u1194',                                // 'ᄁᆔ'
    '\ue1f9': '\u1101\u1194\u11bc',                          // 'ᄁᆔᆼ'
    '\ue1fa': '\u1101\u1173\u11d9',                          // '끄ᇙ'
    '\ue1fb': '\u1101\u1173\u11f0',                          // '끄ᇰ'
    '\ue1fc': '\u1101\u1173\u11f6',                          // '끄ᇶ'
    '\ue1fd': '\u1101\u1174\u11f0',                          // '끠ᇰ'
    '\ue1fe': '\u1101\u1175\u11e2',                          // '끼ᇢ'
    '\ue1ff': '\u1101\u1175\u11f0',                          // '끼ᇰ'
    '\ue200': '\u1101\u1175\u11f9',                          // '끼ᇹ'
    '\ue201': '\u1101\u1198',                                // 'ᄁᆘ'
    '\ue202': '\u1101\u119e',                                // 'ᄁᆞ'
    '\ue203': '\u1101\u11a1',                                // 'ᄁᆡ'
    '\ue204': '\u115a\u1165',                                // 'ᅚᅥ'
    '\ue205': '\u115a\u1173',                                // 'ᅚᅳ'
    '\ue206': '\u1102\u1161\u11c4',                          // '나ᇄ'
    '\ue207': '\u1102\u1161\u11c7',                          // '나ᇇ'
    '\ue208': '\u1102\u1161\ud7cf',                          // '나ퟏ'
    '\ue209': '\u1102\u1161\u11cd',                          // '나ᇍ'
    '\ue20a': '\u1102\u1161\u11d0',                          // '나ᇐ'
    '\ue20b': '\u1102\u1161\u11d9',                          // '나ᇙ'
    '\ue20c': '\u1102\u1161\u11da',                          // '나ᇚ'
    '\ue20d': '\u1102\u1161\u11dc',                          // '나ᇜ'
    '\ue20e': '\u1102\u1161\u11dd',                          // '나ᇝ'
    '\ue20f': '\u1102\u1161\u11e2',                          // '나ᇢ'
    '\ue210': '\u1102\u1161\u11e6',                          // '나ᇦ'
    '\ue211': '\u1102\u1161\u11e7',                          // '나ᇧ'
    '\ue212': '\u1102\u1161\u11ea',                          // '나ᇪ'
    '\ue213': '\u1102\u1161\ud7ef',                          // '나ퟯ'
    '\ue214': '\u1102\u1161\ud7f2',                          // '나ퟲ'
    '\ue215': '\u1102\u1161\u11eb',                          // '나ᇫ'
    '\ue216': '\u1102\u1161\u11ec',                          // '나ᇬ'
    '\ue217': '\u1102\u1161\u11f0',                          // '나ᇰ'
    '\ue218': '\u1102\u1161\ud7f7',                          // '나ퟷ'
    '\ue219': '\u1102\u1161\ud7f8',                          // '나ퟸ'
    '\ue21a': '\u1102\u1161\u11f5',                          // '나ᇵ'
    '\ue21b': '\u1102\u1161\u11f9',                          // '나ᇹ'
    '\ue21c': '\u1102\u1176',                                // 'ᄂᅶ'
    '\ue21d': '\u1102\u1177',                                // 'ᄂᅷ'
    '\ue21e': '\u1102\u1162\ud7cf',                          // '내ퟏ'
    '\ue21f': '\u1102\u1162\u11d9',                          // '내ᇙ'
    '\ue220': '\u1102\u1162\ud7e3',                          // '내ퟣ'
    '\ue221': '\u1102\u1162\u11e3',                          // '내ᇣ'
    '\ue222': '\u1102\u1163\u11e2',                          // '냐ᇢ'
    '\ue223': '\u1102\u1163\u11f0',                          // '냐ᇰ'
    '\ue224': '\u1102\u1178',                                // 'ᄂᅸ'
    '\ue225': '\u1102\u1165\u11d0',                          // '너ᇐ'
    '\ue226': '\u1102\u1165\u11da',                          // '너ᇚ'
    '\ue227': '\u1102\u1165\u11db',                          // '너ᇛ'
    '\ue228': '\u1102\u1165\u11dd',                          // '너ᇝ'
    '\ue229': '\u1102\u1165\u11e3',                          // '너ᇣ'
    '\ue22a': '\u1102\u1165\u11e5',                          // '너ᇥ'
    '\ue22b': '\u1102\u1165\u11e9',                          // '너ᇩ'
    '\ue22c': '\u1102\u1165\ud7f2',                          // '너ퟲ'
    '\ue22d': '\u1102\u1165\u11f0',                          // '너ᇰ'
    '\ue22e': '\u1102\u117b',                                // 'ᄂᅻ'
    '\ue22f': '\u1102\u117b\u11b7',                          // 'ᄂᅻᆷ'
    '\ue230': '\u1102\u117a',                                // 'ᄂᅺ'
    '\ue231': '\u1102\u117a\u11a8',                          // 'ᄂᅺᆨ'
    '\ue232': '\u1102\u117a\u11c2',                          // 'ᄂᅺᇂ'
    '\ue233': '\u1102\u1167\u11fd',                          // '녀ᇽ'
    '\ue234': '\u1102\u1167\u11fe',                          // '녀ᇾ'
    '\ue235': '\u1102\u1167\u11c5',                          // '녀ᇅ'
    '\ue236': '\u1102\u1167\u11ff',                          // '녀ᇿ'
    '\ue237': '\u1102\u1167\u11c7',                          // '녀ᇇ'
    '\ue238': '\u1102\u1167\u11d9',                          // '녀ᇙ'
    '\ue239': '\u1102\u1167\u11e7',                          // '녀ᇧ'
    '\ue23a': '\u1102\u1167\ud7f2',                          // '녀ퟲ'
    '\ue23b': '\u1102\u1167\u11f0',                          // '녀ᇰ'
    '\ue23c': '\u1102\u1167\u11f9',                          // '녀ᇹ'
    '\ue23d': '\u1102\u1169\u11cd',                          // '노ᇍ'
    '\ue23e': '\u1102\u1169\u11d0',                          // '노ᇐ'
    '\ue23f': '\u1102\u1169\u11d9',                          // '노ᇙ'
    '\ue240': '\u1102\u1169\u11e2',                          // '노ᇢ'
    '\ue241': '\u1102\u1169\u11e4',                          // '노ᇤ'
    '\ue242': '\u1102\u1169\u11e5',                          // '노ᇥ'
    '\ue243': '\u1102\u1169\u11e6',                          // '노ᇦ'
    '\ue244': '\u1102\u1169\ud7f2',                          // '노ퟲ'
    '\ue245': '\u1102\u1169\u11eb',                          // '노ᇫ'
    '\ue246': '\u1102\u1169\u11f0',                          // '노ᇰ'
    '\ue247': '\u1102\u1169\ud7f6',                          // '노ퟶ'
    '\ue248': '\u1102\u1169\u11f3',                          // '노ᇳ'
    '\ue249': '\u1102\u116a\u11d9',                          // '놔ᇙ'
    '\ue24a': '\u1102\u117f',                                // 'ᄂᅿ'
    '\ue24b': '\u1102\u116c\u11f0',                          // '뇌ᇰ'
    '\ue24c': '\u1102\u116d\u11d9',                          // '뇨ᇙ'
    '\ue24d': '\u1102\u116d\u11e2',                          // '뇨ᇢ'
    '\ue24e': '\u1102\u116d\u11f0',                          // '뇨ᇰ'
    '\ue24f': '\u1102\ud7b2',                                // 'ᄂힲ'
    '\ue250': '\u1102\ud7b2\u11bc',                          // 'ᄂힲᆼ'
    '\ue251': '\u1102\u1188',                                // 'ᄂᆈ'
    '\ue252': '\u1102\u116e\u11c6',                          // '누ᇆ'
    '\ue253': '\u1102\u116e\u11c7',                          // '누ᇇ'
    '\ue254': '\u1102\u116e\u11c8',                          // '누ᇈ'
    '\ue255': '\u1102\u116e\u11d0',                          // '누ᇐ'
    '\ue256': '\u1102\u116e\u11dd',                          // '누ᇝ'
    '\ue257': '\u1102\u116e\u11e2',                          // '누ᇢ'
    '\ue258': '\u1102\u116e\u11e6',                          // '누ᇦ'
    '\ue259': '\u1102\u116e\u11f5',                          // '누ᇵ'
    '\ue25a': '\u1102\u1189',                                // 'ᄂᆉ'
    '\ue25b': '\u1102\u1189\u11ab',                          // 'ᄂᆉᆫ'
    '\ue25c': '\u1102\ud7b5',                                // 'ᄂힵ'
    '\ue25d': '\u1102\u118c',                                // 'ᄂᆌ'
    '\ue25e': '\u1102\u1171\u11eb',                          // '뉘ᇫ'
    '\ue25f': '\u1102\ud7b6',                                // 'ᄂힶ'
    '\ue260': '\u1102\u1172\u11d9',                          // '뉴ᇙ'
    '\ue261': '\u1102\u1172\u11e2',                          // '뉴ᇢ'
    '\ue262': '\u1102\u1172\u11f0',                          // '뉴ᇰ'
    '\ue263': '\u1102\u118f',                                // 'ᄂᆏ'
    '\ue264': '\u1102\u1194',                                // 'ᄂᆔ'
    '\ue265': '\u1102\u1194\u11ab',                          // 'ᄂᆔᆫ'
    '\ue266': '\u1102\u1194\u11bc',                          // 'ᄂᆔᆼ'
    '\ue267': '\u1102\u1173\u11c3',                          // '느ᇃ'
    '\ue268': '\u1102\u1173\u11cd',                          // '느ᇍ'
    '\ue269': '\u1102\u1173\u11d9',                          // '느ᇙ'
    '\ue26a': '\u1102\u1173\u11f0',                          // '느ᇰ'
    '\ue26b': '\u1102\u1173\ud7f6',                          // '느ퟶ'
    '\ue26c': '\u1102\u1196',                                // 'ᄂᆖ'
    '\ue26d': '\u1102\u1174\u11f0',                          // '늬ᇰ'
    '\ue26e': '\u1102\u1197',                                // 'ᄂᆗ'
    '\ue26f': '\u1102\u1175\u11c3',                          // '니ᇃ'
    '\ue270': '\u1102\u1175\u11ce',                          // '니ᇎ'
    '\ue271': '\u1102\u1175\u11d0',                          // '니ᇐ'
    '\ue272': '\u1102\u1175\ud7d9',                          // '니ퟙ'
    '\ue273': '\u1102\u1175\u11d9',                          // '니ᇙ'
    '\ue274': '\u1102\u1175\u11dd',                          // '니ᇝ'
    '\ue275': '\u1102\u1175\u11e2',                          // '니ᇢ'
    '\ue276': '\u1102\u1175\u11e4',                          // '니ᇤ'
    '\ue277': '\u1102\u1175\u11e5',                          // '니ᇥ'
    '\ue278': '\u1102\u1175\u11e6',                          // '니ᇦ'
    '\ue279': '\u1102\u1175\u11eb',                          // '니ᇫ'
    '\ue27a': '\u1102\u1175\u11f0',                          // '니ᇰ'
    '\ue27b': '\u1102\u1175\u11f9',                          // '니ᇹ'
    '\ue27c': '\u1102\u1198',                                // 'ᄂᆘ'
    '\ue27d': '\u1102\u1199',                                // 'ᄂᆙ'
    '\ue27e': '\u1102\u1199\u11ab',                          // 'ᄂᆙᆫ'
    '\ue27f': '\u1102\u1199\u11bc',                          // 'ᄂᆙᆼ'
    '\ue280': '\u1102\ud7bf',                                // 'ᄂힿ'
    '\ue281': '\u1102\ud7c0',                                // 'ᄂퟀ'
    '\ue282': '\u1102\u119b',                                // 'ᄂᆛ'
    '\ue283': '\u1102\u119e',                                // 'ᄂᆞ'
    '\ue284': '\u1102\u119e\u11a8',                          // 'ᄂᆞᆨ'
    '\ue285': '\u1102\u119e\u11ab',                          // 'ᄂᆞᆫ'
    '\ue286': '\u1102\u119e\u11ae',                          // 'ᄂᆞᆮ'
    '\ue287': '\u1102\u119e\ud7d3',                          // 'ᄂᆞퟓ'
    '\ue288': '\u1102\u119e\u11af',                          // 'ᄂᆞᆯ'
    '\ue289': '\u1102\u119e\u11b0',                          // 'ᄂᆞᆰ'
    '\ue28a': '\u1102\u119e\u11b1',                          // 'ᄂᆞᆱ'
    '\ue28b': '\u1102\u119e\u11b2',                          // 'ᄂᆞᆲ'
    '\ue28c': '\u1102\u119e\u11b3',                          // 'ᄂᆞᆳ'
    '\ue28d': '\u1102\u119e\u11d7',                          // 'ᄂᆞᇗ'
    '\ue28e': '\u1102\u119e\u11b6',                          // 'ᄂᆞᆶ'
    '\ue28f': '\u1102\u119e\u11d9',                          // 'ᄂᆞᇙ'
    '\ue290': '\u1102\u119e\u11b7',                          // 'ᄂᆞᆷ'
    '\ue291': '\u1102\u119e\u11b8',                          // 'ᄂᆞᆸ'
    '\ue292': '\u1102\u119e\u11ba',                          // 'ᄂᆞᆺ'
    '\ue293': '\u1102\u119e\ud7f0',                          // 'ᄂᆞퟰ'
    '\ue294': '\u1102\u119e\u11eb',                          // 'ᄂᆞᇫ'
    '\ue295': '\u1102\u119e\u11bc',                          // 'ᄂᆞᆼ'
    '\ue296': '\u1102\u119e\u11f0',                          // 'ᄂᆞᇰ'
    '\ue297': '\u1102\u119e\u11bd',                          // 'ᄂᆞᆽ'
    '\ue298': '\u1102\u119e\u11be',                          // 'ᄂᆞᆾ'
    '\ue299': '\u1102\u11a1',                                // 'ᄂᆡ'
    '\ue29a': '\u1102\u11a1\u11a8',                          // 'ᄂᆡᆨ'
    '\ue29b': '\u1102\u11a1\u11ab',                          // 'ᄂᆡᆫ'
    '\ue29c': '\u1102\u11a1\u11ae',                          // 'ᄂᆡᆮ'
    '\ue29d': '\u1102\u11a1\u11af',                          // 'ᄂᆡᆯ'
    '\ue29e': '\u1102\u11a1\u11b7',                          // 'ᄂᆡᆷ'
    '\ue29f': '\u1102\u11a1\u11b8',                          // 'ᄂᆡᆸ'
    '\ue2a0': '\u1102\u11a1\u11ba',                          // 'ᄂᆡᆺ'
    '\ue2a1': '\u1102\u11a1\u11e8',                          // 'ᄂᆡᇨ'
    '\ue2a2': '\u1102\u11a1\u11bc',                          // 'ᄂᆡᆼ'
    '\ue2a3': '\u1102\u11a1\u11f0',                          // 'ᄂᆡᇰ'
    '\ue2a4': '\u1102\u11a1\u11c2',                          // 'ᄂᆡᇂ'
    '\ue2a5': '\u1113\u1161',                                // 'ᄓᅡ'
    '\ue2a6': '\u1113\u1162',                                // 'ᄓᅢ'
    '\ue2a7': '\u1113\u1163',                                // 'ᄓᅣ'
    '\ue2a8': '\u1113\u1165',                                // 'ᄓᅥ'
    '\ue2a9': '\u1113\u1166',                                // 'ᄓᅦ'
    '\ue2aa': '\u1113\u1167',                                // 'ᄓᅧ'
    '\ue2ab': '\u1113\u1168',                                // 'ᄓᅨ'
    '\ue2ac': '\u1113\u1168\u11ab',                          // 'ᄓᅨᆫ'
    '\ue2ad': '\u1113\u1169',                                // 'ᄓᅩ'
    '\ue2ae': '\u1113\u116a',                                // 'ᄓᅪ'
    '\ue2af': '\u1113\u116b',                                // 'ᄓᅫ'
    '\ue2b0': '\u1113\u116c',                                // 'ᄓᅬ'
    '\ue2b1': '\u1113\u116d',                                // 'ᄓᅭ'
    '\ue2b2': '\u1113\u116e',                                // 'ᄓᅮ'
    '\ue2b3': '\u1113\u116f',                                // 'ᄓᅯ'
    '\ue2b4': '\u1113\u1170',                                // 'ᄓᅰ'
    '\ue2b5': '\u1113\ud7b5',                                // 'ᄓힵ'
    '\ue2b6': '\u1113\u118c',                                // 'ᄓᆌ'
    '\ue2b7': '\u1113\u1171',                                // 'ᄓᅱ'
    '\ue2b8': '\u1113\u1172',                                // 'ᄓᅲ'
    '\ue2b9': '\u1113\u1194',                                // 'ᄓᆔ'
    '\ue2ba': '\u1113\u1173',                                // 'ᄓᅳ'
    '\ue2bb': '\u1113\u1174',                                // 'ᄓᅴ'
    '\ue2bc': '\u1113\u1175',                                // 'ᄓᅵ'
    '\ue2bd': '\u1113\u1198',                                // 'ᄓᆘ'
    '\ue2be': '\u1113\u119e',                                // 'ᄓᆞ'
    '\ue2bf': '\u1113\u11a1',                                // 'ᄓᆡ'
    '\ue2c0': '\u1114\u1161',                                // 'ᄔᅡ'
    '\ue2c1': '\u1114\u1163',                                // 'ᄔᅣ'
    '\ue2c2': '\u1114\u1163\u11bc',                          // 'ᄔᅣᆼ'
    '\ue2c3': '\u1114\u1165',                                // 'ᄔᅥ'
    '\ue2c4': '\u1114\u1166',                                // 'ᄔᅦ'
    '\ue2c5': '\u1114\u1169',                                // 'ᄔᅩ'
    '\ue2c6': '\u1114\u116e',                                // 'ᄔᅮ'
    '\ue2c7': '\u1114\u1173',                                // 'ᄔᅳ'
    '\ue2c8': '\u1114\u1173\u11ab',                          // 'ᄔᅳᆫ'
    '\ue2c9': '\u1114\u119e',                                // 'ᄔᆞ'
    '\ue2ca': '\u1114\u119e\u11ab',                          // 'ᄔᆞᆫ'
    '\ue2cb': '\u1115\u1161',                                // 'ᄕᅡ'
    '\ue2cc': '\u1115\u1161\u11ab',                          // 'ᄕᅡᆫ'
    '\ue2cd': '\u1115\u1162',                                // 'ᄕᅢ'
    '\ue2ce': '\u1115\u1163',                                // 'ᄕᅣ'
    '\ue2cf': '\u1115\u1165',                                // 'ᄕᅥ'
    '\ue2d0': '\u1115\u1166',                                // 'ᄕᅦ'
    '\ue2d1': '\u1115\u1166\u11ab',                          // 'ᄕᅦᆫ'
    '\ue2d2': '\u1115\u1167',                                // 'ᄕᅧ'
    '\ue2d3': '\u1115\u1168',                                // 'ᄕᅨ'
    '\ue2d4': '\u1115\u1169',                                // 'ᄕᅩ'
    '\ue2d5': '\u1115\u1169\u11ab',                          // 'ᄕᅩᆫ'
    '\ue2d6': '\u1115\u116a',                                // 'ᄕᅪ'
    '\ue2d7': '\u1115\u116b',                                // 'ᄕᅫ'
    '\ue2d8': '\u1115\u116c',                                // 'ᄕᅬ'
    '\ue2d9': '\u1115\u116d',                                // 'ᄕᅭ'
    '\ue2da': '\u1115\u116e',                                // 'ᄕᅮ'
    '\ue2db': '\u1115\u116f',                                // 'ᄕᅯ'
    '\ue2dc': '\u1115\u1170',                                // 'ᄕᅰ'
    '\ue2dd': '\u1115\ud7b5',                                // 'ᄕힵ'
    '\ue2de': '\u1115\u118c',                                // 'ᄕᆌ'
    '\ue2df': '\u1115\u1171',                                // 'ᄕᅱ'
    '\ue2e0': '\u1115\u1172',                                // 'ᄕᅲ'
    '\ue2e1': '\u1115\u1194',                                // 'ᄕᆔ'
    '\ue2e2': '\u1115\u1173',                                // 'ᄕᅳ'
    '\ue2e3': '\u1115\u1174',                                // 'ᄕᅴ'
    '\ue2e4': '\u1115\u1175',                                // 'ᄕᅵ'
    '\ue2e5': '\u1115\u1198',                                // 'ᄕᆘ'
    '\ue2e6': '\u1115\u119e',                                // 'ᄕᆞ'
    '\ue2e7': '\u1115\u11a1',                                // 'ᄕᆡ'
    '\ue2e8': '\u1116\u1161',                                // 'ᄖᅡ'
    '\ue2e9': '\u1116\u1162',                                // 'ᄖᅢ'
    '\ue2ea': '\u1116\u1163',                                // 'ᄖᅣ'
    '\ue2eb': '\u1116\u1165',                                // 'ᄖᅥ'
    '\ue2ec': '\u1116\u1166',                                // 'ᄖᅦ'
    '\ue2ed': '\u1116\u1167',                                // 'ᄖᅧ'
    '\ue2ee': '\u1116\u1168',                                // 'ᄖᅨ'
    '\ue2ef': '\u1116\u1169',                                // 'ᄖᅩ'
    '\ue2f0': '\u1116\u116a',                                // 'ᄖᅪ'
    '\ue2f1': '\u1116\u116b',                                // 'ᄖᅫ'
    '\ue2f2': '\u1116\u116c',                                // 'ᄖᅬ'
    '\ue2f3': '\u1116\u116d',                                // 'ᄖᅭ'
    '\ue2f4': '\u1116\u116e',                                // 'ᄖᅮ'
    '\ue2f5': '\u1116\u116f',                                // 'ᄖᅯ'
    '\ue2f6': '\u1116\u1170',                                // 'ᄖᅰ'
    '\ue2f7': '\u1116\ud7b5',                                // 'ᄖힵ'
    '\ue2f8': '\u1116\u118c',                                // 'ᄖᆌ'
    '\ue2f9': '\u1116\u1171',                                // 'ᄖᅱ'
    '\ue2fa': '\u1116\u1172',                                // 'ᄖᅲ'
    '\ue2fb': '\u1116\u1194',                                // 'ᄖᆔ'
    '\ue2fc': '\u1116\u1173',                                // 'ᄖᅳ'
    '\ue2fd': '\u1116\u1174',                                // 'ᄖᅴ'
    '\ue2fe': '\u1116\u1175',                                // 'ᄖᅵ'
    '\ue2ff': '\u1116\u1198',                                // 'ᄖᆘ'
    '\ue300': '\u1116\u119e',                                // 'ᄖᆞ'
    '\ue301': '\u1116\u11a1',                                // 'ᄖᆡ'
    '\ue302': '\u115b\u1175',                                // 'ᅛᅵ'
    '\ue303': '\u115b\u1198',                                // 'ᅛᆘ'
    '\ue304': '\u115c\u1173',                                // 'ᅜᅳ'
    '\ue305': '\u115c\u1175',                                // 'ᅜᅵ'
    '\ue306': '\u115d\u1163',                                // 'ᅝᅣ'
    '\ue307': '\u115d\u1163\u11bc',                          // 'ᅝᅣᆼ'
    '\ue308': '\u115d\u1169',                                // 'ᅝᅩ'
    '\ue309': '\u115d\u1172',                                // 'ᅝᅲ'
    '\ue30a': '\u115d\u1172\u11ab',                          // 'ᅝᅲᆫ'
    '\ue30b': '\u115d\u1175',                                // 'ᅝᅵ'
    '\ue30c': '\u115d\u1175\u11bc',                          // 'ᅝᅵᆼ'
    '\ue30d': '\u1103\u1161\u11c7',                          // '다ᇇ'
    '\ue30e': '\u1103\u1161\u11ca',                          // '다ᇊ'
    '\ue30f': '\u1103\u1161\ud7cd',                          // '다ퟍ'
    '\ue310': '\u1103\u1161\u11cd',                          // '다ᇍ'
    '\ue311': '\u1103\u1161\u11d0',                          // '다ᇐ'
    '\ue312': '\u1103\u1161\u11d9',                          // '다ᇙ'
    '\ue313': '\u1103\u1161\u11db',                          // '다ᇛ'
    '\ue314': '\u1103\u1161\u11dd',                          // '다ᇝ'
    '\ue315': '\u1103\u1161\u11e2',                          // '다ᇢ'
    '\ue316': '\u1103\u1161\u11e5',                          // '다ᇥ'
    '\ue317': '\u1103\u1161\u11e6',                          // '다ᇦ'
    '\ue318': '\u1103\u1161\u11e7',                          // '다ᇧ'
    '\ue319': '\u1103\u1161\ud7f2',                          // '다ퟲ'
    '\ue31a': '\u1103\u1161\u11eb',                          // '다ᇫ'
    '\ue31b': '\u1103\u1161\u11f0',                          // '다ᇰ'
    '\ue31c': '\u1103\u1161\u11f1',                          // '다ᇱ'
    '\ue31d': '\u1103\u1161\ud7f6',                          // '다ퟶ'
    '\ue31e': '\u1103\u1161\u11f9',                          // '다ᇹ'
    '\ue31f': '\u1103\u1176',                                // 'ᄃᅶ'
    '\ue320': '\u1103\u1176\u11af',                          // 'ᄃᅶᆯ'
    '\ue321': '\u1103\u1162\u11f0',                          // '대ᇰ'
    '\ue322': '\u1103\u1163\u11e2',                          // '댜ᇢ'
    '\ue323': '\u1103\u1163\u11f0',                          // '댜ᇰ'
    '\ue324': '\u1103\u1163\u11f1',                          // '댜ᇱ'
    '\ue325': '\u1103\u1178',                                // 'ᄃᅸ'
    '\ue326': '\u1103\u1165\u11dd',                          // '더ᇝ'
    '\ue327': '\u1103\u1165\u11e4',                          // '더ᇤ'
    '\ue328': '\u1103\u1165\u11e6',                          // '더ᇦ'
    '\ue329': '\u1103\u1165\u11eb',                          // '더ᇫ'
    '\ue32a': '\u1103\u1165\u11f0',                          // '더ᇰ'
    '\ue32b': '\u1103\u117a',                                // 'ᄃᅺ'
    '\ue32c': '\u1103\u117b',                                // 'ᄃᅻ'
    '\ue32d': '\u1103\u117b\u11af',                          // 'ᄃᅻᆯ'
    '\ue32e': '\u1103\u117c',                                // 'ᄃᅼ'
    '\ue32f': '\u1103\u117c\u11af',                          // 'ᄃᅼᆯ'
    '\ue330': '\u1103\u117c\u11b7',                          // 'ᄃᅼᆷ'
    '\ue331': '\u1103\u1166\u11c6',                          // '데ᇆ'
    '\ue332': '\u1103\u1166\ud7e3',                          // '데ퟣ'
    '\ue333': '\u1103\u1167\u11d9',                          // '뎌ᇙ'
    '\ue334': '\u1103\u1167\u11e2',                          // '뎌ᇢ'
    '\ue335': '\u1103\u1167\u11f0',                          // '뎌ᇰ'
    '\ue336': '\u1103\u1167\u11f9',                          // '뎌ᇹ'
    '\ue337': '\u1103\u1169\u11c6',                          // '도ᇆ'
    '\ue338': '\u1103\u1169\u11ca',                          // '도ᇊ'
    '\ue339': '\u1103\u1169\ud7d4',                          // '도ퟔ'
    '\ue33a': '\u1103\u1169\u11d0',                          // '도ᇐ'
    '\ue33b': '\u1103\u1169\u11d6',                          // '도ᇖ'
    '\ue33c': '\u1103\u1169\u11d8',                          // '도ᇘ'
    '\ue33d': '\u1103\u1169\u11d9',                          // '도ᇙ'
    '\ue33e': '\u1103\u1169\u11e2',                          // '도ᇢ'
    '\ue33f': '\u1103\u1169\u11e6',                          // '도ᇦ'
    '\ue340': '\u1103\u1169\u11e7',                          // '도ᇧ'
    '\ue341': '\u1103\u1169\ud7ec',                          // '도ퟬ'
    '\ue342': '\u1103\u1169\ud7f2',                          // '도ퟲ'
    '\ue343': '\u1103\u1169\u11eb',                          // '도ᇫ'
    '\ue344': '\u1103\u1169\u11f0',                          // '도ᇰ'
    '\ue345': '\u1103\u1169\u11f1',                          // '도ᇱ'
    '\ue346': '\u1103\u116a\u11d9',                          // '돠ᇙ'
    '\ue347': '\u1103\u116a\u11f0',                          // '돠ᇰ'
    '\ue348': '\u1103\u1182',                                // 'ᄃᆂ'
    '\ue349': '\u1103\u1182\u11b7',                          // 'ᄃᆂᆷ'
    '\ue34a': '\u1103\u116d\u11e2',                          // '됴ᇢ'
    '\ue34b': '\u1103\u116d\ud7f5',                          // '됴ퟵ'
    '\ue34c': '\u1103\u116d\u11f0',                          // '됴ᇰ'
    '\ue34d': '\u1103\u1188',                                // 'ᄃᆈ'
    '\ue34e': '\u1103\u1188\u11ab',                          // 'ᄃᆈᆫ'
    '\ue34f': '\u1103\u1188\u11af',                          // 'ᄃᆈᆯ'
    '\ue350': '\u1103\u116e\u11ce',                          // '두ᇎ'
    '\ue351': '\u1103\u116e\u11d0',                          // '두ᇐ'
    '\ue352': '\u1103\u116e\u11d9',                          // '두ᇙ'
    '\ue353': '\u1103\u116e\u11da',                          // '두ᇚ'
    '\ue354': '\u1103\u116e\u11e2',                          // '두ᇢ'
    '\ue355': '\u1103\u116e\u11f0',                          // '두ᇰ'
    '\ue356': '\u1103\u1189',                                // 'ᄃᆉ'
    '\ue357': '\u1103\u1189\u11ab',                          // 'ᄃᆉᆫ'
    '\ue358': '\u1103\u116f\u11f9',                          // '둬ᇹ'
    '\ue359': '\u1103\ud7b5',                                // 'ᄃힵ'
    '\ue35a': '\u1103\u118c',                                // 'ᄃᆌ'
    '\ue35b': '\u1103\u1171\u11e6',                          // '뒤ᇦ'
    '\ue35c': '\u1103\u1171\u11f9',                          // '뒤ᇹ'
    '\ue35d': '\u1103\u1172\u11d9',                          // '듀ᇙ'
    '\ue35e': '\u1103\u1172\u11e2',                          // '듀ᇢ'
    '\ue35f': '\u1103\u1172\u11f0',                          // '듀ᇰ'
    '\ue360': '\u1103\u1172\u11f1',                          // '듀ᇱ'
    '\ue361': '\u1103\u1191',                                // 'ᄃᆑ'
    '\ue362': '\u1103\u1191\u11ab',                          // 'ᄃᆑᆫ'
    '\ue363': '\u1103\u1191\u11af',                          // 'ᄃᆑᆯ'
    '\ue364': '\u1103\u1191\u11d9',                          // 'ᄃᆑᇙ'
    '\ue365': '\u1103\u1191\u11bc',                          // 'ᄃᆑᆼ'
    '\ue366': '\u1103\u1192',                                // 'ᄃᆒ'
    '\ue367': '\u1103\u1192\u11bc',                          // 'ᄃᆒᆼ'
    '\ue368': '\u1103\u1194',                                // 'ᄃᆔ'
    '\ue369': '\u1103\u1194\u11ab',                          // 'ᄃᆔᆫ'
    '\ue36a': '\u1103\u1194\u11bc',                          // 'ᄃᆔᆼ'
    '\ue36b': '\u1103\u1173\u11cd',                          // '드ᇍ'
    '\ue36c': '\u1103\u1173\u11ce',                          // '드ᇎ'
    '\ue36d': '\u1103\u1173\u11d0',                          // '드ᇐ'
    '\ue36e': '\u1103\u1173\u11d9',                          // '드ᇙ'
    '\ue36f': '\u1103\u1173\u11da',                          // '드ᇚ'
    '\ue370': '\u1103\u1173\u11e2',                          // '드ᇢ'
    '\ue371': '\u1103\u1173\u11e3',                          // '드ᇣ'
    '\ue372': '\u1103\u1173\u11e6',                          // '드ᇦ'
    '\ue373': '\u1103\u1173\u11eb',                          // '드ᇫ'
    '\ue374': '\u1103\u1173\u11f0',                          // '드ᇰ'
    '\ue375': '\u1103\u1173\u11f1',                          // '드ᇱ'
    '\ue376': '\u1103\u1195',                                // 'ᄃᆕ'
    '\ue377': '\u1103\u1196',                                // 'ᄃᆖ'
    '\ue378': '\u1103\u1174\u11d9',                          // '듸ᇙ'
    '\ue379': '\u1103\u1174\u11f9',                          // '듸ᇹ'
    '\ue37a': '\u1103\u1175\u11d0',                          // '디ᇐ'
    '\ue37b': '\u1103\u1175\u11d9',                          // '디ᇙ'
    '\ue37c': '\u1103\u1175\u11e2',                          // '디ᇢ'
    '\ue37d': '\u1103\u1175\u11e4',                          // '디ᇤ'
    '\ue37e': '\u1103\u1175\u11e5',                          // '디ᇥ'
    '\ue37f': '\u1103\u1175\ud7f1',                          // '디ퟱ'
    '\ue380': '\u1103\u1175\u11eb',                          // '디ᇫ'
    '\ue381': '\u1103\u1175\u11f0',                          // '디ᇰ'
    '\ue382': '\u1103\u1175\u11f9',                          // '디ᇹ'
    '\ue383': '\u1103\u1198',                                // 'ᄃᆘ'
    '\ue384': '\u1103\u1199',                                // 'ᄃᆙ'
    '\ue385': '\u1103\u1199\u11ab',                          // 'ᄃᆙᆫ'
    '\ue386': '\u1103\u1199\u11bc',                          // 'ᄃᆙᆼ'
    '\ue387': '\u1103\ud7bf',                                // 'ᄃힿ'
    '\ue388': '\u1103\ud7c0',                                // 'ᄃퟀ'
    '\ue389': '\u1103\u119b',                                // 'ᄃᆛ'
    '\ue38a': '\u1103\u119e',                                // 'ᄃᆞ'
    '\ue38b': '\u1103\u119e\u11a8',                          // 'ᄃᆞᆨ'
    '\ue38c': '\u1103\u119e\u11c3',                          // 'ᄃᆞᇃ'
    '\ue38d': '\u1103\u119e\u11ab',                          // 'ᄃᆞᆫ'
    '\ue38e': '\u1103\u119e\u11ae',                          // 'ᄃᆞᆮ'
    '\ue38f': '\u1103\u119e\u11af',                          // 'ᄃᆞᆯ'
    '\ue390': '\u1103\u119e\u11b0',                          // 'ᄃᆞᆰ'
    '\ue391': '\u1103\u119e\u11cc',                          // 'ᄃᆞᇌ'
    '\ue392': '\u1103\u119e\u11b1',                          // 'ᄃᆞᆱ'
    '\ue393': '\u1103\u119e\u11b2',                          // 'ᄃᆞᆲ'
    '\ue394': '\u1103\u119e\u11b3',                          // 'ᄃᆞᆳ'
    '\ue395': '\u1103\u119e\u11b6',                          // 'ᄃᆞᆶ'
    '\ue396': '\u1103\u119e\u11d9',                          // 'ᄃᆞᇙ'
    '\ue397': '\u1103\u119e\u11b7',                          // 'ᄃᆞᆷ'
    '\ue398': '\u1103\u119e\u11da',                          // 'ᄃᆞᇚ'
    '\ue399': '\u1103\u119e\ud7de',                          // 'ᄃᆞퟞ'
    '\ue39a': '\u1103\u119e\ud7df',                          // 'ᄃᆞퟟ'
    '\ue39b': '\u1103\u119e\u11b8',                          // 'ᄃᆞᆸ'
    '\ue39c': '\u1103\u119e\u11e3',                          // 'ᄃᆞᇣ'
    '\ue39d': '\u1103\u119e\u11e6',                          // 'ᄃᆞᇦ'
    '\ue39e': '\u1103\u119e\u11ba',                          // 'ᄃᆞᆺ'
    '\ue39f': '\u1103\u119e\u11e7',                          // 'ᄃᆞᇧ'
    '\ue3a0': '\u1103\u119e\u11e8',                          // 'ᄃᆞᇨ'
    '\ue3a1': '\u1103\u119e\u11e9',                          // 'ᄃᆞᇩ'
    '\ue3a2': '\u1103\u119e\u11ea',                          // 'ᄃᆞᇪ'
    '\ue3a3': '\u1103\u119e\u11eb',                          // 'ᄃᆞᇫ'
    '\ue3a4': '\u1103\u119e\u11bc',                          // 'ᄃᆞᆼ'
    '\ue3a5': '\u1103\u119e\u11f0',                          // 'ᄃᆞᇰ'
    '\ue3a6': '\u1103\u119e\u11bd',                          // 'ᄃᆞᆽ'
    '\ue3a7': '\u1103\u119e\u11be',                          // 'ᄃᆞᆾ'
    '\ue3a8': '\u1103\u11a1',                                // 'ᄃᆡ'
    '\ue3a9': '\u1103\u11a1\u11a8',                          // 'ᄃᆡᆨ'
    '\ue3aa': '\u1103\u11a1\u11ab',                          // 'ᄃᆡᆫ'
    '\ue3ab': '\u1103\u11a1\u11ae',                          // 'ᄃᆡᆮ'
    '\ue3ac': '\u1103\u11a1\u11af',                          // 'ᄃᆡᆯ'
    '\ue3ad': '\u1103\u11a1\u11d9',                          // 'ᄃᆡᇙ'
    '\ue3ae': '\u1103\u11a1\u11b7',                          // 'ᄃᆡᆷ'
    '\ue3af': '\u1103\u11a1\u11b8',                          // 'ᄃᆡᆸ'
    '\ue3b0': '\u1103\u11a1\u11ba',                          // 'ᄃᆡᆺ'
    '\ue3b1': '\u1103\u11a1\u11eb',                          // 'ᄃᆡᇫ'
    '\ue3b2': '\u1103\u11a1\u11bc',                          // 'ᄃᆡᆼ'
    '\ue3b3': '\u1103\u11a1\u11f0',                          // 'ᄃᆡᇰ'
    '\ue3b4': '\u1117\u1173',                                // 'ᄗᅳ'
    '\ue3b5': '\u1117\u1173\u11af',                          // 'ᄗᅳᆯ'
    '\ue3b6': '\u1117\u1175',                                // 'ᄗᅵ'
    '\ue3b7': '\u1117\u1175\u11af',                          // 'ᄗᅵᆯ'
    '\ue3b8': '\u1104\u1161\u11d0',                          // '따ᇐ'
    '\ue3b9': '\u1104\u1161\u11d8',                          // '따ᇘ'
    '\ue3ba': '\u1104\u1161\u11d9',                          // '따ᇙ'
    '\ue3bb': '\u1104\u1161\u11e2',                          // '따ᇢ'
    '\ue3bc': '\u1104\u1161\u11e6',                          // '따ᇦ'
    '\ue3bd': '\u1104\u1161\u11f0',                          // '따ᇰ'
    '\ue3be': '\u1104\u1161\u11f9',                          // '따ᇹ'
    '\ue3bf': '\u1104\u1163\u11e2',                          // '땨ᇢ'
    '\ue3c0': '\u1104\u1163\u11f0',                          // '땨ᇰ'
    '\ue3c1': '\u1104\u117b',                                // 'ᄄᅻ'
    '\ue3c2': '\u1104\u117c',                                // 'ᄄᅼ'
    '\ue3c3': '\u1104\u117c\u11af',                          // 'ᄄᅼᆯ'
    '\ue3c4': '\u1104\u117c\u11b2',                          // 'ᄄᅼᆲ'
    '\ue3c5': '\u1104\u1167\u11d9',                          // '뗘ᇙ'
    '\ue3c6': '\u1104\u1167\u11f0',                          // '뗘ᇰ'
    '\ue3c7': '\u1104\u1169\u11d9',                          // '또ᇙ'
    '\ue3c8': '\u1104\u1169\u11e2',                          // '또ᇢ'
    '\ue3c9': '\u1104\u1169\u11f0',                          // '또ᇰ'
    '\ue3ca': '\u1104\u1169\u11f9',                          // '또ᇹ'
    '\ue3cb': '\u1104\u116a\u11d9',                          // '똬ᇙ'
    '\ue3cc': '\u1104\u116a\u11f0',                          // '똬ᇰ'
    '\ue3cd': '\u1104\u117f',                                // 'ᄄᅿ'
    '\ue3ce': '\u1104\u116d\u11e2',                          // '뚀ᇢ'
    '\ue3cf': '\u1104\u116e\u11e2',                          // '뚜ᇢ'
    '\ue3d0': '\u1104\u116e\u11f0',                          // '뚜ᇰ'
    '\ue3d1': '\u1104\u116e\u11f9',                          // '뚜ᇹ'
    '\ue3d2': '\u1104\ud7b5',                                // 'ᄄힵ'
    '\ue3d3': '\u1104\u118c',                                // 'ᄄᆌ'
    '\ue3d4': '\u1104\u1171\u11f9',                          // '뛰ᇹ'
    '\ue3d5': '\u1104\u1172\u11d9',                          // '뜌ᇙ'
    '\ue3d6': '\u1104\u1172\u11e2',                          // '뜌ᇢ'
    '\ue3d7': '\u1104\u1172\u11f0',                          // '뜌ᇰ'
    '\ue3d8': '\u1104\u1191',                                // 'ᄄᆑ'
    '\ue3d9': '\u1104\u1191\u11ab',                          // 'ᄄᆑᆫ'
    '\ue3da': '\u1104\u1194',                                // 'ᄄᆔ'
    '\ue3db': '\u1104\u1194\u11bc',                          // 'ᄄᆔᆼ'
    '\ue3dc': '\u1104\u1173\ud7cd',                          // '뜨ퟍ'
    '\ue3dd': '\u1104\u1173\u11e2',                          // '뜨ᇢ'
    '\ue3de': '\u1104\u1173\u11f0',                          // '뜨ᇰ'
    '\ue3df': '\u1104\u1173\u11f9',                          // '뜨ᇹ'
    '\ue3e0': '\u1104\u1174\u11f9',                          // '띄ᇹ'
    '\ue3e1': '\u1104\u1175\u11d9',                          // '띠ᇙ'
    '\ue3e2': '\u1104\u1175\u11f0',                          // '띠ᇰ'
    '\ue3e3': '\u1104\u1175\u11f9',                          // '띠ᇹ'
    '\ue3e4': '\u1104\u1198',                                // 'ᄄᆘ'
    '\ue3e5': '\u1104\u119e',                                // 'ᄄᆞ'
    '\ue3e6': '\u1104\u119e\u11a8',                          // 'ᄄᆞᆨ'
    '\ue3e7': '\u1104\u119e\u11ab',                          // 'ᄄᆞᆫ'
    '\ue3e8': '\u1104\u119e\u11af',                          // 'ᄄᆞᆯ'
    '\ue3e9': '\u1104\u119e\u11b7',                          // 'ᄄᆞᆷ'
    '\ue3ea': '\u1104\u119e\u11ba',                          // 'ᄄᆞᆺ'
    '\ue3eb': '\u1104\u11a1',                                // 'ᄄᆡ'
    '\ue3ec': '\u1104\u11a1\u11a8',                          // 'ᄄᆡᆨ'
    '\ue3ed': '\u1104\u11a1\u11af',                          // 'ᄄᆡᆯ'
    '\ue3ee': '\u1104\u11a1\u11bc',                          // 'ᄄᆡᆼ'
    '\ue3ef': '\u1104\u11a1\u11f0',                          // 'ᄄᆡᇰ'
    '\ue3f0': '\u115e\u1175',                                // 'ᅞᅵ'
    '\ue3f1': '\u115e\u1175\u11bc',                          // 'ᅞᅵᆼ'
    '\ue3f2': '\u115e\u1175\u11ef',                          // 'ᅞᅵᇯ'
    '\ue3f3': '\ua960\u1161',                                // 'ꥠᅡ'
    '\ue3f4': '\ua960\u1161\u11af',                          // 'ꥠᅡᆯ'
    '\ue3f5': '\ua961\u1161',                                // 'ꥡᅡ'
    '\ue3f6': '\ua961\u1162',                                // 'ꥡᅢ'
    '\ue3f7': '\ua961\u116e',                                // 'ꥡᅮ'
    '\ue3f8': '\ua961\u116e\u11af',                          // 'ꥡᅮᆯ'
    '\ue3f9': '\ua962\u1172',                                // 'ꥢᅲ'
    '\ue3fa': '\ua962\u1172\u11af',                          // 'ꥢᅲᆯ'
    '\ue3fb': '\ua962\u1172\u11bc',                          // 'ꥢᅲᆼ'
    '\ue3fc': '\ua962\u1173',                                // 'ꥢᅳ'
    '\ue3fd': '\ua962\u1175',                                // 'ꥢᅵ'
    '\ue3fe': '\ua962\u1175\u11a8',                          // 'ꥢᅵᆨ'
    '\ue3ff': '\ua963\u1163',                                // 'ꥣᅣ'
    '\ue400': '\ua963\u1163\u11bc',                          // 'ꥣᅣᆼ'
    '\ue401': '\ua963\u119e',                                // 'ꥣᆞ'
    '\ue402': '\u1105\u1161\u11d0',                          // '라ᇐ'
    '\ue403': '\u1105\u1161\u11d9',                          // '라ᇙ'
    '\ue404': '\u1105\u1161\ud7e0',                          // '라ퟠ'
    '\ue405': '\u1105\u1161\u11dd',                          // '라ᇝ'
    '\ue406': '\u1105\u1161\u11e2',                          // '라ᇢ'
    '\ue407': '\u1105\u1161\u11e6',                          // '라ᇦ'
    '\ue408': '\u1105\u1161\u11e7',                          // '라ᇧ'
    '\ue409': '\u1105\u1161\ud7ec',                          // '라ퟬ'
    '\ue40a': '\u1105\u1161\u11eb',                          // '라ᇫ'
    '\ue40b': '\u1105\u1161\u11f0',                          // '라ᇰ'
    '\ue40c': '\u1105\u1161\u11ec',                          // '라ᇬ'
    '\ue40d': '\u1105\u1161\u11f5',                          // '라ᇵ'
    '\ue40e': '\u1105\u1161\u11f9',                          // '라ᇹ'
    '\ue40f': '\u1105\u1176',                                // 'ᄅᅶ'
    '\ue410': '\u1105\u1176\u11af',                          // 'ᄅᅶᆯ'
    '\ue411': '\u1105\u1177',                                // 'ᄅᅷ'
    '\ue412': '\u1105\u1162\u11f0',                          // '래ᇰ'
    '\ue413': '\u1105\u1163\u11e2',                          // '랴ᇢ'
    '\ue414': '\u1105\u1163\u11f0',                          // '랴ᇰ'
    '\ue415': '\u1105\u1178',                                // 'ᄅᅸ'
    '\ue416': '\u1105\u1165\ud7e0',                          // '러ퟠ'
    '\ue417': '\u1105\u1165\u11e6',                          // '러ᇦ'
    '\ue418': '\u1105\u1165\ud7f2',                          // '러ퟲ'
    '\ue419': '\u1105\u1165\u11f0',                          // '러ᇰ'
    '\ue41a': '\u1105\u1165\u11f5',                          // '러ᇵ'
    '\ue41b': '\u1105\u117a',                                // 'ᄅᅺ'
    '\ue41c': '\u1105\u117b',                                // 'ᄅᅻ'
    '\ue41d': '\u1105\u117b\u11af',                          // 'ᄅᅻᆯ'
    '\ue41e': '\u1105\u1166\u11f0',                          // '레ᇰ'
    '\ue41f': '\u1105\u1167\u11c7',                          // '려ᇇ'
    '\ue420': '\u1105\u1167\u11d9',                          // '려ᇙ'
    '\ue421': '\u1105\u1167\u11e2',                          // '려ᇢ'
    '\ue422': '\u1105\u1167\u11e6',                          // '려ᇦ'
    '\ue423': '\u1105\u1167\u11f0',                          // '려ᇰ'
    '\ue424': '\u1105\u1167\u11f9',                          // '려ᇹ'
    '\ue425': '\u1105\u1169\ud7d0',                          // '로ퟐ'
    '\ue426': '\u1105\u1169\u11d9',                          // '로ᇙ'
    '\ue427': '\u1105\u1169\u11dd',                          // '로ᇝ'
    '\ue428': '\u1105\u1169\u11e2',                          // '로ᇢ'
    '\ue429': '\u1105\u1169\ud7e5',                          // '로ퟥ'
    '\ue42a': '\u1105\u1169\u11e6',                          // '로ᇦ'
    '\ue42b': '\u1105\u1169\ud7f0',                          // '로ퟰ'
    '\ue42c': '\u1105\u1169\u11f0',                          // '로ᇰ'
    '\ue42d': '\u1105\u1169\u11f1',                          // '로ᇱ'
    '\ue42e': '\u1105\u116a\u11d9',                          // '롸ᇙ'
    '\ue42f': '\u1105\u116a\u11f0',                          // '롸ᇰ'
    '\ue430': '\u1105\u1182',                                // 'ᄅᆂ'
    '\ue431': '\u1105\u116d\u11d9',                          // '료ᇙ'
    '\ue432': '\u1105\u116d\u11dd',                          // '료ᇝ'
    '\ue433': '\u1105\u116d\u11e2',                          // '료ᇢ'
    '\ue434': '\u1105\u116d\u11f0',                          // '료ᇰ'
    '\ue435': '\u1105\u1186',                                // 'ᄅᆆ'
    '\ue436': '\u1105\u1187',                                // 'ᄅᆇ'
    '\ue437': '\u1105\u1188',                                // 'ᄅᆈ'
    '\ue438': '\u1105\u116e\u11d9',                          // '루ᇙ'
    '\ue439': '\u1105\u116e\u11dd',                          // '루ᇝ'
    '\ue43a': '\u1105\u116e\u11e2',                          // '루ᇢ'
    '\ue43b': '\u1105\u116e\u11e4',                          // '루ᇤ'
    '\ue43c': '\u1105\u116e\u11f0',                          // '루ᇰ'
    '\ue43d': '\u1105\u116e\u11f9',                          // '루ᇹ'
    '\ue43e': '\u1105\u1189',                                // 'ᄅᆉ'
    '\ue43f': '\u1105\u1189\u11ab',                          // 'ᄅᆉᆫ'
    '\ue440': '\u1105\ud7b5',                                // 'ᄅힵ'
    '\ue441': '\u1105\u118c',                                // 'ᄅᆌ'
    '\ue442': '\u1105\u118d',                                // 'ᄅᆍ'
    '\ue443': '\u1105\u1172\u11d9',                          // '류ᇙ'
    '\ue444': '\u1105\u1172\u11e2',                          // '류ᇢ'
    '\ue445': '\u1105\u1172\u11f0',                          // '류ᇰ'
    '\ue446': '\u1105\u1172\u11f9',                          // '류ᇹ'
    '\ue447': '\u1105\u1191',                                // 'ᄅᆑ'
    '\ue448': '\u1105\u1191\u11ab',                          // 'ᄅᆑᆫ'
    '\ue449': '\u1105\u1191\u11af',                          // 'ᄅᆑᆯ'
    '\ue44a': '\u1105\u1191\u11d9',                          // 'ᄅᆑᇙ'
    '\ue44b': '\u1105\u1194',                                // 'ᄅᆔ'
    '\ue44c': '\u1105\u1194\u11bc',                          // 'ᄅᆔᆼ'
    '\ue44d': '\u1105\u1173\ud7d3',                          // '르ퟓ'
    '\ue44e': '\u1105\u1173\u11d0',                          // '르ᇐ'
    '\ue44f': '\u1105\u1173\u11d9',                          // '르ᇙ'
    '\ue450': '\u1105\u1173\ud7e0',                          // '르ퟠ'
    '\ue451': '\u1105\u1173\u11dd',                          // '르ᇝ'
    '\ue452': '\u1105\u1173\u11e2',                          // '르ᇢ'
    '\ue453': '\u1105\u1173\u11e6',                          // '르ᇦ'
    '\ue454': '\u1105\u1173\u11f0',                          // '르ᇰ'
    '\ue455': '\u1105\u1195',                                // 'ᄅᆕ'
    '\ue456': '\u1105\u1196',                                // 'ᄅᆖ'
    '\ue457': '\u1105\u1174\u11f9',                          // '릐ᇹ'
    '\ue458': '\u1105\u1175\u11c7',                          // '리ᇇ'
    '\ue459': '\u1105\u1175\u11d9',                          // '리ᇙ'
    '\ue45a': '\u1105\u1175\u11dd',                          // '리ᇝ'
    '\ue45b': '\u1105\u1175\u11e2',                          // '리ᇢ'
    '\ue45c': '\u1105\u1175\u11e6',                          // '리ᇦ'
    '\ue45d': '\u1105\u1175\u11f0',                          // '리ᇰ'
    '\ue45e': '\u1105\u1175\u11f9',                          // '리ᇹ'
    '\ue45f': '\u1105\u1198',                                // 'ᄅᆘ'
    '\ue460': '\u1105\u1199',                                // 'ᄅᆙ'
    '\ue461': '\u1105\u1199\u11ab',                          // 'ᄅᆙᆫ'
    '\ue462': '\u1105\u1199\u11bc',                          // 'ᄅᆙᆼ'
    '\ue463': '\u1105\ud7bd',                                // 'ᄅힽ'
    '\ue464': '\u1105\ud7bf',                                // 'ᄅힿ'
    '\ue465': '\u1105\ud7c0',                                // 'ᄅퟀ'
    '\ue466': '\u1105\u119a',                                // 'ᄅᆚ'
    '\ue467': '\u1105\u119a\u11af',                          // 'ᄅᆚᆯ'
    '\ue468': '\u1105\ud7c2',                                // 'ᄅퟂ'
    '\ue469': '\u1105\u119b',                                // 'ᄅᆛ'
    '\ue46a': '\u1105\u119b\u11af',                          // 'ᄅᆛᆯ'
    '\ue46b': '\u1105\ud7c4',                                // 'ᄅퟄ'
    '\ue46c': '\u1105\u119e',                                // 'ᄅᆞ'
    '\ue46d': '\u1105\u119e\u11a8',                          // 'ᄅᆞᆨ'
    '\ue46e': '\u1105\u119e\u11ab',                          // 'ᄅᆞᆫ'
    '\ue46f': '\u1105\u119e\u11ae',                          // 'ᄅᆞᆮ'
    '\ue470': '\u1105\u119e\u11af',                          // 'ᄅᆞᆯ'
    '\ue471': '\u1105\u119e\u11b0',                          // 'ᄅᆞᆰ'
    '\ue472': '\u1105\u119e\u11b1',                          // 'ᄅᆞᆱ'
    '\ue473': '\u1105\u119e\u11b3',                          // 'ᄅᆞᆳ'
    '\ue474': '\u1105\u119e\u11d7',                          // 'ᄅᆞᇗ'
    '\ue475': '\u1105\u119e\u11b6',                          // 'ᄅᆞᆶ'
    '\ue476': '\u1105\u119e\u11d9',                          // 'ᄅᆞᇙ'
    '\ue477': '\u1105\u119e\u11b7',                          // 'ᄅᆞᆷ'
    '\ue478': '\u1105\u119e\ud7e0',                          // 'ᄅᆞퟠ'
    '\ue479': '\u1105\u119e\u11dc',                          // 'ᄅᆞᇜ'
    '\ue47a': '\u1105\u119e\u11dd',                          // 'ᄅᆞᇝ'
    '\ue47b': '\u1105\u119e\u11b8',                          // 'ᄅᆞᆸ'
    '\ue47c': '\u1105\u119e\u11b9',                          // 'ᄅᆞᆹ'
    '\ue47d': '\u1105\u119e\u11e6',                          // 'ᄅᆞᇦ'
    '\ue47e': '\u1105\u119e\u11ba',                          // 'ᄅᆞᆺ'
    '\ue47f': '\u1105\u119e\u11bb',                          // 'ᄅᆞᆻ'
    '\ue480': '\u1105\u119e\u11eb',                          // 'ᄅᆞᇫ'
    '\ue481': '\u1105\u119e\u11f0',                          // 'ᄅᆞᇰ'
    '\ue482': '\u1105\u119e\u11bd',                          // 'ᄅᆞᆽ'
    '\ue483': '\u1105\u119e\u11be',                          // 'ᄅᆞᆾ'
    '\ue484': '\u1105\u119e\u11c0',                          // 'ᄅᆞᇀ'
    '\ue485': '\u1105\u119e\u11c2',                          // 'ᄅᆞᇂ'
    '\ue486': '\u1105\ud7c5',                                // 'ᄅퟅ'
    '\ue487': '\u1105\u11a1',                                // 'ᄅᆡ'
    '\ue488': '\u1105\u11a1\u11a8',                          // 'ᄅᆡᆨ'
    '\ue489': '\u1105\u11a1\u11ab',                          // 'ᄅᆡᆫ'
    '\ue48a': '\u1105\u11a1\u11ae',                          // 'ᄅᆡᆮ'
    '\ue48b': '\u1105\u11a1\u11af',                          // 'ᄅᆡᆯ'
    '\ue48c': '\u1105\u11a1\u11b7',                          // 'ᄅᆡᆷ'
    '\ue48d': '\u1105\u11a1\u11ba',                          // 'ᄅᆡᆺ'
    '\ue48e': '\u1105\u11a1\u11bc',                          // 'ᄅᆡᆼ'
    '\ue48f': '\u1105\u11a1\u11f0',                          // 'ᄅᆡᇰ'
    '\ue490': '\ua964\u1161',                                // 'ꥤᅡ'
    '\ue491': '\ua964\u1169',                                // 'ꥤᅩ'
    '\ue492': '\ua964\u1169\u11ba',                          // 'ꥤᅩᆺ'
    '\ue493': '\ua965\u1161',                                // 'ꥥᅡ'
    '\ue494': '\u1118\u1161',                                // 'ᄘᅡ'
    '\ue495': '\u1118\u1161\u11a8',                          // 'ᄘᅡᆨ'
    '\ue496': '\u1118\u1161\u11ab',                          // 'ᄘᅡᆫ'
    '\ue497': '\u1118\u1161\u11bc',                          // 'ᄘᅡᆼ'
    '\ue498': '\u1118\u1165',                                // 'ᄘᅥ'
    '\ue499': '\u1118\u1166',                                // 'ᄘᅦ'
    '\ue49a': '\u1118\u1166\u11af',                          // 'ᄘᅦᆯ'
    '\ue49b': '\u1118\u1166\u11b8',                          // 'ᄘᅦᆸ'
    '\ue49c': '\u1118\u1169',                                // 'ᄘᅩ'
    '\ue49d': '\u1118\u1169\u11a8',                          // 'ᄘᅩᆨ'
    '\ue49e': '\u1118\u1169\u11ab',                          // 'ᄘᅩᆫ'
    '\ue49f': '\u1118\u1169\u11b7',                          // 'ᄘᅩᆷ'
    '\ue4a0': '\u1118\u1169\u11ba',                          // 'ᄘᅩᆺ'
    '\ue4a1': '\u1118\u116d',                                // 'ᄘᅭ'
    '\ue4a2': '\u1118\u116d\u11ab',                          // 'ᄘᅭᆫ'
    '\ue4a3': '\u1118\u116e',                                // 'ᄘᅮ'
    '\ue4a4': '\u1118\u1172',                                // 'ᄘᅲ'
    '\ue4a5': '\u1118\u1172\u11a8',                          // 'ᄘᅲᆨ'
    '\ue4a6': '\u1118\u1173',                                // 'ᄘᅳ'
    '\ue4a7': '\u1118\u1174',                                // 'ᄘᅴ'
    '\ue4a8': '\u1118\u1174\u11a8',                          // 'ᄘᅴᆨ'
    '\ue4a9': '\u1118\u1174\u11ab',                          // 'ᄘᅴᆫ'
    '\ue4aa': '\u1118\u1174\u11b8',                          // 'ᄘᅴᆸ'
    '\ue4ab': '\u1118\u119e',                                // 'ᄘᆞ'
    '\ue4ac': '\u1118\u119e\u11ab',                          // 'ᄘᆞᆫ'
    '\ue4ad': '\u1118\u119e\u11bc',                          // 'ᄘᆞᆼ'
    '\ue4ae': '\u1118\u11a1',                                // 'ᄘᆡ'
    '\ue4af': '\u1118\u11a1\u11a8',                          // 'ᄘᆡᆨ'
    '\ue4b0': '\u1118\u11a1\u11b7',                          // 'ᄘᆡᆷ'
    '\ue4b1': '\ua967\u1161',                                // 'ꥧᅡ'
    '\ue4b2': '\u1119\u1161',                                // 'ᄙᅡ'
    '\ue4b3': '\u1119\u1161\u11a8',                          // 'ᄙᅡᆨ'
    '\ue4b4': '\u1119\u1161\u11ab',                          // 'ᄙᅡᆫ'
    '\ue4b5': '\u1119\u1161\u11bc',                          // 'ᄙᅡᆼ'
    '\ue4b6': '\u1119\u1176',                                // 'ᄙᅶ'
    '\ue4b7': '\u1119\u1162',                                // 'ᄙᅢ'
    '\ue4b8': '\u1119\u1162\u11ab',                          // 'ᄙᅢᆫ'
    '\ue4b9': '\u1119\u1163',                                // 'ᄙᅣ'
    '\ue4ba': '\u1119\u1163\u11bc',                          // 'ᄙᅣᆼ'
    '\ue4bb': '\u1119\u1178',                                // 'ᄙᅸ'
    '\ue4bc': '\u1119\u1165',                                // 'ᄙᅥ'
    '\ue4bd': '\u1119\u1165\u11ab',                          // 'ᄙᅥᆫ'
    '\ue4be': '\u1119\u1165\u11bc',                          // 'ᄙᅥᆼ'
    '\ue4bf': '\u1119\u117b',                                // 'ᄙᅻ'
    '\ue4c0': '\u1119\u1166',                                // 'ᄙᅦ'
    '\ue4c1': '\u1119\u1166\u11a8',                          // 'ᄙᅦᆨ'
    '\ue4c2': '\u1119\u1167',                                // 'ᄙᅧ'
    '\ue4c3': '\u1119\u1167\u11a8',                          // 'ᄙᅧᆨ'
    '\ue4c4': '\u1119\u1168',                                // 'ᄙᅨ'
    '\ue4c5': '\u1119\u1168\u11ab',                          // 'ᄙᅨᆫ'
    '\ue4c6': '\u1119\u1169',                                // 'ᄙᅩ'
    '\ue4c7': '\u1119\u1169\u11bc',                          // 'ᄙᅩᆼ'
    '\ue4c8': '\u1119\u116d',                                // 'ᄙᅭ'
    '\ue4c9': '\u1119\u116e',                                // 'ᄙᅮ'
    '\ue4ca': '\u1119\u116e\u11ab',                          // 'ᄙᅮᆫ'
    '\ue4cb': '\u1119\u116e\u11bc',                          // 'ᄙᅮᆼ'
    '\ue4cc': '\u1119\u1189',                                // 'ᄙᆉ'
    '\ue4cd': '\u1119\u1189\u11ab',                          // 'ᄙᆉᆫ'
    '\ue4ce': '\u1119\u116f',                                // 'ᄙᅯ'
    '\ue4cf': '\u1119\u1170',                                // 'ᄙᅰ'
    '\ue4d0': '\u1119\u1171',                                // 'ᄙᅱ'
    '\ue4d1': '\u1119\u1172',                                // 'ᄙᅲ'
    '\ue4d2': '\u1119\u118f',                                // 'ᄙᆏ'
    '\ue4d3': '\u1119\u1190',                                // 'ᄙᆐ'
    '\ue4d4': '\u1119\u1173',                                // 'ᄙᅳ'
    '\ue4d5': '\u1119\u1173\u11af',                          // 'ᄙᅳᆯ'
    '\ue4d6': '\u1119\u1173\u11d0',                          // 'ᄙᅳᇐ'
    '\ue4d7': '\u1119\u1173\u11bc',                          // 'ᄙᅳᆼ'
    '\ue4d8': '\u1119\u1175',                                // 'ᄙᅵ'
    '\ue4d9': '\u1119\u1175\u11ab',                          // 'ᄙᅵᆫ'
    '\ue4da': '\u1119\u1175\u11bc',                          // 'ᄙᅵᆼ'
    '\ue4db': '\u1119\u119b',                                // 'ᄙᆛ'
    '\ue4dc': '\ua968\u1161',                                // 'ꥨᅡ'
    '\ue4dd': '\ua968\u1161\u11ab',                          // 'ꥨᅡᆫ'
    '\ue4de': '\ua969\u1161',                                // 'ꥩᅡ'
    '\ue4df': '\ua96a\u1161',                                // 'ꥪᅡ'
    '\ue4e0': '\ua96b\u119e',                                // 'ꥫᆞ'
    '\ue4e1': '\ua96b\u11a1',                                // 'ꥫᆡ'
    '\ue4e2': '\ua96c\u1169',                                // 'ꥬᅩ'
    '\ue4e3': '\ua96c\u1169\u11a8',                          // 'ꥬᅩᆨ'
    '\ue4e4': '\ua96c\u1173',                                // 'ꥬᅳ'
    '\ue4e5': '\ua96d\u1165',                                // 'ꥭᅥ'
    '\ue4e6': '\ua96d\u1166',                                // 'ꥭᅦ'
    '\ue4e7': '\ua96d\u1172',                                // 'ꥭᅲ'
    '\ue4e8': '\ua96d\u1172\u11af',                          // 'ꥭᅲᆯ'
    '\ue4e9': '\ua96e\u1161',                                // 'ꥮᅡ'
    '\ue4ea': '\u111a\u1161',                                // 'ᄚᅡ'
    '\ue4eb': '\u111a\u1161\u11ab',                          // 'ᄚᅡᆫ'
    '\ue4ec': '\u111a\u1163',                                // 'ᄚᅣ'
    '\ue4ed': '\u111a\u1163\u11bc',                          // 'ᄚᅣᆼ'
    '\ue4ee': '\u111a\u1169',                                // 'ᄚᅩ'
    '\ue4ef': '\u111a\u116a',                                // 'ᄚᅪ'
    '\ue4f0': '\u111a\u1175',                                // 'ᄚᅵ'
    '\ue4f1': '\u1106\u1161\u11fe',                          // '마ᇾ'
    '\ue4f2': '\u1106\u1161\u11c6',                          // '마ᇆ'
    '\ue4f3': '\u1106\u1161\u11c7',                          // '마ᇇ'
    '\ue4f4': '\u1106\u1161\ud7d4',                          // '마ퟔ'
    '\ue4f5': '\u1106\u1161\u11cd',                          // '마ᇍ'
    '\ue4f6': '\u1106\u1161\u11d0',                          // '마ᇐ'
    '\ue4f7': '\u1106\u1161\u11d8',                          // '마ᇘ'
    '\ue4f8': '\u1106\u1161\u11d9',                          // '마ᇙ'
    '\ue4f9': '\u1106\u1161\u11e2',                          // '마ᇢ'
    '\ue4fa': '\u1106\u1161\u11e6',                          // '마ᇦ'
    '\ue4fb': '\u1106\u1161\u11e8',                          // '마ᇨ'
    '\ue4fc': '\u1106\u1161\ud7ed',                          // '마ퟭ'
    '\ue4fd': '\u1106\u1161\ud7f0',                          // '마ퟰ'
    '\ue4fe': '\u1106\u1161\ud7f1',                          // '마ퟱ'
    '\ue4ff': '\u1106\u1161\u11eb',                          // '마ᇫ'
    '\ue500': '\u1106\u1161\u11f0',                          // '마ᇰ'
    '\ue501': '\u1106\u1161\u11f9',                          // '마ᇹ'
    '\ue502': '\u1106\u1176',                                // 'ᄆᅶ'
    '\ue503': '\u1106\u1177',                                // 'ᄆᅷ'
    '\ue504': '\u1106\u1162\u11f0',                          // '매ᇰ'
    '\ue505': '\u1106\u1163\u11e2',                          // '먀ᇢ'
    '\ue506': '\u1106\u1178',                                // 'ᄆᅸ'
    '\ue507': '\u1106\u1165\u11c6',                          // '머ᇆ'
    '\ue508': '\u1106\u1165\u11cd',                          // '머ᇍ'
    '\ue509': '\u1106\u1165\u11d0',                          // '머ᇐ'
    '\ue50a': '\u1106\u1165\u11d9',                          // '머ᇙ'
    '\ue50b': '\u1106\u1165\u11f0',                          // '머ᇰ'
    '\ue50c': '\u1106\u1165\u11f9',                          // '머ᇹ'
    '\ue50d': '\u1106\u117a',                                // 'ᄆᅺ'
    '\ue50e': '\u1106\u117c',                                // 'ᄆᅼ'
    '\ue50f': '\u1106\u117c\u11af',                          // 'ᄆᅼᆯ'
    '\ue510': '\u1106\u1166\u11d9',                          // '메ᇙ'
    '\ue511': '\u1106\u1167\u11d9',                          // '며ᇙ'
    '\ue512': '\u1106\u1167\u11e2',                          // '며ᇢ'
    '\ue513': '\u1106\u1167\u11eb',                          // '며ᇫ'
    '\ue514': '\u1106\u1167\u11f0',                          // '며ᇰ'
    '\ue515': '\u1106\u1167\ud7f6',                          // '며ퟶ'
    '\ue516': '\u1106\u1167\u11f9',                          // '며ᇹ'
    '\ue517': '\u1106\u1169\u11c6',                          // '모ᇆ'
    '\ue518': '\u1106\u1169\u11d0',                          // '모ᇐ'
    '\ue519': '\u1106\u1169\u11d9',                          // '모ᇙ'
    '\ue51a': '\u1106\u1169\u11dd',                          // '모ᇝ'
    '\ue51b': '\u1106\u1169\u11e2',                          // '모ᇢ'
    '\ue51c': '\u1106\u1169\ud7f0',                          // '모ퟰ'
    '\ue51d': '\u1106\u1169\u11eb',                          // '모ᇫ'
    '\ue51e': '\u1106\u1169\u11f0',                          // '모ᇰ'
    '\ue51f': '\u1106\u117f',                                // 'ᄆᅿ'
    '\ue520': '\u1106\u117f\u11ab',                          // 'ᄆᅿᆫ'
    '\ue521': '\u1106\u1182',                                // 'ᄆᆂ'
    '\ue522': '\u1106\ud7b1',                                // 'ᄆힱ'
    '\ue523': '\u1106\u1183',                                // 'ᄆᆃ'
    '\ue524': '\u1106\u116d\u11e2',                          // '묘ᇢ'
    '\ue525': '\u1106\u1187',                                // 'ᄆᆇ'
    '\ue526': '\u1106\u1188',                                // 'ᄆᆈ'
    '\ue527': '\u1106\u116e\u11c6',                          // '무ᇆ'
    '\ue528': '\u1106\u116e\u11c7',                          // '무ᇇ'
    '\ue529': '\u1106\u116e\u11d0',                          // '무ᇐ'
    '\ue52a': '\u1106\u116e\u11d9',                          // '무ᇙ'
    '\ue52b': '\u1106\u116e\u11dd',                          // '무ᇝ'
    '\ue52c': '\u1106\u116e\u11e2',                          // '무ᇢ'
    '\ue52d': '\u1106\u116e\u11e7',                          // '무ᇧ'
    '\ue52e': '\u1106\u116e\ud7ec',                          // '무ퟬ'
    '\ue52f': '\u1106\u116e\ud7f1',                          // '무ퟱ'
    '\ue530': '\u1106\u116e\u11eb',                          // '무ᇫ'
    '\ue531': '\u1106\u116e\u11ec',                          // '무ᇬ'
    '\ue532': '\u1106\u116e\u11ed',                          // '무ᇭ'
    '\ue533': '\u1106\u116e\u11f0',                          // '무ᇰ'
    '\ue534': '\u1106\u116e\u11f9',                          // '무ᇹ'
    '\ue535': '\u1106\u116f\u11f9',                          // '뭐ᇹ'
    '\ue536': '\u1106\ud7b5',                                // 'ᄆힵ'
    '\ue537': '\u1106\u118c',                                // 'ᄆᆌ'
    '\ue538': '\u1106\u118d',                                // 'ᄆᆍ'
    '\ue539': '\u1106\u1171\u11f9',                          // '뮈ᇹ'
    '\ue53a': '\u1106\u1172\u11e2',                          // '뮤ᇢ'
    '\ue53b': '\u1106\u1194',                                // 'ᄆᆔ'
    '\ue53c': '\u1106\u1173\u11c4',                          // '므ᇄ'
    '\ue53d': '\u1106\u1173\u11d0',                          // '므ᇐ'
    '\ue53e': '\u1106\u1173\u11d9',                          // '므ᇙ'
    '\ue53f': '\u1106\u1173\u11e2',                          // '므ᇢ'
    '\ue540': '\u1106\u1173\u11e7',                          // '므ᇧ'
    '\ue541': '\u1106\u1173\ud7f2',                          // '므ퟲ'
    '\ue542': '\u1106\u1173\u11eb',                          // '므ᇫ'
    '\ue543': '\u1106\u1173\u11f0',                          // '므ᇰ'
    '\ue544': '\u1106\u1195',                                // 'ᄆᆕ'
    '\ue545': '\u1106\u1196',                                // 'ᄆᆖ'
    '\ue546': '\u1106\u1196\u11b7',                          // 'ᄆᆖᆷ'
    '\ue547': '\u1106\u1174\u11d9',                          // '믜ᇙ'
    '\ue548': '\u1106\u1174\u11e6',                          // '믜ᇦ'
    '\ue549': '\u1106\u1174\u11f9',                          // '믜ᇹ'
    '\ue54a': '\u1106\u1175\ud7cf',                          // '미ퟏ'
    '\ue54b': '\u1106\u1175\ud7d3',                          // '미ퟓ'
    '\ue54c': '\u1106\u1175\ud7d4',                          // '미ퟔ'
    '\ue54d': '\u1106\u1175\u11cd',                          // '미ᇍ'
    '\ue54e': '\u1106\u1175\u11d0',                          // '미ᇐ'
    '\ue54f': '\u1106\u1175\u11d9',                          // '미ᇙ'
    '\ue550': '\u1106\u1175\u11e6',                          // '미ᇦ'
    '\ue551': '\u1106\u1175\u11e7',                          // '미ᇧ'
    '\ue552': '\u1106\u1175\ud7f0',                          // '미ퟰ'
    '\ue553': '\u1106\u1175\ud7f1',                          // '미ퟱ'
    '\ue554': '\u1106\u1175\ud7f2',                          // '미ퟲ'
    '\ue555': '\u1106\u1175\u11eb',                          // '미ᇫ'
    '\ue556': '\u1106\u1175\u11f0',                          // '미ᇰ'
    '\ue557': '\u1106\u1175\u11f6',                          // '미ᇶ'
    '\ue558': '\u1106\u1175\u11f9',                          // '미ᇹ'
    '\ue559': '\u1106\u1198',                                // 'ᄆᆘ'
    '\ue55a': '\u1106\u1199',                                // 'ᄆᆙ'
    '\ue55b': '\u1106\u1199\u11ab',                          // 'ᄆᆙᆫ'
    '\ue55c': '\u1106\ud7bf',                                // 'ᄆힿ'
    '\ue55d': '\u1106\ud7c0',                                // 'ᄆퟀ'
    '\ue55e': '\u1106\u119c',                                // 'ᄆᆜ'
    '\ue55f': '\u1106\u119c\u11bc',                          // 'ᄆᆜᆼ'
    '\ue560': '\u1106\u119e',                                // 'ᄆᆞ'
    '\ue561': '\u1106\u119e\u11a8',                          // 'ᄆᆞᆨ'
    '\ue562': '\u1106\u119e\u11c3',                          // 'ᄆᆞᇃ'
    '\ue563': '\u1106\u119e\u11ab',                          // 'ᄆᆞᆫ'
    '\ue564': '\u1106\u119e\u11ae',                          // 'ᄆᆞᆮ'
    '\ue565': '\u1106\u119e\ud7d3',                          // 'ᄆᆞퟓ'
    '\ue566': '\u1106\u119e\u11af',                          // 'ᄆᆞᆯ'
    '\ue567': '\u1106\u119e\u11b0',                          // 'ᄆᆞᆰ'
    '\ue568': '\u1106\u119e\u11d0',                          // 'ᄆᆞᇐ'
    '\ue569': '\u1106\u119e\u11b2',                          // 'ᄆᆞᆲ'
    '\ue56a': '\u1106\u119e\u11d5',                          // 'ᄆᆞᇕ'
    '\ue56b': '\u1106\u119e\u11b3',                          // 'ᄆᆞᆳ'
    '\ue56c': '\u1106\u119e\u11b6',                          // 'ᄆᆞᆶ'
    '\ue56d': '\u1106\u119e\u11d9',                          // 'ᄆᆞᇙ'
    '\ue56e': '\u1106\u119e\u11b7',                          // 'ᄆᆞᆷ'
    '\ue56f': '\u1106\u119e\u11b8',                          // 'ᄆᆞᆸ'
    '\ue570': '\u1106\u119e\u11ba',                          // 'ᄆᆞᆺ'
    '\ue571': '\u1106\u119e\ud7f0',                          // 'ᄆᆞퟰ'
    '\ue572': '\u1106\u119e\u11eb',                          // 'ᄆᆞᇫ'
    '\ue573': '\u1106\u119e\u11bc',                          // 'ᄆᆞᆼ'
    '\ue574': '\u1106\u119e\u11f0',                          // 'ᄆᆞᇰ'
    '\ue575': '\u1106\u119e\u11bd',                          // 'ᄆᆞᆽ'
    '\ue576': '\u1106\u119e\u11be',                          // 'ᄆᆞᆾ'
    '\ue577': '\u1106\u119e\u11c0',                          // 'ᄆᆞᇀ'
    '\ue578': '\u1106\u119f',                                // 'ᄆᆟ'
    '\ue579': '\u1106\u119f\u11ab',                          // 'ᄆᆟᆫ'
    '\ue57a': '\u1106\u119f\u11bc',                          // 'ᄆᆟᆼ'
    '\ue57b': '\u1106\u11a1',                                // 'ᄆᆡ'
    '\ue57c': '\u1106\u11a1\u11a8',                          // 'ᄆᆡᆨ'
    '\ue57d': '\u1106\u11a1\u11a9',                          // 'ᄆᆡᆩ'
    '\ue57e': '\u1106\u11a1\u11ab',                          // 'ᄆᆡᆫ'
    '\ue57f': '\u1106\u11a1\u11ae',                          // 'ᄆᆡᆮ'
    '\ue580': '\u1106\u11a1\u11af',                          // 'ᄆᆡᆯ'
    '\ue581': '\u1106\u11a1\u11b3',                          // 'ᄆᆡᆳ'
    '\ue582': '\u1106\u11a1\u11d9',                          // 'ᄆᆡᇙ'
    '\ue583': '\u1106\u11a1\u11b7',                          // 'ᄆᆡᆷ'
    '\ue584': '\u1106\u11a1\u11b8',                          // 'ᄆᆡᆸ'
    '\ue585': '\u1106\u11a1\u11e6',                          // 'ᄆᆡᇦ'
    '\ue586': '\u1106\u11a1\u11ba',                          // 'ᄆᆡᆺ'
    '\ue587': '\u1106\u11a1\u11bc',                          // 'ᄆᆡᆼ'
    '\ue588': '\u1106\u11a1\u11f0',                          // 'ᄆᆡᇰ'
    '\ue589': '\u1106\u11a1\u11bd',                          // 'ᄆᆡᆽ'
    '\ue58a': '\u1106\u11a1\u11be',                          // 'ᄆᆡᆾ'
    '\ue58b': '\u1106\u11a1\u11c2',                          // 'ᄆᆡᇂ'
    '\ue58c': '\ua96f\u1163',                                // 'ꥯᅣ'
    '\ue58d': '\ua96f\u1178',                                // 'ꥯᅸ'
    '\ue58e': '\ua96f\u1167',                                // 'ꥯᅧ'
    '\ue58f': '\ua96f\u1168',                                // 'ꥯᅨ'
    '\ue590': '\ua970\u1167',                                // 'ꥰᅧ'
    '\ue591': '\ua970\u1167\u11af',                          // 'ꥰᅧᆯ'
    '\ue592': '\ua970\u1169',                                // 'ꥰᅩ'
    '\ue593': '\u111c\u1161',                                // 'ᄜᅡ'
    '\ue594': '\u111c\u1161\u11ab',                          // 'ᄜᅡᆫ'
    '\ue595': '\u111c\u1161\u11ae',                          // 'ᄜᅡᆮ'
    '\ue596': '\u111c\u1162',                                // 'ᄜᅢ'
    '\ue597': '\u111c\u1163',                                // 'ᄜᅣ'
    '\ue598': '\u111c\u1165',                                // 'ᄜᅥ'
    '\ue599': '\u111c\u1166',                                // 'ᄜᅦ'
    '\ue59a': '\u111c\u1167',                                // 'ᄜᅧ'
    '\ue59b': '\u111c\u1168',                                // 'ᄜᅨ'
    '\ue59c': '\u111c\u1168\u11ab',                          // 'ᄜᅨᆫ'
    '\ue59d': '\u111c\u1168\u11ae',                          // 'ᄜᅨᆮ'
    '\ue59e': '\u111c\u1169',                                // 'ᄜᅩ'
    '\ue59f': '\u111c\u1169\u11a8',                          // 'ᄜᅩᆨ'
    '\ue5a0': '\u111c\u1169\u11ab',                          // 'ᄜᅩᆫ'
    '\ue5a1': '\u111c\u1169\u11ae',                          // 'ᄜᅩᆮ'
    '\ue5a2': '\u111c\u116a',                                // 'ᄜᅪ'
    '\ue5a3': '\u111c\u116b',                                // 'ᄜᅫ'
    '\ue5a4': '\u111c\u116c',                                // 'ᄜᅬ'
    '\ue5a5': '\u111c\u116d',                                // 'ᄜᅭ'
    '\ue5a6': '\u111c\u116e',                                // 'ᄜᅮ'
    '\ue5a7': '\u111c\u116e\u11ab',                          // 'ᄜᅮᆫ'
    '\ue5a8': '\u111c\u116e\u11bc',                          // 'ᄜᅮᆼ'
    '\ue5a9': '\u111c\u116f',                                // 'ᄜᅯ'
    '\ue5aa': '\u111c\u1170',                                // 'ᄜᅰ'
    '\ue5ab': '\u111c\ud7b5',                                // 'ᄜힵ'
    '\ue5ac': '\u111c\u118c',                                // 'ᄜᆌ'
    '\ue5ad': '\u111c\u1171',                                // 'ᄜᅱ'
    '\ue5ae': '\u111c\u1172',                                // 'ᄜᅲ'
    '\ue5af': '\u111c\u1194',                                // 'ᄜᆔ'
    '\ue5b0': '\u111c\u1173',                                // 'ᄜᅳ'
    '\ue5b1': '\u111c\u1174',                                // 'ᄜᅴ'
    '\ue5b2': '\u111c\u1175',                                // 'ᄜᅵ'
    '\ue5b3': '\u111c\u1175\u11ab',                          // 'ᄜᅵᆫ'
    '\ue5b4': '\u111c\u1198',                                // 'ᄜᆘ'
    '\ue5b5': '\u111c\u119e',                                // 'ᄜᆞ'
    '\ue5b6': '\u111c\u11a1',                                // 'ᄜᆡ'
    '\ue5b7': '\ua971\u1169',                                // 'ꥱᅩ'
    '\ue5b8': '\ua971\u1169\u11a8',                          // 'ꥱᅩᆨ'
    '\ue5b9': '\u111d\u1161',                                // 'ᄝᅡ'
    '\ue5ba': '\u111d\u1161\u11ab',                          // 'ᄝᅡᆫ'
    '\ue5bb': '\u111d\u1161\u11bc',                          // 'ᄝᅡᆼ'
    '\ue5bc': '\u111d\u1161\u11f0',                          // 'ᄝᅡᇰ'
    '\ue5bd': '\u111d\u1169',                                // 'ᄝᅩ'
    '\ue5be': '\u111d\u116a',                                // 'ᄝᅪ'
    '\ue5bf': '\u111d\u116a\u11ab',                          // 'ᄝᅪᆫ'
    '\ue5c0': '\u111d\u116a\u11f9',                          // 'ᄝᅪᇹ'
    '\ue5c1': '\u111d\u116e',                                // 'ᄝᅮ'
    '\ue5c2': '\u111d\u116e\u11ab',                          // 'ᄝᅮᆫ'
    '\ue5c3': '\u111d\u116e\u11bc',                          // 'ᄝᅮᆼ'
    '\ue5c4': '\u111d\u116e\u11f9',                          // 'ᄝᅮᇹ'
    '\ue5c5': '\u111d\u1171',                                // 'ᄝᅱ'
    '\ue5c6': '\u111d\u1172',                                // 'ᄝᅲ'
    '\ue5c7': '\u111d\u1172\u11ab',                          // 'ᄝᅲᆫ'
    '\ue5c8': '\u111d\u1173',                                // 'ᄝᅳ'
    '\ue5c9': '\u111d\u1173\u11ab',                          // 'ᄝᅳᆫ'
    '\ue5ca': '\u111d\u1173\u11f9',                          // 'ᄝᅳᇹ'
    '\ue5cb': '\u111d\ud7b9\u11ab',                          // 'ᄝힹᆫ'
    '\ue5cc': '\u111d\u1175',                                // 'ᄝᅵ'
    '\ue5cd': '\u111d\u1175\u11bc',                          // 'ᄝᅵᆼ'
    '\ue5ce': '\u1107\u1161\u11c3',                          // '바ᇃ'
    '\ue5cf': '\u1107\u1161\u11fc',                          // '바ᇼ'
    '\ue5d0': '\u1107\u1161\ud7cb',                          // '바ퟋ'
    '\ue5d1': '\u1107\u1161\u11c7',                          // '바ᇇ'
    '\ue5d2': '\u1107\u1161\u11c9',                          // '바ᇉ'
    '\ue5d3': '\u1107\u1161\u11ca',                          // '바ᇊ'
    '\ue5d4': '\u1107\u1161\ud7d0',                          // '바ퟐ'
    '\ue5d5': '\u1107\u1161\ud7d1',                          // '바ퟑ'
    '\ue5d6': '\u1107\u1161\ud7d4',                          // '바ퟔ'
    '\ue5d7': '\u1107\u1161\u11d0',                          // '바ᇐ'
    '\ue5d8': '\u1107\u1161\u11d9',                          // '바ᇙ'
    '\ue5d9': '\u1107\u1161\ud7e0',                          // '바ퟠ'
    '\ue5da': '\u1107\u1161\u11dc',                          // '바ᇜ'
    '\ue5db': '\u1107\u1161\u11dd',                          // '바ᇝ'
    '\ue5dc': '\u1107\u1161\u11e2',                          // '바ᇢ'
    '\ue5dd': '\u1107\u1161\u11e3',                          // '바ᇣ'
    '\ue5de': '\u1107\u1161\u11e6',                          // '바ᇦ'
    '\ue5df': '\u1107\u1161\u11e7',                          // '바ᇧ'
    '\ue5e0': '\u1107\u1161\u11e8',                          // '바ᇨ'
    '\ue5e1': '\u1107\u1161\u11ea',                          // '바ᇪ'
    '\ue5e2': '\u1107\u1161\ud7f0',                          // '바ퟰ'
    '\ue5e3': '\u1107\u1161\u11eb',                          // '바ᇫ'
    '\ue5e4': '\u1107\u1161\u11f1',                          // '바ᇱ'
    '\ue5e5': '\u1107\u1161\u11f0',                          // '바ᇰ'
    '\ue5e6': '\u1107\u1161\u11f1',                          // '바ᇱ'
    '\ue5e7': '\u1107\u1161\u11f6',                          // '바ᇶ'
    '\ue5e8': '\u1107\u1161\u11f9',                          // '바ᇹ'
    '\ue5e9': '\u1107\u1176',                                // 'ᄇᅶ'
    '\ue5ea': '\u1107\u1176\u11af',                          // 'ᄇᅶᆯ'
    '\ue5eb': '\u1107\u1177',                                // 'ᄇᅷ'
    '\ue5ec': '\u1107\u1177\u11af',                          // 'ᄇᅷᆯ'
    '\ue5ed': '\u1107\u1162\u11f0',                          // '배ᇰ'
    '\ue5ee': '\u1107\u1163\u11e2',                          // '뱌ᇢ'
    '\ue5ef': '\u1107\u1178',                                // 'ᄇᅸ'
    '\ue5f0': '\u1107\u1165\u11c7',                          // '버ᇇ'
    '\ue5f1': '\u1107\u1165\u11cd',                          // '버ᇍ'
    '\ue5f2': '\u1107\u1165\u11d9',                          // '버ᇙ'
    '\ue5f3': '\u1107\u1165\u11eb',                          // '버ᇫ'
    '\ue5f4': '\u1107\u1165\u11f0',                          // '버ᇰ'
    '\ue5f5': '\u1107\u117b',                                // 'ᄇᅻ'
    '\ue5f6': '\u1107\u117b\u11af',                          // 'ᄇᅻᆯ'
    '\ue5f7': '\u1107\u117c',                                // 'ᄇᅼ'
    '\ue5f8': '\u1107\u117c\u11a8',                          // 'ᄇᅼᆨ'
    '\ue5f9': '\u1107\u117c\u11af',                          // 'ᄇᅼᆯ'
    '\ue5fa': '\u1107\u117c\u11b7',                          // 'ᄇᅼᆷ'
    '\ue5fb': '\u1107\u117c\u11bc',                          // 'ᄇᅼᆼ'
    '\ue5fc': '\u1107\u1167\u11d9',                          // '벼ᇙ'
    '\ue5fd': '\u1107\u1167\u11e2',                          // '벼ᇢ'
    '\ue5fe': '\u1107\u1167\ud7f1',                          // '벼ퟱ'
    '\ue5ff': '\u1107\u1167\u11f0',                          // '벼ᇰ'
    '\ue600': '\u1107\u1167\u11f1',                          // '벼ᇱ'
    '\ue601': '\u1107\u1167\u11f9',                          // '벼ᇹ'
    '\ue602': '\u1107\u1169\u11d0',                          // '보ᇐ'
    '\ue603': '\u1107\u1169\u11d9',                          // '보ᇙ'
    '\ue604': '\u1107\u1169\ud7e0',                          // '보ퟠ'
    '\ue605': '\u1107\u1169\u11dc',                          // '보ᇜ'
    '\ue606': '\u1107\u1169\u11dd',                          // '보ᇝ'
    '\ue607': '\u1107\u1169\u11e2',                          // '보ᇢ'
    '\ue608': '\u1107\u1169\u11e7',                          // '보ᇧ'
    '\ue609': '\u1107\u1169\ud7ec',                          // '보ퟬ'
    '\ue60a': '\u1107\u1169\u11eb',                          // '보ᇫ'
    '\ue60b': '\u1107\u1169\u11f0',                          // '보ᇰ'
    '\ue60c': '\u1107\u1182',                                // 'ᄇᆂ'
    '\ue60d': '\u1107\ud7b1',                                // 'ᄇힱ'
    '\ue60e': '\u1107\u116c\u11d9',                          // '뵈ᇙ'
    '\ue60f': '\u1107\u116c\ud7f0',                          // '뵈ퟰ'
    '\ue610': '\u1107\u116d\u11d9',                          // '뵤ᇙ'
    '\ue611': '\u1107\u116d\u11e2',                          // '뵤ᇢ'
    '\ue612': '\u1107\u116d\u11f0',                          // '뵤ᇰ'
    '\ue613': '\u1107\u1187',                                // 'ᄇᆇ'
    '\ue614': '\u1107\u1188',                                // 'ᄇᆈ'
    '\ue615': '\u1107\u116e\u11c7',                          // '부ᇇ'
    '\ue616': '\u1107\u116e\u11d0',                          // '부ᇐ'
    '\ue617': '\u1107\u116e\u11d1',                          // '부ᇑ'
    '\ue618': '\u1107\u116e\u11d2',                          // '부ᇒ'
    '\ue619': '\u1107\u116e\u11d9',                          // '부ᇙ'
    '\ue61a': '\u1107\u116e\u11e2',                          // '부ᇢ'
    '\ue61b': '\u1107\u116e\u11e6',                          // '부ᇦ'
    '\ue61c': '\u1107\u116e\u11e7',                          // '부ᇧ'
    '\ue61d': '\u1107\u116e\ud7f0',                          // '부ퟰ'
    '\ue61e': '\u1107\u116e\u11eb',                          // '부ᇫ'
    '\ue61f': '\u1107\u116e\u11ef',                          // '부ᇯ'
    '\ue620': '\u1107\u116e\u11f0',                          // '부ᇰ'
    '\ue621': '\u1107\u116e\u11f9',                          // '부ᇹ'
    '\ue622': '\u1107\u116f\u11f9',                          // '붜ᇹ'
    '\ue623': '\u1107\ud7b5',                                // 'ᄇힵ'
    '\ue624': '\u1107\u118c',                                // 'ᄇᆌ'
    '\ue625': '\u1107\u118d',                                // 'ᄇᆍ'
    '\ue626': '\u1107\u1171\u11d9',                          // '뷔ᇙ'
    '\ue627': '\u1107\u1171\u11f9',                          // '뷔ᇹ'
    '\ue628': '\u1107\u1172\u11d9',                          // '뷰ᇙ'
    '\ue629': '\u1107\u1172\u11e2',                          // '뷰ᇢ'
    '\ue62a': '\u1107\u1194',                                // 'ᄇᆔ'
    '\ue62b': '\u1107\u1173\u11c3',                          // '브ᇃ'
    '\ue62c': '\u1107\u1173\ud7d4',                          // '브ퟔ'
    '\ue62d': '\u1107\u1173\u11d0',                          // '브ᇐ'
    '\ue62e': '\u1107\u1173\u11d9',                          // '브ᇙ'
    '\ue62f': '\u1107\u1173\u11dd',                          // '브ᇝ'
    '\ue630': '\u1107\u1173\u11e7',                          // '브ᇧ'
    '\ue631': '\u1107\u1173\ud7f1',                          // '브ퟱ'
    '\ue632': '\u1107\u1173\u11eb',                          // '브ᇫ'
    '\ue633': '\u1107\u1173\u11f0',                          // '브ᇰ'
    '\ue634': '\u1107\u1173\u11f9',                          // '브ᇹ'
    '\ue635': '\u1107\u1196',                                // 'ᄇᆖ'
    '\ue636': '\u1107\u1174\u11f9',                          // '븨ᇹ'
    '\ue637': '\u1107\u1175\u11ca',                          // '비ᇊ'
    '\ue638': '\u1107\u1175\ud7d0',                          // '비ퟐ'
    '\ue639': '\u1107\u1175\u11d9',                          // '비ᇙ'
    '\ue63a': '\u1107\u1175\u11e2',                          // '비ᇢ'
    '\ue63b': '\u1107\u1175\u11e7',                          // '비ᇧ'
    '\ue63c': '\u1107\u1175\ud7ee',                          // '비ퟮ'
    '\ue63d': '\u1107\u1175\ud7f0',                          // '비ퟰ'
    '\ue63e': '\u1107\u1175\ud7f2',                          // '비ퟲ'
    '\ue63f': '\u1107\u1175\u11eb',                          // '비ᇫ'
    '\ue640': '\u1107\u1175\u11f0',                          // '비ᇰ'
    '\ue641': '\u1107\u1175\u11f1',                          // '비ᇱ'
    '\ue642': '\u1107\u1175\u11f9',                          // '비ᇹ'
    '\ue643': '\u1107\u1198',                                // 'ᄇᆘ'
    '\ue644': '\u1107\u1199',                                // 'ᄇᆙ'
    '\ue645': '\u1107\u1199\u11ab',                          // 'ᄇᆙᆫ'
    '\ue646': '\u1107\ud7bf',                                // 'ᄇힿ'
    '\ue647': '\u1107\ud7c0',                                // 'ᄇퟀ'
    '\ue648': '\u1107\u119a',                                // 'ᄇᆚ'
    '\ue649': '\u1107\u119b',                                // 'ᄇᆛ'
    '\ue64a': '\u1107\u119e',                                // 'ᄇᆞ'
    '\ue64b': '\u1107\u119e\u11a8',                          // 'ᄇᆞᆨ'
    '\ue64c': '\u1107\u119e\u11c3',                          // 'ᄇᆞᇃ'
    '\ue64d': '\u1107\u119e\u11ab',                          // 'ᄇᆞᆫ'
    '\ue64e': '\u1107\u119e\u11ae',                          // 'ᄇᆞᆮ'
    '\ue64f': '\u1107\u119e\u11af',                          // 'ᄇᆞᆯ'
    '\ue650': '\u1107\u119e\u11b0',                          // 'ᄇᆞᆰ'
    '\ue651': '\u1107\u119e\u11d0',                          // 'ᄇᆞᇐ'
    '\ue652': '\u1107\u119e\u11b1',                          // 'ᄇᆞᆱ'
    '\ue653': '\u1107\u119e\u11b2',                          // 'ᄇᆞᆲ'
    '\ue654': '\u1107\u119e\u11d5',                          // 'ᄇᆞᇕ'
    '\ue655': '\u1107\u119e\u11b3',                          // 'ᄇᆞᆳ'
    '\ue656': '\u1107\u119e\u11b4',                          // 'ᄇᆞᆴ'
    '\ue657': '\u1107\u119e\u11b6',                          // 'ᄇᆞᆶ'
    '\ue658': '\u1107\u119e\u11d9',                          // 'ᄇᆞᇙ'
    '\ue659': '\u1107\u119e\u11b7',                          // 'ᄇᆞᆷ'
    '\ue65a': '\u1107\u119e\u11b8',                          // 'ᄇᆞᆸ'
    '\ue65b': '\u1107\u119e\u11e3',                          // 'ᄇᆞᇣ'
    '\ue65c': '\u1107\u119e\u11ba',                          // 'ᄇᆞᆺ'
    '\ue65d': '\u1107\u119e\ud7f1',                          // 'ᄇᆞퟱ'
    '\ue65e': '\u1107\u119e\u11eb',                          // 'ᄇᆞᇫ'
    '\ue65f': '\u1107\u119e\u11f0',                          // 'ᄇᆞᇰ'
    '\ue660': '\u1107\u119e\u11bd',                          // 'ᄇᆞᆽ'
    '\ue661': '\u1107\u119e\u11be',                          // 'ᄇᆞᆾ'
    '\ue662': '\u1107\u119e\u11c0',                          // 'ᄇᆞᇀ'
    '\ue663': '\u1107\u119f',                                // 'ᄇᆟ'
    '\ue664': '\u1107\u119f\u11ab',                          // 'ᄇᆟᆫ'
    '\ue665': '\u1107\u119f\u11af',                          // 'ᄇᆟᆯ'
    '\ue666': '\u1107\u119f\u11bc',                          // 'ᄇᆟᆼ'
    '\ue667': '\u1107\ud7c6',                                // 'ᄇퟆ'
    '\ue668': '\u1107\u11a1',                                // 'ᄇᆡ'
    '\ue669': '\u1107\u11a1\u11a8',                          // 'ᄇᆡᆨ'
    '\ue66a': '\u1107\u11a1\u11ab',                          // 'ᄇᆡᆫ'
    '\ue66b': '\u1107\u11a1\u11ae',                          // 'ᄇᆡᆮ'
    '\ue66c': '\u1107\u11a1\u11af',                          // 'ᄇᆡᆯ'
    '\ue66d': '\u1107\u11a1\u11b0',                          // 'ᄇᆡᆰ'
    '\ue66e': '\u1107\u11a1\u11d9',                          // 'ᄇᆡᇙ'
    '\ue66f': '\u1107\u11a1\u11b7',                          // 'ᄇᆡᆷ'
    '\ue670': '\u1107\u11a1\u11b8',                          // 'ᄇᆡᆸ'
    '\ue671': '\u1107\u11a1\u11ba',                          // 'ᄇᆡᆺ'
    '\ue672': '\u1107\u11a1\u11bc',                          // 'ᄇᆡᆼ'
    '\ue673': '\u1107\u11a1\u11f0',                          // 'ᄇᆡᇰ'
    '\ue674': '\u1107\u11a1\u11f1',                          // 'ᄇᆡᇱ'
    '\ue675': '\u1107\u11a1\u11bd',                          // 'ᄇᆡᆽ'
    '\ue676': '\u1107\u11a1\u11be',                          // 'ᄇᆡᆾ'
    '\ue677': '\u1107\u11a1\u11c0',                          // 'ᄇᆡᇀ'
    '\ue678': '\u1107\u11a1\u11c2',                          // 'ᄇᆡᇂ'
    '\ue679': '\u111e\u1161',                                // 'ᄞᅡ'
    '\ue67a': '\u111e\u1161\u11a8',                          // 'ᄞᅡᆨ'
    '\ue67b': '\u111e\u1161\u11b7',                          // 'ᄞᅡᆷ'
    '\ue67c': '\u111e\u1162',                                // 'ᄞᅢ'
    '\ue67d': '\u111e\u1162\u11ab',                          // 'ᄞᅢᆫ'
    '\ue67e': '\u111e\u1165',                                // 'ᄞᅥ'
    '\ue67f': '\u111e\u1165\u11ba',                          // 'ᄞᅥᆺ'
    '\ue680': '\u111e\u1166',                                // 'ᄞᅦ'
    '\ue681': '\u111e\u1166\u11af',                          // 'ᄞᅦᆯ'
    '\ue682': '\u111e\u1167',                                // 'ᄞᅧ'
    '\ue683': '\u111e\u1167\u11ab',                          // 'ᄞᅧᆫ'
    '\ue684': '\u111e\u1167\u11ba',                          // 'ᄞᅧᆺ'
    '\ue685': '\u111e\u1168',                                // 'ᄞᅨ'
    '\ue686': '\u111e\u1168\u11af',                          // 'ᄞᅨᆯ'
    '\ue687': '\u111e\u1169',                                // 'ᄞᅩ'
    '\ue688': '\u111e\u1169\u11ba',                          // 'ᄞᅩᆺ'
    '\ue689': '\u111e\u116c',                                // 'ᄞᅬ'
    '\ue68a': '\u111e\u116c\u11ba',                          // 'ᄞᅬᆺ'
    '\ue68b': '\u111e\u116e',                                // 'ᄞᅮ'
    '\ue68c': '\u111e\u116e\u11af',                          // 'ᄞᅮᆯ'
    '\ue68d': '\u111e\u116e\u11b7',                          // 'ᄞᅮᆷ'
    '\ue68e': '\u111e\u116e\u11ba',                          // 'ᄞᅮᆺ'
    '\ue68f': '\u111e\u116f',                                // 'ᄞᅯ'
    '\ue690': '\u111e\u1170',                                // 'ᄞᅰ'
    '\ue691': '\u111e\u1171',                                // 'ᄞᅱ'
    '\ue692': '\u111e\u1173',                                // 'ᄞᅳ'
    '\ue693': '\u111e\u1173\u11ab',                          // 'ᄞᅳᆫ'
    '\ue694': '\u111e\u1173\u11ae',                          // 'ᄞᅳᆮ'
    '\ue695': '\u111e\u1173\u11af',                          // 'ᄞᅳᆯ'
    '\ue696': '\u111e\u1173\u11ba',                          // 'ᄞᅳᆺ'
    '\ue697': '\u111e\u1174',                                // 'ᄞᅴ'
    '\ue698': '\u111e\u1174\u11ba',                          // 'ᄞᅴᆺ'
    '\ue699': '\u111e\u1175',                                // 'ᄞᅵ'
    '\ue69a': '\u111e\u1175\u11af',                          // 'ᄞᅵᆯ'
    '\ue69b': '\u111e\u1175\u11b7',                          // 'ᄞᅵᆷ'
    '\ue69c': '\u111e\u1175\u11ba',                          // 'ᄞᅵᆺ'
    '\ue69d': '\u111e\u119e',                                // 'ᄞᆞ'
    '\ue69e': '\u111e\u119e\u11af',                          // 'ᄞᆞᆯ'
    '\ue69f': '\u111e\u11a1',                                // 'ᄞᆡ'
    '\ue6a0': '\u111e\u11a1\u11ab',                          // 'ᄞᆡᆫ'
    '\ue6a1': '\u111e\u11a1\u11ba',                          // 'ᄞᆡᆺ'
    '\ue6a2': '\u111f\u1167',                                // 'ᄟᅧ'
    '\ue6a3': '\u1120\u1161',                                // 'ᄠᅡ'
    '\ue6a4': '\u1120\u1161\u11a8',                          // 'ᄠᅡᆨ'
    '\ue6a5': '\u1120\u1161\u11ab',                          // 'ᄠᅡᆫ'
    '\ue6a6': '\u1120\u1161\u11af',                          // 'ᄠᅡᆯ'
    '\ue6a7': '\u1120\u1161\u11b7',                          // 'ᄠᅡᆷ'
    '\ue6a8': '\u1120\u1161\u11b8',                          // 'ᄠᅡᆸ'
    '\ue6a9': '\u1120\u1161\u11ba',                          // 'ᄠᅡᆺ'
    '\ue6aa': '\u1120\u1162',                                // 'ᄠᅢ'
    '\ue6ab': '\u1120\u1163',                                // 'ᄠᅣ'
    '\ue6ac': '\u1120\u1165',                                // 'ᄠᅥ'
    '\ue6ad': '\u1120\u1165\u11a8',                          // 'ᄠᅥᆨ'
    '\ue6ae': '\u1120\u1165\u11ab',                          // 'ᄠᅥᆫ'
    '\ue6af': '\u1120\u1165\u11ae',                          // 'ᄠᅥᆮ'
    '\ue6b0': '\u1120\u1165\u11af',                          // 'ᄠᅥᆯ'
    '\ue6b1': '\u1120\u1165\u11b2',                          // 'ᄠᅥᆲ'
    '\ue6b2': '\u1120\u1165\u11d5',                          // 'ᄠᅥᇕ'
    '\ue6b3': '\u1120\u1165\u11b7',                          // 'ᄠᅥᆷ'
    '\ue6b4': '\u1120\u1165\u11ba',                          // 'ᄠᅥᆺ'
    '\ue6b5': '\u1120\u1166',                                // 'ᄠᅦ'
    '\ue6b6': '\u1120\u1166\u11ab',                          // 'ᄠᅦᆫ'
    '\ue6b7': '\u1120\u1166\u11ba',                          // 'ᄠᅦᆺ'
    '\ue6b8': '\u1120\u1167',                                // 'ᄠᅧ'
    '\ue6b9': '\u1120\u1167\u11ba',                          // 'ᄠᅧᆺ'
    '\ue6ba': '\u1120\u1168',                                // 'ᄠᅨ'
    '\ue6bb': '\u1120\u1168\u11ba',                          // 'ᄠᅨᆺ'
    '\ue6bc': '\u1120\u1169',                                // 'ᄠᅩ'
    '\ue6bd': '\u1120\u1169\u11a8',                          // 'ᄠᅩᆨ'
    '\ue6be': '\u1120\u1169\u11af',                          // 'ᄠᅩᆯ'
    '\ue6bf': '\u1120\u1169\u11b7',                          // 'ᄠᅩᆷ'
    '\ue6c0': '\u1120\u1169\u11bc',                          // 'ᄠᅩᆼ'
    '\ue6c1': '\u1120\u116a',                                // 'ᄠᅪ'
    '\ue6c2': '\u1120\u116b',                                // 'ᄠᅫ'
    '\ue6c3': '\u1120\u116c',                                // 'ᄠᅬ'
    '\ue6c4': '\u1120\u116c\u11ba',                          // 'ᄠᅬᆺ'
    '\ue6c5': '\u1120\u116d',                                // 'ᄠᅭ'
    '\ue6c6': '\u1120\u116e',                                // 'ᄠᅮ'
    '\ue6c7': '\u1120\u116e\u11a8',                          // 'ᄠᅮᆨ'
    '\ue6c8': '\u1120\u116e\u11af',                          // 'ᄠᅮᆯ'
    '\ue6c9': '\u1120\u116e\u11b2',                          // 'ᄠᅮᆲ'
    '\ue6ca': '\u1120\u116e\u11b7',                          // 'ᄠᅮᆷ'
    '\ue6cb': '\u1120\u116e\u11ba',                          // 'ᄠᅮᆺ'
    '\ue6cc': '\u1120\u116e\u11bc',                          // 'ᄠᅮᆼ'
    '\ue6cd': '\u1120\u116e\u11f0',                          // 'ᄠᅮᇰ'
    '\ue6ce': '\u1120\u116f',                                // 'ᄠᅯ'
    '\ue6cf': '\u1120\u1170',                                // 'ᄠᅰ'
    '\ue6d0': '\u1120\ud7b5',                                // 'ᄠힵ'
    '\ue6d1': '\u1120\u118c',                                // 'ᄠᆌ'
    '\ue6d2': '\u1120\u1171',                                // 'ᄠᅱ'
    '\ue6d3': '\u1120\u1171\u11af',                          // 'ᄠᅱᆯ'
    '\ue6d4': '\u1120\u1171\u11ba',                          // 'ᄠᅱᆺ'
    '\ue6d5': '\u1120\u1172',                                // 'ᄠᅲ'
    '\ue6d6': '\u1120\u1194',                                // 'ᄠᆔ'
    '\ue6d7': '\u1120\u1173',                                // 'ᄠᅳ'
    '\ue6d8': '\u1120\u1173\u11a8',                          // 'ᄠᅳᆨ'
    '\ue6d9': '\u1120\u1173\u11ab',                          // 'ᄠᅳᆫ'
    '\ue6da': '\u1120\u1173\u11ae',                          // 'ᄠᅳᆮ'
    '\ue6db': '\u1120\u1173\u11af',                          // 'ᄠᅳᆯ'
    '\ue6dc': '\u1120\u1173\u11b0',                          // 'ᄠᅳᆰ'
    '\ue6dd': '\u1120\u1173\u11b2',                          // 'ᄠᅳᆲ'
    '\ue6de': '\u1120\u1173\u11b3',                          // 'ᄠᅳᆳ'
    '\ue6df': '\u1120\u1173\u11b6',                          // 'ᄠᅳᆶ'
    '\ue6e0': '\u1120\u1173\u11b7',                          // 'ᄠᅳᆷ'
    '\ue6e1': '\u1120\u1173\u11ba',                          // 'ᄠᅳᆺ'
    '\ue6e2': '\u1120\u1173\u11bc',                          // 'ᄠᅳᆼ'
    '\ue6e3': '\u1120\u1173\u11bd',                          // 'ᄠᅳᆽ'
    '\ue6e4': '\u1120\u1174',                                // 'ᄠᅴ'
    '\ue6e5': '\u1120\u1174\u11af',                          // 'ᄠᅴᆯ'
    '\ue6e6': '\u1120\u1174\u11ba',                          // 'ᄠᅴᆺ'
    '\ue6e7': '\u1120\u1175',                                // 'ᄠᅵ'
    '\ue6e8': '\u1120\u1175\u11a8',                          // 'ᄠᅵᆨ'
    '\ue6e9': '\u1120\u1175\u11ab',                          // 'ᄠᅵᆫ'
    '\ue6ea': '\u1120\u1175\u11af',                          // 'ᄠᅵᆯ'
    '\ue6eb': '\u1120\u1175\u11d0',                          // 'ᄠᅵᇐ'
    '\ue6ec': '\u1120\u1175\u11b7',                          // 'ᄠᅵᆷ'
    '\ue6ed': '\u1120\u1175\u11ba',                          // 'ᄠᅵᆺ'
    '\ue6ee': '\u1120\u1198',                                // 'ᄠᆘ'
    '\ue6ef': '\u1120\u119e',                                // 'ᄠᆞ'
    '\ue6f0': '\u1120\u119e\u11ab',                          // 'ᄠᆞᆫ'
    '\ue6f1': '\u1120\u119e\u11ae',                          // 'ᄠᆞᆮ'
    '\ue6f2': '\u1120\u119e\u11af',                          // 'ᄠᆞᆯ'
    '\ue6f3': '\u1120\u119e\u11b7',                          // 'ᄠᆞᆷ'
    '\ue6f4': '\u1120\u119e\u11ba',                          // 'ᄠᆞᆺ'
    '\ue6f5': '\u1120\u11a1',                                // 'ᄠᆡ'
    '\ue6f6': '\u1120\u11a1\u11ab',                          // 'ᄠᆡᆫ'
    '\ue6f7': '\u1120\u11a1\u11ba',                          // 'ᄠᆡᆺ'
    '\ue6f8': '\u1108\u1161\u11d0',                          // '빠ᇐ'
    '\ue6f9': '\u1108\u1161\u11d9',                          // '빠ᇙ'
    '\ue6fa': '\u1108\u1161\u11e2',                          // '빠ᇢ'
    '\ue6fb': '\u1108\u1161\u11e6',                          // '빠ᇦ'
    '\ue6fc': '\u1108\u1161\u11f0',                          // '빠ᇰ'
    '\ue6fd': '\u1108\u1161\u11f9',                          // '빠ᇹ'
    '\ue6fe': '\u1108\u1163\u11e2',                          // '뺘ᇢ'
    '\ue6ff': '\u1108\u1163\u11f0',                          // '뺘ᇰ'
    '\ue700': '\u1108\u1165\u11d9',                          // '뻐ᇙ'
    '\ue701': '\u1108\u1165\u11f9',                          // '뻐ᇹ'
    '\ue702': '\u1108\u1167\u11d9',                          // '뼈ᇙ'
    '\ue703': '\u1108\u1167\u11e2',                          // '뼈ᇢ'
    '\ue704': '\u1108\u1167\u11f0',                          // '뼈ᇰ'
    '\ue705': '\u1108\u1167\u11f9',                          // '뼈ᇹ'
    '\ue706': '\u1108\u1169\u11d9',                          // '뽀ᇙ'
    '\ue707': '\u1108\u1169\u11e2',                          // '뽀ᇢ'
    '\ue708': '\u1108\u1169\u11f0',                          // '뽀ᇰ'
    '\ue709': '\u1108\u116d\u11e2',                          // '뾰ᇢ'
    '\ue70a': '\u1108\u116e\u11d9',                          // '뿌ᇙ'
    '\ue70b': '\u1108\u116e\u11e2',                          // '뿌ᇢ'
    '\ue70c': '\u1108\u116e\u11f0',                          // '뿌ᇰ'
    '\ue70d': '\u1108\u116e\u11f9',                          // '뿌ᇹ'
    '\ue70e': '\u1108\u116f\u11f9',                          // '뿨ᇹ'
    '\ue70f': '\u1108\ud7b5',                                // 'ᄈힵ'
    '\ue710': '\u1108\ud7b5\u11f9',                          // 'ᄈힵᇹ'
    '\ue711': '\u1108\u1172\u11e2',                          // '쀼ᇢ'
    '\ue712': '\u1108\u1173\u11d9',                          // '쁘ᇙ'
    '\ue713': '\u1108\u1173\u11e2',                          // '쁘ᇢ'
    '\ue714': '\u1108\u1173\ud7e6',                          // '쁘ퟦ'
    '\ue715': '\u1108\u1173\u11f0',                          // '쁘ᇰ'
    '\ue716': '\u1108\u1173\u11f9',                          // '쁘ᇹ'
    '\ue717': '\u1108\u1174\u11f0',                          // '쁴ᇰ'
    '\ue718': '\u1108\u1174\u11f9',                          // '쁴ᇹ'
    '\ue719': '\u1108\u1175\u11d9',                          // '삐ᇙ'
    '\ue71a': '\u1108\u1175\u11e2',                          // '삐ᇢ'
    '\ue71b': '\u1108\u1175\u11f0',                          // '삐ᇰ'
    '\ue71c': '\u1108\u1175\u11f9',                          // '삐ᇹ'
    '\ue71d': '\u1108\u119e',                                // 'ᄈᆞ'
    '\ue71e': '\u1108\u119e\u11af',                          // 'ᄈᆞᆯ'
    '\ue71f': '\u1108\u119e\u11b3',                          // 'ᄈᆞᆳ'
    '\ue720': '\u1108\u119e\u11d9',                          // 'ᄈᆞᇙ'
    '\ue721': '\u1108\u11a1',                                // 'ᄈᆡ'
    '\ue722': '\u1108\u11a1\u11a8',                          // 'ᄈᆡᆨ'
    '\ue723': '\u1108\u11a1\u11bc',                          // 'ᄈᆡᆼ'
    '\ue724': '\u1108\u11a1\u11f0',                          // 'ᄈᆡᇰ'
    '\ue725': '\u1121\u1161',                                // 'ᄡᅡ'
    '\ue726': '\u1121\u1161\u11a8',                          // 'ᄡᅡᆨ'
    '\ue727': '\u1121\u1161\u11ab',                          // 'ᄡᅡᆫ'
    '\ue728': '\u1121\u1161\u11ae',                          // 'ᄡᅡᆮ'
    '\ue729': '\u1121\u1161\u11af',                          // 'ᄡᅡᆯ'
    '\ue72a': '\u1121\u1161\u11b2',                          // 'ᄡᅡᆲ'
    '\ue72b': '\u1121\u1161\u11b7',                          // 'ᄡᅡᆷ'
    '\ue72c': '\u1121\u1161\u11b8',                          // 'ᄡᅡᆸ'
    '\ue72d': '\u1121\u1161\u11ba',                          // 'ᄡᅡᆺ'
    '\ue72e': '\u1121\u1161\ud7f1',                          // 'ᄡᅡퟱ'
    '\ue72f': '\u1121\u1161\ud7f2',                          // 'ᄡᅡퟲ'
    '\ue730': '\u1121\u1161\u11bc',                          // 'ᄡᅡᆼ'
    '\ue731': '\u1121\u1161\u11f0',                          // 'ᄡᅡᇰ'
    '\ue732': '\u1121\u1161\u11c2',                          // 'ᄡᅡᇂ'
    '\ue733': '\u1121\u1162',                                // 'ᄡᅢ'
    '\ue734': '\u1121\u1162\u11b8',                          // 'ᄡᅢᆸ'
    '\ue735': '\u1121\u1163',                                // 'ᄡᅣ'
    '\ue736': '\u1121\u1163\u11bc',                          // 'ᄡᅣᆼ'
    '\ue737': '\u1121\u1163\u11f0',                          // 'ᄡᅣᇰ'
    '\ue738': '\u1121\u1163\u11c1',                          // 'ᄡᅣᇁ'
    '\ue739': '\u1121\u1163\u11c2',                          // 'ᄡᅣᇂ'
    '\ue73a': '\u1121\u1164',                                // 'ᄡᅤ'
    '\ue73b': '\u1121\u1165',                                // 'ᄡᅥ'
    '\ue73c': '\u1121\u1165\u11a8',                          // 'ᄡᅥᆨ'
    '\ue73d': '\u1121\u1165\u11ab',                          // 'ᄡᅥᆫ'
    '\ue73e': '\u1121\u1165\u11af',                          // 'ᄡᅥᆯ'
    '\ue73f': '\u1121\u1165\u11b7',                          // 'ᄡᅥᆷ'
    '\ue740': '\u1121\u1165\u11ba',                          // 'ᄡᅥᆺ'
    '\ue741': '\u1121\u1166',                                // 'ᄡᅦ'
    '\ue742': '\u1121\u1166\u11ab',                          // 'ᄡᅦᆫ'
    '\ue743': '\u1121\u1166\u11af',                          // 'ᄡᅦᆯ'
    '\ue744': '\u1121\u1167',                                // 'ᄡᅧ'
    '\ue745': '\u1121\u1167\u11a8',                          // 'ᄡᅧᆨ'
    '\ue746': '\u1121\u1167\u11ab',                          // 'ᄡᅧᆫ'
    '\ue747': '\u1121\u1167\u11af',                          // 'ᄡᅧᆯ'
    '\ue748': '\u1121\u1167\u11b7',                          // 'ᄡᅧᆷ'
    '\ue749': '\u1121\u1167\u11ba',                          // 'ᄡᅧᆺ'
    '\ue74a': '\u1121\u1168',                                // 'ᄡᅨ'
    '\ue74b': '\u1121\u1168\u11ab',                          // 'ᄡᅨᆫ'
    '\ue74c': '\u1121\u1169',                                // 'ᄡᅩ'
    '\ue74d': '\u1121\u1169\u11a8',                          // 'ᄡᅩᆨ'
    '\ue74e': '\u1121\u1169\u11ab',                          // 'ᄡᅩᆫ'
    '\ue74f': '\u1121\u1169\u11ae',                          // 'ᄡᅩᆮ'
    '\ue750': '\u1121\u1169\u11af',                          // 'ᄡᅩᆯ'
    '\ue751': '\u1121\u1169\u11b7',                          // 'ᄡᅩᆷ'
    '\ue752': '\u1121\u1169\u11ba',                          // 'ᄡᅩᆺ'
    '\ue753': '\u1121\u1169\u11bc',                          // 'ᄡᅩᆼ'
    '\ue754': '\u1121\u1169\u11f0',                          // 'ᄡᅩᇰ'
    '\ue755': '\u1121\u116a',                                // 'ᄡᅪ'
    '\ue756': '\u1121\u116a\u11bc',                          // 'ᄡᅪᆼ'
    '\ue757': '\u1121\u116a\u11f0',                          // 'ᄡᅪᇰ'
    '\ue758': '\u1121\u116a\u11f9',                          // 'ᄡᅪᇹ'
    '\ue759': '\u1121\u116b',                                // 'ᄡᅫ'
    '\ue75a': '\u1121\u116b\u11ba',                          // 'ᄡᅫᆺ'
    '\ue75b': '\u1121\u116c',                                // 'ᄡᅬ'
    '\ue75c': '\u1121\u116d',                                // 'ᄡᅭ'
    '\ue75d': '\u1121\u116e',                                // 'ᄡᅮ'
    '\ue75e': '\u1121\u116e\u11a8',                          // 'ᄡᅮᆨ'
    '\ue75f': '\u1121\u116e\u11ab',                          // 'ᄡᅮᆫ'
    '\ue760': '\u1121\u116e\u11af',                          // 'ᄡᅮᆯ'
    '\ue761': '\u1121\u116e\u11d9',                          // 'ᄡᅮᇙ'
    '\ue762': '\u1121\u116e\u11b7',                          // 'ᄡᅮᆷ'
    '\ue763': '\u1121\u116e\u11ba',                          // 'ᄡᅮᆺ'
    '\ue764': '\u1121\u116e\u11bd',                          // 'ᄡᅮᆽ'
    '\ue765': '\u1121\u116e\u11be',                          // 'ᄡᅮᆾ'
    '\ue766': '\u1121\u116f',                                // 'ᄡᅯ'
    '\ue767': '\u1121\u1170',                                // 'ᄡᅰ'
    '\ue768': '\u1121\ud7b5',                                // 'ᄡힵ'
    '\ue769': '\u1121\u118c',                                // 'ᄡᆌ'
    '\ue76a': '\u1121\u1171',                                // 'ᄡᅱ'
    '\ue76b': '\u1121\u1172',                                // 'ᄡᅲ'
    '\ue76c': '\u1121\u1172\u11b8',                          // 'ᄡᅲᆸ'
    '\ue76d': '\u1121\u1172\u11ba',                          // 'ᄡᅲᆺ'
    '\ue76e': '\u1121\u1172\u11bd',                          // 'ᄡᅲᆽ'
    '\ue76f': '\u1121\u1194',                                // 'ᄡᆔ'
    '\ue770': '\u1121\u1173',                                // 'ᄡᅳ'
    '\ue771': '\u1121\u1173\u11a8',                          // 'ᄡᅳᆨ'
    '\ue772': '\u1121\u1173\u11ab',                          // 'ᄡᅳᆫ'
    '\ue773': '\u1121\u1173\u11ae',                          // 'ᄡᅳᆮ'
    '\ue774': '\u1121\u1173\u11af',                          // 'ᄡᅳᆯ'
    '\ue775': '\u1121\u1173\u11b6',                          // 'ᄡᅳᆶ'
    '\ue776': '\u1121\u1173\u11d9',                          // 'ᄡᅳᇙ'
    '\ue777': '\u1121\u1173\u11b7',                          // 'ᄡᅳᆷ'
    '\ue778': '\u1121\u1173\u11ba',                          // 'ᄡᅳᆺ'
    '\ue779': '\u1121\u1173\u11be',                          // 'ᄡᅳᆾ'
    '\ue77a': '\u1121\u1174',                                // 'ᄡᅴ'
    '\ue77b': '\u1121\u1174\u11a8',                          // 'ᄡᅴᆨ'
    '\ue77c': '\u1121\u1175',                                // 'ᄡᅵ'
    '\ue77d': '\u1121\u1175\u11a8',                          // 'ᄡᅵᆨ'
    '\ue77e': '\u1121\u1175\u11ab',                          // 'ᄡᅵᆫ'
    '\ue77f': '\u1121\u1175\u11af',                          // 'ᄡᅵᆯ'
    '\ue780': '\u1121\u1175\u11b7',                          // 'ᄡᅵᆷ'
    '\ue781': '\u1121\u1175\u11b8',                          // 'ᄡᅵᆸ'
    '\ue782': '\u1121\u1175\u11ba',                          // 'ᄡᅵᆺ'
    '\ue783': '\u1121\u1175\u11bc',                          // 'ᄡᅵᆼ'
    '\ue784': '\u1121\u1175\u11f0',                          // 'ᄡᅵᇰ'
    '\ue785': '\u1121\u1198',                                // 'ᄡᆘ'
    '\ue786': '\u1121\u119e',                                // 'ᄡᆞ'
    '\ue787': '\u1121\u119e\u11ab',                          // 'ᄡᆞᆫ'
    '\ue788': '\u1121\u119e\u11ae',                          // 'ᄡᆞᆮ'
    '\ue789': '\u1121\u119e\u11af',                          // 'ᄡᆞᆯ'
    '\ue78a': '\u1121\u119e\u11b3',                          // 'ᄡᆞᆳ'
    '\ue78b': '\u1121\u119e\u11d9',                          // 'ᄡᆞᇙ'
    '\ue78c': '\u1121\u119e\u11b7',                          // 'ᄡᆞᆷ'
    '\ue78d': '\u1121\u119e\u11b8',                          // 'ᄡᆞᆸ'
    '\ue78e': '\u1121\u119e\u11ba',                          // 'ᄡᆞᆺ'
    '\ue78f': '\u1121\u119e\ud7f2',                          // 'ᄡᆞퟲ'
    '\ue790': '\u1121\u119e\u11f0',                          // 'ᄡᆞᇰ'
    '\ue791': '\u1121\u119e\u11c2',                          // 'ᄡᆞᇂ'
    '\ue792': '\u1121\u11a1',                                // 'ᄡᆡ'
    '\ue793': '\u1121\u11a1\u11ba',                          // 'ᄡᆡᆺ'
    '\ue794': '\u1122\u1161',                                // 'ᄢᅡ'
    '\ue795': '\u1122\u1162',                                // 'ᄢᅢ'
    '\ue796': '\u1122\u1162\u11ab',                          // 'ᄢᅢᆫ'
    '\ue797': '\u1122\u1162\u11ba',                          // 'ᄢᅢᆺ'
    '\ue798': '\u1122\u1165',                                // 'ᄢᅥ'
    '\ue799': '\u1122\u1165\u11b8',                          // 'ᄢᅥᆸ'
    '\ue79a': '\u1122\u1165\u11e6',                          // 'ᄢᅥᇦ'
    '\ue79b': '\u1122\u1165\u11ba',                          // 'ᄢᅥᆺ'
    '\ue79c': '\u1122\u1166',                                // 'ᄢᅦ'
    '\ue79d': '\u1122\u1166\u11af',                          // 'ᄢᅦᆯ'
    '\ue79e': '\u1122\u1167',                                // 'ᄢᅧ'
    '\ue79f': '\u1122\u1167\u11a8',                          // 'ᄢᅧᆨ'
    '\ue7a0': '\u1122\u1167\u11ba',                          // 'ᄢᅧᆺ'
    '\ue7a1': '\u1122\u1168',                                // 'ᄢᅨ'
    '\ue7a2': '\u1122\u1168\u11ba',                          // 'ᄢᅨᆺ'
    '\ue7a3': '\u1122\u1169',                                // 'ᄢᅩ'
    '\ue7a4': '\u1122\u116c',                                // 'ᄢᅬ'
    '\ue7a5': '\u1122\u116c\u11ba',                          // 'ᄢᅬᆺ'
    '\ue7a6': '\u1122\u116e',                                // 'ᄢᅮ'
    '\ue7a7': '\u1122\u116e\u11ab',                          // 'ᄢᅮᆫ'
    '\ue7a8': '\u1122\u116e\u11af',                          // 'ᄢᅮᆯ'
    '\ue7a9': '\u1122\u116e\u11b7',                          // 'ᄢᅮᆷ'
    '\ue7aa': '\u1122\u116e\u11ba',                          // 'ᄢᅮᆺ'
    '\ue7ab': '\u1122\u116f',                                // 'ᄢᅯ'
    '\ue7ac': '\u1122\u1170',                                // 'ᄢᅰ'
    '\ue7ad': '\u1122\u1171',                                // 'ᄢᅱ'
    '\ue7ae': '\u1122\u1171\u11ab',                          // 'ᄢᅱᆫ'
    '\ue7af': '\u1122\u1173',                                // 'ᄢᅳ'
    '\ue7b0': '\u1122\u1173\u11ab',                          // 'ᄢᅳᆫ'
    '\ue7b1': '\u1122\u1173\u11af',                          // 'ᄢᅳᆯ'
    '\ue7b2': '\u1122\u1173\u11b7',                          // 'ᄢᅳᆷ'
    '\ue7b3': '\u1122\u1173\u11ba',                          // 'ᄢᅳᆺ'
    '\ue7b4': '\u1122\u1174',                                // 'ᄢᅴ'
    '\ue7b5': '\u1122\u1174\u11ab',                          // 'ᄢᅴᆫ'
    '\ue7b6': '\u1122\u1174\u11af',                          // 'ᄢᅴᆯ'
    '\ue7b7': '\u1122\u1174\u11b7',                          // 'ᄢᅴᆷ'
    '\ue7b8': '\u1122\u1174\u11ba',                          // 'ᄢᅴᆺ'
    '\ue7b9': '\u1122\u1175',                                // 'ᄢᅵ'
    '\ue7ba': '\u1122\u1175\u11af',                          // 'ᄢᅵᆯ'
    '\ue7bb': '\u1122\u1175\u11b7',                          // 'ᄢᅵᆷ'
    '\ue7bc': '\u1122\u119e',                                // 'ᄢᆞ'
    '\ue7bd': '\u1122\u119e\u11ab',                          // 'ᄢᆞᆫ'
    '\ue7be': '\u1123\u1161',                                // 'ᄣᅡ'
    '\ue7bf': '\u1123\u1161\u11b8',                          // 'ᄣᅡᆸ'
    '\ue7c0': '\u1123\u1162',                                // 'ᄣᅢ'
    '\ue7c1': '\u1123\u1165',                                // 'ᄣᅥ'
    '\ue7c2': '\u1123\u1165\u11af',                          // 'ᄣᅥᆯ'
    '\ue7c3': '\u1123\u1165\u11b2',                          // 'ᄣᅥᆲ'
    '\ue7c4': '\u1123\u1165\u11ba',                          // 'ᄣᅥᆺ'
    '\ue7c5': '\u1123\u1167',                                // 'ᄣᅧ'
    '\ue7c6': '\u1123\u1167\u11ba',                          // 'ᄣᅧᆺ'
    '\ue7c7': '\u1123\u1169',                                // 'ᄣᅩ'
    '\ue7c8': '\u1123\u116c',                                // 'ᄣᅬ'
    '\ue7c9': '\u1123\u116e',                                // 'ᄣᅮ'
    '\ue7ca': '\u1123\u116e\u11af',                          // 'ᄣᅮᆯ'
    '\ue7cb': '\u1123\u116e\u11b7',                          // 'ᄣᅮᆷ'
    '\ue7cc': '\u1123\u1172',                                // 'ᄣᅲ'
    '\ue7cd': '\u1123\u1172\u11b7',                          // 'ᄣᅲᆷ'
    '\ue7ce': '\u1123\u1173',                                // 'ᄣᅳ'
    '\ue7cf': '\u1123\u1173\u11ab',                          // 'ᄣᅳᆫ'
    '\ue7d0': '\u1123\u1173\u11ba',                          // 'ᄣᅳᆺ'
    '\ue7d1': '\u1123\u1174',                                // 'ᄣᅴ'
    '\ue7d2': '\u1123\u1175',                                // 'ᄣᅵ'
    '\ue7d3': '\u1123\u1175\u11af',                          // 'ᄣᅵᆯ'
    '\ue7d4': '\u1123\u119e',                                // 'ᄣᆞ'
    '\ue7d5': '\u1123\u119e\u11af',                          // 'ᄣᆞᆯ'
    '\ue7d6': '\u1123\u11a1',                                // 'ᄣᆡ'
    '\ue7d7': '\u1124\u1167',                                // 'ᄤᅧ'
    '\ue7d8': '\u1124\u1168',                                // 'ᄤᅨ'
    '\ue7d9': '\u1125\u1165',                                // 'ᄥᅥ'
    '\ue7da': '\u1125\u1169',                                // 'ᄥᅩ'
    '\ue7db': '\u1125\u1173',                                // 'ᄥᅳ'
    '\ue7dc': '\u1126\u119e',                                // 'ᄦᆞ'
    '\ue7dd': '\u1126\u119e\u11ab',                          // 'ᄦᆞᆫ'
    '\ue7de': '\ua972\u1161',                                // 'ꥲᅡ'
    '\ue7df': '\u1127\u1161',                                // 'ᄧᅡ'
    '\ue7e0': '\u1127\u1161\u11a8',                          // 'ᄧᅡᆨ'
    '\ue7e1': '\u1127\u1161\u11ab',                          // 'ᄧᅡᆫ'
    '\ue7e2': '\u1127\u1161\u11b7',                          // 'ᄧᅡᆷ'
    '\ue7e3': '\u1127\u1161\u11ba',                          // 'ᄧᅡᆺ'
    '\ue7e4': '\u1127\u1161\u11bc',                          // 'ᄧᅡᆼ'
    '\ue7e5': '\u1127\u1161\u11f0',                          // 'ᄧᅡᇰ'
    '\ue7e6': '\u1127\u1162',                                // 'ᄧᅢ'
    '\ue7e7': '\u1127\u1163',                                // 'ᄧᅣ'
    '\ue7e8': '\u1127\u1163\u11a8',                          // 'ᄧᅣᆨ'
    '\ue7e9': '\u1127\u1163\u11bc',                          // 'ᄧᅣᆼ'
    '\ue7ea': '\u1127\u1163\u11f0',                          // 'ᄧᅣᇰ'
    '\ue7eb': '\u1127\u1165',                                // 'ᄧᅥ'
    '\ue7ec': '\u1127\u1165\u11a8',                          // 'ᄧᅥᆨ'
    '\ue7ed': '\u1127\u1166',                                // 'ᄧᅦ'
    '\ue7ee': '\u1127\u1167',                                // 'ᄧᅧ'
    '\ue7ef': '\u1127\u1169',                                // 'ᄧᅩ'
    '\ue7f0': '\u1127\u1169\u11a8',                          // 'ᄧᅩᆨ'
    '\ue7f1': '\u1127\u1169\u11ab',                          // 'ᄧᅩᆫ'
    '\ue7f2': '\u1127\u1169\u11b7',                          // 'ᄧᅩᆷ'
    '\ue7f3': '\u1127\u1169\u11ba',                          // 'ᄧᅩᆺ'
    '\ue7f4': '\u1127\u1169\u11bc',                          // 'ᄧᅩᆼ'
    '\ue7f5': '\u1127\u1169\u11f0',                          // 'ᄧᅩᇰ'
    '\ue7f6': '\u1127\u1169\u11bd',                          // 'ᄧᅩᆽ'
    '\ue7f7': '\u1127\u1169\u11be',                          // 'ᄧᅩᆾ'
    '\ue7f8': '\u1127\u116a',                                // 'ᄧᅪ'
    '\ue7f9': '\u1127\u116a\u11bc',                          // 'ᄧᅪᆼ'
    '\ue7fa': '\u1127\u116a\u11f0',                          // 'ᄧᅪᇰ'
    '\ue7fb': '\u1127\u116b',                                // 'ᄧᅫ'
    '\ue7fc': '\u1127\u116c',                                // 'ᄧᅬ'
    '\ue7fd': '\u1127\u116c\u11ab',                          // 'ᄧᅬᆫ'
    '\ue7fe': '\u1127\u116c\u11af',                          // 'ᄧᅬᆯ'
    '\ue7ff': '\u1127\u116d',                                // 'ᄧᅭ'
    '\ue800': '\u1127\u116e',                                // 'ᄧᅮ'
    '\ue801': '\u1127\u116e\u11a8',                          // 'ᄧᅮᆨ'
    '\ue802': '\u1127\u116e\u11af',                          // 'ᄧᅮᆯ'
    '\ue803': '\u1127\u116e\u11b7',                          // 'ᄧᅮᆷ'
    '\ue804': '\u1127\u1171',                                // 'ᄧᅱ'
    '\ue805': '\u1127\u1173',                                // 'ᄧᅳ'
    '\ue806': '\u1127\u1173\u11a8',                          // 'ᄧᅳᆨ'
    '\ue807': '\u1127\u1173\u11ab',                          // 'ᄧᅳᆫ'
    '\ue808': '\u1127\u1173\u11ae',                          // 'ᄧᅳᆮ'
    '\ue809': '\u1127\u1173\u11af',                          // 'ᄧᅳᆯ'
    '\ue80a': '\u1127\u1173\u11b7',                          // 'ᄧᅳᆷ'
    '\ue80b': '\u1127\u1173\u11ba',                          // 'ᄧᅳᆺ'
    '\ue80c': '\u1127\u1173\u11bd',                          // 'ᄧᅳᆽ'
    '\ue80d': '\u1127\u1174',                                // 'ᄧᅴ'
    '\ue80e': '\u1127\u1174\u11bc',                          // 'ᄧᅴᆼ'
    '\ue80f': '\u1127\u1174\u11f0',                          // 'ᄧᅴᇰ'
    '\ue810': '\u1127\u1175',                                // 'ᄧᅵ'
    '\ue811': '\u1127\u1175\u11a8',                          // 'ᄧᅵᆨ'
    '\ue812': '\u1127\u1175\u11ab',                          // 'ᄧᅵᆫ'
    '\ue813': '\u1127\u1175\u11ae',                          // 'ᄧᅵᆮ'
    '\ue814': '\u1127\u1175\u11af',                          // 'ᄧᅵᆯ'
    '\ue815': '\u1127\u1175\u11b7',                          // 'ᄧᅵᆷ'
    '\ue816': '\u1127\u1175\u11ba',                          // 'ᄧᅵᆺ'
    '\ue817': '\u1127\u1175\ud7f2',                          // 'ᄧᅵퟲ'
    '\ue818': '\u1127\u1175\u11bc',                          // 'ᄧᅵᆼ'
    '\ue819': '\u1127\u1175\u11f0',                          // 'ᄧᅵᇰ'
    '\ue81a': '\u1127\u119e',                                // 'ᄧᆞ'
    '\ue81b': '\u1127\u119e\u11a8',                          // 'ᄧᆞᆨ'
    '\ue81c': '\u1127\u119e\u11ab',                          // 'ᄧᆞᆫ'
    '\ue81d': '\u1127\u119e\u11af',                          // 'ᄧᆞᆯ'
    '\ue81e': '\u1127\u119e\u11b7',                          // 'ᄧᆞᆷ'
    '\ue81f': '\u1127\u11a1',                                // 'ᄧᆡ'
    '\ue820': '\u1127\u11a1\u11ab',                          // 'ᄧᆡᆫ'
    '\ue821': '\u1127\u11a1\u11af',                          // 'ᄧᆡᆯ'
    '\ue822': '\u1127\u11a1\u11bc',                          // 'ᄧᆡᆼ'
    '\ue823': '\u1128\u1169',                                // 'ᄨᅩ'
    '\ue824': '\u1128\u1169\u11bc',                          // 'ᄨᅩᆼ'
    '\ue825': '\u1128\u119e',                                // 'ᄨᆞ'
    '\ue826': '\u1128\u119e\u11af',                          // 'ᄨᆞᆯ'
    '\ue827': '\ua973\u1169',                                // 'ꥳᅩ'
    '\ue828': '\ua973\u116a',                                // 'ꥳᅪ'
    '\ue829': '\ua973\u116b',                                // 'ꥳᅫ'
    '\ue82a': '\ua973\u1173',                                // 'ꥳᅳ'
    '\ue82b': '\u1129\u1161',                                // 'ᄩᅡ'
    '\ue82c': '\u1129\u1165',                                // 'ᄩᅥ'
    '\ue82d': '\u1129\u1165\u11a8',                          // 'ᄩᅥᆨ'
    '\ue82e': '\u1129\u1165\u11af',                          // 'ᄩᅥᆯ'
    '\ue82f': '\u1129\u1165\u11b2',                          // 'ᄩᅥᆲ'
    '\ue830': '\u1129\u1165\u11d5',                          // 'ᄩᅥᇕ'
    '\ue831': '\u1129\u1165\u11b7',                          // 'ᄩᅥᆷ'
    '\ue832': '\u1129\u1165\u11ba',                          // 'ᄩᅥᆺ'
    '\ue833': '\u1129\u1167',                                // 'ᄩᅧ'
    '\ue834': '\u1129\u1169',                                // 'ᄩᅩ'
    '\ue835': '\u1129\u1169\u11b7',                          // 'ᄩᅩᆷ'
    '\ue836': '\u1129\u1169\u11b8',                          // 'ᄩᅩᆸ'
    '\ue837': '\u1129\u116e',                                // 'ᄩᅮ'
    '\ue838': '\u1129\u116e\u11a8',                          // 'ᄩᅮᆨ'
    '\ue839': '\u1129\u116e\u11fb',                          // 'ᄩᅮᇻ'
    '\ue83a': '\u1129\u1171',                                // 'ᄩᅱ'
    '\ue83b': '\u1129\u1171\u11af',                          // 'ᄩᅱᆯ'
    '\ue83c': '\u1129\u1171\u11b7',                          // 'ᄩᅱᆷ'
    '\ue83d': '\u1129\u1173',                                // 'ᄩᅳ'
    '\ue83e': '\u1129\u1173\u11ab',                          // 'ᄩᅳᆫ'
    '\ue83f': '\u1129\u1173\u11ae',                          // 'ᄩᅳᆮ'
    '\ue840': '\u1129\u1173\u11ba',                          // 'ᄩᅳᆺ'
    '\ue841': '\u1129\u1174',                                // 'ᄩᅴ'
    '\ue842': '\u1129\u1174\u11b7',                          // 'ᄩᅴᆷ'
    '\ue843': '\u1129\u1175',                                // 'ᄩᅵ'
    '\ue844': '\u1129\u1175\u11ab',                          // 'ᄩᅵᆫ'
    '\ue845': '\u1129\u1175\u11af',                          // 'ᄩᅵᆯ'
    '\ue846': '\u1129\u1175\u11b7',                          // 'ᄩᅵᆷ'
    '\ue847': '\u1129\u119e',                                // 'ᄩᆞ'
    '\ue848': '\u1129\u119e\u11ab',                          // 'ᄩᆞᆫ'
    '\ue849': '\u1129\u119e\u11af',                          // 'ᄩᆞᆯ'
    '\ue84a': '\u1129\u11a1',                                // 'ᄩᆡ'
    '\ue84b': '\u112a\u1161',                                // 'ᄪᅡ'
    '\ue84c': '\u112a\u1161\u11ab',                          // 'ᄪᅡᆫ'
    '\ue84d': '\u112a\u1169',                                // 'ᄪᅩ'
    '\ue84e': '\u112a\u1169\u11ab',                          // 'ᄪᅩᆫ'
    '\ue84f': '\u112a\u116d',                                // 'ᄪᅭ'
    '\ue850': '\u112a\u1173',                                // 'ᄪᅳ'
    '\ue851': '\u112a\u1173\u11af',                          // 'ᄪᅳᆯ'
    '\ue852': '\u112a\u1175',                                // 'ᄪᅵ'
    '\ue853': '\u112a\u119e',                                // 'ᄪᆞ'
    '\ue854': '\u112a\u119e\u11af',                          // 'ᄪᆞᆯ'
    '\ue855': '\ua974\u1161',                                // 'ꥴᅡ'
    '\ue856': '\ua974\u1161\u11ab',                          // 'ꥴᅡᆫ'
    '\ue857': '\u112b\u1161',                                // 'ᄫᅡ'
    '\ue858': '\u112b\u1161\u11a8',                          // 'ᄫᅡᆨ'
    '\ue859': '\u112b\u1161\u11ab',                          // 'ᄫᅡᆫ'
    '\ue85a': '\u112b\u1161\u11ae',                          // 'ᄫᅡᆮ'
    '\ue85b': '\u112b\u1161\u11af',                          // 'ᄫᅡᆯ'
    '\ue85c': '\u112b\u1161\u11b0',                          // 'ᄫᅡᆰ'
    '\ue85d': '\u112b\u1161\u11d9',                          // 'ᄫᅡᇙ'
    '\ue85e': '\u112b\u1161\u11b7',                          // 'ᄫᅡᆷ'
    '\ue85f': '\u112b\u1161\u11ba',                          // 'ᄫᅡᆺ'
    '\ue860': '\u112b\u1161\u11eb',                          // 'ᄫᅡᇫ'
    '\ue861': '\u112b\u1161\u11bc',                          // 'ᄫᅡᆼ'
    '\ue862': '\u112b\u1161\u11f0',                          // 'ᄫᅡᇰ'
    '\ue863': '\u112b\u1161\u11c0',                          // 'ᄫᅡᇀ'
    '\ue864': '\u112b\u1161\u11f9',                          // 'ᄫᅡᇹ'
    '\ue865': '\u112b\u1162',                                // 'ᄫᅢ'
    '\ue866': '\u112b\u1162\u11ab',                          // 'ᄫᅢᆫ'
    '\ue867': '\u112b\u1162\u11ba',                          // 'ᄫᅢᆺ'
    '\ue868': '\u112b\u1165',                                // 'ᄫᅥ'
    '\ue869': '\u112b\u1165\u11ab',                          // 'ᄫᅥᆫ'
    '\ue86a': '\u112b\u1165\u11b7',                          // 'ᄫᅥᆷ'
    '\ue86b': '\u112b\u1165\u11b8',                          // 'ᄫᅥᆸ'
    '\ue86c': '\u112b\u1165\u11bc',                          // 'ᄫᅥᆼ'
    '\ue86d': '\u112b\u1166',                                // 'ᄫᅦ'
    '\ue86e': '\u112b\u1166\u11ba',                          // 'ᄫᅦᆺ'
    '\ue86f': '\u112b\u1167',                                // 'ᄫᅧ'
    '\ue870': '\u112b\u1167\u11a8',                          // 'ᄫᅧᆨ'
    '\ue871': '\u112b\u1167\u11af',                          // 'ᄫᅧᆯ'
    '\ue872': '\u112b\u1167\u11d9',                          // 'ᄫᅧᇙ'
    '\ue873': '\u112b\u1167\u11ba',                          // 'ᄫᅧᆺ'
    '\ue874': '\u112b\u1168',                                // 'ᄫᅨ'
    '\ue875': '\u112b\u1168\u11ba',                          // 'ᄫᅨᆺ'
    '\ue876': '\u112b\u1169',                                // 'ᄫᅩ'
    '\ue877': '\u112b\u1169\u11ab',                          // 'ᄫᅩᆫ'
    '\ue878': '\u112b\u1169\u11af',                          // 'ᄫᅩᆯ'
    '\ue879': '\u112b\u1169\u11b3',                          // 'ᄫᅩᆳ'
    '\ue87a': '\u112b\u1169\u11d9',                          // 'ᄫᅩᇙ'
    '\ue87b': '\u112b\u1169\u11b7',                          // 'ᄫᅩᆷ'
    '\ue87c': '\u112b\u1169\u11dd',                          // 'ᄫᅩᇝ'
    '\ue87d': '\u112b\u1169\u11b8',                          // 'ᄫᅩᆸ'
    '\ue87e': '\u112b\u1169\u11ba',                          // 'ᄫᅩᆺ'
    '\ue87f': '\u112b\u1169\u11bc',                          // 'ᄫᅩᆼ'
    '\ue880': '\u112b\u116a',                                // 'ᄫᅪ'
    '\ue881': '\u112b\u116a\u11ab',                          // 'ᄫᅪᆫ'
    '\ue882': '\u112b\u116d',                                // 'ᄫᅭ'
    '\ue883': '\u112b\u1187',                                // 'ᄫᆇ'
    '\ue884': '\u112b\u1188',                                // 'ᄫᆈ'
    '\ue885': '\u112b\u116e',                                // 'ᄫᅮ'
    '\ue886': '\u112b\u116e\u11ab',                          // 'ᄫᅮᆫ'
    '\ue887': '\u112b\u116e\u11af',                          // 'ᄫᅮᆯ'
    '\ue888': '\u112b\u116e\u11b7',                          // 'ᄫᅮᆷ'
    '\ue889': '\u112b\u116e\u11b8',                          // 'ᄫᅮᆸ'
    '\ue88a': '\u112b\u116e\u11ba',                          // 'ᄫᅮᆺ'
    '\ue88b': '\u112b\u116e\u11bc',                          // 'ᄫᅮᆼ'
    '\ue88c': '\u112b\u116e\u11f0',                          // 'ᄫᅮᇰ'
    '\ue88d': '\u112b\u116e\u11c2',                          // 'ᄫᅮᇂ'
    '\ue88e': '\u112b\u116e\u11f9',                          // 'ᄫᅮᇹ'
    '\ue88f': '\u112b\u116f',                                // 'ᄫᅯ'
    '\ue890': '\u112b\u1171',                                // 'ᄫᅱ'
    '\ue891': '\u112b\u1171\u11af',                          // 'ᄫᅱᆯ'
    '\ue892': '\u112b\u1171\u11d9',                          // 'ᄫᅱᇙ'
    '\ue893': '\u112b\u1172',                                // 'ᄫᅲ'
    '\ue894': '\u112b\u1172\u11f9',                          // 'ᄫᅲᇹ'
    '\ue895': '\u112b\u1173',                                // 'ᄫᅳ'
    '\ue896': '\u112b\u1173\u11ab',                          // 'ᄫᅳᆫ'
    '\ue897': '\u112b\u1173\u11c7',                          // 'ᄫᅳᇇ'
    '\ue898': '\u112b\u1173\u11af',                          // 'ᄫᅳᆯ'
    '\ue899': '\u112b\u1173\u11b3',                          // 'ᄫᅳᆳ'
    '\ue89a': '\u112b\u1173\u11d9',                          // 'ᄫᅳᇙ'
    '\ue89b': '\u112b\u1173\u11b7',                          // 'ᄫᅳᆷ'
    '\ue89c': '\u112b\u1173\u11e2',                          // 'ᄫᅳᇢ'
    '\ue89d': '\u112b\u1173\u11ba',                          // 'ᄫᅳᆺ'
    '\ue89e': '\u112b\u1173\u11bc',                          // 'ᄫᅳᆼ'
    '\ue89f': '\u112b\u1195',                                // 'ᄫᆕ'
    '\ue8a0': '\u112b\u1174',                                // 'ᄫᅴ'
    '\ue8a1': '\u112b\u1175',                                // 'ᄫᅵ'
    '\ue8a2': '\u112b\u1175\u11a8',                          // 'ᄫᅵᆨ'
    '\ue8a3': '\u112b\u1175\u11ab',                          // 'ᄫᅵᆫ'
    '\ue8a4': '\u112b\u1175\u11af',                          // 'ᄫᅵᆯ'
    '\ue8a5': '\u112b\u1175\u11d9',                          // 'ᄫᅵᇙ'
    '\ue8a6': '\u112b\u1175\u11bc',                          // 'ᄫᅵᆼ'
    '\ue8a7': '\u112b\u119e',                                // 'ᄫᆞ'
    '\ue8a8': '\u112b\u119e\u11a8',                          // 'ᄫᆞᆨ'
    '\ue8a9': '\u112b\u119e\u11ab',                          // 'ᄫᆞᆫ'
    '\ue8aa': '\u112b\u119e\u11af',                          // 'ᄫᆞᆯ'
    '\ue8ab': '\u112b\u119e\u11b0',                          // 'ᄫᆞᆰ'
    '\ue8ac': '\u112b\u119e\u11b2',                          // 'ᄫᆞᆲ'
    '\ue8ad': '\u112b\u119e\u11b3',                          // 'ᄫᆞᆳ'
    '\ue8ae': '\u112b\u119e\u11d9',                          // 'ᄫᆞᇙ'
    '\ue8af': '\u112b\u119e\u11b7',                          // 'ᄫᆞᆷ'
    '\ue8b0': '\u112b\u119e\u11eb',                          // 'ᄫᆞᇫ'
    '\ue8b1': '\u112b\u119e\u11bc',                          // 'ᄫᆞᆼ'
    '\ue8b2': '\u112b\u11a1',                                // 'ᄫᆡ'
    '\ue8b3': '\u112b\u11a1\u11a8',                          // 'ᄫᆡᆨ'
    '\ue8b4': '\u112b\u11a1\u11ab',                          // 'ᄫᆡᆫ'
    '\ue8b5': '\u112b\u11a1\u11af',                          // 'ᄫᆡᆯ'
    '\ue8b6': '\u112c\u1161',                                // 'ᄬᅡ'
    '\ue8b7': '\u112c\u1161\u11ab',                          // 'ᄬᅡᆫ'
    '\ue8b8': '\u112c\u1161\u11bc',                          // 'ᄬᅡᆼ'
    '\ue8b9': '\u112c\u1161\u11f0',                          // 'ᄬᅡᇰ'
    '\ue8ba': '\u112c\u1161\u11f9',                          // 'ᄬᅡᇹ'
    '\ue8bb': '\u112c\u1169',                                // 'ᄬᅩ'
    '\ue8bc': '\u112c\u1169\u11ab',                          // 'ᄬᅩᆫ'
    '\ue8bd': '\u112c\u116a',                                // 'ᄬᅪ'
    '\ue8be': '\u112c\u116a\u11ab',                          // 'ᄬᅪᆫ'
    '\ue8bf': '\u112c\u116e',                                // 'ᄬᅮ'
    '\ue8c0': '\u112c\u116e\u11ab',                          // 'ᄬᅮᆫ'
    '\ue8c1': '\u112c\u116e\u11bc',                          // 'ᄬᅮᆼ'
    '\ue8c2': '\u112c\u116e\u11f0',                          // 'ᄬᅮᇰ'
    '\ue8c3': '\u112c\u116e\u11f9',                          // 'ᄬᅮᇹ'
    '\ue8c4': '\u112c\u1172',                                // 'ᄬᅲ'
    '\ue8c5': '\u112c\u1172\u11ab',                          // 'ᄬᅲᆫ'
    '\ue8c6': '\u112c\u1173',                                // 'ᄬᅳ'
    '\ue8c7': '\u112c\u1173\u11ab',                          // 'ᄬᅳᆫ'
    '\ue8c8': '\u112c\u1173\u11b7',                          // 'ᄬᅳᆷ'
    '\ue8c9': '\u112c\u1173\u11e2',                          // 'ᄬᅳᇢ'
    '\ue8ca': '\u112c\u1173\u11bc',                          // 'ᄬᅳᆼ'
    '\ue8cb': '\u112c\u1174',                                // 'ᄬᅴ'
    '\ue8cc': '\u112c\u1175',                                // 'ᄬᅵ'
    '\ue8cd': '\u1109\u1161\u11c7',                          // '사ᇇ'
    '\ue8ce': '\u1109\u1161\u11ca',                          // '사ᇊ'
    '\ue8cf': '\u1109\u1161\ud7d4',                          // '사ퟔ'
    '\ue8d0': '\u1109\u1161\u11cd',                          // '사ᇍ'
    '\ue8d1': '\u1109\u1161\u11d0',                          // '사ᇐ'
    '\ue8d2': '\u1109\u1161\u11d9',                          // '사ᇙ'
    '\ue8d3': '\u1109\u1161\u11dd',                          // '사ᇝ'
    '\ue8d4': '\u1109\u1161\u11e2',                          // '사ᇢ'
    '\ue8d5': '\u1109\u1161\u11e7',                          // '사ᇧ'
    '\ue8d6': '\u1109\u1161\u11e8',                          // '사ᇨ'
    '\ue8d7': '\u1109\u1161\ud7f2',                          // '사ퟲ'
    '\ue8d8': '\u1109\u1161\u11eb',                          // '사ᇫ'
    '\ue8d9': '\u1109\u1161\u11f1',                          // '사ᇱ'
    '\ue8da': '\u1109\u1161\u11f0',                          // '사ᇰ'
    '\ue8db': '\u1109\u1161\u11f1',                          // '사ᇱ'
    '\ue8dc': '\u1109\u1161\u11f9',                          // '사ᇹ'
    '\ue8dd': '\u1109\u1176',                                // 'ᄉᅶ'
    '\ue8de': '\u1109\u1177',                                // 'ᄉᅷ'
    '\ue8df': '\u1109\u11a3',                                // 'ᄉᆣ'
    '\ue8e0': '\u1109\u11a3\u11b7',                          // 'ᄉᆣᆷ'
    '\ue8e1': '\u1109\u1162\u11e6',                          // '새ᇦ'
    '\ue8e2': '\u1109\u1162\u11f0',                          // '새ᇰ'
    '\ue8e3': '\u1109\u1163\u11d9',                          // '샤ᇙ'
    '\ue8e4': '\u1109\u1163\u11e2',                          // '샤ᇢ'
    '\ue8e5': '\u1109\u1163\u11eb',                          // '샤ᇫ'
    '\ue8e6': '\u1109\u1163\u11f0',                          // '샤ᇰ'
    '\ue8e7': '\u1109\u1163\u11f1',                          // '샤ᇱ'
    '\ue8e8': '\u1109\u1178',                                // 'ᄉᅸ'
    '\ue8e9': '\u1109\u1165\u11c3',                          // '서ᇃ'
    '\ue8ea': '\u1109\u1165\u11d9',                          // '서ᇙ'
    '\ue8eb': '\u1109\u1165\u11e5',                          // '서ᇥ'
    '\ue8ec': '\u1109\u1165\u11e7',                          // '서ᇧ'
    '\ue8ed': '\u1109\u1165\u11eb',                          // '서ᇫ'
    '\ue8ee': '\u1109\u1165\u11ec',                          // '서ᇬ'
    '\ue8ef': '\u1109\u1165\u11ed',                          // '서ᇭ'
    '\ue8f0': '\u1109\u1165\u11f0',                          // '서ᇰ'
    '\ue8f1': '\u1109\u1165\u11f9',                          // '서ᇹ'
    '\ue8f2': '\u1109\u117a',                                // 'ᄉᅺ'
    '\ue8f3': '\u1109\u117b',                                // 'ᄉᅻ'
    '\ue8f4': '\u1109\u117c',                                // 'ᄉᅼ'
    '\ue8f5': '\u1109\u117c\u11a8',                          // 'ᄉᅼᆨ'
    '\ue8f6': '\u1109\u117c\u11af',                          // 'ᄉᅼᆯ'
    '\ue8f7': '\u1109\u117c\u11b7',                          // 'ᄉᅼᆷ'
    '\ue8f8': '\u1109\u117c\u11ba',                          // 'ᄉᅼᆺ'
    '\ue8f9': '\u1109\u117c\u11bb',                          // 'ᄉᅼᆻ'
    '\ue8fa': '\u1109\u117c\u11bc',                          // 'ᄉᅼᆼ'
    '\ue8fb': '\u1109\u1166\ud7fb',                          // '세ퟻ'
    '\ue8fc': '\u1109\u1167\u11d5',                          // '셔ᇕ'
    '\ue8fd': '\u1109\u1167\u11d9',                          // '셔ᇙ'
    '\ue8fe': '\u1109\u1167\u11dd',                          // '셔ᇝ'
    '\ue8ff': '\u1109\u1167\u11e2',                          // '셔ᇢ'
    '\ue900': '\u1109\u1167\u11e3',                          // '셔ᇣ'
    '\ue901': '\u1109\u1167\u11e4',                          // '셔ᇤ'
    '\ue902': '\u1109\u1167\u11e5',                          // '셔ᇥ'
    '\ue903': '\u1109\u1167\u11e7',                          // '셔ᇧ'
    '\ue904': '\u1109\u1167\u11f0',                          // '셔ᇰ'
    '\ue905': '\u1109\u1167\u11f1',                          // '셔ᇱ'
    '\ue906': '\u1109\u1167\u11f9',                          // '셔ᇹ'
    '\ue907': '\u1109\u117d',                                // 'ᄉᅽ'
    '\ue908': '\u1109\u117e',                                // 'ᄉᅾ'
    '\ue909': '\u1109\u1168\u11f0',                          // '셰ᇰ'
    '\ue90a': '\u1109\u1169\u11c7',                          // '소ᇇ'
    '\ue90b': '\u1109\u1169\u11d9',                          // '소ᇙ'
    '\ue90c': '\u1109\u1169\u11e2',                          // '소ᇢ'
    '\ue90d': '\u1109\u1169\ud7e7',                          // '소ퟧ'
    '\ue90e': '\u1109\u1169\u11e8',                          // '소ᇨ'
    '\ue90f': '\u1109\u1169\ud7f0',                          // '소ퟰ'
    '\ue910': '\u1109\u1169\u11eb',                          // '소ᇫ'
    '\ue911': '\u1109\u1169\u11f0',                          // '소ᇰ'
    '\ue912': '\u1109\u116a\u11d9',                          // '솨ᇙ'
    '\ue913': '\u1109\u116a\u11f0',                          // '솨ᇰ'
    '\ue914': '\u1109\u116a\u11f9',                          // '솨ᇹ'
    '\ue915': '\u1109\u116b\u11f9',                          // '쇄ᇹ'
    '\ue916': '\u1109\u11a6',                                // 'ᄉᆦ'
    '\ue917': '\u1109\u11a7',                                // 'ᄉᆧ'
    '\ue918': '\u1109\u1182',                                // 'ᄉᆂ'
    '\ue919': '\u1109\u116c\u11f0',                          // '쇠ᇰ'
    '\ue91a': '\u1109\u116d\u11d9',                          // '쇼ᇙ'
    '\ue91b': '\u1109\u116d\u11e2',                          // '쇼ᇢ'
    '\ue91c': '\u1109\u116d\u11f0',                          // '쇼ᇰ'
    '\ue91d': '\u1109\u116d\u11f1',                          // '쇼ᇱ'
    '\ue91e': '\u1109\u1187',                                // 'ᄉᆇ'
    '\ue91f': '\u1109\u1187\u11af',                          // 'ᄉᆇᆯ'
    '\ue920': '\u1109\u1188',                                // 'ᄉᆈ'
    '\ue921': '\u1109\u1188\u11ab',                          // 'ᄉᆈᆫ'
    '\ue922': '\u1109\u1188\u11af',                          // 'ᄉᆈᆯ'
    '\ue923': '\u1109\u1188\u11ba',                          // 'ᄉᆈᆺ'
    '\ue924': '\u1109\u116e\u11d0',                          // '수ᇐ'
    '\ue925': '\u1109\u116e\u11d9',                          // '수ᇙ'
    '\ue926': '\u1109\u116e\u11e2',                          // '수ᇢ'
    '\ue927': '\u1109\u116e\u11e4',                          // '수ᇤ'
    '\ue928': '\u1109\u116e\u11e6',                          // '수ᇦ'
    '\ue929': '\u1109\u116e\u11e7',                          // '수ᇧ'
    '\ue92a': '\u1109\u116e\ud7f0',                          // '수ퟰ'
    '\ue92b': '\u1109\u116e\u11eb',                          // '수ᇫ'
    '\ue92c': '\u1109\u116e\u11f0',                          // '수ᇰ'
    '\ue92d': '\u1109\u116e\u11f9',                          // '수ᇹ'
    '\ue92e': '\u1109\u1189',                                // 'ᄉᆉ'
    '\ue92f': '\u1109\u1189\u11ab',                          // 'ᄉᆉᆫ'
    '\ue930': '\u1109\u1189\u11b7',                          // 'ᄉᆉᆷ'
    '\ue931': '\u1109\ud7b5',                                // 'ᄉힵ'
    '\ue932': '\u1109\ud7b5\u11ab',                          // 'ᄉힵᆫ'
    '\ue933': '\u1109\u118c',                                // 'ᄉᆌ'
    '\ue934': '\u1109\u1171\u11d9',                          // '쉬ᇙ'
    '\ue935': '\u1109\u1171\u11e6',                          // '쉬ᇦ'
    '\ue936': '\u1109\u1171\u11e7',                          // '쉬ᇧ'
    '\ue937': '\u1109\u1172\u11d9',                          // '슈ᇙ'
    '\ue938': '\u1109\u1172\u11e2',                          // '슈ᇢ'
    '\ue939': '\u1109\u1172\u11e6',                          // '슈ᇦ'
    '\ue93a': '\u1109\u1172\u11f0',                          // '슈ᇰ'
    '\ue93b': '\u1109\u118e',                                // 'ᄉᆎ'
    '\ue93c': '\u1109\u118e\u11ab',                          // 'ᄉᆎᆫ'
    '\ue93d': '\u1109\u118e\u11f0',                          // 'ᄉᆎᇰ'
    '\ue93e': '\u1109\ud7b7',                                // 'ᄉힷ'
    '\ue93f': '\u1109\u118f',                                // 'ᄉᆏ'
    '\ue940': '\u1109\u1190',                                // 'ᄉᆐ'
    '\ue941': '\u1109\u1191',                                // 'ᄉᆑ'
    '\ue942': '\u1109\u1191\u11ab',                          // 'ᄉᆑᆫ'
    '\ue943': '\u1109\u1191\u11af',                          // 'ᄉᆑᆯ'
    '\ue944': '\u1109\u1191\u11d9',                          // 'ᄉᆑᇙ'
    '\ue945': '\u1109\u1191\u11f0',                          // 'ᄉᆑᇰ'
    '\ue946': '\u1109\u1191\u11f9',                          // 'ᄉᆑᇹ'
    '\ue947': '\u1109\u1192',                                // 'ᄉᆒ'
    '\ue948': '\u1109\u1192\u11bc',                          // 'ᄉᆒᆼ'
    '\ue949': '\u1109\ud7b8',                                // 'ᄉힸ'
    '\ue94a': '\u1109\ud7b8\u11af',                          // 'ᄉힸᆯ'
    '\ue94b': '\u1109\u1193',                                // 'ᄉᆓ'
    '\ue94c': '\u1109\u1193\u11af',                          // 'ᄉᆓᆯ'
    '\ue94d': '\u1109\u1194',                                // 'ᄉᆔ'
    '\ue94e': '\u1109\u1194\u11ab',                          // 'ᄉᆔᆫ'
    '\ue94f': '\u1109\u1194\u11af',                          // 'ᄉᆔᆯ'
    '\ue950': '\u1109\u1194\u11b7',                          // 'ᄉᆔᆷ'
    '\ue951': '\u1109\u1194\u11b8',                          // 'ᄉᆔᆸ'
    '\ue952': '\u1109\u1194\u11bc',                          // 'ᄉᆔᆼ'
    '\ue953': '\u1109\u1173\u11d9',                          // '스ᇙ'
    '\ue954': '\u1109\u1173\u11e2',                          // '스ᇢ'
    '\ue955': '\u1109\u1173\u11e3',                          // '스ᇣ'
    '\ue956': '\u1109\u1173\ud7f0',                          // '스ퟰ'
    '\ue957': '\u1109\u1173\ud7f2',                          // '스ퟲ'
    '\ue958': '\u1109\u1173\u11eb',                          // '스ᇫ'
    '\ue959': '\u1109\u1173\u11f1',                          // '스ᇱ'
    '\ue95a': '\u1109\u1173\ud7f6',                          // '스ퟶ'
    '\ue95b': '\u1109\u1173\u11f0',                          // '스ᇰ'
    '\ue95c': '\u1109\u1173\u11f2',                          // '스ᇲ'
    '\ue95d': '\u1109\u1173\u11f9',                          // '스ᇹ'
    '\ue95e': '\u1109\u1195',                                // 'ᄉᆕ'
    '\ue95f': '\u1109\u1196',                                // 'ᄉᆖ'
    '\ue960': '\u1109\u1174\u11f0',                          // '싀ᇰ'
    '\ue961': '\u1109\u1174\u11f9',                          // '싀ᇹ'
    '\ue962': '\u1109\u1175\u11fa',                          // '시ᇺ'
    '\ue963': '\u1109\u1175\u11ff',                          // '시ᇿ'
    '\ue964': '\u1109\u1175\u11c7',                          // '시ᇇ'
    '\ue965': '\u1109\u1175\u11ce',                          // '시ᇎ'
    '\ue966': '\u1109\u1175\u11d0',                          // '시ᇐ'
    '\ue967': '\u1109\u1175\u11d9',                          // '시ᇙ'
    '\ue968': '\u1109\u1175\u11da',                          // '시ᇚ'
    '\ue969': '\u1109\u1175\u11e1',                          // '시ᇡ'
    '\ue96a': '\u1109\u1175\u11e2',                          // '시ᇢ'
    '\ue96b': '\u1109\u1175\u11e6',                          // '시ᇦ'
    '\ue96c': '\u1109\u1175\u11eb',                          // '시ᇫ'
    '\ue96d': '\u1109\u1175\u11f0',                          // '시ᇰ'
    '\ue96e': '\u1109\u1175\u11f9',                          // '시ᇹ'
    '\ue96f': '\u1109\u1198',                                // 'ᄉᆘ'
    '\ue970': '\u1109\u1199',                                // 'ᄉᆙ'
    '\ue971': '\u1109\u1199\u11ab',                          // 'ᄉᆙᆫ'
    '\ue972': '\u1109\u1199\u11bc',                          // 'ᄉᆙᆼ'
    '\ue973': '\u1109\ud7bd',                                // 'ᄉힽ'
    '\ue974': '\u1109\ud7be',                                // 'ᄉힾ'
    '\ue975': '\u1109\ud7bf',                                // 'ᄉힿ'
    '\ue976': '\u1109\ud7c0',                                // 'ᄉퟀ'
    '\ue977': '\u1109\u119a',                                // 'ᄉᆚ'
    '\ue978': '\u1109\ud7c1',                                // 'ᄉퟁ'
    '\ue979': '\u1109\ud7c2',                                // 'ᄉퟂ'
    '\ue97a': '\u1109\u119b',                                // 'ᄉᆛ'
    '\ue97b': '\u1109\ud7c3',                                // 'ᄉퟃ'
    '\ue97c': '\u1109\ud7c3\u11ab',                          // 'ᄉퟃᆫ'
    '\ue97d': '\u1109\u119e',                                // 'ᄉᆞ'
    '\ue97e': '\u1109\u119e\u11a8',                          // 'ᄉᆞᆨ'
    '\ue97f': '\u1109\u119e\u11ab',                          // 'ᄉᆞᆫ'
    '\ue980': '\u1109\u119e\u11ae',                          // 'ᄉᆞᆮ'
    '\ue981': '\u1109\u119e\u11ca',                          // 'ᄉᆞᇊ'
    '\ue982': '\u1109\u119e\u11af',                          // 'ᄉᆞᆯ'
    '\ue983': '\u1109\u119e\u11b0',                          // 'ᄉᆞᆰ'
    '\ue984': '\u1109\u119e\u11d0',                          // 'ᄉᆞᇐ'
    '\ue985': '\u1109\u119e\u11b1',                          // 'ᄉᆞᆱ'
    '\ue986': '\u1109\u119e\u11b2',                          // 'ᄉᆞᆲ'
    '\ue987': '\u1109\u119e\u11d5',                          // 'ᄉᆞᇕ'
    '\ue988': '\u1109\u119e\u11b3',                          // 'ᄉᆞᆳ'
    '\ue989': '\u1109\u119e\u11b6',                          // 'ᄉᆞᆶ'
    '\ue98a': '\u1109\u119e\u11d9',                          // 'ᄉᆞᇙ'
    '\ue98b': '\u1109\u119e\u11b7',                          // 'ᄉᆞᆷ'
    '\ue98c': '\u1109\u119e\ud7e0',                          // 'ᄉᆞퟠ'
    '\ue98d': '\u1109\u119e\u11dd',                          // 'ᄉᆞᇝ'
    '\ue98e': '\u1109\u119e\u11b8',                          // 'ᄉᆞᆸ'
    '\ue98f': '\u1109\u119e\u11e3',                          // 'ᄉᆞᇣ'
    '\ue990': '\u1109\u119e\u11e6',                          // 'ᄉᆞᇦ'
    '\ue991': '\u1109\u119e\u11ba',                          // 'ᄉᆞᆺ'
    '\ue992': '\u1109\u119e\ud7f0',                          // 'ᄉᆞퟰ'
    '\ue993': '\u1109\u119e\u11eb',                          // 'ᄉᆞᇫ'
    '\ue994': '\u1109\u119e\u11bc',                          // 'ᄉᆞᆼ'
    '\ue995': '\u1109\u119e\u11f0',                          // 'ᄉᆞᇰ'
    '\ue996': '\u1109\u119e\u11bd',                          // 'ᄉᆞᆽ'
    '\ue997': '\u1109\u119e\u11be',                          // 'ᄉᆞᆾ'
    '\ue998': '\u1109\u119e\u11c0',                          // 'ᄉᆞᇀ'
    '\ue999': '\u1109\u11a1',                                // 'ᄉᆡ'
    '\ue99a': '\u1109\u11a1\u11a8',                          // 'ᄉᆡᆨ'
    '\ue99b': '\u1109\u11a1\u11ab',                          // 'ᄉᆡᆫ'
    '\ue99c': '\u1109\u11a1\u11af',                          // 'ᄉᆡᆯ'
    '\ue99d': '\u1109\u11a1\u11d9',                          // 'ᄉᆡᇙ'
    '\ue99e': '\u1109\u11a1\u11b7',                          // 'ᄉᆡᆷ'
    '\ue99f': '\u1109\u11a1\u11dd',                          // 'ᄉᆡᇝ'
    '\ue9a0': '\u1109\u11a1\u11b8',                          // 'ᄉᆡᆸ'
    '\ue9a1': '\u1109\u11a1\u11ba',                          // 'ᄉᆡᆺ'
    '\ue9a2': '\u1109\u11a1\u11bc',                          // 'ᄉᆡᆼ'
    '\ue9a3': '\u1109\u11a1\u11f0',                          // 'ᄉᆡᇰ'
    '\ue9a4': '\u1109\u11a1\u11f1',                          // 'ᄉᆡᇱ'
    '\ue9a5': '\u112d\u1161',                                // 'ᄭᅡ'
    '\ue9a6': '\u112d\u1161\u11a8',                          // 'ᄭᅡᆨ'
    '\ue9a7': '\u112d\u1161\u11a9',                          // 'ᄭᅡᆩ'
    '\ue9a8': '\u112d\u1161\u11aa',                          // 'ᄭᅡᆪ'
    '\ue9a9': '\u112d\u1161\u11ab',                          // 'ᄭᅡᆫ'
    '\ue9aa': '\u112d\u1161\u11af',                          // 'ᄭᅡᆯ'
    '\ue9ab': '\u112d\u1161\u11b6',                          // 'ᄭᅡᆶ'
    '\ue9ac': '\u112d\u1161\u11b7',                          // 'ᄭᅡᆷ'
    '\ue9ad': '\u112d\u1161\u11b8',                          // 'ᄭᅡᆸ'
    '\ue9ae': '\u112d\u1161\u11b9',                          // 'ᄭᅡᆹ'
    '\ue9af': '\u112d\u1161\u11e6',                          // 'ᄭᅡᇦ'
    '\ue9b0': '\u112d\u1161\u11ba',                          // 'ᄭᅡᆺ'
    '\ue9b1': '\u112d\u1161\u11e7',                          // 'ᄭᅡᇧ'
    '\ue9b2': '\u112d\u1161\u11bc',                          // 'ᄭᅡᆼ'
    '\ue9b3': '\u112d\u1161\u11f0',                          // 'ᄭᅡᇰ'
    '\ue9b4': '\u112d\u1162',                                // 'ᄭᅢ'
    '\ue9b5': '\u112d\u1162\u11a8',                          // 'ᄭᅢᆨ'
    '\ue9b6': '\u112d\u1162\u11ab',                          // 'ᄭᅢᆫ'
    '\ue9b7': '\u112d\u1162\u11af',                          // 'ᄭᅢᆯ'
    '\ue9b8': '\u112d\u1162\u11b7',                          // 'ᄭᅢᆷ'
    '\ue9b9': '\u112d\u1162\u11ba',                          // 'ᄭᅢᆺ'
    '\ue9ba': '\u112d\u1162\u11f0',                          // 'ᄭᅢᇰ'
    '\ue9bb': '\u112d\u1163',                                // 'ᄭᅣ'
    '\ue9bc': '\u112d\u1163\u11a8',                          // 'ᄭᅣᆨ'
    '\ue9bd': '\u112d\u1164',                                // 'ᄭᅤ'
    '\ue9be': '\u112d\u1164\u11ba',                          // 'ᄭᅤᆺ'
    '\ue9bf': '\u112d\u1165',                                // 'ᄭᅥ'
    '\ue9c0': '\u112d\u1165\u11a8',                          // 'ᄭᅥᆨ'
    '\ue9c1': '\u112d\u1165\u11a9',                          // 'ᄭᅥᆩ'
    '\ue9c2': '\u112d\u1165\u11ab',                          // 'ᄭᅥᆫ'
    '\ue9c3': '\u112d\u1165\u11af',                          // 'ᄭᅥᆯ'
    '\ue9c4': '\u112d\u1165\u11b2',                          // 'ᄭᅥᆲ'
    '\ue9c5': '\u112d\u1165\u11d5',                          // 'ᄭᅥᇕ'
    '\ue9c6': '\u112d\u1165\u11b7',                          // 'ᄭᅥᆷ'
    '\ue9c7': '\u112d\u1165\u11b8',                          // 'ᄭᅥᆸ'
    '\ue9c8': '\u112d\u1165\u11e6',                          // 'ᄭᅥᇦ'
    '\ue9c9': '\u112d\u1165\u11ba',                          // 'ᄭᅥᆺ'
    '\ue9ca': '\u112d\u1165\u11e7',                          // 'ᄭᅥᇧ'
    '\ue9cb': '\u112d\u1165\u11eb',                          // 'ᄭᅥᇫ'
    '\ue9cc': '\u112d\u1165\u11f0',                          // 'ᄭᅥᇰ'
    '\ue9cd': '\u112d\u1166',                                // 'ᄭᅦ'
    '\ue9ce': '\u112d\u1166\u11a8',                          // 'ᄭᅦᆨ'
    '\ue9cf': '\u112d\u1166\u11ab',                          // 'ᄭᅦᆫ'
    '\ue9d0': '\u112d\u1166\u11af',                          // 'ᄭᅦᆯ'
    '\ue9d1': '\u112d\u1166\u11b7',                          // 'ᄭᅦᆷ'
    '\ue9d2': '\u112d\u1166\u11b8',                          // 'ᄭᅦᆸ'
    '\ue9d3': '\u112d\u1166\u11ba',                          // 'ᄭᅦᆺ'
    '\ue9d4': '\u112d\u1166\u11f0',                          // 'ᄭᅦᇰ'
    '\ue9d5': '\u112d\u1167',                                // 'ᄭᅧ'
    '\ue9d6': '\u112d\u1167\u11a8',                          // 'ᄭᅧᆨ'
    '\ue9d7': '\u112d\u1167\u11ab',                          // 'ᄭᅧᆫ'
    '\ue9d8': '\u112d\u1167\u11ae',                          // 'ᄭᅧᆮ'
    '\ue9d9': '\u112d\u1167\u11af',                          // 'ᄭᅧᆯ'
    '\ue9da': '\u112d\u1167\u11b7',                          // 'ᄭᅧᆷ'
    '\ue9db': '\u112d\u1167\u11b8',                          // 'ᄭᅧᆸ'
    '\ue9dc': '\u112d\u1167\u11ba',                          // 'ᄭᅧᆺ'
    '\ue9dd': '\u112d\u1167\u11bc',                          // 'ᄭᅧᆼ'
    '\ue9de': '\u112d\u1167\u11f0',                          // 'ᄭᅧᇰ'
    '\ue9df': '\u112d\u1167\u11c0',                          // 'ᄭᅧᇀ'
    '\ue9e0': '\u112d\u1168',                                // 'ᄭᅨ'
    '\ue9e1': '\u112d\u1169',                                // 'ᄭᅩ'
    '\ue9e2': '\u112d\u1169\u11a8',                          // 'ᄭᅩᆨ'
    '\ue9e3': '\u112d\u1169\u11aa',                          // 'ᄭᅩᆪ'
    '\ue9e4': '\u112d\u1169\u11ab',                          // 'ᄭᅩᆫ'
    '\ue9e5': '\u112d\u1169\u11ae',                          // 'ᄭᅩᆮ'
    '\ue9e6': '\u112d\u1169\u11af',                          // 'ᄭᅩᆯ'
    '\ue9e7': '\u112d\u1169\u11b3',                          // 'ᄭᅩᆳ'
    '\ue9e8': '\u112d\u1169\u11b7',                          // 'ᄭᅩᆷ'
    '\ue9e9': '\u112d\u1169\u11b8',                          // 'ᄭᅩᆸ'
    '\ue9ea': '\u112d\u1169\u11ba',                          // 'ᄭᅩᆺ'
    '\ue9eb': '\u112d\u1169\ud7f0',                          // 'ᄭᅩퟰ'
    '\ue9ec': '\u112d\u1169\u11bc',                          // 'ᄭᅩᆼ'
    '\ue9ed': '\u112d\u1169\u11f0',                          // 'ᄭᅩᇰ'
    '\ue9ee': '\u112d\u1169\u11bd',                          // 'ᄭᅩᆽ'
    '\ue9ef': '\u112d\u1169\u11be',                          // 'ᄭᅩᆾ'
    '\ue9f0': '\u112d\u116a',                                // 'ᄭᅪ'
    '\ue9f1': '\u112d\u116a\u11a8',                          // 'ᄭᅪᆨ'
    '\ue9f2': '\u112d\u116a\u11ab',                          // 'ᄭᅪᆫ'
    '\ue9f3': '\u112d\u116a\u11af',                          // 'ᄭᅪᆯ'
    '\ue9f4': '\u112d\u116a\u11bc',                          // 'ᄭᅪᆼ'
    '\ue9f5': '\u112d\u116a\u11f0',                          // 'ᄭᅪᇰ'
    '\ue9f6': '\u112d\u116b',                                // 'ᄭᅫ'
    '\ue9f7': '\u112d\u116b\u11af',                          // 'ᄭᅫᆯ'
    '\ue9f8': '\u112d\u116b\u11b0',                          // 'ᄭᅫᆰ'
    '\ue9f9': '\u112d\u116b\u11bc',                          // 'ᄭᅫᆼ'
    '\ue9fa': '\u112d\u116c',                                // 'ᄭᅬ'
    '\ue9fb': '\u112d\u116c\u11a8',                          // 'ᄭᅬᆨ'
    '\ue9fc': '\u112d\u116c\u11ab',                          // 'ᄭᅬᆫ'
    '\ue9fd': '\u112d\u116c\u11af',                          // 'ᄭᅬᆯ'
    '\ue9fe': '\u112d\u116c\u11b7',                          // 'ᄭᅬᆷ'
    '\ue9ff': '\u112d\u116c\u11ba',                          // 'ᄭᅬᆺ'
    '\uea00': '\u112d\u116c\u11f0',                          // 'ᄭᅬᇰ'
    '\uea01': '\u112d\u116d',                                // 'ᄭᅭ'
    '\uea02': '\u112d\u116d\u11ab',                          // 'ᄭᅭᆫ'
    '\uea03': '\u112d\u116d\u11af',                          // 'ᄭᅭᆯ'
    '\uea04': '\u112d\u116d\u11b7',                          // 'ᄭᅭᆷ'
    '\uea05': '\u112d\u116e',                                // 'ᄭᅮ'
    '\uea06': '\u112d\u116e\u11a8',                          // 'ᄭᅮᆨ'
    '\uea07': '\u112d\u116e\u11ab',                          // 'ᄭᅮᆫ'
    '\uea08': '\u112d\u116e\u11ae',                          // 'ᄭᅮᆮ'
    '\uea09': '\u112d\u116e\u11af',                          // 'ᄭᅮᆯ'
    '\uea0a': '\u112d\u116e\u11d9',                          // 'ᄭᅮᇙ'
    '\uea0b': '\u112d\u116e\u11b7',                          // 'ᄭᅮᆷ'
    '\uea0c': '\u112d\u116e\u11da',                          // 'ᄭᅮᇚ'
    '\uea0d': '\u112d\u116e\ud7e0',                          // 'ᄭᅮퟠ'
    '\uea0e': '\u112d\u116e\u11dd',                          // 'ᄭᅮᇝ'
    '\uea0f': '\u112d\u116e\u11b8',                          // 'ᄭᅮᆸ'
    '\uea10': '\u112d\u116e\u11ba',                          // 'ᄭᅮᆺ'
    '\uea11': '\u112d\u116e\u11bc',                          // 'ᄭᅮᆼ'
    '\uea12': '\u112d\u116e\u11ec',                          // 'ᄭᅮᇬ'
    '\uea13': '\u112d\u116e\u11f0',                          // 'ᄭᅮᇰ'
    '\uea14': '\u112d\u116e\u11bd',                          // 'ᄭᅮᆽ'
    '\uea15': '\u112d\u116e\u11be',                          // 'ᄭᅮᆾ'
    '\uea16': '\u112d\u116f',                                // 'ᄭᅯ'
    '\uea17': '\u112d\u116f\u11a8',                          // 'ᄭᅯᆨ'
    '\uea18': '\u112d\u116f\u11ab',                          // 'ᄭᅯᆫ'
    '\uea19': '\u112d\u116f\u11af',                          // 'ᄭᅯᆯ'
    '\uea1a': '\u112d\u116f\u11b7',                          // 'ᄭᅯᆷ'
    '\uea1b': '\u112d\u116f\u11bc',                          // 'ᄭᅯᆼ'
    '\uea1c': '\u112d\u116f\u11f0',                          // 'ᄭᅯᇰ'
    '\uea1d': '\u112d\u1170',                                // 'ᄭᅰ'
    '\uea1e': '\u112d\u1170\u11ab',                          // 'ᄭᅰᆫ'
    '\uea1f': '\u112d\u1170\u11af',                          // 'ᄭᅰᆯ'
    '\uea20': '\u112d\ud7b5',                                // 'ᄭힵ'
    '\uea21': '\u112d\u118c',                                // 'ᄭᆌ'
    '\uea22': '\u112d\u1171',                                // 'ᄭᅱ'
    '\uea23': '\u112d\u1171\u11ab',                          // 'ᄭᅱᆫ'
    '\uea24': '\u112d\u1171\u11af',                          // 'ᄭᅱᆯ'
    '\uea25': '\u112d\u1171\u11b7',                          // 'ᄭᅱᆷ'
    '\uea26': '\u112d\u1171\u11f0',                          // 'ᄭᅱᇰ'
    '\uea27': '\u112d\u1172',                                // 'ᄭᅲ'
    '\uea28': '\u112d\u1172\u11ab',                          // 'ᄭᅲᆫ'
    '\uea29': '\u112d\u1172\u11af',                          // 'ᄭᅲᆯ'
    '\uea2a': '\u112d\u1172\u11b7',                          // 'ᄭᅲᆷ'
    '\uea2b': '\u112d\u118f',                                // 'ᄭᆏ'
    '\uea2c': '\u112d\u118f\u11bc',                          // 'ᄭᆏᆼ'
    '\uea2d': '\u112d\u1194',                                // 'ᄭᆔ'
    '\uea2e': '\u112d\u1173',                                // 'ᄭᅳ'
    '\uea2f': '\u112d\u1173\u11a8',                          // 'ᄭᅳᆨ'
    '\uea30': '\u112d\u1173\u11ab',                          // 'ᄭᅳᆫ'
    '\uea31': '\u112d\u1173\ud7cc',                          // 'ᄭᅳퟌ'
    '\uea32': '\u112d\u1173\u11ad',                          // 'ᄭᅳᆭ'
    '\uea33': '\u112d\u1173\u11ae',                          // 'ᄭᅳᆮ'
    '\uea34': '\u112d\u1173\ud7d3',                          // 'ᄭᅳퟓ'
    '\uea35': '\u112d\u1173\u11af',                          // 'ᄭᅳᆯ'
    '\uea36': '\u112d\u1173\u11d0',                          // 'ᄭᅳᇐ'
    '\uea37': '\u112d\u1173\u11b3',                          // 'ᄭᅳᆳ'
    '\uea38': '\u112d\u1173\u11b4',                          // 'ᄭᅳᆴ'
    '\uea39': '\u112d\u1173\u11b6',                          // 'ᄭᅳᆶ'
    '\uea3a': '\u112d\u1173\u11d9',                          // 'ᄭᅳᇙ'
    '\uea3b': '\u112d\u1173\u11b7',                          // 'ᄭᅳᆷ'
    '\uea3c': '\u112d\u1173\u11ba',                          // 'ᄭᅳᆺ'
    '\uea3d': '\u112d\u1173\ud7f0',                          // 'ᄭᅳퟰ'
    '\uea3e': '\u112d\u1173\ud7f1',                          // 'ᄭᅳퟱ'
    '\uea3f': '\u112d\u1173\ud7f2',                          // 'ᄭᅳퟲ'
    '\uea40': '\u112d\u1173\u11eb',                          // 'ᄭᅳᇫ'
    '\uea41': '\u112d\u1173\u11bc',                          // 'ᄭᅳᆼ'
    '\uea42': '\u112d\u1173\u11f0',                          // 'ᄭᅳᇰ'
    '\uea43': '\u112d\u1173\u11be',                          // 'ᄭᅳᆾ'
    '\uea44': '\u112d\u1173\u11c0',                          // 'ᄭᅳᇀ'
    '\uea45': '\u112d\u1174',                                // 'ᄭᅴ'
    '\uea46': '\u112d\u1174\u11ab',                          // 'ᄭᅴᆫ'
    '\uea47': '\u112d\u1174\u11af',                          // 'ᄭᅴᆯ'
    '\uea48': '\u112d\u1174\u11d0',                          // 'ᄭᅴᇐ'
    '\uea49': '\u112d\u1174\u11b7',                          // 'ᄭᅴᆷ'
    '\uea4a': '\u112d\u1174\u11ba',                          // 'ᄭᅴᆺ'
    '\uea4b': '\u112d\u1175',                                // 'ᄭᅵ'
    '\uea4c': '\u112d\u1175\u11a8',                          // 'ᄭᅵᆨ'
    '\uea4d': '\u112d\u1175\u11ab',                          // 'ᄭᅵᆫ'
    '\uea4e': '\u112d\u1175\u11ad',                          // 'ᄭᅵᆭ'
    '\uea4f': '\u112d\u1175\u11ae',                          // 'ᄭᅵᆮ'
    '\uea50': '\u112d\u1175\u11af',                          // 'ᄭᅵᆯ'
    '\uea51': '\u112d\u1175\u11b6',                          // 'ᄭᅵᆶ'
    '\uea52': '\u112d\u1175\u11b7',                          // 'ᄭᅵᆷ'
    '\uea53': '\u112d\u1175\u11b8',                          // 'ᄭᅵᆸ'
    '\uea54': '\u112d\u1175\u11ba',                          // 'ᄭᅵᆺ'
    '\uea55': '\u112d\u1175\u11bc',                          // 'ᄭᅵᆼ'
    '\uea56': '\u112d\u1175\u11c2',                          // 'ᄭᅵᇂ'
    '\uea57': '\u112d\u1198',                                // 'ᄭᆘ'
    '\uea58': '\u112d\u119e',                                // 'ᄭᆞ'
    '\uea59': '\u112d\u119e\u11a8',                          // 'ᄭᆞᆨ'
    '\uea5a': '\u112d\u119e\u11ab',                          // 'ᄭᆞᆫ'
    '\uea5b': '\u112d\u119e\u11ae',                          // 'ᄭᆞᆮ'
    '\uea5c': '\u112d\u119e\u11af',                          // 'ᄭᆞᆯ'
    '\uea5d': '\u112d\u119e\u11b7',                          // 'ᄭᆞᆷ'
    '\uea5e': '\u112d\u119e\u11b8',                          // 'ᄭᆞᆸ'
    '\uea5f': '\u112d\u119e\u11ba',                          // 'ᄭᆞᆺ'
    '\uea60': '\u112d\u119e\u11f0',                          // 'ᄭᆞᇰ'
    '\uea61': '\u112d\u11a1',                                // 'ᄭᆡ'
    '\uea62': '\u112d\u11a1\u11a8',                          // 'ᄭᆡᆨ'
    '\uea63': '\u112d\u11a1\u11ab',                          // 'ᄭᆡᆫ'
    '\uea64': '\u112d\u11a1\u11af',                          // 'ᄭᆡᆯ'
    '\uea65': '\u112d\u11a1\u11d9',                          // 'ᄭᆡᇙ'
    '\uea66': '\u112d\u11a1\u11b7',                          // 'ᄭᆡᆷ'
    '\uea67': '\u112d\u11a1\u11b8',                          // 'ᄭᆡᆸ'
    '\uea68': '\u112d\u11a1\u11ba',                          // 'ᄭᆡᆺ'
    '\uea69': '\u112d\u11a1\u11bc',                          // 'ᄭᆡᆼ'
    '\uea6a': '\u112e\u1161',                                // 'ᄮᅡ'
    '\uea6b': '\u112e\u1161\u11af',                          // 'ᄮᅡᆯ'
    '\uea6c': '\u112e\u1167',                                // 'ᄮᅧ'
    '\uea6d': '\u112e\u1167\u11a8',                          // 'ᄮᅧᆨ'
    '\uea6e': '\u112e\u1169',                                // 'ᄮᅩ'
    '\uea6f': '\u112e\u1175',                                // 'ᄮᅵ'
    '\uea70': '\u112e\u119e',                                // 'ᄮᆞ'
    '\uea71': '\u112f\u1161',                                // 'ᄯᅡ'
    '\uea72': '\u112f\u1161\u11a8',                          // 'ᄯᅡᆨ'
    '\uea73': '\u112f\u1161\u11ab',                          // 'ᄯᅡᆫ'
    '\uea74': '\u112f\u1161\u11ae',                          // 'ᄯᅡᆮ'
    '\uea75': '\u112f\u1161\u11af',                          // 'ᄯᅡᆯ'
    '\uea76': '\u112f\u1161\u11b6',                          // 'ᄯᅡᆶ'
    '\uea77': '\u112f\u1161\u11b7',                          // 'ᄯᅡᆷ'
    '\uea78': '\u112f\u1161\u11b8',                          // 'ᄯᅡᆸ'
    '\uea79': '\u112f\u1161\u11ba',                          // 'ᄯᅡᆺ'
    '\uea7a': '\u112f\u1161\u11bc',                          // 'ᄯᅡᆼ'
    '\uea7b': '\u112f\u1161\ud7f6',                          // 'ᄯᅡퟶ'
    '\uea7c': '\u112f\u1161\u11f0',                          // 'ᄯᅡᇰ'
    '\uea7d': '\u112f\u1161\ud7f6',                          // 'ᄯᅡퟶ'
    '\uea7e': '\u112f\u1161\u11c2',                          // 'ᄯᅡᇂ'
    '\uea7f': '\u112f\u1162',                                // 'ᄯᅢ'
    '\uea80': '\u112f\u1162\u11a8',                          // 'ᄯᅢᆨ'
    '\uea81': '\u112f\u1162\u11ab',                          // 'ᄯᅢᆫ'
    '\uea82': '\u112f\u1162\u11af',                          // 'ᄯᅢᆯ'
    '\uea83': '\u112f\u1162\u11b7',                          // 'ᄯᅢᆷ'
    '\uea84': '\u112f\u1162\u11b8',                          // 'ᄯᅢᆸ'
    '\uea85': '\u112f\u1162\u11ba',                          // 'ᄯᅢᆺ'
    '\uea86': '\u112f\u1162\u11bc',                          // 'ᄯᅢᆼ'
    '\uea87': '\u112f\u1162\u11f0',                          // 'ᄯᅢᇰ'
    '\uea88': '\u112f\u1163',                                // 'ᄯᅣ'
    '\uea89': '\u112f\u1163\u11a8',                          // 'ᄯᅣᆨ'
    '\uea8a': '\u112f\u1163\u11bc',                          // 'ᄯᅣᆼ'
    '\uea8b': '\u112f\u1163\u11f0',                          // 'ᄯᅣᇰ'
    '\uea8c': '\u112f\u1164',                                // 'ᄯᅤ'
    '\uea8d': '\u112f\u1165',                                // 'ᄯᅥ'
    '\uea8e': '\u112f\u1165\u11a8',                          // 'ᄯᅥᆨ'
    '\uea8f': '\u112f\u1165\u11ab',                          // 'ᄯᅥᆫ'
    '\uea90': '\u112f\u1165\u11ae',                          // 'ᄯᅥᆮ'
    '\uea91': '\u112f\u1165\u11af',                          // 'ᄯᅥᆯ'
    '\uea92': '\u112f\u1165\u11b0',                          // 'ᄯᅥᆰ'
    '\uea93': '\u112f\u1165\u11b2',                          // 'ᄯᅥᆲ'
    '\uea94': '\u112f\u1165\u11b7',                          // 'ᄯᅥᆷ'
    '\uea95': '\u112f\u1165\u11b8',                          // 'ᄯᅥᆸ'
    '\uea96': '\u112f\u1165\u11ba',                          // 'ᄯᅥᆺ'
    '\uea97': '\u112f\u1165\u11bc',                          // 'ᄯᅥᆼ'
    '\uea98': '\u112f\u1165\u11f0',                          // 'ᄯᅥᇰ'
    '\uea99': '\u112f\u1165\u11bf',                          // 'ᄯᅥᆿ'
    '\uea9a': '\u112f\u117c',                                // 'ᄯᅼ'
    '\uea9b': '\u112f\u117c\u11ba',                          // 'ᄯᅼᆺ'
    '\uea9c': '\u112f\u117c\u11bb',                          // 'ᄯᅼᆻ'
    '\uea9d': '\u112f\u117c\u11bf',                          // 'ᄯᅼᆿ'
    '\uea9e': '\u112f\u1166',                                // 'ᄯᅦ'
    '\uea9f': '\u112f\u1166\u11a8',                          // 'ᄯᅦᆨ'
    '\ueaa0': '\u112f\u1166\u11ab',                          // 'ᄯᅦᆫ'
    '\ueaa1': '\u112f\u1166\u11af',                          // 'ᄯᅦᆯ'
    '\ueaa2': '\u112f\u1166\u11b7',                          // 'ᄯᅦᆷ'
    '\ueaa3': '\u112f\u1166\u11ba',                          // 'ᄯᅦᆺ'
    '\ueaa4': '\u112f\u1166\u11bc',                          // 'ᄯᅦᆼ'
    '\ueaa5': '\u112f\u1167',                                // 'ᄯᅧ'
    '\ueaa6': '\u112f\u1167\u11a8',                          // 'ᄯᅧᆨ'
    '\ueaa7': '\u112f\u1167\u11ab',                          // 'ᄯᅧᆫ'
    '\ueaa8': '\u112f\u1167\u11ba',                          // 'ᄯᅧᆺ'
    '\ueaa9': '\u112f\u1167\u11bc',                          // 'ᄯᅧᆼ'
    '\ueaaa': '\u112f\u1168',                                // 'ᄯᅨ'
    '\ueaab': '\u112f\u1169',                                // 'ᄯᅩ'
    '\ueaac': '\u112f\u1169\u11a8',                          // 'ᄯᅩᆨ'
    '\ueaad': '\u112f\u1169\u11aa',                          // 'ᄯᅩᆪ'
    '\ueaae': '\u112f\u1169\u11ab',                          // 'ᄯᅩᆫ'
    '\ueaaf': '\u112f\u1169\u11af',                          // 'ᄯᅩᆯ'
    '\ueab0': '\u112f\u1169\u11b7',                          // 'ᄯᅩᆷ'
    '\ueab1': '\u112f\u1169\u11b8',                          // 'ᄯᅩᆸ'
    '\ueab2': '\u112f\u1169\u11ba',                          // 'ᄯᅩᆺ'
    '\ueab3': '\u112f\u1169\u11bc',                          // 'ᄯᅩᆼ'
    '\ueab4': '\u112f\u1169\u11f0',                          // 'ᄯᅩᇰ'
    '\ueab5': '\u112f\u1169\u11f1',                          // 'ᄯᅩᇱ'
    '\ueab6': '\u112f\u116a',                                // 'ᄯᅪ'
    '\ueab7': '\u112f\u116b',                                // 'ᄯᅫ'
    '\ueab8': '\u112f\u116c',                                // 'ᄯᅬ'
    '\ueab9': '\u112f\u116c\u11ab',                          // 'ᄯᅬᆫ'
    '\ueaba': '\u112f\u116c\u11af',                          // 'ᄯᅬᆯ'
    '\ueabb': '\u112f\u116d',                                // 'ᄯᅭ'
    '\ueabc': '\u112f\u116d\u11a8',                          // 'ᄯᅭᆨ'
    '\ueabd': '\u112f\u116d\u11ba',                          // 'ᄯᅭᆺ'
    '\ueabe': '\u112f\u116d\u11bc',                          // 'ᄯᅭᆼ'
    '\ueabf': '\u112f\u116e',                                // 'ᄯᅮ'
    '\ueac0': '\u112f\u116e\u11a8',                          // 'ᄯᅮᆨ'
    '\ueac1': '\u112f\u116e\u11ab',                          // 'ᄯᅮᆫ'
    '\ueac2': '\u112f\u116e\u11af',                          // 'ᄯᅮᆯ'
    '\ueac3': '\u112f\u116e\u11b0',                          // 'ᄯᅮᆰ'
    '\ueac4': '\u112f\u116e\u11b2',                          // 'ᄯᅮᆲ'
    '\ueac5': '\u112f\u116e\u11b7',                          // 'ᄯᅮᆷ'
    '\ueac6': '\u112f\u116e\u11ba',                          // 'ᄯᅮᆺ'
    '\ueac7': '\u112f\u116e\u11bc',                          // 'ᄯᅮᆼ'
    '\ueac8': '\u112f\u116e\u11f0',                          // 'ᄯᅮᇰ'
    '\ueac9': '\u112f\u116f',                                // 'ᄯᅯ'
    '\ueaca': '\u112f\u1170',                                // 'ᄯᅰ'
    '\ueacb': '\u112f\ud7b5',                                // 'ᄯힵ'
    '\ueacc': '\u112f\u118c',                                // 'ᄯᆌ'
    '\ueacd': '\u112f\u1171',                                // 'ᄯᅱ'
    '\ueace': '\u112f\u1171\u11ab',                          // 'ᄯᅱᆫ'
    '\ueacf': '\u112f\u1171\u11af',                          // 'ᄯᅱᆯ'
    '\uead0': '\u112f\u1171\u11b7',                          // 'ᄯᅱᆷ'
    '\uead1': '\u112f\u1171\u11ba',                          // 'ᄯᅱᆺ'
    '\uead2': '\u112f\u1172',                                // 'ᄯᅲ'
    '\uead3': '\u112f\u1172\u11a8',                          // 'ᄯᅲᆨ'
    '\uead4': '\u112f\u1172\u11af',                          // 'ᄯᅲᆯ'
    '\uead5': '\u112f\u1172\u11b7',                          // 'ᄯᅲᆷ'
    '\uead6': '\u112f\u1172\u11bc',                          // 'ᄯᅲᆼ'
    '\uead7': '\u112f\u1172\u11f0',                          // 'ᄯᅲᇰ'
    '\uead8': '\u112f\u1194',                                // 'ᄯᆔ'
    '\uead9': '\u112f\u1173',                                // 'ᄯᅳ'
    '\ueada': '\u112f\u1173\u11a8',                          // 'ᄯᅳᆨ'
    '\ueadb': '\u112f\u1173\u11ab',                          // 'ᄯᅳᆫ'
    '\ueadc': '\u112f\u1173\u11ae',                          // 'ᄯᅳᆮ'
    '\ueadd': '\u112f\u1173\u11af',                          // 'ᄯᅳᆯ'
    '\ueade': '\u112f\u1173\u11b2',                          // 'ᄯᅳᆲ'
    '\ueadf': '\u112f\u1173\u11b6',                          // 'ᄯᅳᆶ'
    '\ueae0': '\u112f\u1173\u11b7',                          // 'ᄯᅳᆷ'
    '\ueae1': '\u112f\u1173\u11b8',                          // 'ᄯᅳᆸ'
    '\ueae2': '\u112f\u1173\u11ba',                          // 'ᄯᅳᆺ'
    '\ueae3': '\u112f\u1173\u11bc',                          // 'ᄯᅳᆼ'
    '\ueae4': '\u112f\u1173\u11f0',                          // 'ᄯᅳᇰ'
    '\ueae5': '\u112f\u1173\u11f1',                          // 'ᄯᅳᇱ'
    '\ueae6': '\u112f\u1173\u11bd',                          // 'ᄯᅳᆽ'
    '\ueae7': '\u112f\u1174',                                // 'ᄯᅴ'
    '\ueae8': '\u112f\u1174\u11ab',                          // 'ᄯᅴᆫ'
    '\ueae9': '\u112f\u1174\u11af',                          // 'ᄯᅴᆯ'
    '\ueaea': '\u112f\u1174\u11ba',                          // 'ᄯᅴᆺ'
    '\ueaeb': '\u112f\u1174\u11eb',                          // 'ᄯᅴᇫ'
    '\ueaec': '\u112f\u1174\u11be',                          // 'ᄯᅴᆾ'
    '\ueaed': '\u112f\u1175',                                // 'ᄯᅵ'
    '\ueaee': '\u112f\u1175\u11a8',                          // 'ᄯᅵᆨ'
    '\ueaef': '\u112f\u1175\u11ab',                          // 'ᄯᅵᆫ'
    '\ueaf0': '\u112f\u1175\u11ae',                          // 'ᄯᅵᆮ'
    '\ueaf1': '\u112f\u1175\u11af',                          // 'ᄯᅵᆯ'
    '\ueaf2': '\u112f\u1175\u11b7',                          // 'ᄯᅵᆷ'
    '\ueaf3': '\u112f\u1175\u11ba',                          // 'ᄯᅵᆺ'
    '\ueaf4': '\u112f\u1175\ud7f2',                          // 'ᄯᅵퟲ'
    '\ueaf5': '\u112f\u1175\u11bc',                          // 'ᄯᅵᆼ'
    '\ueaf6': '\u112f\u1175\u11c2',                          // 'ᄯᅵᇂ'
    '\ueaf7': '\u112f\u1198',                                // 'ᄯᆘ'
    '\ueaf8': '\u112f\u119e',                                // 'ᄯᆞ'
    '\ueaf9': '\u112f\u119e\u11a8',                          // 'ᄯᆞᆨ'
    '\ueafa': '\u112f\u119e\u11ab',                          // 'ᄯᆞᆫ'
    '\ueafb': '\u112f\u119e\u11ae',                          // 'ᄯᆞᆮ'
    '\ueafc': '\u112f\u119e\u11af',                          // 'ᄯᆞᆯ'
    '\ueafd': '\u112f\u119e\u11b6',                          // 'ᄯᆞᆶ'
    '\ueafe': '\u112f\u119e\u11b7',                          // 'ᄯᆞᆷ'
    '\ueaff': '\u112f\u119e\u11b8',                          // 'ᄯᆞᆸ'
    '\ueb00': '\u112f\u119e\u11ba',                          // 'ᄯᆞᆺ'
    '\ueb01': '\u112f\u119e\u11bc',                          // 'ᄯᆞᆼ'
    '\ueb02': '\u112f\u119e\u11f0',                          // 'ᄯᆞᇰ'
    '\ueb03': '\u112f\u119e\u11c2',                          // 'ᄯᆞᇂ'
    '\ueb04': '\u112f\u11a1',                                // 'ᄯᆡ'
    '\ueb05': '\u112f\u11a1\u11a8',                          // 'ᄯᆡᆨ'
    '\ueb06': '\u112f\u11a1\u11ab',                          // 'ᄯᆡᆫ'
    '\ueb07': '\u112f\u11a1\u11af',                          // 'ᄯᆡᆯ'
    '\ueb08': '\u112f\u11a1\u11b7',                          // 'ᄯᆡᆷ'
    '\ueb09': '\u112f\u11a1\u11b8',                          // 'ᄯᆡᆸ'
    '\ueb0a': '\u112f\u11a1\u11ba',                          // 'ᄯᆡᆺ'
    '\ueb0b': '\u112f\u11a1\u11bc',                          // 'ᄯᆡᆼ'
    '\ueb0c': '\u112f\u11a1\u11f0',                          // 'ᄯᆡᇰ'
    '\ueb0d': '\u1130\u1161',                                // 'ᄰᅡ'
    '\ueb0e': '\u1130\u1162',                                // 'ᄰᅢ'
    '\ueb0f': '\u1130\u1162\u11ab',                          // 'ᄰᅢᆫ'
    '\ueb10': '\u1130\u1163',                                // 'ᄰᅣ'
    '\ueb11': '\u1130\u1165',                                // 'ᄰᅥ'
    '\ueb12': '\u1130\u1165\u11ba',                          // 'ᄰᅥᆺ'
    '\ueb13': '\u1130\u1166',                                // 'ᄰᅦ'
    '\ueb14': '\u1130\u1167',                                // 'ᄰᅧ'
    '\ueb15': '\u1130\u1167\u11ba',                          // 'ᄰᅧᆺ'
    '\ueb16': '\u1130\u116e',                                // 'ᄰᅮ'
    '\ueb17': '\u1130\u1172',                                // 'ᄰᅲ'
    '\ueb18': '\u1130\u1172\u11ab',                          // 'ᄰᅲᆫ'
    '\ueb19': '\u1130\u1172\u11bc',                          // 'ᄰᅲᆼ'
    '\ueb1a': '\u1130\u1173',                                // 'ᄰᅳ'
    '\ueb1b': '\u1130\u1173\u11ab',                          // 'ᄰᅳᆫ'
    '\ueb1c': '\u1130\u1173\u11af',                          // 'ᄰᅳᆯ'
    '\ueb1d': '\u1130\u1173\u11b7',                          // 'ᄰᅳᆷ'
    '\ueb1e': '\u1130\u1175',                                // 'ᄰᅵ'
    '\ueb1f': '\u1130\u1175\u11ab',                          // 'ᄰᅵᆫ'
    '\ueb20': '\u1131\u1161',                                // 'ᄱᅡ'
    '\ueb21': '\u1131\u1161\u11af',                          // 'ᄱᅡᆯ'
    '\ueb22': '\u1131\u1169',                                // 'ᄱᅩ'
    '\ueb23': '\u1131\u1173',                                // 'ᄱᅳ'
    '\ueb24': '\u1131\u1173\u11af',                          // 'ᄱᅳᆯ'
    '\ueb25': '\u1131\u119e',                                // 'ᄱᆞ'
    '\ueb26': '\u1131\u119e\u11af',                          // 'ᄱᆞᆯ'
    '\ueb27': '\u1132\u1161',                                // 'ᄲᅡ'
    '\ueb28': '\u1132\u1161\u11a8',                          // 'ᄲᅡᆨ'
    '\ueb29': '\u1132\u1161\u11ab',                          // 'ᄲᅡᆫ'
    '\ueb2a': '\u1132\u1161\u11ae',                          // 'ᄲᅡᆮ'
    '\ueb2b': '\u1132\u1161\u11af',                          // 'ᄲᅡᆯ'
    '\ueb2c': '\u1132\u1161\u11b0',                          // 'ᄲᅡᆰ'
    '\ueb2d': '\u1132\u1161\u11d0',                          // 'ᄲᅡᇐ'
    '\ueb2e': '\u1132\u1161\u11b7',                          // 'ᄲᅡᆷ'
    '\ueb2f': '\u1132\u1161\u11b8',                          // 'ᄲᅡᆸ'
    '\ueb30': '\u1132\u1161\u11ba',                          // 'ᄲᅡᆺ'
    '\ueb31': '\u1132\u1161\u11bc',                          // 'ᄲᅡᆼ'
    '\ueb32': '\u1132\u1161\u11f0',                          // 'ᄲᅡᇰ'
    '\ueb33': '\u1132\u1161\u11c2',                          // 'ᄲᅡᇂ'
    '\ueb34': '\u1132\u1162',                                // 'ᄲᅢ'
    '\ueb35': '\u1132\u1162\u11a8',                          // 'ᄲᅢᆨ'
    '\ueb36': '\u1132\u1162\u11ab',                          // 'ᄲᅢᆫ'
    '\ueb37': '\u1132\u1162\u11af',                          // 'ᄲᅢᆯ'
    '\ueb38': '\u1132\u1162\u11b7',                          // 'ᄲᅢᆷ'
    '\ueb39': '\u1132\u1162\u11ba',                          // 'ᄲᅢᆺ'
    '\ueb3a': '\u1132\u1162\u11f0',                          // 'ᄲᅢᇰ'
    '\ueb3b': '\u1132\u1163',                                // 'ᄲᅣ'
    '\ueb3c': '\u1132\u1163\u11b7',                          // 'ᄲᅣᆷ'
    '\ueb3d': '\u1132\u1165',                                // 'ᄲᅥ'
    '\ueb3e': '\u1132\u1165\u11a8',                          // 'ᄲᅥᆨ'
    '\ueb3f': '\u1132\u1165\u11ab',                          // 'ᄲᅥᆫ'
    '\ueb40': '\u1132\u1165\u11ae',                          // 'ᄲᅥᆮ'
    '\ueb41': '\u1132\u1165\u11af',                          // 'ᄲᅥᆯ'
    '\ueb42': '\u1132\u1165\u11b7',                          // 'ᄲᅥᆷ'
    '\ueb43': '\u1132\u1165\u11b8',                          // 'ᄲᅥᆸ'
    '\ueb44': '\u1132\u1165\u11ba',                          // 'ᄲᅥᆺ'
    '\ueb45': '\u1132\u1165\u11bc',                          // 'ᄲᅥᆼ'
    '\ueb46': '\u1132\u1165\u11f0',                          // 'ᄲᅥᇰ'
    '\ueb47': '\u1132\u1166',                                // 'ᄲᅦ'
    '\ueb48': '\u1132\u1166\u11ab',                          // 'ᄲᅦᆫ'
    '\ueb49': '\u1132\u1166\u11af',                          // 'ᄲᅦᆯ'
    '\ueb4a': '\u1132\u1166\u11b7',                          // 'ᄲᅦᆷ'
    '\ueb4b': '\u1132\u1166\u11ba',                          // 'ᄲᅦᆺ'
    '\ueb4c': '\u1132\u1167',                                // 'ᄲᅧ'
    '\ueb4d': '\u1132\u1167\u11a8',                          // 'ᄲᅧᆨ'
    '\ueb4e': '\u1132\u1167\u11ab',                          // 'ᄲᅧᆫ'
    '\ueb4f': '\u1132\u1167\u11af',                          // 'ᄲᅧᆯ'
    '\ueb50': '\u1132\u1167\u11b7',                          // 'ᄲᅧᆷ'
    '\ueb51': '\u1132\u1167\u11ba',                          // 'ᄲᅧᆺ'
    '\ueb52': '\u1132\u1167\ud7f2',                          // 'ᄲᅧퟲ'
    '\ueb53': '\u1132\u1167\u11f0',                          // 'ᄲᅧᇰ'
    '\ueb54': '\u1132\u1167\u11c2',                          // 'ᄲᅧᇂ'
    '\ueb55': '\u1132\u1168',                                // 'ᄲᅨ'
    '\ueb56': '\u1132\u1169',                                // 'ᄲᅩ'
    '\ueb57': '\u1132\u1169\u11a8',                          // 'ᄲᅩᆨ'
    '\ueb58': '\u1132\u1169\u11ab',                          // 'ᄲᅩᆫ'
    '\ueb59': '\u1132\u1169\u11af',                          // 'ᄲᅩᆯ'
    '\ueb5a': '\u1132\u1169\u11b7',                          // 'ᄲᅩᆷ'
    '\ueb5b': '\u1132\u1169\u11b8',                          // 'ᄲᅩᆸ'
    '\ueb5c': '\u1132\u1169\u11ba',                          // 'ᄲᅩᆺ'
    '\ueb5d': '\u1132\u1169\u11e7',                          // 'ᄲᅩᇧ'
    '\ueb5e': '\u1132\u1169\u11bc',                          // 'ᄲᅩᆼ'
    '\ueb5f': '\u1132\u1169\u11f0',                          // 'ᄲᅩᇰ'
    '\ueb60': '\u1132\u1169\u11f1',                          // 'ᄲᅩᇱ'
    '\ueb61': '\u1132\u1169\u11c2',                          // 'ᄲᅩᇂ'
    '\ueb62': '\u1132\u116a',                                // 'ᄲᅪ'
    '\ueb63': '\u1132\u116b',                                // 'ᄲᅫ'
    '\ueb64': '\u1132\u116c',                                // 'ᄲᅬ'
    '\ueb65': '\u1132\u116d',                                // 'ᄲᅭ'
    '\ueb66': '\u1132\u116e',                                // 'ᄲᅮ'
    '\ueb67': '\u1132\u116e\u11a8',                          // 'ᄲᅮᆨ'
    '\ueb68': '\u1132\u116e\u11ab',                          // 'ᄲᅮᆫ'
    '\ueb69': '\u1132\u116e\u11ae',                          // 'ᄲᅮᆮ'
    '\ueb6a': '\u1132\u116e\u11af',                          // 'ᄲᅮᆯ'
    '\ueb6b': '\u1132\u116e\u11b1',                          // 'ᄲᅮᆱ'
    '\ueb6c': '\u1132\u116e\u11b7',                          // 'ᄲᅮᆷ'
    '\ueb6d': '\u1132\u116e\u11ba',                          // 'ᄲᅮᆺ'
    '\ueb6e': '\u1132\u116e\u11bc',                          // 'ᄲᅮᆼ'
    '\ueb6f': '\u1132\u116e\u11bd',                          // 'ᄲᅮᆽ'
    '\ueb70': '\u1132\u116e\u11be',                          // 'ᄲᅮᆾ'
    '\ueb71': '\u1132\u116f',                                // 'ᄲᅯ'
    '\ueb72': '\u1132\u1170',                                // 'ᄲᅰ'
    '\ueb73': '\u1132\ud7b5',                                // 'ᄲힵ'
    '\ueb74': '\u1132\u118c',                                // 'ᄲᆌ'
    '\ueb75': '\u1132\u1171',                                // 'ᄲᅱ'
    '\ueb76': '\u1132\u1172',                                // 'ᄲᅲ'
    '\ueb77': '\u1132\u1194',                                // 'ᄲᆔ'
    '\ueb78': '\u1132\u1173',                                // 'ᄲᅳ'
    '\ueb79': '\u1132\u1173\u11a8',                          // 'ᄲᅳᆨ'
    '\ueb7a': '\u1132\u1173\u11ab',                          // 'ᄲᅳᆫ'
    '\ueb7b': '\u1132\u1173\u11af',                          // 'ᄲᅳᆯ'
    '\ueb7c': '\u1132\u1173\u11b3',                          // 'ᄲᅳᆳ'
    '\ueb7d': '\u1132\u1173\u11b7',                          // 'ᄲᅳᆷ'
    '\ueb7e': '\u1132\u1173\u11ba',                          // 'ᄲᅳᆺ'
    '\ueb7f': '\u1132\u1173\u11bc',                          // 'ᄲᅳᆼ'
    '\ueb80': '\u1132\u1174',                                // 'ᄲᅴ'
    '\ueb81': '\u1132\u1174\u11af',                          // 'ᄲᅴᆯ'
    '\ueb82': '\u1132\u1175',                                // 'ᄲᅵ'
    '\ueb83': '\u1132\u1175\u11a8',                          // 'ᄲᅵᆨ'
    '\ueb84': '\u1132\u1175\u11ab',                          // 'ᄲᅵᆫ'
    '\ueb85': '\u1132\u1175\u11af',                          // 'ᄲᅵᆯ'
    '\ueb86': '\u1132\u1175\u11b7',                          // 'ᄲᅵᆷ'
    '\ueb87': '\u1132\u1175\u11ba',                          // 'ᄲᅵᆺ'
    '\ueb88': '\u1132\u1175\u11e7',                          // 'ᄲᅵᇧ'
    '\ueb89': '\u1132\u1175\ud7f2',                          // 'ᄲᅵퟲ'
    '\ueb8a': '\u1132\u1175\u11f0',                          // 'ᄲᅵᇰ'
    '\ueb8b': '\u1132\u1175\u11be',                          // 'ᄲᅵᆾ'
    '\ueb8c': '\u1132\u1175\u11c2',                          // 'ᄲᅵᇂ'
    '\ueb8d': '\u1132\u1198',                                // 'ᄲᆘ'
    '\ueb8e': '\u1132\u119e',                                // 'ᄲᆞ'
    '\ueb8f': '\u1132\u119e\u11a8',                          // 'ᄲᆞᆨ'
    '\ueb90': '\u1132\u119e\u11ab',                          // 'ᄲᆞᆫ'
    '\ueb91': '\u1132\u119e\u11af',                          // 'ᄲᆞᆯ'
    '\ueb92': '\u1132\u119e\u11d0',                          // 'ᄲᆞᇐ'
    '\ueb93': '\u1132\u119e\u11b7',                          // 'ᄲᆞᆷ'
    '\ueb94': '\u1132\u119e\u11b8',                          // 'ᄲᆞᆸ'
    '\ueb95': '\u1132\u119e\u11ba',                          // 'ᄲᆞᆺ'
    '\ueb96': '\u1132\u119e\u11bc',                          // 'ᄲᆞᆼ'
    '\ueb97': '\u1132\u11a1',                                // 'ᄲᆡ'
    '\ueb98': '\u1132\u11a1\u11a8',                          // 'ᄲᆡᆨ'
    '\ueb99': '\u1132\u11a1\u11ab',                          // 'ᄲᆡᆫ'
    '\ueb9a': '\u1132\u11a1\u11af',                          // 'ᄲᆡᆯ'
    '\ueb9b': '\u1132\u11a1\u11b7',                          // 'ᄲᆡᆷ'
    '\ueb9c': '\u1132\u11a1\u11ba',                          // 'ᄲᆡᆺ'
    '\ueb9d': '\u1132\u11a1\u11e7',                          // 'ᄲᆡᇧ'
    '\ueb9e': '\u1132\u11a1\u11bc',                          // 'ᄲᆡᆼ'
    '\ueb9f': '\u1132\u11a1\u11f0',                          // 'ᄲᆡᇰ'
    '\ueba0': '\u1133\u1161',                                // 'ᄳᅡ'
    '\ueba1': '\u1133\u1162',                                // 'ᄳᅢ'
    '\ueba2': '\u1133\u116e',                                // 'ᄳᅮ'
    '\ueba3': '\u1133\u116e\u11af',                          // 'ᄳᅮᆯ'
    '\ueba4': '\u1133\u1173',                                // 'ᄳᅳ'
    '\ueba5': '\u1133\u1173\u11af',                          // 'ᄳᅳᆯ'
    '\ueba6': '\u1133\u1174',                                // 'ᄳᅴ'
    '\ueba7': '\u1133\u1175',                                // 'ᄳᅵ'
    '\ueba8': '\u110a\u1161\u11e2',                          // '싸ᇢ'
    '\ueba9': '\u110a\u1161\ud7f2',                          // '싸ퟲ'
    '\uebaa': '\u110a\u1161\u11f0',                          // '싸ᇰ'
    '\uebab': '\u110a\u1163\u11e2',                          // '쌰ᇢ'
    '\uebac': '\u110a\u1163\u11f0',                          // '쌰ᇰ'
    '\uebad': '\u110a\u1165\u11f0',                          // '써ᇰ'
    '\uebae': '\u110a\u1167\u11d9',                          // '쎠ᇙ'
    '\uebaf': '\u110a\u1167\u11f0',                          // '쎠ᇰ'
    '\uebb0': '\u110a\u1167\u11f9',                          // '쎠ᇹ'
    '\uebb1': '\u110a\u1169\u11e2',                          // '쏘ᇢ'
    '\uebb2': '\u110a\u1169\u11f0',                          // '쏘ᇰ'
    '\uebb3': '\u110a\u116d\u11e2',                          // '쑈ᇢ'
    '\uebb4': '\u110a\u116d\u11f0',                          // '쑈ᇰ'
    '\uebb5': '\u110a\u1188',                                // 'ᄊᆈ'
    '\uebb6': '\u110a\u116e\u11d9',                          // '쑤ᇙ'
    '\uebb7': '\u110a\u116e\u11e2',                          // '쑤ᇢ'
    '\uebb8': '\u110a\u116e\u11f9',                          // '쑤ᇹ'
    '\uebb9': '\u110a\u1189',                                // 'ᄊᆉ'
    '\uebba': '\u110a\u1189\u11ab',                          // 'ᄊᆉᆫ'
    '\uebbb': '\u110a\u1189\u11bc',                          // 'ᄊᆉᆼ'
    '\uebbc': '\u110a\ud7b5',                                // 'ᄊힵ'
    '\uebbd': '\u110a\ud7b5\u11ab',                          // 'ᄊힵᆫ'
    '\uebbe': '\u110a\u118c',                                // 'ᄊᆌ'
    '\uebbf': '\u110a\u1172\u11d9',                          // '쓔ᇙ'
    '\uebc0': '\u110a\u1172\u11e2',                          // '쓔ᇢ'
    '\uebc1': '\u110a\u1172\u11f0',                          // '쓔ᇰ'
    '\uebc2': '\u110a\u1172\u11f9',                          // '쓔ᇹ'
    '\uebc3': '\u110a\u1191',                                // 'ᄊᆑ'
    '\uebc4': '\u110a\u1191\u11ab',                          // 'ᄊᆑᆫ'
    '\uebc5': '\u110a\u1191\u11bc',                          // 'ᄊᆑᆼ'
    '\uebc6': '\u110a\u1192',                                // 'ᄊᆒ'
    '\uebc7': '\u110a\u1192\u11bc',                          // 'ᄊᆒᆼ'
    '\uebc8': '\u110a\u1194',                                // 'ᄊᆔ'
    '\uebc9': '\u110a\u1194\u11bc',                          // 'ᄊᆔᆼ'
    '\uebca': '\u110a\u1194\u11f0',                          // 'ᄊᆔᇰ'
    '\uebcb': '\u110a\u1173\u11d9',                          // '쓰ᇙ'
    '\uebcc': '\u110a\u1173\u11eb',                          // '쓰ᇫ'
    '\uebcd': '\u110a\u1173\u11f0',                          // '쓰ᇰ'
    '\uebce': '\u110a\u1175\u11d9',                          // '씨ᇙ'
    '\uebcf': '\u110a\u1175\u11e2',                          // '씨ᇢ'
    '\uebd0': '\u110a\u1175\u11eb',                          // '씨ᇫ'
    '\uebd1': '\u110a\u1175\u11f0',                          // '씨ᇰ'
    '\uebd2': '\u110a\u1175\u11f9',                          // '씨ᇹ'
    '\uebd3': '\u110a\u1198',                                // 'ᄊᆘ'
    '\uebd4': '\u110a\u119e',                                // 'ᄊᆞ'
    '\uebd5': '\u110a\u119e\u11a8',                          // 'ᄊᆞᆨ'
    '\uebd6': '\u110a\u119e\u11ab',                          // 'ᄊᆞᆫ'
    '\uebd7': '\u110a\u119e\u11ae',                          // 'ᄊᆞᆮ'
    '\uebd8': '\u110a\u119e\u11af',                          // 'ᄊᆞᆯ'
    '\uebd9': '\u110a\u119e\u11b7',                          // 'ᄊᆞᆷ'
    '\uebda': '\u110a\u119e\u11dd',                          // 'ᄊᆞᇝ'
    '\uebdb': '\u110a\u119e\u11b8',                          // 'ᄊᆞᆸ'
    '\uebdc': '\u110a\u119e\u11e6',                          // 'ᄊᆞᇦ'
    '\uebdd': '\u110a\u119e\u11ba',                          // 'ᄊᆞᆺ'
    '\uebde': '\u110a\u119e\u11bc',                          // 'ᄊᆞᆼ'
    '\uebdf': '\u110a\u119e\u11f0',                          // 'ᄊᆞᇰ'
    '\uebe0': '\u110a\u119e\u11c1',                          // 'ᄊᆞᇁ'
    '\uebe1': '\u110a\u11a1',                                // 'ᄊᆡ'
    '\uebe2': '\u110a\u11a1\u11ab',                          // 'ᄊᆡᆫ'
    '\uebe3': '\u110a\u11a1\u11f0',                          // 'ᄊᆡᇰ'
    '\uebe4': '\ua975\u1169',                                // 'ꥵᅩ'
    '\uebe5': '\u1134\u1173',                                // 'ᄴᅳ'
    '\uebe6': '\u1135\u1173',                                // 'ᄵᅳ'
    '\uebe7': '\u1135\u1173\u11af',                          // 'ᄵᅳᆯ'
    '\uebe8': '\u1135\u1173\u11b7',                          // 'ᄵᅳᆷ'
    '\uebe9': '\u1135\u1175',                                // 'ᄵᅵ'
    '\uebea': '\u1135\u1175\u11af',                          // 'ᄵᅵᆯ'
    '\uebeb': '\u1136\u1161',                                // 'ᄶᅡ'
    '\uebec': '\u1136\u1161\u11a8',                          // 'ᄶᅡᆨ'
    '\uebed': '\u1136\u1161\u11ab',                          // 'ᄶᅡᆫ'
    '\uebee': '\u1136\u1161\u11af',                          // 'ᄶᅡᆯ'
    '\uebef': '\u1136\u1161\u11b2',                          // 'ᄶᅡᆲ'
    '\uebf0': '\u1136\u1161\u11b7',                          // 'ᄶᅡᆷ'
    '\uebf1': '\u1136\u1161\u11b8',                          // 'ᄶᅡᆸ'
    '\uebf2': '\u1136\u1161\u11ba',                          // 'ᄶᅡᆺ'
    '\uebf3': '\u1136\u1161\u11e9',                          // 'ᄶᅡᇩ'
    '\uebf4': '\u1136\u1161\u11bc',                          // 'ᄶᅡᆼ'
    '\uebf5': '\u1136\u1161\u11f0',                          // 'ᄶᅡᇰ'
    '\uebf6': '\u1136\u1162',                                // 'ᄶᅢ'
    '\uebf7': '\u1136\u1162\u11a8',                          // 'ᄶᅢᆨ'
    '\uebf8': '\u1136\u1162\u11ab',                          // 'ᄶᅢᆫ'
    '\uebf9': '\u1136\u1162\u11af',                          // 'ᄶᅢᆯ'
    '\uebfa': '\u1136\u1162\u11ba',                          // 'ᄶᅢᆺ'
    '\uebfb': '\u1136\u1162\u11bc',                          // 'ᄶᅢᆼ'
    '\uebfc': '\u1136\u1162\u11f0',                          // 'ᄶᅢᇰ'
    '\uebfd': '\u1136\u1163',                                // 'ᄶᅣ'
    '\uebfe': '\u1136\u1163\u11a8',                          // 'ᄶᅣᆨ'
    '\uebff': '\u1136\u1163\u11bc',                          // 'ᄶᅣᆼ'
    '\uec00': '\u1136\u1163\u11f0',                          // 'ᄶᅣᇰ'
    '\uec01': '\u1136\u1165',                                // 'ᄶᅥ'
    '\uec02': '\u1136\u1165\u11a8',                          // 'ᄶᅥᆨ'
    '\uec03': '\u1136\u1165\u11ab',                          // 'ᄶᅥᆫ'
    '\uec04': '\u1136\u1165\u11af',                          // 'ᄶᅥᆯ'
    '\uec05': '\u1136\u1165\u11b7',                          // 'ᄶᅥᆷ'
    '\uec06': '\u1136\u1165\u11b8',                          // 'ᄶᅥᆸ'
    '\uec07': '\u1136\u1165\u11ba',                          // 'ᄶᅥᆺ'
    '\uec08': '\u1136\u1165\u11bc',                          // 'ᄶᅥᆼ'
    '\uec09': '\u1136\u1166',                                // 'ᄶᅦ'
    '\uec0a': '\u1136\u1166\u11ab',                          // 'ᄶᅦᆫ'
    '\uec0b': '\u1136\u1166\u11af',                          // 'ᄶᅦᆯ'
    '\uec0c': '\u1136\u1166\u11b8',                          // 'ᄶᅦᆸ'
    '\uec0d': '\u1136\u1166\u11ba',                          // 'ᄶᅦᆺ'
    '\uec0e': '\u1136\u1167',                                // 'ᄶᅧ'
    '\uec0f': '\u1136\u1167\u11a8',                          // 'ᄶᅧᆨ'
    '\uec10': '\u1136\u1167\u11ab',                          // 'ᄶᅧᆫ'
    '\uec11': '\u1136\u1167\u11af',                          // 'ᄶᅧᆯ'
    '\uec12': '\u1136\u1167\u11ba',                          // 'ᄶᅧᆺ'
    '\uec13': '\u1136\u1167\u11f0',                          // 'ᄶᅧᇰ'
    '\uec14': '\u1136\u1168',                                // 'ᄶᅨ'
    '\uec15': '\u1136\u1168\u11ab',                          // 'ᄶᅨᆫ'
    '\uec16': '\u1136\u1169',                                // 'ᄶᅩ'
    '\uec17': '\u1136\u1169\u11a8',                          // 'ᄶᅩᆨ'
    '\uec18': '\u1136\u1169\u11ab',                          // 'ᄶᅩᆫ'
    '\uec19': '\u1136\u1169\u11af',                          // 'ᄶᅩᆯ'
    '\uec1a': '\u1136\u1169\u11b7',                          // 'ᄶᅩᆷ'
    '\uec1b': '\u1136\u1169\u11b8',                          // 'ᄶᅩᆸ'
    '\uec1c': '\u1136\u1169\u11ba',                          // 'ᄶᅩᆺ'
    '\uec1d': '\u1136\u1169\u11bc',                          // 'ᄶᅩᆼ'
    '\uec1e': '\u1136\u1169\u11bd',                          // 'ᄶᅩᆽ'
    '\uec1f': '\u1136\u1169\u11be',                          // 'ᄶᅩᆾ'
    '\uec20': '\u1136\u116a',                                // 'ᄶᅪ'
    '\uec21': '\u1136\u116a\u11a8',                          // 'ᄶᅪᆨ'
    '\uec22': '\u1136\u116c',                                // 'ᄶᅬ'
    '\uec23': '\u1136\u116c\u11a8',                          // 'ᄶᅬᆨ'
    '\uec24': '\u1136\u116c\u11ab',                          // 'ᄶᅬᆫ'
    '\uec25': '\u1136\u116c\u11af',                          // 'ᄶᅬᆯ'
    '\uec26': '\u1136\u116c\u11b7',                          // 'ᄶᅬᆷ'
    '\uec27': '\u1136\u116d',                                // 'ᄶᅭ'
    '\uec28': '\u1136\u116d\u11a8',                          // 'ᄶᅭᆨ'
    '\uec29': '\u1136\u116d\u11ae',                          // 'ᄶᅭᆮ'
    '\uec2a': '\u1136\u116d\u11b7',                          // 'ᄶᅭᆷ'
    '\uec2b': '\u1136\u116d\u11ba',                          // 'ᄶᅭᆺ'
    '\uec2c': '\u1136\u116d\u11bc',                          // 'ᄶᅭᆼ'
    '\uec2d': '\u1136\u116e',                                // 'ᄶᅮ'
    '\uec2e': '\u1136\u116e\u11a8',                          // 'ᄶᅮᆨ'
    '\uec2f': '\u1136\u116e\u11ab',                          // 'ᄶᅮᆫ'
    '\uec30': '\u1136\u116e\u11af',                          // 'ᄶᅮᆯ'
    '\uec31': '\u1136\u116e\u11b7',                          // 'ᄶᅮᆷ'
    '\uec32': '\u1136\u116e\u11b8',                          // 'ᄶᅮᆸ'
    '\uec33': '\u1136\u116e\u11bc',                          // 'ᄶᅮᆼ'
    '\uec34': '\u1136\u116f',                                // 'ᄶᅯ'
    '\uec35': '\u1136\u1170',                                // 'ᄶᅰ'
    '\uec36': '\u1136\u1171',                                // 'ᄶᅱ'
    '\uec37': '\u1136\u1171\u11a8',                          // 'ᄶᅱᆨ'
    '\uec38': '\u1136\u1172',                                // 'ᄶᅲ'
    '\uec39': '\u1136\u1172\u11a8',                          // 'ᄶᅲᆨ'
    '\uec3a': '\u1136\u1172\u11af',                          // 'ᄶᅲᆯ'
    '\uec3b': '\u1136\u1172\u11b7',                          // 'ᄶᅲᆷ'
    '\uec3c': '\u1136\u1173',                                // 'ᄶᅳ'
    '\uec3d': '\u1136\u1173\u11a8',                          // 'ᄶᅳᆨ'
    '\uec3e': '\u1136\u1173\u11ab',                          // 'ᄶᅳᆫ'
    '\uec3f': '\u1136\u1173\u11af',                          // 'ᄶᅳᆯ'
    '\uec40': '\u1136\u1173\u11b7',                          // 'ᄶᅳᆷ'
    '\uec41': '\u1136\u1173\u11ba',                          // 'ᄶᅳᆺ'
    '\uec42': '\u1136\u1173\u11bc',                          // 'ᄶᅳᆼ'
    '\uec43': '\u1136\u1174',                                // 'ᄶᅴ'
    '\uec44': '\u1136\u1174\u11af',                          // 'ᄶᅴᆯ'
    '\uec45': '\u1136\u1175',                                // 'ᄶᅵ'
    '\uec46': '\u1136\u1175\u11a8',                          // 'ᄶᅵᆨ'
    '\uec47': '\u1136\u1175\u11ab',                          // 'ᄶᅵᆫ'
    '\uec48': '\u1136\u1175\u11af',                          // 'ᄶᅵᆯ'
    '\uec49': '\u1136\u1175\u11d9',                          // 'ᄶᅵᇙ'
    '\uec4a': '\u1136\u1175\u11b7',                          // 'ᄶᅵᆷ'
    '\uec4b': '\u1136\u1175\u11b8',                          // 'ᄶᅵᆸ'
    '\uec4c': '\u1136\u1175\u11ba',                          // 'ᄶᅵᆺ'
    '\uec4d': '\u1136\u1175\ud7f2',                          // 'ᄶᅵퟲ'
    '\uec4e': '\u1136\u1175\u11bc',                          // 'ᄶᅵᆼ'
    '\uec4f': '\u1136\u1175\u11f0',                          // 'ᄶᅵᇰ'
    '\uec50': '\u1136\u1175\u11c2',                          // 'ᄶᅵᇂ'
    '\uec51': '\u1136\u119e',                                // 'ᄶᆞ'
    '\uec52': '\u1136\u119e\u11a8',                          // 'ᄶᆞᆨ'
    '\uec53': '\u1136\u119e\u11ab',                          // 'ᄶᆞᆫ'
    '\uec54': '\u1136\u119e\u11af',                          // 'ᄶᆞᆯ'
    '\uec55': '\u1136\u119e\u11b7',                          // 'ᄶᆞᆷ'
    '\uec56': '\u1136\u119e\u11b8',                          // 'ᄶᆞᆸ'
    '\uec57': '\u1136\u119e\u11ba',                          // 'ᄶᆞᆺ'
    '\uec58': '\u1136\u119e\u11bc',                          // 'ᄶᆞᆼ'
    '\uec59': '\u1136\u11a1',                                // 'ᄶᆡ'
    '\uec5a': '\u1136\u11a1\u11a8',                          // 'ᄶᆡᆨ'
    '\uec5b': '\u1136\u11a1\u11af',                          // 'ᄶᆡᆯ'
    '\uec5c': '\u1136\u11a1\u11bc',                          // 'ᄶᆡᆼ'
    '\uec5d': '\u1136\u11a1\u11f0',                          // 'ᄶᆡᇰ'
    '\uec5e': '\u1137\u1169',                                // 'ᄷᅩ'
    '\uec5f': '\u1137\u1175',                                // 'ᄷᅵ'
    '\uec60': '\u1137\u119e',                                // 'ᄷᆞ'
    '\uec61': '\u1137\u119e\u11af',                          // 'ᄷᆞᆯ'
    '\uec62': '\u1137\u119e\u11b8',                          // 'ᄷᆞᆸ'
    '\uec63': '\u1138\u1161',                                // 'ᄸᅡ'
    '\uec64': '\u1138\u1161\u11bc',                          // 'ᄸᅡᆼ'
    '\uec65': '\u1138\u1165',                                // 'ᄸᅥ'
    '\uec66': '\u1138\u1165\u11ab',                          // 'ᄸᅥᆫ'
    '\uec67': '\u1138\u1165\u11af',                          // 'ᄸᅥᆯ'
    '\uec68': '\u1138\u1167',                                // 'ᄸᅧ'
    '\uec69': '\u1138\u1169',                                // 'ᄸᅩ'
    '\uec6a': '\u1138\u1169\u11ab',                          // 'ᄸᅩᆫ'
    '\uec6b': '\u1138\u1169\u11ba',                          // 'ᄸᅩᆺ'
    '\uec6c': '\u1138\u1169\u11bc',                          // 'ᄸᅩᆼ'
    '\uec6d': '\u1138\u1169\u11f0',                          // 'ᄸᅩᇰ'
    '\uec6e': '\u1138\u116a',                                // 'ᄸᅪ'
    '\uec6f': '\u1138\u116b',                                // 'ᄸᅫ'
    '\uec70': '\u1138\u116c',                                // 'ᄸᅬ'
    '\uec71': '\u1138\u116c\u11bc',                          // 'ᄸᅬᆼ'
    '\uec72': '\u1138\u116c\u11f0',                          // 'ᄸᅬᇰ'
    '\uec73': '\u1138\u116e',                                // 'ᄸᅮ'
    '\uec74': '\u1138\u116f',                                // 'ᄸᅯ'
    '\uec75': '\u1138\u1173',                                // 'ᄸᅳ'
    '\uec76': '\u1138\u1173\u11ab',                          // 'ᄸᅳᆫ'
    '\uec77': '\u1138\u1174',                                // 'ᄸᅴ'
    '\uec78': '\u1138\u1175',                                // 'ᄸᅵ'
    '\uec79': '\u1138\u119e',                                // 'ᄸᆞ'
    '\uec7a': '\u1139\u1161',                                // 'ᄹᅡ'
    '\uec7b': '\u1139\u1161\u11af',                          // 'ᄹᅡᆯ'
    '\uec7c': '\u1139\u1165',                                // 'ᄹᅥ'
    '\uec7d': '\u1139\u1165\u11af',                          // 'ᄹᅥᆯ'
    '\uec7e': '\u1139\u1166',                                // 'ᄹᅦ'
    '\uec7f': '\u1139\u1169',                                // 'ᄹᅩ'
    '\uec80': '\u1139\u1169\u11b8',                          // 'ᄹᅩᆸ'
    '\uec81': '\u1139\u116e',                                // 'ᄹᅮ'
    '\uec82': '\u1139\u116e\u11a8',                          // 'ᄹᅮᆨ'
    '\uec83': '\u1139\u1171',                                // 'ᄹᅱ'
    '\uec84': '\u1139\u1173',                                // 'ᄹᅳ'
    '\uec85': '\u1139\u1173\u11af',                          // 'ᄹᅳᆯ'
    '\uec86': '\u1139\u1173\u11b7',                          // 'ᄹᅳᆷ'
    '\uec87': '\u1139\u1173\u11ba',                          // 'ᄹᅳᆺ'
    '\uec88': '\u1139\u1175',                                // 'ᄹᅵ'
    '\uec89': '\u1139\u1175\u11bf',                          // 'ᄹᅵᆿ'
    '\uec8a': '\u1139\u119e',                                // 'ᄹᆞ'
    '\uec8b': '\u1139\u119e\u11af',                          // 'ᄹᆞᆯ'
    '\uec8c': '\u1139\u119e\u11b7',                          // 'ᄹᆞᆷ'
    '\uec8d': '\u113a\u1167',                                // 'ᄺᅧ'
    '\uec8e': '\u113a\u1167\u11ab',                          // 'ᄺᅧᆫ'
    '\uec8f': '\u113a\u116e',                                // 'ᄺᅮ'
    '\uec90': '\u113a\u116e\u11ab',                          // 'ᄺᅮᆫ'
    '\uec91': '\u113a\u1175',                                // 'ᄺᅵ'
    '\uec92': '\u113a\u119e',                                // 'ᄺᆞ'
    '\uec93': '\u113a\u119e\u11af',                          // 'ᄺᆞᆯ'
    '\uec94': '\u113b\u1165',                                // 'ᄻᅥ'
    '\uec95': '\u113b\u1165\u11af',                          // 'ᄻᅥᆯ'
    '\uec96': '\u113b\u1167',                                // 'ᄻᅧ'
    '\uec97': '\u113b\u1173',                                // 'ᄻᅳ'
    '\uec98': '\u113b\u1173\u11af',                          // 'ᄻᅳᆯ'
    '\uec99': '\u113c\u1161',                                // 'ᄼᅡ'
    '\uec9a': '\u113c\u1161\u11ab',                          // 'ᄼᅡᆫ'
    '\uec9b': '\u113c\u1161\u11f0',                          // 'ᄼᅡᇰ'
    '\uec9c': '\u113c\u1162',                                // 'ᄼᅢ'
    '\uec9d': '\u113c\u1163',                                // 'ᄼᅣ'
    '\uec9e': '\u113c\u1163\u11b7',                          // 'ᄼᅣᆷ'
    '\uec9f': '\u113c\u1163\u11e2',                          // 'ᄼᅣᇢ'
    '\ueca0': '\u113c\u1163\u11f0',                          // 'ᄼᅣᇰ'
    '\ueca1': '\u113c\u1165',                                // 'ᄼᅥ'
    '\ueca2': '\u113c\u1165\u11ab',                          // 'ᄼᅥᆫ'
    '\ueca3': '\u113c\u1167',                                // 'ᄼᅧ'
    '\ueca4': '\u113c\u1167\u11ab',                          // 'ᄼᅧᆫ'
    '\ueca5': '\u113c\u1168',                                // 'ᄼᅨ'
    '\ueca6': '\u113c\u116e',                                // 'ᄼᅮ'
    '\ueca7': '\u113c\u116e\u11ab',                          // 'ᄼᅮᆫ'
    '\ueca8': '\u113c\u116e\u11bc',                          // 'ᄼᅮᆼ'
    '\ueca9': '\u113c\u1171',                                // 'ᄼᅱ'
    '\uecaa': '\u113c\u1172',                                // 'ᄼᅲ'
    '\uecab': '\u113c\u1172\u11ab',                          // 'ᄼᅲᆫ'
    '\uecac': '\u113c\u1191',                                // 'ᄼᆑ'
    '\uecad': '\u113c\u1191\u11ab',                          // 'ᄼᆑᆫ'
    '\uecae': '\u113c\u1173',                                // 'ᄼᅳ'
    '\uecaf': '\u113c\u1173\u11ab',                          // 'ᄼᅳᆫ'
    '\uecb0': '\u113c\u1173\u11eb',                          // 'ᄼᅳᇫ'
    '\uecb1': '\u113c\u1175',                                // 'ᄼᅵ'
    '\uecb2': '\u113c\u1175\u11bc',                          // 'ᄼᅵᆼ'
    '\uecb3': '\u113d\u1163',                                // 'ᄽᅣ'
    '\uecb4': '\u113d\u1163\u11bc',                          // 'ᄽᅣᆼ'
    '\uecb5': '\u113d\u1163\u11f0',                          // 'ᄽᅣᇰ'
    '\uecb6': '\u113d\u1167',                                // 'ᄽᅧ'
    '\uecb7': '\u113d\u1167\u11ab',                          // 'ᄽᅧᆫ'
    '\uecb8': '\u113d\u116e',                                // 'ᄽᅮ'
    '\uecb9': '\u113d\u1171',                                // 'ᄽᅱ'
    '\uecba': '\u113d\u1172',                                // 'ᄽᅲ'
    '\uecbb': '\u113d\u1172\u11ab',                          // 'ᄽᅲᆫ'
    '\uecbc': '\u113d\u1172\u11f0',                          // 'ᄽᅲᇰ'
    '\uecbd': '\u113d\u1191',                                // 'ᄽᆑ'
    '\uecbe': '\u113d\u1191\u11ab',                          // 'ᄽᆑᆫ'
    '\uecbf': '\u113d\u1194',                                // 'ᄽᆔ'
    '\uecc0': '\u113d\u1173',                                // 'ᄽᅳ'
    '\uecc1': '\u113d\u1173\u11eb',                          // 'ᄽᅳᇫ'
    '\uecc2': '\u113d\u1175',                                // 'ᄽᅵ'
    '\uecc3': '\u113d\u1175\u11ab',                          // 'ᄽᅵᆫ'
    '\uecc4': '\u113d\u1175\u11eb',                          // 'ᄽᅵᇫ'
    '\uecc5': '\u113d\u1175\u11f9',                          // 'ᄽᅵᇹ'
    '\uecc6': '\u113e\u1161',                                // 'ᄾᅡ'
    '\uecc7': '\u113e\u1161\u11ab',                          // 'ᄾᅡᆫ'
    '\uecc8': '\u113e\u1161\u11ae',                          // 'ᄾᅡᆮ'
    '\uecc9': '\u113e\u1162',                                // 'ᄾᅢ'
    '\uecca': '\u113e\u1163',                                // 'ᄾᅣ'
    '\ueccb': '\u113e\u1163\u11b7',                          // 'ᄾᅣᆷ'
    '\ueccc': '\u113e\u1163\u11e2',                          // 'ᄾᅣᇢ'
    '\ueccd': '\u113e\u1167',                                // 'ᄾᅧ'
    '\uecce': '\u113e\u1167\u11ab',                          // 'ᄾᅧᆫ'
    '\ueccf': '\u113e\u1169',                                // 'ᄾᅩ'
    '\uecd0': '\u113e\u116a',                                // 'ᄾᅪ'
    '\uecd1': '\u113e\u116b',                                // 'ᄾᅫ'
    '\uecd2': '\u113e\u116e',                                // 'ᄾᅮ'
    '\uecd3': '\u113e\u116e\u11a8',                          // 'ᄾᅮᆨ'
    '\uecd4': '\u113e\u116e\u11bc',                          // 'ᄾᅮᆼ'
    '\uecd5': '\u113e\u1171',                                // 'ᄾᅱ'
    '\uecd6': '\u113e\u1172',                                // 'ᄾᅲ'
    '\uecd7': '\u113e\u1172\u11ab',                          // 'ᄾᅲᆫ'
    '\uecd8': '\u113e\u1191',                                // 'ᄾᆑ'
    '\uecd9': '\u113e\u1191\u11ab',                          // 'ᄾᆑᆫ'
    '\uecda': '\u113e\u1173',                                // 'ᄾᅳ'
    '\uecdb': '\u113e\u1173\u11eb',                          // 'ᄾᅳᇫ'
    '\uecdc': '\u113e\u1173\u11bc',                          // 'ᄾᅳᆼ'
    '\uecdd': '\u113e\u1175',                                // 'ᄾᅵ'
    '\uecde': '\u113e\u1175\u11ab',                          // 'ᄾᅵᆫ'
    '\uecdf': '\u113e\u1175\u11b7',                          // 'ᄾᅵᆷ'
    '\uece0': '\u113e\u1175\u11e2',                          // 'ᄾᅵᇢ'
    '\uece1': '\u113f\u1163',                                // 'ᄿᅣ'
    '\uece2': '\u113f\u1163\u11f0',                          // 'ᄿᅣᇰ'
    '\uece3': '\u113f\u1167',                                // 'ᄿᅧ'
    '\uece4': '\u113f\u1167\u11ab',                          // 'ᄿᅧᆫ'
    '\uece5': '\u113f\u116e',                                // 'ᄿᅮ'
    '\uece6': '\u113f\u1171',                                // 'ᄿᅱ'
    '\uece7': '\u113f\u1172',                                // 'ᄿᅲ'
    '\uece8': '\u113f\u1172\u11a8',                          // 'ᄿᅲᆨ'
    '\uece9': '\u113f\u1172\u11ab',                          // 'ᄿᅲᆫ'
    '\uecea': '\u113f\u1172\u11f0',                          // 'ᄿᅲᇰ'
    '\ueceb': '\u113f\u1173',                                // 'ᄿᅳ'
    '\uecec': '\u113f\u1173\u11eb',                          // 'ᄿᅳᇫ'
    '\ueced': '\u113f\u1175',                                // 'ᄿᅵ'
    '\uecee': '\u113f\u1175\u11ab',                          // 'ᄿᅵᆫ'
    '\uecef': '\u113f\u1175\u11f9',                          // 'ᄿᅵᇹ'
    '\uecf0': '\u1140\u1161',                                // 'ᅀᅡ'
    '\uecf1': '\u1140\u1161\u11a8',                          // 'ᅀᅡᆨ'
    '\uecf2': '\u1140\u1161\u11ab',                          // 'ᅀᅡᆫ'
    '\uecf3': '\u1140\u1161\u11ae',                          // 'ᅀᅡᆮ'
    '\uecf4': '\u1140\u1161\u11af',                          // 'ᅀᅡᆯ'
    '\uecf5': '\u1140\u1161\u11b7',                          // 'ᅀᅡᆷ'
    '\uecf6': '\u1140\u1161\u11dd',                          // 'ᅀᅡᇝ'
    '\uecf7': '\u1140\u1161\u11b8',                          // 'ᅀᅡᆸ'
    '\uecf8': '\u1140\u1161\u11ba',                          // 'ᅀᅡᆺ'
    '\uecf9': '\u1140\u1161\u11bc',                          // 'ᅀᅡᆼ'
    '\uecfa': '\u1140\u1161\u11f0',                          // 'ᅀᅡᇰ'
    '\uecfb': '\u1140\u1162',                                // 'ᅀᅢ'
    '\uecfc': '\u1140\u1162\u11ab',                          // 'ᅀᅢᆫ'
    '\uecfd': '\u1140\u1162\u11ba',                          // 'ᅀᅢᆺ'
    '\uecfe': '\u1140\u1162\u11eb',                          // 'ᅀᅢᇫ'
    '\uecff': '\u1140\u1162\u11bc',                          // 'ᅀᅢᆼ'
    '\ued00': '\u1140\u1162\u11f0',                          // 'ᅀᅢᇰ'
    '\ued01': '\u1140\u1163',                                // 'ᅀᅣ'
    '\ued02': '\u1140\u1163\u11a8',                          // 'ᅀᅣᆨ'
    '\ued03': '\u1140\u1163\u11ab',                          // 'ᅀᅣᆫ'
    '\ued04': '\u1140\u1163\u11b7',                          // 'ᅀᅣᆷ'
    '\ued05': '\u1140\u1163\u11e2',                          // 'ᅀᅣᇢ'
    '\ued06': '\u1140\u1163\u11b8',                          // 'ᅀᅣᆸ'
    '\ued07': '\u1140\u1163\u11e6',                          // 'ᅀᅣᇦ'
    '\ued08': '\u1140\u1163\u11bc',                          // 'ᅀᅣᆼ'
    '\ued09': '\u1140\u1163\u11f0',                          // 'ᅀᅣᇰ'
    '\ued0a': '\u1140\u1163\u11f9',                          // 'ᅀᅣᇹ'
    '\ued0b': '\u1140\u1178',                                // 'ᅀᅸ'
    '\ued0c': '\u1140\u1165',                                // 'ᅀᅥ'
    '\ued0d': '\u1140\u1165\u11a8',                          // 'ᅀᅥᆨ'
    '\ued0e': '\u1140\u1165\u11ab',                          // 'ᅀᅥᆫ'
    '\ued0f': '\u1140\u1165\u11af',                          // 'ᅀᅥᆯ'
    '\ued10': '\u1140\u1165\u11b7',                          // 'ᅀᅥᆷ'
    '\ued11': '\u1140\u1165\u11dd',                          // 'ᅀᅥᇝ'
    '\ued12': '\u1140\u1165\u11b8',                          // 'ᅀᅥᆸ'
    '\ued13': '\u1140\u1165\u11ba',                          // 'ᅀᅥᆺ'
    '\ued14': '\u1140\u1165\u11eb',                          // 'ᅀᅥᇫ'
    '\ued15': '\u1140\u1165\u11bc',                          // 'ᅀᅥᆼ'
    '\ued16': '\u1140\u1165\u11f0',                          // 'ᅀᅥᇰ'
    '\ued17': '\u1140\u1165\u11c2',                          // 'ᅀᅥᇂ'
    '\ued18': '\u1140\u117b',                                // 'ᅀᅻ'
    '\ued19': '\u1140\u1166',                                // 'ᅀᅦ'
    '\ued1a': '\u1140\u1166\u11ab',                          // 'ᅀᅦᆫ'
    '\ued1b': '\u1140\u1166\u11ba',                          // 'ᅀᅦᆺ'
    '\ued1c': '\u1140\u1167',                                // 'ᅀᅧ'
    '\ued1d': '\u1140\u1167\u11a8',                          // 'ᅀᅧᆨ'
    '\ued1e': '\u1140\u1167\u11ab',                          // 'ᅀᅧᆫ'
    '\ued1f': '\u1140\u1167\u11af',                          // 'ᅀᅧᆯ'
    '\ued20': '\u1140\u1167\u11b3',                          // 'ᅀᅧᆳ'
    '\ued21': '\u1140\u1167\u11d9',                          // 'ᅀᅧᇙ'
    '\ued22': '\u1140\u1167\u11b7',                          // 'ᅀᅧᆷ'
    '\ued23': '\u1140\u1167\u11b8',                          // 'ᅀᅧᆸ'
    '\ued24': '\u1140\u1167\u11bc',                          // 'ᅀᅧᆼ'
    '\ued25': '\u1140\u1167\u11f0',                          // 'ᅀᅧᇰ'
    '\ued26': '\u1140\u1167\u11f9',                          // 'ᅀᅧᇹ'
    '\ued27': '\u1140\u1168',                                // 'ᅀᅨ'
    '\ued28': '\u1140\u1168\u11ab',                          // 'ᅀᅨᆫ'
    '\ued29': '\u1140\u1168\u11ae',                          // 'ᅀᅨᆮ'
    '\ued2a': '\u1140\u1168\u11b7',                          // 'ᅀᅨᆷ'
    '\ued2b': '\u1140\u1168\u11ba',                          // 'ᅀᅨᆺ'
    '\ued2c': '\u1140\u1168\u11bc',                          // 'ᅀᅨᆼ'
    '\ued2d': '\u1140\u1169',                                // 'ᅀᅩ'
    '\ued2e': '\u1140\u1169\u11ab',                          // 'ᅀᅩᆫ'
    '\ued2f': '\u1140\u1169\u11af',                          // 'ᅀᅩᆯ'
    '\ued30': '\u1140\u1169\u11d9',                          // 'ᅀᅩᇙ'
    '\ued31': '\u1140\u1169\u11b7',                          // 'ᅀᅩᆷ'
    '\ued32': '\u1140\u1169\u11b8',                          // 'ᅀᅩᆸ'
    '\ued33': '\u1140\u1169\u11eb',                          // 'ᅀᅩᇫ'
    '\ued34': '\u1140\u1169\u11bc',                          // 'ᅀᅩᆼ'
    '\ued35': '\u1140\u1169\u11f0',                          // 'ᅀᅩᇰ'
    '\ued36': '\u1140\u116a',                                // 'ᅀᅪ'
    '\ued37': '\u1140\u116b',                                // 'ᅀᅫ'
    '\ued38': '\u1140\u116c',                                // 'ᅀᅬ'
    '\ued39': '\u1140\u116d',                                // 'ᅀᅭ'
    '\ued3a': '\u1140\u116d\u11a8',                          // 'ᅀᅭᆨ'
    '\ued3b': '\u1140\u116d\u11b7',                          // 'ᅀᅭᆷ'
    '\ued3c': '\u1140\u116d\u11e2',                          // 'ᅀᅭᇢ'
    '\ued3d': '\u1140\u116d\u11ba',                          // 'ᅀᅭᆺ'
    '\ued3e': '\u1140\u116d\u11bc',                          // 'ᅀᅭᆼ'
    '\ued3f': '\u1140\u116d\u11f0',                          // 'ᅀᅭᇰ'
    '\ued40': '\u1140\u116d\u11c2',                          // 'ᅀᅭᇂ'
    '\ued41': '\u1140\u116e',                                // 'ᅀᅮ'
    '\ued42': '\u1140\u116e\u11a8',                          // 'ᅀᅮᆨ'
    '\ued43': '\u1140\u116e\u11ab',                          // 'ᅀᅮᆫ'
    '\ued44': '\u1140\u116e\u11af',                          // 'ᅀᅮᆯ'
    '\ued45': '\u1140\u116e\u11d9',                          // 'ᅀᅮᇙ'
    '\ued46': '\u1140\u116e\u11b7',                          // 'ᅀᅮᆷ'
    '\ued47': '\u1140\u116e\u11dd',                          // 'ᅀᅮᇝ'
    '\ued48': '\u1140\u116e\u11b8',                          // 'ᅀᅮᆸ'
    '\ued49': '\u1140\u116e\u11ba',                          // 'ᅀᅮᆺ'
    '\ued4a': '\u1140\u116e\u11bc',                          // 'ᅀᅮᆼ'
    '\ued4b': '\u1140\u116e\u11f0',                          // 'ᅀᅮᇰ'
    '\ued4c': '\u1140\u116e\u11be',                          // 'ᅀᅮᆾ'
    '\ued4d': '\u1140\u116e\u11c2',                          // 'ᅀᅮᇂ'
    '\ued4e': '\u1140\u1189',                                // 'ᅀᆉ'
    '\ued4f': '\u1140\u1189\u11ab',                          // 'ᅀᆉᆫ'
    '\ued50': '\u1140\u116f',                                // 'ᅀᅯ'
    '\ued51': '\u1140\u1170',                                // 'ᅀᅰ'
    '\ued52': '\u1140\ud7b5',                                // 'ᅀힵ'
    '\ued53': '\u1140\u118c',                                // 'ᅀᆌ'
    '\ued54': '\u1140\u1171',                                // 'ᅀᅱ'
    '\ued55': '\u1140\u1171\u11ab',                          // 'ᅀᅱᆫ'
    '\ued56': '\u1140\u1172',                                // 'ᅀᅲ'
    '\ued57': '\u1140\u1172\u11a8',                          // 'ᅀᅲᆨ'
    '\ued58': '\u1140\u1172\u11ab',                          // 'ᅀᅲᆫ'
    '\ued59': '\u1140\u1172\u11b7',                          // 'ᅀᅲᆷ'
    '\ued5a': '\u1140\u1172\u11e2',                          // 'ᅀᅲᇢ'
    '\ued5b': '\u1140\u1172\u11ba',                          // 'ᅀᅲᆺ'
    '\ued5c': '\u1140\u1172\u11bc',                          // 'ᅀᅲᆼ'
    '\ued5d': '\u1140\u1172\u11f0',                          // 'ᅀᅲᇰ'
    '\ued5e': '\u1140\u1172\u11bd',                          // 'ᅀᅲᆽ'
    '\ued5f': '\u1140\u1172\u11be',                          // 'ᅀᅲᆾ'
    '\ued60': '\u1140\u1172\u11f9',                          // 'ᅀᅲᇹ'
    '\ued61': '\u1140\u118f',                                // 'ᅀᆏ'
    '\ued62': '\u1140\u118f\u11ab',                          // 'ᅀᆏᆫ'
    '\ued63': '\u1140\u1191',                                // 'ᅀᆑ'
    '\ued64': '\u1140\u1191\u11ab',                          // 'ᅀᆑᆫ'
    '\ued65': '\u1140\u1191\u11af',                          // 'ᅀᆑᆯ'
    '\ued66': '\u1140\u1191\u11d9',                          // 'ᅀᆑᇙ'
    '\ued67': '\u1140\u1192',                                // 'ᅀᆒ'
    '\ued68': '\u1140\u1192\u11bc',                          // 'ᅀᆒᆼ'
    '\ued69': '\u1140\u1194',                                // 'ᅀᆔ'
    '\ued6a': '\u1140\u1194\u11bc',                          // 'ᅀᆔᆼ'
    '\ued6b': '\u1140\u1173',                                // 'ᅀᅳ'
    '\ued6c': '\u1140\u1173\u11a8',                          // 'ᅀᅳᆨ'
    '\ued6d': '\u1140\u1173\u11ab',                          // 'ᅀᅳᆫ'
    '\ued6e': '\u1140\u1173\u11af',                          // 'ᅀᅳᆯ'
    '\ued6f': '\u1140\u1173\u11b1',                          // 'ᅀᅳᆱ'
    '\ued70': '\u1140\u1173\u11b3',                          // 'ᅀᅳᆳ'
    '\ued71': '\u1140\u1173\u11b6',                          // 'ᅀᅳᆶ'
    '\ued72': '\u1140\u1173\u11d9',                          // 'ᅀᅳᇙ'
    '\ued73': '\u1140\u1173\u11b7',                          // 'ᅀᅳᆷ'
    '\ued74': '\u1140\u1173\u11dd',                          // 'ᅀᅳᇝ'
    '\ued75': '\u1140\u1173\u11e0',                          // 'ᅀᅳᇠ'
    '\ued76': '\u1140\u1173\u11b8',                          // 'ᅀᅳᆸ'
    '\ued77': '\u1140\u1173\u11ba',                          // 'ᅀᅳᆺ'
    '\ued78': '\u1140\u1173\ud7f0',                          // 'ᅀᅳퟰ'
    '\ued79': '\u1140\u1173\u11eb',                          // 'ᅀᅳᇫ'
    '\ued7a': '\u1140\u1173\u11f0',                          // 'ᅀᅳᇰ'
    '\ued7b': '\u1140\u1173\u11be',                          // 'ᅀᅳᆾ'
    '\ued7c': '\u1140\u1174',                                // 'ᅀᅴ'
    '\ued7d': '\u1140\u1174\u11ab',                          // 'ᅀᅴᆫ'
    '\ued7e': '\u1140\u1174\u11ba',                          // 'ᅀᅴᆺ'
    '\ued7f': '\u1140\u1175',                                // 'ᅀᅵ'
    '\ued80': '\u1140\u1175\u11a8',                          // 'ᅀᅵᆨ'
    '\ued81': '\u1140\u1175\u11ab',                          // 'ᅀᅵᆫ'
    '\ued82': '\u1140\u1175\u11ae',                          // 'ᅀᅵᆮ'
    '\ued83': '\u1140\u1175\u11af',                          // 'ᅀᅵᆯ'
    '\ued84': '\u1140\u1175\u11b3',                          // 'ᅀᅵᆳ'
    '\ued85': '\u1140\u1175\u11d9',                          // 'ᅀᅵᇙ'
    '\ued86': '\u1140\u1175\u11b7',                          // 'ᅀᅵᆷ'
    '\ued87': '\u1140\u1175\u11b8',                          // 'ᅀᅵᆸ'
    '\ued88': '\u1140\u1175\u11e6',                          // 'ᅀᅵᇦ'
    '\ued89': '\u1140\u1175\u11ba',                          // 'ᅀᅵᆺ'
    '\ued8a': '\u1140\u1175\u11eb',                          // 'ᅀᅵᇫ'
    '\ued8b': '\u1140\u1175\u11bc',                          // 'ᅀᅵᆼ'
    '\ued8c': '\u1140\u1175\u11f0',                          // 'ᅀᅵᇰ'
    '\ued8d': '\u1140\u1175\u11f9',                          // 'ᅀᅵᇹ'
    '\ued8e': '\u1140\u1198',                                // 'ᅀᆘ'
    '\ued8f': '\u1140\u119e',                                // 'ᅀᆞ'
    '\ued90': '\u1140\u119e\u11a8',                          // 'ᅀᆞᆨ'
    '\ued91': '\u1140\u119e\u11ab',                          // 'ᅀᆞᆫ'
    '\ued92': '\u1140\u119e\u11ae',                          // 'ᅀᆞᆮ'
    '\ued93': '\u1140\u119e\u11af',                          // 'ᅀᆞᆯ'
    '\ued94': '\u1140\u119e\u11b0',                          // 'ᅀᆞᆰ'
    '\ued95': '\u1140\u119e\u11b3',                          // 'ᅀᆞᆳ'
    '\ued96': '\u1140\u119e\u11b6',                          // 'ᅀᆞᆶ'
    '\ued97': '\u1140\u119e\u11d9',                          // 'ᅀᆞᇙ'
    '\ued98': '\u1140\u119e\u11b7',                          // 'ᅀᆞᆷ'
    '\ued99': '\u1140\u119e\u11dd',                          // 'ᅀᆞᇝ'
    '\ued9a': '\u1140\u119e\u11b8',                          // 'ᅀᆞᆸ'
    '\ued9b': '\u1140\u119e\u11e6',                          // 'ᅀᆞᇦ'
    '\ued9c': '\u1140\u119e\u11ba',                          // 'ᅀᆞᆺ'
    '\ued9d': '\u1140\u119e\u11eb',                          // 'ᅀᆞᇫ'
    '\ued9e': '\u1140\u119e\u11bc',                          // 'ᅀᆞᆼ'
    '\ued9f': '\u1140\u119e\u11bd',                          // 'ᅀᆞᆽ'
    '\ueda0': '\u1140\u11a1',                                // 'ᅀᆡ'
    '\ueda1': '\u1140\u11a1\u11ab',                          // 'ᅀᆡᆫ'
    '\ueda2': '\u1140\u11a1\u11af',                          // 'ᅀᆡᆯ'
    '\ueda3': '\u1140\u11a1\u11b8',                          // 'ᅀᆡᆸ'
    '\ueda4': '\u1140\u11a1\u11ba',                          // 'ᅀᆡᆺ'
    '\ueda5': '\u110b\u1161\u11c7',                          // '아ᇇ'
    '\ueda6': '\u110b\u1161\u11c9',                          // '아ᇉ'
    '\ueda7': '\u110b\u1161\u11cd',                          // '아ᇍ'
    '\ueda8': '\u110b\u1161\u11d0',                          // '아ᇐ'
    '\ueda9': '\u110b\u1161\u11d3',                          // '아ᇓ'
    '\uedaa': '\u110b\u1161\ud7da',                          // '아ퟚ'
    '\uedab': '\u110b\u1161\u11d4',                          // '아ᇔ'
    '\uedac': '\u110b\u1161\u11d6',                          // '아ᇖ'
    '\uedad': '\u110b\u1161\u11d9',                          // '아ᇙ'
    '\uedae': '\u110b\u1161\u11dd',                          // '아ᇝ'
    '\uedaf': '\u110b\u1161\u11e1',                          // '아ᇡ'
    '\uedb0': '\u110b\u1161\u11e2',                          // '아ᇢ'
    '\uedb1': '\u110b\u1161\u11e3',                          // '아ᇣ'
    '\uedb2': '\u110b\u1161\ud7e4',                          // '아ퟤ'
    '\uedb3': '\u110b\u1161\u11e4',                          // '아ᇤ'
    '\uedb4': '\u110b\u1161\u11e5',                          // '아ᇥ'
    '\uedb5': '\u110b\u1161\u11e6',                          // '아ᇦ'
    '\uedb6': '\u110b\u1161\u11ea',                          // '아ᇪ'
    '\uedb7': '\u110b\u1161\u11eb',                          // '아ᇫ'
    '\uedb8': '\u110b\u1161\u11f0',                          // '아ᇰ'
    '\uedb9': '\u110b\u1161\u11f5',                          // '아ᇵ'
    '\uedba': '\u110b\u1176',                                // 'ᄋᅶ'
    '\uedbb': '\u110b\u1176\u11af',                          // 'ᄋᅶᆯ'
    '\uedbc': '\u110b\u1162\u11eb',                          // '애ᇫ'
    '\uedbd': '\u110b\u1162\u11f0',                          // '애ᇰ'
    '\uedbe': '\u110b\u1163\u11d9',                          // '야ᇙ'
    '\uedbf': '\u110b\u1163\u11dc',                          // '야ᇜ'
    '\uedc0': '\u110b\u1163\u11dd',                          // '야ᇝ'
    '\uedc1': '\u110b\u1163\u11e2',                          // '야ᇢ'
    '\uedc2': '\u110b\u1163\u11e6',                          // '야ᇦ'
    '\uedc3': '\u110b\u1163\u11ea',                          // '야ᇪ'
    '\uedc4': '\u110b\u1163\u11f1',                          // '야ᇱ'
    '\uedc5': '\u110b\u1163\u11f0',                          // '야ᇰ'
    '\uedc6': '\u110b\u1163\u11f5',                          // '야ᇵ'
    '\uedc7': '\u110b\u1163\u11f9',                          // '야ᇹ'
    '\uedc8': '\u110b\u1178',                                // 'ᄋᅸ'
    '\uedc9': '\u110b\u11a4',                                // 'ᄋᆤ'
    '\uedca': '\u110b\u1165\u11c3',                          // '어ᇃ'
    '\uedcb': '\u110b\u1165\u11c6',                          // '어ᇆ'
    '\uedcc': '\u110b\u1165\u11c7',                          // '어ᇇ'
    '\uedcd': '\u110b\u1165\u11cd',                          // '어ᇍ'
    '\uedce': '\u110b\u1165\u11d0',                          // '어ᇐ'
    '\uedcf': '\u110b\u1165\u11d9',                          // '어ᇙ'
    '\uedd0': '\u110b\u1165\u11dd',                          // '어ᇝ'
    '\uedd1': '\u110b\u1165\u11de',                          // '어ᇞ'
    '\uedd2': '\u110b\u1165\u11e4',                          // '어ᇤ'
    '\uedd3': '\u110b\u1165\u11e6',                          // '어ᇦ'
    '\uedd4': '\u110b\u1165\ud7ef',                          // '어ퟯ'
    '\uedd5': '\u110b\u1165\u11eb',                          // '어ᇫ'
    '\uedd6': '\u110b\u1165\u11f0',                          // '어ᇰ'
    '\uedd7': '\u110b\u1165\ud7fa',                          // '어ퟺ'
    '\uedd8': '\u110b\u117a',                                // 'ᄋᅺ'
    '\uedd9': '\u110b\u117c',                                // 'ᄋᅼ'
    '\uedda': '\u110b\u117c\u11ab',                          // 'ᄋᅼᆫ'
    '\ueddb': '\u110b\u117c\u11af',                          // 'ᄋᅼᆯ'
    '\ueddc': '\u110b\u117c\u11b7',                          // 'ᄋᅼᆷ'
    '\ueddd': '\u110b\u117c\u11b8',                          // 'ᄋᅼᆸ'
    '\uedde': '\u110b\u117c\u11b9',                          // 'ᄋᅼᆹ'
    '\ueddf': '\u110b\u117c\u11ba',                          // 'ᄋᅼᆺ'
    '\uede0': '\u110b\u117c\u11bc',                          // 'ᄋᅼᆼ'
    '\uede1': '\u110b\u1166\u11c6',                          // '에ᇆ'
    '\uede2': '\u110b\u1166\u11eb',                          // '에ᇫ'
    '\uede3': '\u110b\u1166\u11f0',                          // '에ᇰ'
    '\uede4': '\u110b\u1167\u11c4',                          // '여ᇄ'
    '\uede5': '\u110b\u1167\u11fd',                          // '여ᇽ'
    '\uede6': '\u110b\u1167\u11c6',                          // '여ᇆ'
    '\uede7': '\u110b\u1167\u11c7',                          // '여ᇇ'
    '\uede8': '\u110b\u1167\u11c9',                          // '여ᇉ'
    '\uede9': '\u110b\u1167\ud7d4',                          // '여ퟔ'
    '\uedea': '\u110b\u1167\u11d0',                          // '여ᇐ'
    '\uedeb': '\u110b\u1167\u11d5',                          // '여ᇕ'
    '\uedec': '\u110b\u1167\u11d9',                          // '여ᇙ'
    '\ueded': '\u110b\u1167\u11e2',                          // '여ᇢ'
    '\uedee': '\u110b\u1167\u11e3',                          // '여ᇣ'
    '\uedef': '\u110b\u1167\ud7e6',                          // '여ퟦ'
    '\uedf0': '\u110b\u1167\u11e6',                          // '여ᇦ'
    '\uedf1': '\u110b\u1167\u11e7',                          // '여ᇧ'
    '\uedf2': '\u110b\u1167\u11ea',                          // '여ᇪ'
    '\uedf3': '\u110b\u1167\ud7f1',                          // '여ퟱ'
    '\uedf4': '\u110b\u1167\ud7f2',                          // '여ퟲ'
    '\uedf5': '\u110b\u1167\u11eb',                          // '여ᇫ'
    '\uedf6': '\u110b\u1167\u11ec',                          // '여ᇬ'
    '\uedf7': '\u110b\u1167\u11ed',                          // '여ᇭ'
    '\uedf8': '\u110b\u1167\u11f0',                          // '여ᇰ'
    '\uedf9': '\u110b\u1167\u11f9',                          // '여ᇹ'
    '\uedfa': '\u110b\u117d',                                // 'ᄋᅽ'
    '\uedfb': '\u110b\u117e',                                // 'ᄋᅾ'
    '\uedfc': '\u110b\u1169\u11c7',                          // '오ᇇ'
    '\uedfd': '\u110b\u1169\ud7d5',                          // '오ퟕ'
    '\uedfe': '\u110b\u1169\ud7d6',                          // '오ퟖ'
    '\uedff': '\u110b\u1169\u11cd',                          // '오ᇍ'
    '\uee00': '\u110b\u1169\u11d0',                          // '오ᇐ'
    '\uee01': '\u110b\u1169\u11d6',                          // '오ᇖ'
    '\uee02': '\u110b\u1169\u11d9',                          // '오ᇙ'
    '\uee03': '\u110b\u1169\u11dd',                          // '오ᇝ'
    '\uee04': '\u110b\u1169\u11e2',                          // '오ᇢ'
    '\uee05': '\u110b\u1169\ud7f0',                          // '오ퟰ'
    '\uee06': '\u110b\u1169\u11eb',                          // '오ᇫ'
    '\uee07': '\u110b\u1169\u11f0',                          // '오ᇰ'
    '\uee08': '\u110b\u1169\u11f1',                          // '오ᇱ'
    '\uee09': '\u110b\u116a\ud7cf',                          // '와ퟏ'
    '\uee0a': '\u110b\u116a\ud7d4',                          // '와ퟔ'
    '\uee0b': '\u110b\u116a\u11dd',                          // '와ᇝ'
    '\uee0c': '\u110b\u116a\u11ea',                          // '와ᇪ'
    '\uee0d': '\u110b\u116a\u11f0',                          // '와ᇰ'
    '\uee0e': '\u110b\u116a\u11f9',                          // '와ᇹ'
    '\uee0f': '\u110b\u117f',                                // 'ᄋᅿ'
    '\uee10': '\u110b\u117f\u11ab',                          // 'ᄋᅿᆫ'
    '\uee11': '\u110b\u117f\u11af',                          // 'ᄋᅿᆯ'
    '\uee12': '\u110b\u1182',                                // 'ᄋᆂ'
    '\uee13': '\u110b\u1182\u11af',                          // 'ᄋᆂᆯ'
    '\uee14': '\u110b\u116c\u11d0',                          // '외ᇐ'
    '\uee15': '\u110b\u116c\u11d9',                          // '외ᇙ'
    '\uee16': '\u110b\u116c\u11f0',                          // '외ᇰ'
    '\uee17': '\u110b\u116d\u11d9',                          // '요ᇙ'
    '\uee18': '\u110b\u116d\u11e2',                          // '요ᇢ'
    '\uee19': '\u110b\u116d\u11f0',                          // '요ᇰ'
    '\uee1a': '\u110b\ud7b2',                                // 'ᄋힲ'
    '\uee1b': '\u110b\ud7b3',                                // 'ᄋힳ'
    '\uee1c': '\u110b\ud7b3\u11ab',                          // 'ᄋힳᆫ'
    '\uee1d': '\u110b\ud7b4',                                // 'ᄋힴ'
    '\uee1e': '\u110b\ud7b4\u11ab',                          // 'ᄋힴᆫ'
    '\uee1f': '\u110b\u1187',                                // 'ᄋᆇ'
    '\uee20': '\u110b\u1188',                                // 'ᄋᆈ'
    '\uee21': '\u110b\u1188\u11af',                          // 'ᄋᆈᆯ'
    '\uee22': '\u110b\u116e\u11c9',                          // '우ᇉ'
    '\uee23': '\u110b\u116e\ud7d3',                          // '우ퟓ'
    '\uee24': '\u110b\u116e\u11cd',                          // '우ᇍ'
    '\uee25': '\u110b\u116e\u11d0',                          // '우ᇐ'
    '\uee26': '\u110b\u116e\u11d9',                          // '우ᇙ'
    '\uee27': '\u110b\u116e\u11dd',                          // '우ᇝ'
    '\uee28': '\u110b\u116e\u11e0',                          // '우ᇠ'
    '\uee29': '\u110b\u116e\u11e1',                          // '우ᇡ'
    '\uee2a': '\u110b\u116e\u11e2',                          // '우ᇢ'
    '\uee2b': '\u110b\u116e\u11e4',                          // '우ᇤ'
    '\uee2c': '\u110b\u116e\u11ea',                          // '우ᇪ'
    '\uee2d': '\u110b\u116e\ud7eb',                          // '우ퟫ'
    '\uee2e': '\u110b\u116e\ud7f0',                          // '우ퟰ'
    '\uee2f': '\u110b\u116e\u11eb',                          // '우ᇫ'
    '\uee30': '\u110b\u116e\ud7f3',                          // '우ퟳ'
    '\uee31': '\u110b\u116e\ud7f4',                          // '우ퟴ'
    '\uee32': '\u110b\u116e\u11f0',                          // '우ᇰ'
    '\uee33': '\u110b\u116e\u11f9',                          // '우ᇹ'
    '\uee34': '\u110b\u1189',                                // 'ᄋᆉ'
    '\uee35': '\u110b\u1189\u11ab',                          // 'ᄋᆉᆫ'
    '\uee36': '\u110b\u1189\u11bc',                          // 'ᄋᆉᆼ'
    '\uee37': '\u110b\u1189\u11f0',                          // 'ᄋᆉᇰ'
    '\uee38': '\u110b\u118a',                                // 'ᄋᆊ'
    '\uee39': '\u110b\u116f\u11d8',                          // '워ᇘ'
    '\uee3a': '\u110b\u116f\u11d9',                          // '워ᇙ'
    '\uee3b': '\u110b\u116f\u11eb',                          // '워ᇫ'
    '\uee3c': '\u110b\u116f\u11f0',                          // '워ᇰ'
    '\uee3d': '\u110b\u116f\u11f1',                          // '워ᇱ'
    '\uee3e': '\u110b\u118b',                                // 'ᄋᆋ'
    '\uee3f': '\u110b\u118b\u11ab',                          // 'ᄋᆋᆫ'
    '\uee40': '\u110b\ud7b5',                                // 'ᄋힵ'
    '\uee41': '\u110b\u118c',                                // 'ᄋᆌ'
    '\uee42': '\u110b\u1171\u11d9',                          // '위ᇙ'
    '\uee43': '\u110b\u1171\u11f0',                          // '위ᇰ'
    '\uee44': '\u110b\u1172\u11d9',                          // '유ᇙ'
    '\uee45': '\u110b\u1172\u11e2',                          // '유ᇢ'
    '\uee46': '\u110b\u1172\u11f0',                          // '유ᇰ'
    '\uee47': '\u110b\u1172\u11f9',                          // '유ᇹ'
    '\uee48': '\u110b\u118e',                                // 'ᄋᆎ'
    '\uee49': '\u110b\u118e\u11ab',                          // 'ᄋᆎᆫ'
    '\uee4a': '\u110b\u118e\u11b7',                          // 'ᄋᆎᆷ'
    '\uee4b': '\u110b\u118f',                                // 'ᄋᆏ'
    '\uee4c': '\u110b\u1190',                                // 'ᄋᆐ'
    '\uee4d': '\u110b\u1190\u11ab',                          // 'ᄋᆐᆫ'
    '\uee4e': '\u110b\u1191',                                // 'ᄋᆑ'
    '\uee4f': '\u110b\u1191\u11a8',                          // 'ᄋᆑᆨ'
    '\uee50': '\u110b\u1191\u11ab',                          // 'ᄋᆑᆫ'
    '\uee51': '\u110b\u1191\u11af',                          // 'ᄋᆑᆯ'
    '\uee52': '\u110b\u1191\u11d9',                          // 'ᄋᆑᇙ'
    '\uee53': '\u110b\u1191\u11f0',                          // 'ᄋᆑᇰ'
    '\uee54': '\u110b\u1191\u11f9',                          // 'ᄋᆑᇹ'
    '\uee55': '\u110b\u1192',                                // 'ᄋᆒ'
    '\uee56': '\u110b\u1192\u11bc',                          // 'ᄋᆒᆼ'
    '\uee57': '\u110b\u1192\u11f0',                          // 'ᄋᆒᇰ'
    '\uee58': '\u110b\u1193',                                // 'ᄋᆓ'
    '\uee59': '\u110b\u1194',                                // 'ᄋᆔ'
    '\uee5a': '\u110b\u1194\u11ab',                          // 'ᄋᆔᆫ'
    '\uee5b': '\u110b\u1194\u11bc',                          // 'ᄋᆔᆼ'
    '\uee5c': '\u110b\u1194\u11f0',                          // 'ᄋᆔᇰ'
    '\uee5d': '\u110b\u1173\u11ff',                          // '으ᇿ'
    '\uee5e': '\u110b\u1173\u11c7',                          // '으ᇇ'
    '\uee5f': '\u110b\u1173\ud7cd',                          // '으ퟍ'
    '\uee60': '\u110b\u1173\u11d0',                          // '으ᇐ'
    '\uee61': '\u110b\u1173\ud7da',                          // '으ퟚ'
    '\uee62': '\u110b\u1173\u11d4',                          // '으ᇔ'
    '\uee63': '\u110b\u1173\u11d9',                          // '으ᇙ'
    '\uee64': '\u110b\u1173\u11dd',                          // '으ᇝ'
    '\uee65': '\u110b\u1173\u11e2',                          // '으ᇢ'
    '\uee66': '\u110b\u1173\u11e3',                          // '으ᇣ'
    '\uee67': '\u110b\u1173\ud7e6',                          // '으ퟦ'
    '\uee68': '\u110b\u1173\u11e4',                          // '으ᇤ'
    '\uee69': '\u110b\u1173\ud7f0',                          // '으ퟰ'
    '\uee6a': '\u110b\u1173\u11eb',                          // '으ᇫ'
    '\uee6b': '\u110b\u1173\u11f0',                          // '으ᇰ'
    '\uee6c': '\u110b\u1173\ud7f9',                          // '으ퟹ'
    '\uee6d': '\u110b\u1173\u11f9',                          // '으ᇹ'
    '\uee6e': '\u110b\u1195',                                // 'ᄋᆕ'
    '\uee6f': '\u110b\u1196',                                // 'ᄋᆖ'
    '\uee70': '\u110b\u1196\u11ab',                          // 'ᄋᆖᆫ'
    '\uee71': '\u110b\u1196\u11bc',                          // 'ᄋᆖᆼ'
    '\uee72': '\u110b\u1196\u11f0',                          // 'ᄋᆖᇰ'
    '\uee73': '\u110b\u1196\u11f9',                          // 'ᄋᆖᇹ'
    '\uee74': '\u110b\u1174\u11d9',                          // '의ᇙ'
    '\uee75': '\u110b\u1174\u11f9',                          // '의ᇹ'
    '\uee76': '\u110b\u1175\u11c7',                          // '이ᇇ'
    '\uee77': '\u110b\u1175\u11cd',                          // '이ᇍ'
    '\uee78': '\u110b\u1175\u11d0',                          // '이ᇐ'
    '\uee79': '\u110b\u1175\u11d9',                          // '이ᇙ'
    '\uee7a': '\u110b\u1175\u11e2',                          // '이ᇢ'
    '\uee7b': '\u110b\u1175\u11e5',                          // '이ᇥ'
    '\uee7c': '\u110b\u1175\u11e6',                          // '이ᇦ'
    '\uee7d': '\u110b\u1175\u11e7',                          // '이ᇧ'
    '\uee7e': '\u110b\u1175\u11e9',                          // '이ᇩ'
    '\uee7f': '\u110b\u1175\u11ea',                          // '이ᇪ'
    '\uee80': '\u110b\u1175\u11f0',                          // '이ᇰ'
    '\uee81': '\u110b\u1175\u11f9',                          // '이ᇹ'
    '\uee82': '\u110b\u1198',                                // 'ᄋᆘ'
    '\uee83': '\u110b\u119a',                                // 'ᄋᆚ'
    '\uee84': '\u110b\ud7c1',                                // 'ᄋퟁ'
    '\uee85': '\u110b\u119b',                                // 'ᄋᆛ'
    '\uee86': '\u110b\u119c',                                // 'ᄋᆜ'
    '\uee87': '\u110b\u119c\u11ab',                          // 'ᄋᆜᆫ'
    '\uee88': '\u110b\u119e',                                // 'ᄋᆞ'
    '\uee89': '\u110b\u119e\u11a8',                          // 'ᄋᆞᆨ'
    '\uee8a': '\u110b\u119e\u11ab',                          // 'ᄋᆞᆫ'
    '\uee8b': '\u110b\u119e\u11ae',                          // 'ᄋᆞᆮ'
    '\uee8c': '\u110b\u119e\u11af',                          // 'ᄋᆞᆯ'
    '\uee8d': '\u110b\u119e\u11b0',                          // 'ᄋᆞᆰ'
    '\uee8e': '\u110b\u119e\u11b1',                          // 'ᄋᆞᆱ'
    '\uee8f': '\u110b\u119e\u11b2',                          // 'ᄋᆞᆲ'
    '\uee90': '\u110b\u119e\u11b3',                          // 'ᄋᆞᆳ'
    '\uee91': '\u110b\u119e\u11b6',                          // 'ᄋᆞᆶ'
    '\uee92': '\u110b\u119e\u11d9',                          // 'ᄋᆞᇙ'
    '\uee93': '\u110b\u119e\u11b7',                          // 'ᄋᆞᆷ'
    '\uee94': '\u110b\u119e\u11dd',                          // 'ᄋᆞᇝ'
    '\uee95': '\u110b\u119e\u11b8',                          // 'ᄋᆞᆸ'
    '\uee96': '\u110b\u119e\u11b9',                          // 'ᄋᆞᆹ'
    '\uee97': '\u110b\u119e\u11ba',                          // 'ᄋᆞᆺ'
    '\uee98': '\u110b\u119e\u11eb',                          // 'ᄋᆞᇫ'
    '\uee99': '\u110b\u119e\u11bc',                          // 'ᄋᆞᆼ'
    '\uee9a': '\u110b\u119e\u11f0',                          // 'ᄋᆞᇰ'
    '\uee9b': '\u110b\u119e\u11bd',                          // 'ᄋᆞᆽ'
    '\uee9c': '\u110b\u119e\u11be',                          // 'ᄋᆞᆾ'
    '\uee9d': '\u110b\u119e\u11c0',                          // 'ᄋᆞᇀ'
    '\uee9e': '\u110b\u119f',                                // 'ᄋᆟ'
    '\uee9f': '\u110b\u119f\u11ab',                          // 'ᄋᆟᆫ'
    '\ueea0': '\u110b\u119f\u11af',                          // 'ᄋᆟᆯ'
    '\ueea1': '\u110b\u119f\u11b2',                          // 'ᄋᆟᆲ'
    '\ueea2': '\u110b\u119f\u11b7',                          // 'ᄋᆟᆷ'
    '\ueea3': '\u110b\u119f\u11bc',                          // 'ᄋᆟᆼ'
    '\ueea4': '\u110b\u11a1',                                // 'ᄋᆡ'
    '\ueea5': '\u110b\u11a1\u11a8',                          // 'ᄋᆡᆨ'
    '\ueea6': '\u110b\u11a1\u11ab',                          // 'ᄋᆡᆫ'
    '\ueea7': '\u110b\u11a1\u11af',                          // 'ᄋᆡᆯ'
    '\ueea8': '\u110b\u11a1\u11b7',                          // 'ᄋᆡᆷ'
    '\ueea9': '\u110b\u11a1\u11b8',                          // 'ᄋᆡᆸ'
    '\ueeaa': '\u110b\u11a1\u11ba',                          // 'ᄋᆡᆺ'
    '\ueeab': '\u110b\u11a1\u11eb',                          // 'ᄋᆡᇫ'
    '\ueeac': '\u110b\u11a1\u11bc',                          // 'ᄋᆡᆼ'
    '\ueead': '\u110b\u11a1\u11f0',                          // 'ᄋᆡᇰ'
    '\ueeae': '\u110b\u11a2',                                // 'ᄋᆢ'
    '\ueeaf': '\u110b\u11a2\u11af',                          // 'ᄋᆢᆯ'
    '\ueeb0': '\u1141\u1161',                                // 'ᅁᅡ'
    '\ueeb1': '\u1141\u1161\u11a8',                          // 'ᅁᅡᆨ'
    '\ueeb2': '\u1141\u1162',                                // 'ᅁᅢ'
    '\ueeb3': '\u1141\u1163',                                // 'ᅁᅣ'
    '\ueeb4': '\u1141\u1163\u11ab',                          // 'ᅁᅣᆫ'
    '\ueeb5': '\u1141\u1165',                                // 'ᅁᅥ'
    '\ueeb6': '\u1141\u1166',                                // 'ᅁᅦ'
    '\ueeb7': '\u1141\u1167',                                // 'ᅁᅧ'
    '\ueeb8': '\u1141\u1168',                                // 'ᅁᅨ'
    '\ueeb9': '\u1141\u1168\u11ab',                          // 'ᅁᅨᆫ'
    '\ueeba': '\u1141\u1169',                                // 'ᅁᅩ'
    '\ueebb': '\u1141\u1169\u11a8',                          // 'ᅁᅩᆨ'
    '\ueebc': '\u1141\u1169\u11ab',                          // 'ᅁᅩᆫ'
    '\ueebd': '\u1141\u116a',                                // 'ᅁᅪ'
    '\ueebe': '\u1141\u116a\u11ab',                          // 'ᅁᅪᆫ'
    '\ueebf': '\u1141\u116b',                                // 'ᅁᅫ'
    '\ueec0': '\u1141\u116c',                                // 'ᅁᅬ'
    '\ueec1': '\u1141\u116d',                                // 'ᅁᅭ'
    '\ueec2': '\u1141\u116e',                                // 'ᅁᅮ'
    '\ueec3': '\u1141\u116e\u11ab',                          // 'ᅁᅮᆫ'
    '\ueec4': '\u1141\u116f',                                // 'ᅁᅯ'
    '\ueec5': '\u1141\u1170',                                // 'ᅁᅰ'
    '\ueec6': '\u1141\ud7b5',                                // 'ᅁힵ'
    '\ueec7': '\u1141\u118c',                                // 'ᅁᆌ'
    '\ueec8': '\u1141\u1171',                                // 'ᅁᅱ'
    '\ueec9': '\u1141\u1172',                                // 'ᅁᅲ'
    '\ueeca': '\u1141\u1194',                                // 'ᅁᆔ'
    '\ueecb': '\u1141\u1173',                                // 'ᅁᅳ'
    '\ueecc': '\u1141\u1174',                                // 'ᅁᅴ'
    '\ueecd': '\u1141\u1175',                                // 'ᅁᅵ'
    '\ueece': '\u1141\u1175\u11ab',                          // 'ᅁᅵᆫ'
    '\ueecf': '\u1141\u1198',                                // 'ᅁᆘ'
    '\ueed0': '\u1141\u119e',                                // 'ᅁᆞ'
    '\ueed1': '\u1141\u11a1',                                // 'ᅁᆡ'
    '\ueed2': '\u1142\u1161',                                // 'ᅂᅡ'
    '\ueed3': '\u1142\u1161\u11ab',                          // 'ᅂᅡᆫ'
    '\ueed4': '\u1142\u1165',                                // 'ᅂᅥ'
    '\ueed5': '\u1142\u1165\u11af',                          // 'ᅂᅥᆯ'
    '\ueed6': '\u1142\u1166',                                // 'ᅂᅦ'
    '\ueed7': '\u1142\u1166\u11ab',                          // 'ᅂᅦᆫ'
    '\ueed8': '\u1142\u1169',                                // 'ᅂᅩ'
    '\ueed9': '\u1142\u1169\u11ab',                          // 'ᅂᅩᆫ'
    '\ueeda': '\u1142\u1169\u11af',                          // 'ᅂᅩᆯ'
    '\ueedb': '\u1142\u1173',                                // 'ᅂᅳ'
    '\ueedc': '\u1142\u1173\u11b7',                          // 'ᅂᅳᆷ'
    '\ueedd': '\u1142\u1174',                                // 'ᅂᅴ'
    '\ueede': '\u1142\u1175',                                // 'ᅂᅵ'
    '\ueedf': '\u1142\u1175\u11bc',                          // 'ᅂᅵᆼ'
    '\ueee0': '\u1142\u119e',                                // 'ᅂᆞ'
    '\ueee1': '\u1142\u119e\u11b7',                          // 'ᅂᆞᆷ'
    '\ueee2': '\ua976\u1165',                                // 'ꥶᅥ'
    '\ueee3': '\ua976\u1169',                                // 'ꥶᅩ'
    '\ueee4': '\ua976\u1175',                                // 'ꥶᅵ'
    '\ueee5': '\u1143\u116e',                                // 'ᅃᅮ'
    '\ueee6': '\u1143\u119e',                                // 'ᅃᆞ'
    '\ueee7': '\u1143\u11a1',                                // 'ᅃᆡ'
    '\ueee8': '\u1144\u1161',                                // 'ᅄᅡ'
    '\ueee9': '\u1144\u1161\u11ab',                          // 'ᅄᅡᆫ'
    '\ueeea': '\u1144\u1161\u11af',                          // 'ᅄᅡᆯ'
    '\ueeeb': '\u1144\u1161\u11b7',                          // 'ᅄᅡᆷ'
    '\ueeec': '\u1144\u1162',                                // 'ᅄᅢ'
    '\ueeed': '\u1144\u1162\u11af',                          // 'ᅄᅢᆯ'
    '\ueeee': '\u1144\u1162\u11b7',                          // 'ᅄᅢᆷ'
    '\ueeef': '\u1144\u1165',                                // 'ᅄᅥ'
    '\ueef0': '\u1144\u1165\u11af',                          // 'ᅄᅥᆯ'
    '\ueef1': '\u1144\u1165\u11c0',                          // 'ᅄᅥᇀ'
    '\ueef2': '\u1144\u1166',                                // 'ᅄᅦ'
    '\ueef3': '\u1144\u1166\u11ab',                          // 'ᅄᅦᆫ'
    '\ueef4': '\u1144\u1166\u11af',                          // 'ᅄᅦᆯ'
    '\ueef5': '\u1144\u1166\u11c0',                          // 'ᅄᅦᇀ'
    '\ueef6': '\u1144\u1167',                                // 'ᅄᅧ'
    '\ueef7': '\u1144\u1168',                                // 'ᅄᅨ'
    '\ueef8': '\u1144\u1168\u11ab',                          // 'ᅄᅨᆫ'
    '\ueef9': '\u1144\u1169',                                // 'ᅄᅩ'
    '\ueefa': '\u1144\u1169\u11ab',                          // 'ᅄᅩᆫ'
    '\ueefb': '\u1144\u1169\u11af',                          // 'ᅄᅩᆯ'
    '\ueefc': '\u1144\u1169\u11c0',                          // 'ᅄᅩᇀ'
    '\ueefd': '\u1144\u116a',                                // 'ᅄᅪ'
    '\ueefe': '\u1144\u116a\u11b7',                          // 'ᅄᅪᆷ'
    '\ueeff': '\u1144\u116d',                                // 'ᅄᅭ'
    '\uef00': '\u1144\u116e',                                // 'ᅄᅮ'
    '\uef01': '\u1144\u116e\u11ab',                          // 'ᅄᅮᆫ'
    '\uef02': '\u1144\u116e\u11af',                          // 'ᅄᅮᆯ'
    '\uef03': '\u1144\u116f',                                // 'ᅄᅯ'
    '\uef04': '\u1144\u1170',                                // 'ᅄᅰ'
    '\uef05': '\u1144\u1170\u11af',                          // 'ᅄᅰᆯ'
    '\uef06': '\u1144\u1171',                                // 'ᅄᅱ'
    '\uef07': '\u1144\u1171\u11bc',                          // 'ᅄᅱᆼ'
    '\uef08': '\u1144\u1172',                                // 'ᅄᅲ'
    '\uef09': '\u1144\u1173',                                // 'ᅄᅳ'
    '\uef0a': '\u1144\u1173\u11ab',                          // 'ᅄᅳᆫ'
    '\uef0b': '\u1144\u1173\u11af',                          // 'ᅄᅳᆯ'
    '\uef0c': '\u1144\u1174',                                // 'ᅄᅴ'
    '\uef0d': '\u1144\u1175',                                // 'ᅄᅵ'
    '\uef0e': '\u1144\u1175\u11a8',                          // 'ᅄᅵᆨ'
    '\uef0f': '\u1144\u1175\u11ab',                          // 'ᅄᅵᆫ'
    '\uef10': '\u1144\u1175\u11af',                          // 'ᅄᅵᆯ'
    '\uef11': '\u1144\u1175\u11bc',                          // 'ᅄᅵᆼ'
    '\uef12': '\u1144\u1175\u11bf',                          // 'ᅄᅵᆿ'
    '\uef13': '\u1144\u119e',                                // 'ᅄᆞ'
    '\uef14': '\u1144\u11a1',                                // 'ᅄᆡ'
    '\uef15': '\u1145\u1161',                                // 'ᅅᅡ'
    '\uef16': '\u1145\u1161\u11ab',                          // 'ᅅᅡᆫ'
    '\uef17': '\u1145\u1161\u11bc',                          // 'ᅅᅡᆼ'
    '\uef18': '\u1145\u1176',                                // 'ᅅᅶ'
    '\uef19': '\u1145\u1162',                                // 'ᅅᅢ'
    '\uef1a': '\u1145\u1165',                                // 'ᅅᅥ'
    '\uef1b': '\u1145\u1165\u11ab',                          // 'ᅅᅥᆫ'
    '\uef1c': '\u1145\u1165\u11af',                          // 'ᅅᅥᆯ'
    '\uef1d': '\u1145\u1165\u11b7',                          // 'ᅅᅥᆷ'
    '\uef1e': '\u1145\u1165\u11bc',                          // 'ᅅᅥᆼ'
    '\uef1f': '\u1145\u117b',                                // 'ᅅᅻ'
    '\uef20': '\u1145\u1166',                                // 'ᅅᅦ'
    '\uef21': '\u1145\u1167',                                // 'ᅅᅧ'
    '\uef22': '\u1145\u1168',                                // 'ᅅᅨ'
    '\uef23': '\u1145\u1168\u11ab',                          // 'ᅅᅨᆫ'
    '\uef24': '\u1145\u1169',                                // 'ᅅᅩ'
    '\uef25': '\u1145\u1169\u11ab',                          // 'ᅅᅩᆫ'
    '\uef26': '\u1145\u116e',                                // 'ᅅᅮ'
    '\uef27': '\u1145\u116e\u11ab',                          // 'ᅅᅮᆫ'
    '\uef28': '\u1145\u1189',                                // 'ᅅᆉ'
    '\uef29': '\u1145\u1189\u11ab',                          // 'ᅅᆉᆫ'
    '\uef2a': '\u1145\u1189\u11bc',                          // 'ᅅᆉᆼ'
    '\uef2b': '\u1145\u118a',                                // 'ᅅᆊ'
    '\uef2c': '\u1145\u116f',                                // 'ᅅᅯ'
    '\uef2d': '\u1145\u1170',                                // 'ᅅᅰ'
    '\uef2e': '\u1145\u1172',                                // 'ᅅᅲ'
    '\uef2f': '\u1145\u118e',                                // 'ᅅᆎ'
    '\uef30': '\u1145\u118e\u11ab',                          // 'ᅅᆎᆫ'
    '\uef31': '\u1145\u1191',                                // 'ᅅᆑ'
    '\uef32': '\u1145\u1192',                                // 'ᅅᆒ'
    '\uef33': '\u1145\u1194',                                // 'ᅅᆔ'
    '\uef34': '\u1145\u1173',                                // 'ᅅᅳ'
    '\uef35': '\u1145\u1173\u11ab',                          // 'ᅅᅳᆫ'
    '\uef36': '\u1145\u1174',                                // 'ᅅᅴ'
    '\uef37': '\u1145\u1174\u11ab',                          // 'ᅅᅴᆫ'
    '\uef38': '\u1145\u1175',                                // 'ᅅᅵ'
    '\uef39': '\u1145\u1175\u11af',                          // 'ᅅᅵᆯ'
    '\uef3a': '\u1145\u119e',                                // 'ᅅᆞ'
    '\uef3b': '\u1145\u119e\u11af',                          // 'ᅅᆞᆯ'
    '\uef3c': '\u1146\u1161',                                // 'ᅆᅡ'
    '\uef3d': '\u1146\u1162',                                // 'ᅆᅢ'
    '\uef3e': '\u1146\u1163',                                // 'ᅆᅣ'
    '\uef3f': '\u1146\u1165',                                // 'ᅆᅥ'
    '\uef40': '\u1146\u1166',                                // 'ᅆᅦ'
    '\uef41': '\u1146\u1167',                                // 'ᅆᅧ'
    '\uef42': '\u1146\u1168',                                // 'ᅆᅨ'
    '\uef43': '\u1146\u1169',                                // 'ᅆᅩ'
    '\uef44': '\u1146\u116a',                                // 'ᅆᅪ'
    '\uef45': '\u1146\u116b',                                // 'ᅆᅫ'
    '\uef46': '\u1146\u116c',                                // 'ᅆᅬ'
    '\uef47': '\u1146\u116d',                                // 'ᅆᅭ'
    '\uef48': '\u1146\u116e',                                // 'ᅆᅮ'
    '\uef49': '\u1146\u116f',                                // 'ᅆᅯ'
    '\uef4a': '\u1146\u1170',                                // 'ᅆᅰ'
    '\uef4b': '\u1146\ud7b5',                                // 'ᅆힵ'
    '\uef4c': '\u1146\u118c',                                // 'ᅆᆌ'
    '\uef4d': '\u1146\u1171',                                // 'ᅆᅱ'
    '\uef4e': '\u1146\u1172',                                // 'ᅆᅲ'
    '\uef4f': '\u1146\u1194',                                // 'ᅆᆔ'
    '\uef50': '\u1146\u1173',                                // 'ᅆᅳ'
    '\uef51': '\u1146\u1174',                                // 'ᅆᅴ'
    '\uef52': '\u1146\u1175',                                // 'ᅆᅵ'
    '\uef53': '\u1146\u1198',                                // 'ᅆᆘ'
    '\uef54': '\u1146\u119e',                                // 'ᅆᆞ'
    '\uef55': '\u1146\u11a1',                                // 'ᅆᆡ'
    '\uef56': '\u1147\u1163',                                // 'ᅇᅣ'
    '\uef57': '\u1147\u1163\u11ab',                          // 'ᅇᅣᆫ'
    '\uef58': '\u1147\u1167',                                // 'ᅇᅧ'
    '\uef59': '\u1147\u1167\u11ab',                          // 'ᅇᅧᆫ'
    '\uef5a': '\u1147\u1167\u11ba',                          // 'ᅇᅧᆺ'
    '\uef5b': '\u1147\u1168',                                // 'ᅇᅨ'
    '\uef5c': '\u1147\u1168\u11ba',                          // 'ᅇᅨᆺ'
    '\uef5d': '\u1147\u116d',                                // 'ᅇᅭ'
    '\uef5e': '\u1147\u116d\u11ab',                          // 'ᅇᅭᆫ'
    '\uef5f': '\u1147\u116d\u11b7',                          // 'ᅇᅭᆷ'
    '\uef60': '\u1147\u116d\u11e2',                          // 'ᅇᅭᇢ'
    '\uef61': '\u1147\u116d\u11ba',                          // 'ᅇᅭᆺ'
    '\uef62': '\u1147\u116e',                                // 'ᅇᅮ'
    '\uef63': '\u1147\u116f',                                // 'ᅇᅯ'
    '\uef64': '\u1147\u1172',                                // 'ᅇᅲ'
    '\uef65': '\u1147\u1172\u11ab',                          // 'ᅇᅲᆫ'
    '\uef66': '\u1147\u1172\u11b7',                          // 'ᅇᅲᆷ'
    '\uef67': '\u1147\u118e',                                // 'ᅇᆎ'
    '\uef68': '\u1147\u118e\u11ab',                          // 'ᅇᆎᆫ'
    '\uef69': '\u1147\u1175',                                // 'ᅇᅵ'
    '\uef6a': '\u1147\u1175\u11ab',                          // 'ᅇᅵᆫ'
    '\uef6b': '\u1148\u1161',                                // 'ᅈᅡ'
    '\uef6c': '\u1148\u1161\u11ab',                          // 'ᅈᅡᆫ'
    '\uef6d': '\u1148\u1161\u11bc',                          // 'ᅈᅡᆼ'
    '\uef6e': '\u1148\u1176',                                // 'ᅈᅶ'
    '\uef6f': '\u1148\u1162',                                // 'ᅈᅢ'
    '\uef70': '\u1148\u1165',                                // 'ᅈᅥ'
    '\uef71': '\u1148\u1165\u11ab',                          // 'ᅈᅥᆫ'
    '\uef72': '\u1148\u1165\u11b7',                          // 'ᅈᅥᆷ'
    '\uef73': '\u1148\u1165\u11bc',                          // 'ᅈᅥᆼ'
    '\uef74': '\u1148\u117b',                                // 'ᅈᅻ'
    '\uef75': '\u1148\u1169',                                // 'ᅈᅩ'
    '\uef76': '\u1148\u116d',                                // 'ᅈᅭ'
    '\uef77': '\u1148\u116e',                                // 'ᅈᅮ'
    '\uef78': '\u1148\u116e\u11ab',                          // 'ᅈᅮᆫ'
    '\uef79': '\u1148\u116e\u11bc',                          // 'ᅈᅮᆼ'
    '\uef7a': '\u1148\u1189',                                // 'ᅈᆉ'
    '\uef7b': '\u1148\u1189\u11ab',                          // 'ᅈᆉᆫ'
    '\uef7c': '\u1148\u116f',                                // 'ᅈᅯ'
    '\uef7d': '\u1148\u1170',                                // 'ᅈᅰ'
    '\uef7e': '\u1148\u1171',                                // 'ᅈᅱ'
    '\uef7f': '\u1148\u1172',                                // 'ᅈᅲ'
    '\uef80': '\u1148\u1194',                                // 'ᅈᆔ'
    '\uef81': '\u1148\u1173',                                // 'ᅈᅳ'
    '\uef82': '\u1148\u1175',                                // 'ᅈᅵ'
    '\uef83': '\u1149\u1161',                                // 'ᅉᅡ'
    '\uef84': '\u1149\u1161\u11ab',                          // 'ᅉᅡᆫ'
    '\uef85': '\u1149\u1161\u11bc',                          // 'ᅉᅡᆼ'
    '\uef86': '\u1149\u1162',                                // 'ᅉᅢ'
    '\uef87': '\u1149\u1165',                                // 'ᅉᅥ'
    '\uef88': '\u1149\u1165\u11ab',                          // 'ᅉᅥᆫ'
    '\uef89': '\u1149\u1165\u11bc',                          // 'ᅉᅥᆼ'
    '\uef8a': '\u1149\u116e',                                // 'ᅉᅮ'
    '\uef8b': '\u1149\u116e\u11ab',                          // 'ᅉᅮᆫ'
    '\uef8c': '\u1149\u116e\u11bc',                          // 'ᅉᅮᆼ'
    '\uef8d': '\u1149\u1189',                                // 'ᅉᆉ'
    '\uef8e': '\u1149\u1189\u11ab',                          // 'ᅉᆉᆫ'
    '\uef8f': '\u1149\u116f',                                // 'ᅉᅯ'
    '\uef90': '\u1149\u1170',                                // 'ᅉᅰ'
    '\uef91': '\u1149\u1172',                                // 'ᅉᅲ'
    '\uef92': '\u1149\u118e',                                // 'ᅉᆎ'
    '\uef93': '\u1149\u118e\u11ab',                          // 'ᅉᆎᆫ'
    '\uef94': '\u1149\u1194',                                // 'ᅉᆔ'
    '\uef95': '\u1149\u1173',                                // 'ᅉᅳ'
    '\uef96': '\u114a\u1173',                                // 'ᅊᅳ'
    '\uef97': '\u114b\u1161',                                // 'ᅋᅡ'
    '\uef98': '\u114b\u1161\u11a8',                          // 'ᅋᅡᆨ'
    '\uef99': '\u114b\u1161\u11ab',                          // 'ᅋᅡᆫ'
    '\uef9a': '\u114b\u1161\u11af',                          // 'ᅋᅡᆯ'
    '\uef9b': '\u114b\u1161\u11d8',                          // 'ᅋᅡᇘ'
    '\uef9c': '\u114b\u1161\u11bf',                          // 'ᅋᅡᆿ'
    '\uef9d': '\u114b\u1162',                                // 'ᅋᅢ'
    '\uef9e': '\u114b\u1162\u11ab',                          // 'ᅋᅢᆫ'
    '\uef9f': '\u114b\u1162\u11bf',                          // 'ᅋᅢᆿ'
    '\uefa0': '\u114b\u1165',                                // 'ᅋᅥ'
    '\uefa1': '\u114b\u1165\u11ab',                          // 'ᅋᅥᆫ'
    '\uefa2': '\u114b\u1165\u11af',                          // 'ᅋᅥᆯ'
    '\uefa3': '\u114b\u1165\u11b7',                          // 'ᅋᅥᆷ'
    '\uefa4': '\u114b\u1165\u11bc',                          // 'ᅋᅥᆼ'
    '\uefa5': '\u114b\u1166',                                // 'ᅋᅦ'
    '\uefa6': '\u114b\u1166\u11a8',                          // 'ᅋᅦᆨ'
    '\uefa7': '\u114b\u1166\u11ab',                          // 'ᅋᅦᆫ'
    '\uefa8': '\u114b\u1166\u11af',                          // 'ᅋᅦᆯ'
    '\uefa9': '\u114b\u1166\u11bf',                          // 'ᅋᅦᆿ'
    '\uefaa': '\u114b\u1169',                                // 'ᅋᅩ'
    '\uefab': '\u114b\u1169\u11ab',                          // 'ᅋᅩᆫ'
    '\uefac': '\u114b\u1169\u11af',                          // 'ᅋᅩᆯ'
    '\uefad': '\u114b\u1169\u11b1',                          // 'ᅋᅩᆱ'
    '\uefae': '\u114b\u1169\u11d8',                          // 'ᅋᅩᇘ'
    '\uefaf': '\u114b\u1169\u11b7',                          // 'ᅋᅩᆷ'
    '\uefb0': '\u114b\u1169\u11bf',                          // 'ᅋᅩᆿ'
    '\uefb1': '\u114b\u116a',                                // 'ᅋᅪ'
    '\uefb2': '\u114b\u116b',                                // 'ᅋᅫ'
    '\uefb3': '\u114b\u116b\u11ab',                          // 'ᅋᅫᆫ'
    '\uefb4': '\u114b\u116e',                                // 'ᅋᅮ'
    '\uefb5': '\u114b\u116e\u11ab',                          // 'ᅋᅮᆫ'
    '\uefb6': '\u114b\u116e\u11af',                          // 'ᅋᅮᆯ'
    '\uefb7': '\u114b\u116e\u11bf',                          // 'ᅋᅮᆿ'
    '\uefb8': '\u114b\u116e\u11c0',                          // 'ᅋᅮᇀ'
    '\uefb9': '\u114b\u1189',                                // 'ᅋᆉ'
    '\uefba': '\u114b\u1189\u11ab',                          // 'ᅋᆉᆫ'
    '\uefbb': '\u114b\u116f',                                // 'ᅋᅯ'
    '\uefbc': '\u114b\u116f\u11ab',                          // 'ᅋᅯᆫ'
    '\uefbd': '\u114b\u1170',                                // 'ᅋᅰ'
    '\uefbe': '\u114b\u1170\u11ab',                          // 'ᅋᅰᆫ'
    '\uefbf': '\u114b\u1171',                                // 'ᅋᅱ'
    '\uefc0': '\u114b\u1171\u11a8',                          // 'ᅋᅱᆨ'
    '\uefc1': '\u114b\u1171\u11ab',                          // 'ᅋᅱᆫ'
    '\uefc2': '\u114b\u1171\u11af',                          // 'ᅋᅱᆯ'
    '\uefc3': '\u114b\u1172',                                // 'ᅋᅲ'
    '\uefc4': '\u114b\u1172\u11b7',                          // 'ᅋᅲᆷ'
    '\uefc5': '\u114b\u1173',                                // 'ᅋᅳ'
    '\uefc6': '\u114b\u1173\u11af',                          // 'ᅋᅳᆯ'
    '\uefc7': '\u114b\u1175',                                // 'ᅋᅵ'
    '\uefc8': '\u114b\u1175\u11ab',                          // 'ᅋᅵᆫ'
    '\uefc9': '\u114b\u1175\u11af',                          // 'ᅋᅵᆯ'
    '\uefca': '\u114b\u1175\u11bc',                          // 'ᅋᅵᆼ'
    '\uefcb': '\u114b\u1175\u11bf',                          // 'ᅋᅵᆿ'
    '\uefcc': '\u114b\u1175\u11c0',                          // 'ᅋᅵᇀ'
    '\uefcd': '\u114b\u1175\u11c1',                          // 'ᅋᅵᇁ'
    '\uefce': '\u114b\u119e',                                // 'ᅋᆞ'
    '\uefcf': '\u114b\u11a1',                                // 'ᅋᆡ'
    '\uefd0': '\ua977\u1169',                                // 'ꥷᅩ'
    '\uefd1': '\ua977\u116e',                                // 'ꥷᅮ'
    '\uefd2': '\u114c\u1161',                                // 'ᅌᅡ'
    '\uefd3': '\u114c\u1161\u11a8',                          // 'ᅌᅡᆨ'
    '\uefd4': '\u114c\u1161\u11ab',                          // 'ᅌᅡᆫ'
    '\uefd5': '\u114c\u1161\u11af',                          // 'ᅌᅡᆯ'
    '\uefd6': '\u114c\u1161\u11d9',                          // 'ᅌᅡᇙ'
    '\uefd7': '\u114c\u1161\u11b7',                          // 'ᅌᅡᆷ'
    '\uefd8': '\u114c\u1161\u11b8',                          // 'ᅌᅡᆸ'
    '\uefd9': '\u114c\u1161\u11eb',                          // 'ᅌᅡᇫ'
    '\uefda': '\u114c\u1161\u11bc',                          // 'ᅌᅡᆼ'
    '\uefdb': '\u114c\u1161\u11f0',                          // 'ᅌᅡᇰ'
    '\uefdc': '\u114c\u1161\u11f9',                          // 'ᅌᅡᇹ'
    '\uefdd': '\u114c\u1162',                                // 'ᅌᅢ'
    '\uefde': '\u114c\u1162\u11ba',                          // 'ᅌᅢᆺ'
    '\uefdf': '\u114c\u1162\u11bc',                          // 'ᅌᅢᆼ'
    '\uefe0': '\u114c\u1163',                                // 'ᅌᅣ'
    '\uefe1': '\u114c\u1163\u11a8',                          // 'ᅌᅣᆨ'
    '\uefe2': '\u114c\u1163\u11ab',                          // 'ᅌᅣᆫ'
    '\uefe3': '\u114c\u1163\u11bc',                          // 'ᅌᅣᆼ'
    '\uefe4': '\u114c\u1163\u11f0',                          // 'ᅌᅣᇰ'
    '\uefe5': '\u114c\u1164',                                // 'ᅌᅤ'
    '\uefe6': '\u114c\u1164\u11ba',                          // 'ᅌᅤᆺ'
    '\uefe7': '\u114c\u1165',                                // 'ᅌᅥ'
    '\uefe8': '\u114c\u1165\u11a8',                          // 'ᅌᅥᆨ'
    '\uefe9': '\u114c\u1165\u11ab',                          // 'ᅌᅥᆫ'
    '\uefea': '\u114c\u1165\u11af',                          // 'ᅌᅥᆯ'
    '\uefeb': '\u114c\u1165\u11d9',                          // 'ᅌᅥᇙ'
    '\uefec': '\u114c\u1165\u11b7',                          // 'ᅌᅥᆷ'
    '\uefed': '\u114c\u1165\u11b8',                          // 'ᅌᅥᆸ'
    '\uefee': '\u114c\u1165\u11ba',                          // 'ᅌᅥᆺ'
    '\uefef': '\u114c\u1165\u11eb',                          // 'ᅌᅥᇫ'
    '\ueff0': '\u114c\u1165\u11bc',                          // 'ᅌᅥᆼ'
    '\ueff1': '\u114c\u1165\u11f0',                          // 'ᅌᅥᇰ'
    '\ueff2': '\u114c\u1166',                                // 'ᅌᅦ'
    '\ueff3': '\u114c\u1166\u11ab',                          // 'ᅌᅦᆫ'
    '\ueff4': '\u114c\u1166\u11ba',                          // 'ᅌᅦᆺ'
    '\ueff5': '\u114c\u1166\u11eb',                          // 'ᅌᅦᇫ'
    '\ueff6': '\u114c\u1166\u11bc',                          // 'ᅌᅦᆼ'
    '\ueff7': '\u114c\u1166\u11f0',                          // 'ᅌᅦᇰ'
    '\ueff8': '\u114c\u1167',                                // 'ᅌᅧ'
    '\ueff9': '\u114c\u1167\u11a8',                          // 'ᅌᅧᆨ'
    '\ueffa': '\u114c\u1167\u11ab',                          // 'ᅌᅧᆫ'
    '\ueffb': '\u114c\u1167\u11af',                          // 'ᅌᅧᆯ'
    '\ueffc': '\u114c\u1167\u11b7',                          // 'ᅌᅧᆷ'
    '\ueffd': '\u114c\u1167\u11e2',                          // 'ᅌᅧᇢ'
    '\ueffe': '\u114c\u1167\u11b8',                          // 'ᅌᅧᆸ'
    '\uefff': '\u114c\u1167\u11ba',                          // 'ᅌᅧᆺ'
    '\uf100': '\u114c\u1167\u11bc',                          // 'ᅌᅧᆼ'
    '\uf101': '\u114c\u1167\u11f0',                          // 'ᅌᅧᇰ'
    '\uf102': '\u114c\u1167\u11c1',                          // 'ᅌᅧᇁ'
    '\uf103': '\u114c\u1167\u11f4',                          // 'ᅌᅧᇴ'
    '\uf104': '\u114c\u1168',                                // 'ᅌᅨ'
    '\uf105': '\u114c\u1168\u11ba',                          // 'ᅌᅨᆺ'
    '\uf106': '\u114c\u1168\u11bc',                          // 'ᅌᅨᆼ'
    '\uf107': '\u114c\u1169',                                // 'ᅌᅩ'
    '\uf108': '\u114c\u1169\u11a8',                          // 'ᅌᅩᆨ'
    '\uf109': '\u114c\u1169\u11ab',                          // 'ᅌᅩᆫ'
    '\uf10a': '\u114c\u1169\u11af',                          // 'ᅌᅩᆯ'
    '\uf10b': '\u114c\u1169\u11b3',                          // 'ᅌᅩᆳ'
    '\uf10c': '\u114c\u1169\u11d9',                          // 'ᅌᅩᇙ'
    '\uf10d': '\u114c\u1169\u11b7',                          // 'ᅌᅩᆷ'
    '\uf10e': '\u114c\u1169\u11e2',                          // 'ᅌᅩᇢ'
    '\uf10f': '\u114c\u1169\u11ba',                          // 'ᅌᅩᆺ'
    '\uf110': '\u114c\u1169\u11bc',                          // 'ᅌᅩᆼ'
    '\uf111': '\u114c\u1169\u11f0',                          // 'ᅌᅩᇰ'
    '\uf112': '\u114c\u116a',                                // 'ᅌᅪ'
    '\uf113': '\u114c\u116a\u11a8',                          // 'ᅌᅪᆨ'
    '\uf114': '\u114c\u116a\u11ab',                          // 'ᅌᅪᆫ'
    '\uf115': '\u114c\u116a\u11af',                          // 'ᅌᅪᆯ'
    '\uf116': '\u114c\u116a\u11d9',                          // 'ᅌᅪᇙ'
    '\uf117': '\u114c\u116a\u11b7',                          // 'ᅌᅪᆷ'
    '\uf118': '\u114c\u116a\u11dd',                          // 'ᅌᅪᇝ'
    '\uf119': '\u114c\u116a\u11bc',                          // 'ᅌᅪᆼ'
    '\uf11a': '\u114c\u116a\u11f0',                          // 'ᅌᅪᇰ'
    '\uf11b': '\u114c\u116b',                                // 'ᅌᅫ'
    '\uf11c': '\u114c\u116b\u11bc',                          // 'ᅌᅫᆼ'
    '\uf11d': '\u114c\u116c',                                // 'ᅌᅬ'
    '\uf11e': '\u114c\u116c\u11ab',                          // 'ᅌᅬᆫ'
    '\uf11f': '\u114c\u116c\u11bc',                          // 'ᅌᅬᆼ'
    '\uf120': '\u114c\u116c\u11f0',                          // 'ᅌᅬᇰ'
    '\uf121': '\u114c\u116d',                                // 'ᅌᅭ'
    '\uf122': '\u114c\u116d\u11b7',                          // 'ᅌᅭᆷ'
    '\uf123': '\u114c\u116d\u11e2',                          // 'ᅌᅭᇢ'
    '\uf124': '\u114c\u116d\u11f0',                          // 'ᅌᅭᇰ'
    '\uf125': '\u114c\u1184',                                // 'ᅌᆄ'
    '\uf126': '\u114c\u1185',                                // 'ᅌᆅ'
    '\uf127': '\u114c\u1185\u11bc',                          // 'ᅌᆅᆼ'
    '\uf128': '\u114c\u116e',                                // 'ᅌᅮ'
    '\uf129': '\u114c\u116e\u11a8',                          // 'ᅌᅮᆨ'
    '\uf12a': '\u114c\u116e\u11ab',                          // 'ᅌᅮᆫ'
    '\uf12b': '\u114c\u116e\u11af',                          // 'ᅌᅮᆯ'
    '\uf12c': '\u114c\u116e\u11d9',                          // 'ᅌᅮᇙ'
    '\uf12d': '\u114c\u116e\u11b7',                          // 'ᅌᅮᆷ'
    '\uf12e': '\u114c\u116e\u11e2',                          // 'ᅌᅮᇢ'
    '\uf12f': '\u114c\u116e\u11bc',                          // 'ᅌᅮᆼ'
    '\uf130': '\u114c\u116f',                                // 'ᅌᅯ'
    '\uf131': '\u114c\u116f\u11ab',                          // 'ᅌᅯᆫ'
    '\uf132': '\u114c\u116f\u11af',                          // 'ᅌᅯᆯ'
    '\uf133': '\u114c\u116f\u11d9',                          // 'ᅌᅯᇙ'
    '\uf134': '\u114c\u116f\u11bc',                          // 'ᅌᅯᆼ'
    '\uf135': '\u114c\u116f\u11f0',                          // 'ᅌᅯᇰ'
    '\uf136': '\u114c\u1170',                                // 'ᅌᅰ'
    '\uf137': '\u114c\u1171',                                // 'ᅌᅱ'
    '\uf138': '\u114c\u1171\u11af',                          // 'ᅌᅱᆯ'
    '\uf139': '\u114c\u1171\u11d9',                          // 'ᅌᅱᇙ'
    '\uf13a': '\u114c\u1171\u11bc',                          // 'ᅌᅱᆼ'
    '\uf13b': '\u114c\u1171\u11f0',                          // 'ᅌᅱᇰ'
    '\uf13c': '\u114c\u1172',                                // 'ᅌᅲ'
    '\uf13d': '\u114c\u1172\u11a8',                          // 'ᅌᅲᆨ'
    '\uf13e': '\u114c\u1172\u11ab',                          // 'ᅌᅲᆫ'
    '\uf13f': '\u114c\u1172\u11af',                          // 'ᅌᅲᆯ'
    '\uf140': '\u114c\u1172\u11d9',                          // 'ᅌᅲᇙ'
    '\uf141': '\u114c\u1172\u11b7',                          // 'ᅌᅲᆷ'
    '\uf142': '\u114c\u1172\u11e2',                          // 'ᅌᅲᇢ'
    '\uf143': '\u114c\u1191',                                // 'ᅌᆑ'
    '\uf144': '\u114c\u1191\u11a8',                          // 'ᅌᆑᆨ'
    '\uf145': '\u114c\u1191\u11ab',                          // 'ᅌᆑᆫ'
    '\uf146': '\u114c\u1191\u11f0',                          // 'ᅌᆑᇰ'
    '\uf147': '\u114c\u1191\u11f9',                          // 'ᅌᆑᇹ'
    '\uf148': '\u114c\u1194',                                // 'ᅌᆔ'
    '\uf149': '\u114c\u1173',                                // 'ᅌᅳ'
    '\uf14a': '\u114c\u1173\u11a8',                          // 'ᅌᅳᆨ'
    '\uf14b': '\u114c\u1173\u11ab',                          // 'ᅌᅳᆫ'
    '\uf14c': '\u114c\u1173\u11af',                          // 'ᅌᅳᆯ'
    '\uf14d': '\u114c\u1173\u11d9',                          // 'ᅌᅳᇙ'
    '\uf14e': '\u114c\u1173\u11b7',                          // 'ᅌᅳᆷ'
    '\uf14f': '\u114c\u1173\u11b8',                          // 'ᅌᅳᆸ'
    '\uf150': '\u114c\u1173\u11bc',                          // 'ᅌᅳᆼ'
    '\uf151': '\u114c\u1173\u11f0',                          // 'ᅌᅳᇰ'
    '\uf152': '\u114c\u1174',                                // 'ᅌᅴ'
    '\uf153': '\u114c\u1174\u11bc',                          // 'ᅌᅴᆼ'
    '\uf154': '\u114c\u1175',                                // 'ᅌᅵ'
    '\uf155': '\u114c\u1175\u11ab',                          // 'ᅌᅵᆫ'
    '\uf156': '\u114c\u1175\u11ae',                          // 'ᅌᅵᆮ'
    '\uf157': '\u114c\u1175\u11af',                          // 'ᅌᅵᆯ'
    '\uf158': '\u114c\u1175\u11ba',                          // 'ᅌᅵᆺ'
    '\uf159': '\u114c\u1175\u11eb',                          // 'ᅌᅵᇫ'
    '\uf15a': '\u114c\u1175\u11bc',                          // 'ᅌᅵᆼ'
    '\uf15b': '\u114c\u1175\u11f0',                          // 'ᅌᅵᇰ'
    '\uf15c': '\u114c\u119e',                                // 'ᅌᆞ'
    '\uf15d': '\u114c\u119e\u11a8',                          // 'ᅌᆞᆨ'
    '\uf15e': '\u114c\u119e\u11ab',                          // 'ᅌᆞᆫ'
    '\uf15f': '\u114c\u119e\u11ae',                          // 'ᅌᆞᆮ'
    '\uf160': '\u114c\u119e\u11af',                          // 'ᅌᆞᆯ'
    '\uf161': '\u114c\u119e\u11ba',                          // 'ᅌᆞᆺ'
    '\uf162': '\u114c\u11a1',                                // 'ᅌᆡ'
    '\uf163': '\u114c\u11a1\u11a8',                          // 'ᅌᆡᆨ'
    '\uf164': '\u110c\u1161\u11c7',                          // '자ᇇ'
    '\uf165': '\u110c\u1161\u11cd',                          // '자ᇍ'
    '\uf166': '\u110c\u1161\u11d0',                          // '자ᇐ'
    '\uf167': '\u110c\u1161\u11d9',                          // '자ᇙ'
    '\uf168': '\u110c\u1161\u11da',                          // '자ᇚ'
    '\uf169': '\u110c\u1161\u11dd',                          // '자ᇝ'
    '\uf16a': '\u110c\u1161\u11e2',                          // '자ᇢ'
    '\uf16b': '\u110c\u1161\ud7e6',                          // '자ퟦ'
    '\uf16c': '\u110c\u1161\u11e6',                          // '자ᇦ'
    '\uf16d': '\u110c\u1161\u11eb',                          // '자ᇫ'
    '\uf16e': '\u110c\u1161\u11f0',                          // '자ᇰ'
    '\uf16f': '\u110c\u1161\u11ec',                          // '자ᇬ'
    '\uf170': '\u110c\u1161\u11f1',                          // '자ᇱ'
    '\uf171': '\u110c\u1161\u11f9',                          // '자ᇹ'
    '\uf172': '\u110c\u1176',                                // 'ᄌᅶ'
    '\uf173': '\u110c\u1162\u11f0',                          // '재ᇰ'
    '\uf174': '\u110c\u1163\u11c7',                          // '쟈ᇇ'
    '\uf175': '\u110c\u1163\u11cd',                          // '쟈ᇍ'
    '\uf176': '\u110c\u1163\u11d0',                          // '쟈ᇐ'
    '\uf177': '\u110c\u1163\u11e2',                          // '쟈ᇢ'
    '\uf178': '\u110c\u1163\u11eb',                          // '쟈ᇫ'
    '\uf179': '\u110c\u1163\u11f1',                          // '쟈ᇱ'
    '\uf17a': '\u110c\u1163\u11f0',                          // '쟈ᇰ'
    '\uf17b': '\u110c\u1163\u11f1',                          // '쟈ᇱ'
    '\uf17c': '\u110c\u1178',                                // 'ᄌᅸ'
    '\uf17d': '\u110c\u11a4',                                // 'ᄌᆤ'
    '\uf17e': '\u110c\u11a4\u11af',                          // 'ᄌᆤᆯ'
    '\uf17f': '\u110c\u1165\u11fe',                          // '저ᇾ'
    '\uf180': '\u110c\u1165\u11cd',                          // '저ᇍ'
    '\uf181': '\u110c\u1165\u11d0',                          // '저ᇐ'
    '\uf182': '\u110c\u1165\u11d9',                          // '저ᇙ'
    '\uf183': '\u110c\u1165\u11dd',                          // '저ᇝ'
    '\uf184': '\u110c\u1165\u11e4',                          // '저ᇤ'
    '\uf185': '\u110c\u1165\ud7f2',                          // '저ퟲ'
    '\uf186': '\u110c\u1165\u11eb',                          // '저ᇫ'
    '\uf187': '\u110c\u1165\u11f0',                          // '저ᇰ'
    '\uf188': '\u110c\u1165\ud7f6',                          // '저ퟶ'
    '\uf189': '\u110c\u1165\u11f9',                          // '저ᇹ'
    '\uf18a': '\u110c\u117b',                                // 'ᄌᅻ'
    '\uf18b': '\u110c\u117c',                                // 'ᄌᅼ'
    '\uf18c': '\u110c\u117c\u11a8',                          // 'ᄌᅼᆨ'
    '\uf18d': '\u110c\u117c\u11ab',                          // 'ᄌᅼᆫ'
    '\uf18e': '\u110c\u117c\u11bc',                          // 'ᄌᅼᆼ'
    '\uf18f': '\u110c\u117c\u11f9',                          // 'ᄌᅼᇹ'
    '\uf190': '\u110c\u1167\u11c7',                          // '져ᇇ'
    '\uf191': '\u110c\u1167\u11ca',                          // '져ᇊ'
    '\uf192': '\u110c\u1167\ud7cd',                          // '져ퟍ'
    '\uf193': '\u110c\u1167\ud7ce',                          // '져ퟎ'
    '\uf194': '\u110c\u1167\ud7d2',                          // '져ퟒ'
    '\uf195': '\u110c\u1167\u11d0',                          // '져ᇐ'
    '\uf196': '\u110c\u1167\u11d9',                          // '져ᇙ'
    '\uf197': '\u110c\u1167\u11e2',                          // '져ᇢ'
    '\uf198': '\u110c\u1167\u11eb',                          // '져ᇫ'
    '\uf199': '\u110c\u1167\u11f0',                          // '져ᇰ'
    '\uf19a': '\u110c\u1167\u11f9',                          // '져ᇹ'
    '\uf19b': '\u110c\u11a5',                                // 'ᄌᆥ'
    '\uf19c': '\u110c\u11a5\u11bc',                          // 'ᄌᆥᆼ'
    '\uf19d': '\u110c\u117d',                                // 'ᄌᅽ'
    '\uf19e': '\u110c\u117e',                                // 'ᄌᅾ'
    '\uf19f': '\u110c\u1169\u11c7',                          // '조ᇇ'
    '\uf1a0': '\u110c\u1169\u11d9',                          // '조ᇙ'
    '\uf1a1': '\u110c\u1169\u11dd',                          // '조ᇝ'
    '\uf1a2': '\u110c\u1169\u11e2',                          // '조ᇢ'
    '\uf1a3': '\u110c\u1169\u11eb',                          // '조ᇫ'
    '\uf1a4': '\u110c\u1169\u11f0',                          // '조ᇰ'
    '\uf1a5': '\u110c\u116a\u11d9',                          // '좌ᇙ'
    '\uf1a6': '\u110c\u116a\u11e2',                          // '좌ᇢ'
    '\uf1a7': '\u110c\u116a\u11f0',                          // '좌ᇰ'
    '\uf1a8': '\u110c\u116d\u11d9',                          // '죠ᇙ'
    '\uf1a9': '\u110c\u116d\u11e2',                          // '죠ᇢ'
    '\uf1aa': '\u110c\u116d\u11e6',                          // '죠ᇦ'
    '\uf1ab': '\u110c\u116d\u11f1',                          // '죠ᇱ'
    '\uf1ac': '\u110c\u116d\u11f0',                          // '죠ᇰ'
    '\uf1ad': '\u110c\u116d\u11f1',                          // '죠ᇱ'
    '\uf1ae': '\u110c\u1187',                                // 'ᄌᆇ'
    '\uf1af': '\u110c\u1188',                                // 'ᄌᆈ'
    '\uf1b0': '\u110c\u1188\u11af',                          // 'ᄌᆈᆯ'
    '\uf1b1': '\u110c\u1188\u11d9',                          // 'ᄌᆈᇙ'
    '\uf1b2': '\u110c\u116e\u11c7',                          // '주ᇇ'
    '\uf1b3': '\u110c\u116e\u11cd',                          // '주ᇍ'
    '\uf1b4': '\u110c\u116e\u11d9',                          // '주ᇙ'
    '\uf1b5': '\u110c\u116e\u11dd',                          // '주ᇝ'
    '\uf1b6': '\u110c\u116e\u11e2',                          // '주ᇢ'
    '\uf1b7': '\u110c\u116e\u11eb',                          // '주ᇫ'
    '\uf1b8': '\u110c\u116e\u11f0',                          // '주ᇰ'
    '\uf1b9': '\u110c\u116e\u11f9',                          // '주ᇹ'
    '\uf1ba': '\u110c\u1189',                                // 'ᄌᆉ'
    '\uf1bb': '\u110c\u1189\u11bc',                          // 'ᄌᆉᆼ'
    '\uf1bc': '\u110c\u118a',                                // 'ᄌᆊ'
    '\uf1bd': '\u110c\ud7b5',                                // 'ᄌힵ'
    '\uf1be': '\u110c\ud7b5\u11ab',                          // 'ᄌힵᆫ'
    '\uf1bf': '\u110c\ud7b5\u11af',                          // 'ᄌힵᆯ'
    '\uf1c0': '\u110c\ud7b5\u11d9',                          // 'ᄌힵᇙ'
    '\uf1c1': '\u110c\u118c',                                // 'ᄌᆌ'
    '\uf1c2': '\u110c\u1172\u11d9',                          // '쥬ᇙ'
    '\uf1c3': '\u110c\u1172\u11e2',                          // '쥬ᇢ'
    '\uf1c4': '\u110c\u1172\u11f0',                          // '쥬ᇰ'
    '\uf1c5': '\u110c\u1172\u11ec',                          // '쥬ᇬ'
    '\uf1c6': '\u110c\u1172\u11f9',                          // '쥬ᇹ'
    '\uf1c7': '\u110c\u118e',                                // 'ᄌᆎ'
    '\uf1c8': '\u110c\u118e\u11ab',                          // 'ᄌᆎᆫ'
    '\uf1c9': '\u110c\u118f',                                // 'ᄌᆏ'
    '\uf1ca': '\u110c\u118f\u11ab',                          // 'ᄌᆏᆫ'
    '\uf1cb': '\u110c\u118f\u11af',                          // 'ᄌᆏᆯ'
    '\uf1cc': '\u110c\u1190',                                // 'ᄌᆐ'
    '\uf1cd': '\u110c\u1191',                                // 'ᄌᆑ'
    '\uf1ce': '\u110c\u1191\u11ab',                          // 'ᄌᆑᆫ'
    '\uf1cf': '\u110c\u1191\u11af',                          // 'ᄌᆑᆯ'
    '\uf1d0': '\u110c\u1191\u11d9',                          // 'ᄌᆑᇙ'
    '\uf1d1': '\u110c\u1191\u11f9',                          // 'ᄌᆑᇹ'
    '\uf1d2': '\u110c\u1192',                                // 'ᄌᆒ'
    '\uf1d3': '\u110c\u1192\u11ab',                          // 'ᄌᆒᆫ'
    '\uf1d4': '\u110c\u1192\u11b8',                          // 'ᄌᆒᆸ'
    '\uf1d5': '\u110c\u1192\u11bc',                          // 'ᄌᆒᆼ'
    '\uf1d6': '\u110c\u1194',                                // 'ᄌᆔ'
    '\uf1d7': '\u110c\u1194\u11a8',                          // 'ᄌᆔᆨ'
    '\uf1d8': '\u110c\u1194\u11ab',                          // 'ᄌᆔᆫ'
    '\uf1d9': '\u110c\u1194\u11af',                          // 'ᄌᆔᆯ'
    '\uf1da': '\u110c\u1194\u11d9',                          // 'ᄌᆔᇙ'
    '\uf1db': '\u110c\u1194\u11ba',                          // 'ᄌᆔᆺ'
    '\uf1dc': '\u110c\u1194\u11bc',                          // 'ᄌᆔᆼ'
    '\uf1dd': '\u110c\u1173\u11d0',                          // '즈ᇐ'
    '\uf1de': '\u110c\u1173\u11d9',                          // '즈ᇙ'
    '\uf1df': '\u110c\u1173\u11e2',                          // '즈ᇢ'
    '\uf1e0': '\u110c\u1173\u11e6',                          // '즈ᇦ'
    '\uf1e1': '\u110c\u1173\u11eb',                          // '즈ᇫ'
    '\uf1e2': '\u110c\u1173\u11f0',                          // '즈ᇰ'
    '\uf1e3': '\u110c\u1195',                                // 'ᄌᆕ'
    '\uf1e4': '\u110c\u1196',                                // 'ᄌᆖ'
    '\uf1e5': '\u110c\u1174\u11f0',                          // '즤ᇰ'
    '\uf1e6': '\u110c\u1174\u11f9',                          // '즤ᇹ'
    '\uf1e7': '\u110c\u1175\u11fe',                          // '지ᇾ'
    '\uf1e8': '\u110c\u1175\ud7d3',                          // '지ퟓ'
    '\uf1e9': '\u110c\u1175\u11cd',                          // '지ᇍ'
    '\uf1ea': '\u110c\u1175\u11d9',                          // '지ᇙ'
    '\uf1eb': '\u110c\u1175\u11dd',                          // '지ᇝ'
    '\uf1ec': '\u110c\u1175\u11e2',                          // '지ᇢ'
    '\uf1ed': '\u110c\u1175\u11e6',                          // '지ᇦ'
    '\uf1ee': '\u110c\u1175\ud7f0',                          // '지ퟰ'
    '\uf1ef': '\u110c\u1175\ud7f1',                          // '지ퟱ'
    '\uf1f0': '\u110c\u1175\ud7f2',                          // '지ퟲ'
    '\uf1f1': '\u110c\u1175\u11eb',                          // '지ᇫ'
    '\uf1f2': '\u110c\u1175\u11f0',                          // '지ᇰ'
    '\uf1f3': '\u110c\u1175\u11f9',                          // '지ᇹ'
    '\uf1f4': '\u110c\u1198',                                // 'ᄌᆘ'
    '\uf1f5': '\u110c\u1199',                                // 'ᄌᆙ'
    '\uf1f6': '\u110c\u1199\u11ab',                          // 'ᄌᆙᆫ'
    '\uf1f7': '\u110c\ud7bf',                                // 'ᄌힿ'
    '\uf1f8': '\u110c\ud7c0',                                // 'ᄌퟀ'
    '\uf1f9': '\u110c\u119a',                                // 'ᄌᆚ'
    '\uf1fa': '\u110c\u119a\u11af',                          // 'ᄌᆚᆯ'
    '\uf1fb': '\u110c\u119b',                                // 'ᄌᆛ'
    '\uf1fc': '\u110c\u119e',                                // 'ᄌᆞ'
    '\uf1fd': '\u110c\u119e\u11a8',                          // 'ᄌᆞᆨ'
    '\uf1fe': '\u110c\u119e\u11ab',                          // 'ᄌᆞᆫ'
    '\uf1ff': '\u110c\u119e\u11ae',                          // 'ᄌᆞᆮ'
    '\uf200': '\u110c\u119e\u11af',                          // 'ᄌᆞᆯ'
    '\uf201': '\u110c\u119e\u11cd',                          // 'ᄌᆞᇍ'
    '\uf202': '\u110c\u119e\u11d0',                          // 'ᄌᆞᇐ'
    '\uf203': '\u110c\u119e\u11d9',                          // 'ᄌᆞᇙ'
    '\uf204': '\u110c\u119e\u11b7',                          // 'ᄌᆞᆷ'
    '\uf205': '\u110c\u119e\u11da',                          // 'ᄌᆞᇚ'
    '\uf206': '\u110c\u119e\u11dd',                          // 'ᄌᆞᇝ'
    '\uf207': '\u110c\u119e\u11b8',                          // 'ᄌᆞᆸ'
    '\uf208': '\u110c\u119e\u11e6',                          // 'ᄌᆞᇦ'
    '\uf209': '\u110c\u119e\u11ba',                          // 'ᄌᆞᆺ'
    '\uf20a': '\u110c\u119e\u11eb',                          // 'ᄌᆞᇫ'
    '\uf20b': '\u110c\u119e\u11bc',                          // 'ᄌᆞᆼ'
    '\uf20c': '\u110c\u119e\u11f0',                          // 'ᄌᆞᇰ'
    '\uf20d': '\u110c\u119e\u11bd',                          // 'ᄌᆞᆽ'
    '\uf20e': '\u110c\u119e\u11be',                          // 'ᄌᆞᆾ'
    '\uf20f': '\u110c\u119e\u11c0',                          // 'ᄌᆞᇀ'
    '\uf210': '\u110c\ud7c5',                                // 'ᄌퟅ'
    '\uf211': '\u110c\ud7c5\u11bc',                          // 'ᄌퟅᆼ'
    '\uf212': '\u110c\u11a1',                                // 'ᄌᆡ'
    '\uf213': '\u110c\u11a1\u11a8',                          // 'ᄌᆡᆨ'
    '\uf214': '\u110c\u11a1\u11ab',                          // 'ᄌᆡᆫ'
    '\uf215': '\u110c\u11a1\u11ae',                          // 'ᄌᆡᆮ'
    '\uf216': '\u110c\u11a1\u11af',                          // 'ᄌᆡᆯ'
    '\uf217': '\u110c\u11a1\u11b7',                          // 'ᄌᆡᆷ'
    '\uf218': '\u110c\u11a1\u11b8',                          // 'ᄌᆡᆸ'
    '\uf219': '\u110c\u11a1\u11ba',                          // 'ᄌᆡᆺ'
    '\uf21a': '\u110c\u11a1\u11bc',                          // 'ᄌᆡᆼ'
    '\uf21b': '\u110c\u11a1\u11f0',                          // 'ᄌᆡᇰ'
    '\uf21c': '\u114d\u1173',                                // 'ᅍᅳ'
    '\uf21d': '\u114d\u1173\u11af',                          // 'ᅍᅳᆯ'
    '\uf21e': '\u114d\u1175',                                // 'ᅍᅵ'
    '\uf21f': '\u114d\u1175\u11af',                          // 'ᅍᅵᆯ'
    '\uf220': '\u110d\u1161\u11d0',                          // '짜ᇐ'
    '\uf221': '\u110d\u1161\u11d9',                          // '짜ᇙ'
    '\uf222': '\u110d\u1161\u11e2',                          // '짜ᇢ'
    '\uf223': '\u110d\u1161\ud7e6',                          // '짜ퟦ'
    '\uf224': '\u110d\u1161\u11f0',                          // '짜ᇰ'
    '\uf225': '\u110d\u1161\u11f9',                          // '짜ᇹ'
    '\uf226': '\u110d\u1163\u11e2',                          // '쨔ᇢ'
    '\uf227': '\u110d\u1163\u11e6',                          // '쨔ᇦ'
    '\uf228': '\u110d\u1163\u11f0',                          // '쨔ᇰ'
    '\uf229': '\u110d\u1165\u11f0',                          // '쩌ᇰ'
    '\uf22a': '\u110d\u1165\u11f9',                          // '쩌ᇹ'
    '\uf22b': '\u110d\u1167\u11d9',                          // '쪄ᇙ'
    '\uf22c': '\u110d\u1167\u11f0',                          // '쪄ᇰ'
    '\uf22d': '\u110d\u1169\u11d9',                          // '쪼ᇙ'
    '\uf22e': '\u110d\u1169\u11e2',                          // '쪼ᇢ'
    '\uf22f': '\u110d\u1169\u11f0',                          // '쪼ᇰ'
    '\uf230': '\u110d\u1169\ud7f9',                          // '쪼ퟹ'
    '\uf231': '\u110d\u1169\u11f9',                          // '쪼ᇹ'
    '\uf232': '\u110d\u116a\u11f0',                          // '쫘ᇰ'
    '\uf233': '\u110d\u117f',                                // 'ᄍᅿ'
    '\uf234': '\u110d\u116c\u11f9',                          // '쬐ᇹ'
    '\uf235': '\u110d\u116d\u11e2',                          // '쬬ᇢ'
    '\uf236': '\u110d\u116d\u11f0',                          // '쬬ᇰ'
    '\uf237': '\u110d\u116e\u11d9',                          // '쭈ᇙ'
    '\uf238': '\u110d\u116e\u11e2',                          // '쭈ᇢ'
    '\uf239': '\u110d\u116e\u11f0',                          // '쭈ᇰ'
    '\uf23a': '\u110d\u116e\u11f9',                          // '쭈ᇹ'
    '\uf23b': '\u110d\u1171\u11f9',                          // '쮜ᇹ'
    '\uf23c': '\u110d\u1172\u11d9',                          // '쮸ᇙ'
    '\uf23d': '\u110d\u1172\u11e2',                          // '쮸ᇢ'
    '\uf23e': '\u110d\u1191',                                // 'ᄍᆑ'
    '\uf23f': '\u110d\u1191\u11ab',                          // 'ᄍᆑᆫ'
    '\uf240': '\u110d\u1191\u11af',                          // 'ᄍᆑᆯ'
    '\uf241': '\u110d\u1191\u11d9',                          // 'ᄍᆑᇙ'
    '\uf242': '\u110d\u1191\u11f9',                          // 'ᄍᆑᇹ'
    '\uf243': '\u110d\u1194',                                // 'ᄍᆔ'
    '\uf244': '\u110d\u1194\u11bc',                          // 'ᄍᆔᆼ'
    '\uf245': '\u110d\u1173\u11e2',                          // '쯔ᇢ'
    '\uf246': '\u110d\u1173\u11eb',                          // '쯔ᇫ'
    '\uf247': '\u110d\u1173\u11f0',                          // '쯔ᇰ'
    '\uf248': '\u110d\u1173\ud7f9',                          // '쯔ퟹ'
    '\uf249': '\u110d\u1174\u11f0',                          // '쯰ᇰ'
    '\uf24a': '\u110d\u1174\u11f9',                          // '쯰ᇹ'
    '\uf24b': '\u110d\u1175\u11d9',                          // '찌ᇙ'
    '\uf24c': '\u110d\u1175\u11e2',                          // '찌ᇢ'
    '\uf24d': '\u110d\u1175\u11eb',                          // '찌ᇫ'
    '\uf24e': '\u110d\u1175\u11f0',                          // '찌ᇰ'
    '\uf24f': '\u110d\u1175\u11f9',                          // '찌ᇹ'
    '\uf250': '\u110d\u119e',                                // 'ᄍᆞ'
    '\uf251': '\u110d\u119e\u11a8',                          // 'ᄍᆞᆨ'
    '\uf252': '\u110d\u119e\u11ab',                          // 'ᄍᆞᆫ'
    '\uf253': '\u110d\u119e\u11af',                          // 'ᄍᆞᆯ'
    '\uf254': '\u110d\u119e\u11b7',                          // 'ᄍᆞᆷ'
    '\uf255': '\u110d\u119e\u11b8',                          // 'ᄍᆞᆸ'
    '\uf256': '\u110d\u119e\u11e6',                          // 'ᄍᆞᇦ'
    '\uf257': '\u110d\u119e\u11ba',                          // 'ᄍᆞᆺ'
    '\uf258': '\u110d\u119e\u11bc',                          // 'ᄍᆞᆼ'
    '\uf259': '\u110d\u119e\u11f0',                          // 'ᄍᆞᇰ'
    '\uf25a': '\u110d\u11a1',                                // 'ᄍᆡ'
    '\uf25b': '\u110d\u11a1\u11a8',                          // 'ᄍᆡᆨ'
    '\uf25c': '\u110d\u11a1\u11ab',                          // 'ᄍᆡᆫ'
    '\uf25d': '\u110d\u11a1\u11af',                          // 'ᄍᆡᆯ'
    '\uf25e': '\u110d\u11a1\u11d9',                          // 'ᄍᆡᇙ'
    '\uf25f': '\u110d\u11a1\u11b7',                          // 'ᄍᆡᆷ'
    '\uf260': '\u110d\u11a1\u11bc',                          // 'ᄍᆡᆼ'
    '\uf261': '\u110d\u11a1\u11f0',                          // 'ᄍᆡᇰ'
    '\uf262': '\u110d\u11a1\u11f9',                          // 'ᄍᆡᇹ'
    '\uf263': '\ua978\u1165',                                // 'ꥸᅥ'
    '\uf264': '\ua978\u1166',                                // 'ꥸᅦ'
    '\uf265': '\ua978\u1166\u11af',                          // 'ꥸᅦᆯ'
    '\uf266': '\ua978\u1166\u11d0',                          // 'ꥸᅦᇐ'
    '\uf267': '\ua978\u1166\ud7d7',                          // 'ꥸᅦퟗ'
    '\uf268': '\u114e\u1161',                                // 'ᅎᅡ'
    '\uf269': '\u114e\u1161\u11ab',                          // 'ᅎᅡᆫ'
    '\uf26a': '\u114e\u1161\u11b7',                          // 'ᅎᅡᆷ'
    '\uf26b': '\u114e\u1161\u11e2',                          // 'ᅎᅡᇢ'
    '\uf26c': '\u114e\u1162',                                // 'ᅎᅢ'
    '\uf26d': '\u114e\u1163',                                // 'ᅎᅣ'
    '\uf26e': '\u114e\u1163\u11bc',                          // 'ᅎᅣᆼ'
    '\uf26f': '\u114e\u1165',                                // 'ᅎᅥ'
    '\uf270': '\u114e\u1165\u11ab',                          // 'ᅎᅥᆫ'
    '\uf271': '\u114e\u1167',                                // 'ᅎᅧ'
    '\uf272': '\u114e\u1167\u11ab',                          // 'ᅎᅧᆫ'
    '\uf273': '\u114e\u1167\u11f9',                          // 'ᅎᅧᇹ'
    '\uf274': '\u114e\u1168',                                // 'ᅎᅨ'
    '\uf275': '\u114e\u1169',                                // 'ᅎᅩ'
    '\uf276': '\u114e\u116a',                                // 'ᅎᅪ'
    '\uf277': '\u114e\u116d',                                // 'ᅎᅭ'
    '\uf278': '\u114e\u1184',                                // 'ᅎᆄ'
    '\uf279': '\u114e\u116e',                                // 'ᅎᅮ'
    '\uf27a': '\u114e\u116e\u11ab',                          // 'ᅎᅮᆫ'
    '\uf27b': '\u114e\u116e\u11bc',                          // 'ᅎᅮᆼ'
    '\uf27c': '\u114e\u1171',                                // 'ᅎᅱ'
    '\uf27d': '\u114e\u1172',                                // 'ᅎᅲ'
    '\uf27e': '\u114e\u1172\u11ab',                          // 'ᅎᅲᆫ'
    '\uf27f': '\u114e\u1172\u11bc',                          // 'ᅎᅲᆼ'
    '\uf280': '\u114e\u1191',                                // 'ᅎᆑ'
    '\uf281': '\u114e\u1191\u11ab',                          // 'ᅎᆑᆫ'
    '\uf282': '\u114e\u1173',                                // 'ᅎᅳ'
    '\uf283': '\u114e\u1173\u11ab',                          // 'ᅎᅳᆫ'
    '\uf284': '\u114e\u1173\u11ba',                          // 'ᅎᅳᆺ'
    '\uf285': '\u114e\u1173\u11eb',                          // 'ᅎᅳᇫ'
    '\uf286': '\u114e\u1175',                                // 'ᅎᅵ'
    '\uf287': '\u114e\u1175\u11ab',                          // 'ᅎᅵᆫ'
    '\uf288': '\u114e\u1175\u11b7',                          // 'ᅎᅵᆷ'
    '\uf289': '\u114e\u1175\u11e2',                          // 'ᅎᅵᇢ'
    '\uf28a': '\u114e\u1175\u11eb',                          // 'ᅎᅵᇫ'
    '\uf28b': '\u114f\u1161',                                // 'ᅏᅡ'
    '\uf28c': '\u114f\u1161\u11ab',                          // 'ᅏᅡᆫ'
    '\uf28d': '\u114f\u1161\u11b7',                          // 'ᅏᅡᆷ'
    '\uf28e': '\u114f\u1161\u11e2',                          // 'ᅏᅡᇢ'
    '\uf28f': '\u114f\u1161\u11f0',                          // 'ᅏᅡᇰ'
    '\uf290': '\u114f\u1162',                                // 'ᅏᅢ'
    '\uf291': '\u114f\u1163',                                // 'ᅏᅣ'
    '\uf292': '\u114f\u1163\u11b7',                          // 'ᅏᅣᆷ'
    '\uf293': '\u114f\u1163\u11f0',                          // 'ᅏᅣᇰ'
    '\uf294': '\u114f\u1165',                                // 'ᅏᅥ'
    '\uf295': '\u114f\u1165\u11ab',                          // 'ᅏᅥᆫ'
    '\uf296': '\u114f\u1167',                                // 'ᅏᅧ'
    '\uf297': '\u114f\u1167\u11ab',                          // 'ᅏᅧᆫ'
    '\uf298': '\u114f\u1168',                                // 'ᅏᅨ'
    '\uf299': '\u114f\u116e',                                // 'ᅏᅮ'
    '\uf29a': '\u114f\u1171',                                // 'ᅏᅱ'
    '\uf29b': '\u114f\u1172',                                // 'ᅏᅲ'
    '\uf29c': '\u114f\u1172\u11f0',                          // 'ᅏᅲᇰ'
    '\uf29d': '\u114f\u1191',                                // 'ᅏᆑ'
    '\uf29e': '\u114f\u1191\u11ab',                          // 'ᅏᆑᆫ'
    '\uf29f': '\u114f\u1173',                                // 'ᅏᅳ'
    '\uf2a0': '\u114f\u1173\u11eb',                          // 'ᅏᅳᇫ'
    '\uf2a1': '\u114f\u1173\u11f0',                          // 'ᅏᅳᇰ'
    '\uf2a2': '\u114f\u1175',                                // 'ᅏᅵ'
    '\uf2a3': '\u114f\u1175\u11ab',                          // 'ᅏᅵᆫ'
    '\uf2a4': '\u114f\u119e',                                // 'ᅏᆞ'
    '\uf2a5': '\u114f\u11a1',                                // 'ᅏᆡ'
    '\uf2a6': '\u1150\u1161',                                // 'ᅐᅡ'
    '\uf2a7': '\u1150\u1161\u11ab',                          // 'ᅐᅡᆫ'
    '\uf2a8': '\u1150\u1161\u11ae',                          // 'ᅐᅡᆮ'
    '\uf2a9': '\u1150\u1161\u11b7',                          // 'ᅐᅡᆷ'
    '\uf2aa': '\u1150\u1161\u11e2',                          // 'ᅐᅡᇢ'
    '\uf2ab': '\u1150\u1161\u11b8',                          // 'ᅐᅡᆸ'
    '\uf2ac': '\u1150\u1162',                                // 'ᅐᅢ'
    '\uf2ad': '\u1150\u1163',                                // 'ᅐᅣ'
    '\uf2ae': '\u1150\u1163\u11b7',                          // 'ᅐᅣᆷ'
    '\uf2af': '\u1150\u1163\u11e2',                          // 'ᅐᅣᇢ'
    '\uf2b0': '\u1150\u1163\u11f0',                          // 'ᅐᅣᇰ'
    '\uf2b1': '\u1150\u1167',                                // 'ᅐᅧ'
    '\uf2b2': '\u1150\u1167\u11ab',                          // 'ᅐᅧᆫ'
    '\uf2b3': '\u1150\u1169',                                // 'ᅐᅩ'
    '\uf2b4': '\u1150\u116a',                                // 'ᅐᅪ'
    '\uf2b5': '\u1150\u116a\u11bc',                          // 'ᅐᅪᆼ'
    '\uf2b6': '\u1150\u116e',                                // 'ᅐᅮ'
    '\uf2b7': '\u1150\u116e\u11bc',                          // 'ᅐᅮᆼ'
    '\uf2b8': '\u1150\u116e\u11f9',                          // 'ᅐᅮᇹ'
    '\uf2b9': '\u1150\u1172',                                // 'ᅐᅲ'
    '\uf2ba': '\u1150\u1172\u11a8',                          // 'ᅐᅲᆨ'
    '\uf2bb': '\u1150\u1172\u11ab',                          // 'ᅐᅲᆫ'
    '\uf2bc': '\u1150\u1172\u11f0',                          // 'ᅐᅲᇰ'
    '\uf2bd': '\u1150\u1191',                                // 'ᅐᆑ'
    '\uf2be': '\u1150\u1191\u11ab',                          // 'ᅐᆑᆫ'
    '\uf2bf': '\u1150\u1173',                                // 'ᅐᅳ'
    '\uf2c0': '\u1150\u1173\u11b7',                          // 'ᅐᅳᆷ'
    '\uf2c1': '\u1150\u1173\u11eb',                          // 'ᅐᅳᇫ'
    '\uf2c2': '\u1150\u1175',                                // 'ᅐᅵ'
    '\uf2c3': '\u1150\u1175\u11ab',                          // 'ᅐᅵᆫ'
    '\uf2c4': '\u1150\u1175\u11eb',                          // 'ᅐᅵᇫ'
    '\uf2c5': '\u1150\u1175\u11f0',                          // 'ᅐᅵᇰ'
    '\uf2c6': '\u1151\u1161',                                // 'ᅑᅡ'
    '\uf2c7': '\u1151\u1161\u11ab',                          // 'ᅑᅡᆫ'
    '\uf2c8': '\u1151\u1161\u11c2',                          // 'ᅑᅡᇂ'
    '\uf2c9': '\u1151\u1162',                                // 'ᅑᅢ'
    '\uf2ca': '\u1151\u1163',                                // 'ᅑᅣ'
    '\uf2cb': '\u1151\u1163\u11b7',                          // 'ᅑᅣᆷ'
    '\uf2cc': '\u1151\u1163\u11e2',                          // 'ᅑᅣᇢ'
    '\uf2cd': '\u1151\u1163\u11b8',                          // 'ᅑᅣᆸ'
    '\uf2ce': '\u1151\u1163\u11f0',                          // 'ᅑᅣᇰ'
    '\uf2cf': '\u1151\u1167',                                // 'ᅑᅧ'
    '\uf2d0': '\u1151\u1167\u11ab',                          // 'ᅑᅧᆫ'
    '\uf2d1': '\u1151\u116e',                                // 'ᅑᅮ'
    '\uf2d2': '\u1151\u1171',                                // 'ᅑᅱ'
    '\uf2d3': '\u1151\u1172',                                // 'ᅑᅲ'
    '\uf2d4': '\u1151\u1172\u11f0',                          // 'ᅑᅲᇰ'
    '\uf2d5': '\u1151\u1191',                                // 'ᅑᆑ'
    '\uf2d6': '\u1151\u1191\u11ab',                          // 'ᅑᆑᆫ'
    '\uf2d7': '\u1151\u1173',                                // 'ᅑᅳ'
    '\uf2d8': '\u1151\u1173\u11b7',                          // 'ᅑᅳᆷ'
    '\uf2d9': '\u1151\u1173\u11e2',                          // 'ᅑᅳᇢ'
    '\uf2da': '\u1151\u1175',                                // 'ᅑᅵ'
    '\uf2db': '\u1151\u1175\u11ab',                          // 'ᅑᅵᆫ'
    '\uf2dc': '\u1151\u1175\u11b7',                          // 'ᅑᅵᆷ'
    '\uf2dd': '\u1151\u1175\u11eb',                          // 'ᅑᅵᇫ'
    '\uf2de': '\u1151\u1175\u11f0',                          // 'ᅑᅵᇰ'
    '\uf2df': '\u110e\u1161\u11d9',                          // '차ᇙ'
    '\uf2e0': '\u110e\u1161\u11e2',                          // '차ᇢ'
    '\uf2e1': '\u110e\u1161\u11e6',                          // '차ᇦ'
    '\uf2e2': '\u110e\u1161\u11f0',                          // '차ᇰ'
    '\uf2e3': '\u110e\u1161\u11f9',                          // '차ᇹ'
    '\uf2e4': '\u110e\u1176',                                // 'ᄎᅶ'
    '\uf2e5': '\u110e\u1163\u11d9',                          // '챠ᇙ'
    '\uf2e6': '\u110e\u1163\u11e6',                          // '챠ᇦ'
    '\uf2e7': '\u110e\u1163\u11f0',                          // '챠ᇰ'
    '\uf2e8': '\u110e\u1163\u11f1',                          // '챠ᇱ'
    '\uf2e9': '\u110e\u1178',                                // 'ᄎᅸ'
    '\uf2ea': '\u110e\u1178\u11af',                          // 'ᄎᅸᆯ'
    '\uf2eb': '\u110e\u11a4',                                // 'ᄎᆤ'
    '\uf2ec': '\u110e\u1165\u11eb',                          // '처ᇫ'
    '\uf2ed': '\u110e\u1165\u11f0',                          // '처ᇰ'
    '\uf2ee': '\u110e\u117c',                                // 'ᄎᅼ'
    '\uf2ef': '\u110e\u117c\u11ab',                          // 'ᄎᅼᆫ'
    '\uf2f0': '\u110e\u1167\ud7cd',                          // '쳐ퟍ'
    '\uf2f1': '\u110e\u1167\ud7ce',                          // '쳐ퟎ'
    '\uf2f2': '\u110e\u1167\ud7cf',                          // '쳐ퟏ'
    '\uf2f3': '\u110e\u1167\u11d9',                          // '쳐ᇙ'
    '\uf2f4': '\u110e\u1167\u11e2',                          // '쳐ᇢ'
    '\uf2f5': '\u110e\u1167\u11f0',                          // '쳐ᇰ'
    '\uf2f6': '\u110e\u1167\u11f1',                          // '쳐ᇱ'
    '\uf2f7': '\u110e\u1167\u11f9',                          // '쳐ᇹ'
    '\uf2f8': '\u110e\u117d',                                // 'ᄎᅽ'
    '\uf2f9': '\u110e\u117e',                                // 'ᄎᅾ'
    '\uf2fa': '\u110e\u1169\u11d9',                          // '초ᇙ'
    '\uf2fb': '\u110e\u1169\u11e2',                          // '초ᇢ'
    '\uf2fc': '\u110e\u1169\ud7e8',                          // '초ퟨ'
    '\uf2fd': '\u110e\u1169\u11eb',                          // '초ᇫ'
    '\uf2fe': '\u110e\u1169\u11f0',                          // '초ᇰ'
    '\uf2ff': '\u110e\u116a\u11d9',                          // '촤ᇙ'
    '\uf300': '\u110e\u116a\u11f0',                          // '촤ᇰ'
    '\uf301': '\u110e\u1182',                                // 'ᄎᆂ'
    '\uf302': '\u110e\u116d\u11e2',                          // '쵸ᇢ'
    '\uf303': '\u110e\u116d\u11f0',                          // '쵸ᇰ'
    '\uf304': '\u110e\u1187',                                // 'ᄎᆇ'
    '\uf305': '\u110e\u1188',                                // 'ᄎᆈ'
    '\uf306': '\u110e\u1188\u11ab',                          // 'ᄎᆈᆫ'
    '\uf307': '\u110e\u116e\u11d9',                          // '추ᇙ'
    '\uf308': '\u110e\u116e\u11e0',                          // '추ᇠ'
    '\uf309': '\u110e\u116e\u11e2',                          // '추ᇢ'
    '\uf30a': '\u110e\u116e\u11f0',                          // '추ᇰ'
    '\uf30b': '\u110e\u116e\u11f9',                          // '추ᇹ'
    '\uf30c': '\u110e\u1189',                                // 'ᄎᆉ'
    '\uf30d': '\u110e\u1189\u11ab',                          // 'ᄎᆉᆫ'
    '\uf30e': '\u110e\u1189\u11bc',                          // 'ᄎᆉᆼ'
    '\uf30f': '\u110e\u118a',                                // 'ᄎᆊ'
    '\uf310': '\u110e\u116f\u11f9',                          // '춰ᇹ'
    '\uf311': '\u110e\ud7b5',                                // 'ᄎힵ'
    '\uf312': '\u110e\u118c',                                // 'ᄎᆌ'
    '\uf313': '\u110e\u1171\u11d9',                          // '취ᇙ'
    '\uf314': '\u110e\u1171\u11f9',                          // '취ᇹ'
    '\uf315': '\u110e\u1172\u11d9',                          // '츄ᇙ'
    '\uf316': '\u110e\u1172\u11e2',                          // '츄ᇢ'
    '\uf317': '\u110e\u1172\u11f0',                          // '츄ᇰ'
    '\uf318': '\u110e\u1172\u11f9',                          // '츄ᇹ'
    '\uf319': '\u110e\u118e',                                // 'ᄎᆎ'
    '\uf31a': '\u110e\u118e\u11ab',                          // 'ᄎᆎᆫ'
    '\uf31b': '\u110e\u118f',                                // 'ᄎᆏ'
    '\uf31c': '\u110e\u118f\u11b7',                          // 'ᄎᆏᆷ'
    '\uf31d': '\u110e\u1190',                                // 'ᄎᆐ'
    '\uf31e': '\u110e\u1191',                                // 'ᄎᆑ'
    '\uf31f': '\u110e\u1191\u11ab',                          // 'ᄎᆑᆫ'
    '\uf320': '\u110e\u1191\u11af',                          // 'ᄎᆑᆯ'
    '\uf321': '\u110e\u1191\u11d9',                          // 'ᄎᆑᇙ'
    '\uf322': '\u110e\u1191\u11b7',                          // 'ᄎᆑᆷ'
    '\uf323': '\u110e\u1192',                                // 'ᄎᆒ'
    '\uf324': '\u110e\u1192\u11bc',                          // 'ᄎᆒᆼ'
    '\uf325': '\u110e\u1194',                                // 'ᄎᆔ'
    '\uf326': '\u110e\u1194\u11ab',                          // 'ᄎᆔᆫ'
    '\uf327': '\u110e\u1194\u11af',                          // 'ᄎᆔᆯ'
    '\uf328': '\u110e\u1194\u11bc',                          // 'ᄎᆔᆼ'
    '\uf329': '\u110e\u1173\ud7db',                          // '츠ퟛ'
    '\uf32a': '\u110e\u1173\u11d9',                          // '츠ᇙ'
    '\uf32b': '\u110e\u1173\ud7dd',                          // '츠ퟝ'
    '\uf32c': '\u110e\u1173\u11e2',                          // '츠ᇢ'
    '\uf32d': '\u110e\u1173\u11eb',                          // '츠ᇫ'
    '\uf32e': '\u110e\u1173\u11f0',                          // '츠ᇰ'
    '\uf32f': '\u110e\u1195',                                // 'ᄎᆕ'
    '\uf330': '\u110e\u1196',                                // 'ᄎᆖ'
    '\uf331': '\u110e\u1174\u11f9',                          // '츼ᇹ'
    '\uf332': '\u110e\u1175\u11d9',                          // '치ᇙ'
    '\uf333': '\u110e\u1175\u11e2',                          // '치ᇢ'
    '\uf334': '\u110e\u1175\ud7e3',                          // '치ퟣ'
    '\uf335': '\u110e\u1175\u11e6',                          // '치ᇦ'
    '\uf336': '\u110e\u1175\u11eb',                          // '치ᇫ'
    '\uf337': '\u110e\u1175\u11f0',                          // '치ᇰ'
    '\uf338': '\u110e\u1175\u11f9',                          // '치ᇹ'
    '\uf339': '\u110e\u1198',                                // 'ᄎᆘ'
    '\uf33a': '\u110e\u1199',                                // 'ᄎᆙ'
    '\uf33b': '\u110e\u1199\u11ab',                          // 'ᄎᆙᆫ'
    '\uf33c': '\u110e\u1199\u11bc',                          // 'ᄎᆙᆼ'
    '\uf33d': '\u110e\ud7bf',                                // 'ᄎힿ'
    '\uf33e': '\u110e\ud7c0',                                // 'ᄎퟀ'
    '\uf33f': '\u110e\ud7c2',                                // 'ᄎퟂ'
    '\uf340': '\u110e\u119b',                                // 'ᄎᆛ'
    '\uf341': '\u110e\u119e',                                // 'ᄎᆞ'
    '\uf342': '\u110e\u119e\u11a8',                          // 'ᄎᆞᆨ'
    '\uf343': '\u110e\u119e\u11ab',                          // 'ᄎᆞᆫ'
    '\uf344': '\u110e\u119e\u11ae',                          // 'ᄎᆞᆮ'
    '\uf345': '\u110e\u119e\u11af',                          // 'ᄎᆞᆯ'
    '\uf346': '\u110e\u119e\u11ce',                          // 'ᄎᆞᇎ'
    '\uf347': '\u110e\u119e\u11b1',                          // 'ᄎᆞᆱ'
    '\uf348': '\u110e\u119e\u11b3',                          // 'ᄎᆞᆳ'
    '\uf349': '\u110e\u119e\u11b6',                          // 'ᄎᆞᆶ'
    '\uf34a': '\u110e\u119e\u11d9',                          // 'ᄎᆞᇙ'
    '\uf34b': '\u110e\u119e\u11b7',                          // 'ᄎᆞᆷ'
    '\uf34c': '\u110e\u119e\u11dd',                          // 'ᄎᆞᇝ'
    '\uf34d': '\u110e\u119e\u11b8',                          // 'ᄎᆞᆸ'
    '\uf34e': '\u110e\u119e\u11ba',                          // 'ᄎᆞᆺ'
    '\uf34f': '\u110e\u119e\u11bb',                          // 'ᄎᆞᆻ'
    '\uf350': '\u110e\u119e\u11bc',                          // 'ᄎᆞᆼ'
    '\uf351': '\u110e\u119e\u11bd',                          // 'ᄎᆞᆽ'
    '\uf352': '\u110e\u119e\u11be',                          // 'ᄎᆞᆾ'
    '\uf353': '\u110e\u11a1',                                // 'ᄎᆡ'
    '\uf354': '\u110e\u11a1\u11a8',                          // 'ᄎᆡᆨ'
    '\uf355': '\u110e\u11a1\u11ab',                          // 'ᄎᆡᆫ'
    '\uf356': '\u110e\u11a1\u11af',                          // 'ᄎᆡᆯ'
    '\uf357': '\u110e\u11a1\u11b7',                          // 'ᄎᆡᆷ'
    '\uf358': '\u110e\u11a1\u11ba',                          // 'ᄎᆡᆺ'
    '\uf359': '\u110e\u11a1\u11bc',                          // 'ᄎᆡᆼ'
    '\uf35a': '\u110e\u11a1\u11f0',                          // 'ᄎᆡᇰ'
    '\uf35b': '\u1152\u116e',                                // 'ᅒᅮ'
    '\uf35c': '\u1152\u116f',                                // 'ᅒᅯ'
    '\uf35d': '\u1153\u1175',                                // 'ᅓᅵ'
    '\uf35e': '\u1153\u1175\u11af',                          // 'ᅓᅵᆯ'
    '\uf35f': '\u1154\u1161',                                // 'ᅔᅡ'
    '\uf360': '\u1154\u1161\u11ab',                          // 'ᅔᅡᆫ'
    '\uf361': '\u1154\u1176',                                // 'ᅔᅶ'
    '\uf362': '\u1154\u1162',                                // 'ᅔᅢ'
    '\uf363': '\u1154\u1165',                                // 'ᅔᅥ'
    '\uf364': '\u1154\u1165\u11ab',                          // 'ᅔᅥᆫ'
    '\uf365': '\u1154\u1167',                                // 'ᅔᅧ'
    '\uf366': '\u1154\u1167\u11ab',                          // 'ᅔᅧᆫ'
    '\uf367': '\u1154\u1168',                                // 'ᅔᅨ'
    '\uf368': '\u1154\u1169',                                // 'ᅔᅩ'
    '\uf369': '\u1154\u116d',                                // 'ᅔᅭ'
    '\uf36a': '\u1154\ud7b2',                                // 'ᅔힲ'
    '\uf36b': '\u1154\u1184',                                // 'ᅔᆄ'
    '\uf36c': '\u1154\u116e',                                // 'ᅔᅮ'
    '\uf36d': '\u1154\u116e\u11ab',                          // 'ᅔᅮᆫ'
    '\uf36e': '\u1154\u116e\u11bc',                          // 'ᅔᅮᆼ'
    '\uf36f': '\u1154\u1171',                                // 'ᅔᅱ'
    '\uf370': '\u1154\u1172',                                // 'ᅔᅲ'
    '\uf371': '\u1154\u1172\u11ab',                          // 'ᅔᅲᆫ'
    '\uf372': '\u1154\u1191',                                // 'ᅔᆑ'
    '\uf373': '\u1154\u1191\u11ab',                          // 'ᅔᆑᆫ'
    '\uf374': '\u1154\u1173',                                // 'ᅔᅳ'
    '\uf375': '\u1154\u1175',                                // 'ᅔᅵ'
    '\uf376': '\u1154\u1175\u11ab',                          // 'ᅔᅵᆫ'
    '\uf377': '\u1154\u1175\u11eb',                          // 'ᅔᅵᇫ'
    '\uf378': '\u1154\u1175\u11f0',                          // 'ᅔᅵᇰ'
    '\uf379': '\u1154\u1175\u11f9',                          // 'ᅔᅵᇹ'
    '\uf37a': '\u1155\u1161',                                // 'ᅕᅡ'
    '\uf37b': '\u1155\u1161\u11ab',                          // 'ᅕᅡᆫ'
    '\uf37c': '\u1155\u1161\u11b7',                          // 'ᅕᅡᆷ'
    '\uf37d': '\u1155\u1161\u11e2',                          // 'ᅕᅡᇢ'
    '\uf37e': '\u1155\u1161\u11f9',                          // 'ᅕᅡᇹ'
    '\uf37f': '\u1155\u1162',                                // 'ᅕᅢ'
    '\uf380': '\u1155\u1163',                                // 'ᅕᅣ'
    '\uf381': '\u1155\u1163\u11f0',                          // 'ᅕᅣᇰ'
    '\uf382': '\u1155\u1167',                                // 'ᅕᅧ'
    '\uf383': '\u1155\u1167\u11ab',                          // 'ᅕᅧᆫ'
    '\uf384': '\u1155\u1169',                                // 'ᅕᅩ'
    '\uf385': '\u1155\u116a',                                // 'ᅕᅪ'
    '\uf386': '\u1155\u116a\u11ab',                          // 'ᅕᅪᆫ'
    '\uf387': '\u1155\u116b',                                // 'ᅕᅫ'
    '\uf388': '\u1155\u116e',                                // 'ᅕᅮ'
    '\uf389': '\u1155\u1171',                                // 'ᅕᅱ'
    '\uf38a': '\u1155\u1172',                                // 'ᅕᅲ'
    '\uf38b': '\u1155\u1172\u11a8',                          // 'ᅕᅲᆨ'
    '\uf38c': '\u1155\u1172\u11ab',                          // 'ᅕᅲᆫ'
    '\uf38d': '\u1155\u1172\u11bc',                          // 'ᅕᅲᆼ'
    '\uf38e': '\u1155\u1172\u11f0',                          // 'ᅕᅲᇰ'
    '\uf38f': '\u1155\u1191',                                // 'ᅕᆑ'
    '\uf390': '\u1155\u1191\u11ab',                          // 'ᅕᆑᆫ'
    '\uf391': '\u1155\u1173',                                // 'ᅕᅳ'
    '\uf392': '\u1155\u1173\u11ab',                          // 'ᅕᅳᆫ'
    '\uf393': '\u1155\u1173\u11eb',                          // 'ᅕᅳᇫ'
    '\uf394': '\u1155\u1175',                                // 'ᅕᅵ'
    '\uf395': '\u1155\u1175\u11ab',                          // 'ᅕᅵᆫ'
    '\uf396': '\u1155\u1175\u11b7',                          // 'ᅕᅵᆷ'
    '\uf397': '\u1155\u1175\u11e2',                          // 'ᅕᅵᇢ'
    '\uf398': '\u1155\u1175\u11eb',                          // 'ᅕᅵᇫ'
    '\uf399': '\u1155\u1175\u11f9',                          // 'ᅕᅵᇹ'
    '\uf39a': '\u1155\u119b',                                // 'ᅕᆛ'
    '\uf39b': '\u110f\u1161\u11d0',                          // '카ᇐ'
    '\uf39c': '\u110f\u1161\u11d9',                          // '카ᇙ'
    '\uf39d': '\u110f\u1161\u11e2',                          // '카ᇢ'
    '\uf39e': '\u110f\u1161\u11e6',                          // '카ᇦ'
    '\uf39f': '\u110f\u1161\u11f0',                          // '카ᇰ'
    '\uf3a0': '\u110f\u1176',                                // 'ᄏᅶ'
    '\uf3a1': '\u110f\u1163\u11e2',                          // '캬ᇢ'
    '\uf3a2': '\u110f\u1163\u11f0',                          // '캬ᇰ'
    '\uf3a3': '\u110f\u1163\u11f9',                          // '캬ᇹ'
    '\uf3a4': '\u110f\u1178',                                // 'ᄏᅸ'
    '\uf3a5': '\u110f\u1165\u11d9',                          // '커ᇙ'
    '\uf3a6': '\u110f\u1165\u11f9',                          // '커ᇹ'
    '\uf3a7': '\u110f\u117a',                                // 'ᄏᅺ'
    '\uf3a8': '\u110f\u117b',                                // 'ᄏᅻ'
    '\uf3a9': '\u110f\u1167\u11d9',                          // '켜ᇙ'
    '\uf3aa': '\u110f\u1167\u11e2',                          // '켜ᇢ'
    '\uf3ab': '\u110f\u1167\u11f0',                          // '켜ᇰ'
    '\uf3ac': '\u110f\u1167\u11f9',                          // '켜ᇹ'
    '\uf3ad': '\u110f\u117e',                                // 'ᄏᅾ'
    '\uf3ae': '\u110f\u1169\u11d9',                          // '코ᇙ'
    '\uf3af': '\u110f\u1169\u11e2',                          // '코ᇢ'
    '\uf3b0': '\u110f\u1169\u11f0',                          // '코ᇰ'
    '\uf3b1': '\u110f\u116a\u11d9',                          // '콰ᇙ'
    '\uf3b2': '\u110f\u116a\u11f0',                          // '콰ᇰ'
    '\uf3b3': '\u110f\u1182',                                // 'ᄏᆂ'
    '\uf3b4': '\u110f\u116c\u11f0',                          // '쾨ᇰ'
    '\uf3b5': '\u110f\u116d\u11d9',                          // '쿄ᇙ'
    '\uf3b6': '\u110f\u116d\u11e2',                          // '쿄ᇢ'
    '\uf3b7': '\u110f\u1187',                                // 'ᄏᆇ'
    '\uf3b8': '\u110f\u116e\u11d9',                          // '쿠ᇙ'
    '\uf3b9': '\u110f\u116e\u11e2',                          // '쿠ᇢ'
    '\uf3ba': '\u110f\u116e\u11f0',                          // '쿠ᇰ'
    '\uf3bb': '\u110f\u116e\u11f9',                          // '쿠ᇹ'
    '\uf3bc': '\u110f\u1189',                                // 'ᄏᆉ'
    '\uf3bd': '\u110f\u1189\u11ab',                          // 'ᄏᆉᆫ'
    '\uf3be': '\u110f\u1189\u11bc',                          // 'ᄏᆉᆼ'
    '\uf3bf': '\u110f\u118a',                                // 'ᄏᆊ'
    '\uf3c0': '\u110f\u116f\u11d9',                          // '쿼ᇙ'
    '\uf3c1': '\u110f\u116f\u11f9',                          // '쿼ᇹ'
    '\uf3c2': '\u110f\ud7b5',                                // 'ᄏힵ'
    '\uf3c3': '\u110f\ud7b5\u11ab',                          // 'ᄏힵᆫ'
    '\uf3c4': '\u110f\u118c',                                // 'ᄏᆌ'
    '\uf3c5': '\u110f\u1172\u11f0',                          // '큐ᇰ'
    '\uf3c6': '\u110f\u1172\u11f9',                          // '큐ᇹ'
    '\uf3c7': '\u110f\u118f',                                // 'ᄏᆏ'
    '\uf3c8': '\u110f\u1190',                                // 'ᄏᆐ'
    '\uf3c9': '\u110f\u1191',                                // 'ᄏᆑ'
    '\uf3ca': '\u110f\u1191\u11a8',                          // 'ᄏᆑᆨ'
    '\uf3cb': '\u110f\u1191\u11ab',                          // 'ᄏᆑᆫ'
    '\uf3cc': '\u110f\u1191\u11af',                          // 'ᄏᆑᆯ'
    '\uf3cd': '\u110f\u1191\u11d9',                          // 'ᄏᆑᇙ'
    '\uf3ce': '\u110f\u1191\u11f0',                          // 'ᄏᆑᇰ'
    '\uf3cf': '\u110f\u1191\u11f9',                          // 'ᄏᆑᇹ'
    '\uf3d0': '\u110f\u1192',                                // 'ᄏᆒ'
    '\uf3d1': '\u110f\u1192\u11bc',                          // 'ᄏᆒᆼ'
    '\uf3d2': '\u110f\u1194',                                // 'ᄏᆔ'
    '\uf3d3': '\u110f\u1194\u11bc',                          // 'ᄏᆔᆼ'
    '\uf3d4': '\u110f\u1173\u11d9',                          // '크ᇙ'
    '\uf3d5': '\u110f\u1173\u11e2',                          // '크ᇢ'
    '\uf3d6': '\u110f\u1173\u11f0',                          // '크ᇰ'
    '\uf3d7': '\u110f\u1195',                                // 'ᄏᆕ'
    '\uf3d8': '\u110f\u1196',                                // 'ᄏᆖ'
    '\uf3d9': '\u110f\u1174\u11f9',                          // '킈ᇹ'
    '\uf3da': '\u110f\u1175\u11d9',                          // '키ᇙ'
    '\uf3db': '\u110f\u1175\u11f0',                          // '키ᇰ'
    '\uf3dc': '\u110f\u1175\u11f9',                          // '키ᇹ'
    '\uf3dd': '\u110f\u1198',                                // 'ᄏᆘ'
    '\uf3de': '\u110f\u1199',                                // 'ᄏᆙ'
    '\uf3df': '\u110f\u1199\u11ab',                          // 'ᄏᆙᆫ'
    '\uf3e0': '\u110f\u1199\u11bc',                          // 'ᄏᆙᆼ'
    '\uf3e1': '\u110f\ud7bd',                                // 'ᄏힽ'
    '\uf3e2': '\u110f\ud7bf',                                // 'ᄏힿ'
    '\uf3e3': '\u110f\ud7c0',                                // 'ᄏퟀ'
    '\uf3e4': '\u110f\u119a',                                // 'ᄏᆚ'
    '\uf3e5': '\u110f\ud7c2',                                // 'ᄏퟂ'
    '\uf3e6': '\u110f\u119b',                                // 'ᄏᆛ'
    '\uf3e7': '\u110f\u119b\u11af',                          // 'ᄏᆛᆯ'
    '\uf3e8': '\u110f\ud7c3',                                // 'ᄏퟃ'
    '\uf3e9': '\u110f\ud7c3\u11ab',                          // 'ᄏퟃᆫ'
    '\uf3ea': '\u110f\u119e',                                // 'ᄏᆞ'
    '\uf3eb': '\u110f\u119e\u11a8',                          // 'ᄏᆞᆨ'
    '\uf3ec': '\u110f\u119e\u11ab',                          // 'ᄏᆞᆫ'
    '\uf3ed': '\u110f\u119e\u11ae',                          // 'ᄏᆞᆮ'
    '\uf3ee': '\u110f\u119e\u11af',                          // 'ᄏᆞᆯ'
    '\uf3ef': '\u110f\u119e\u11b7',                          // 'ᄏᆞᆷ'
    '\uf3f0': '\u110f\u119e\u11b8',                          // 'ᄏᆞᆸ'
    '\uf3f1': '\u110f\u119e\u11ba',                          // 'ᄏᆞᆺ'
    '\uf3f2': '\u110f\u119e\u11c0',                          // 'ᄏᆞᇀ'
    '\uf3f3': '\u110f\u11a1',                                // 'ᄏᆡ'
    '\uf3f4': '\u110f\u11a1\u11a8',                          // 'ᄏᆡᆨ'
    '\uf3f5': '\u110f\u11a1\u11ab',                          // 'ᄏᆡᆫ'
    '\uf3f6': '\u110f\u11a1\u11af',                          // 'ᄏᆡᆯ'
    '\uf3f7': '\u110f\u11a1\u11b7',                          // 'ᄏᆡᆷ'
    '\uf3f8': '\u110f\u11a1\u11ba',                          // 'ᄏᆡᆺ'
    '\uf3f9': '\u110f\u11a1\u11bc',                          // 'ᄏᆡᆼ'
    '\uf3fa': '\u110f\u11a1\u11f0',                          // 'ᄏᆡᇰ'
    '\uf3fb': '\u1110\u1161\u11d9',                          // '타ᇙ'
    '\uf3fc': '\u1110\u1161\u11e2',                          // '타ᇢ'
    '\uf3fd': '\u1110\u1161\u11f0',                          // '타ᇰ'
    '\uf3fe': '\u1110\u1161\u11f9',                          // '타ᇹ'
    '\uf3ff': '\u1110\u1176',                                // 'ᄐᅶ'
    '\uf400': '\u1110\u1177',                                // 'ᄐᅷ'
    '\uf401': '\u1110\u1163\u11e2',                          // '탸ᇢ'
    '\uf402': '\u1110\u1163\u11f0',                          // '탸ᇰ'
    '\uf403': '\u1110\u1178',                                // 'ᄐᅸ'
    '\uf404': '\u1110\u1165\u11dd',                          // '터ᇝ'
    '\uf405': '\u1110\u1165\u11e6',                          // '터ᇦ'
    '\uf406': '\u1110\u1165\u11f0',                          // '터ᇰ'
    '\uf407': '\u1110\u1165\u11f1',                          // '터ᇱ'
    '\uf408': '\u1110\u117b',                                // 'ᄐᅻ'
    '\uf409': '\u1110\u117b\u11ae',                          // 'ᄐᅻᆮ'
    '\uf40a': '\u1110\u117b\u11af',                          // 'ᄐᅻᆯ'
    '\uf40b': '\u1110\u1167\u11d9',                          // '텨ᇙ'
    '\uf40c': '\u1110\u1167\u11f0',                          // '텨ᇰ'
    '\uf40d': '\u1110\u1167\u11f9',                          // '텨ᇹ'
    '\uf40e': '\u1110\u1168\u11f0',                          // '톄ᇰ'
    '\uf40f': '\u1110\u1169\u11d0',                          // '토ᇐ'
    '\uf410': '\u1110\u1169\u11d9',                          // '토ᇙ'
    '\uf411': '\u1110\u1169\u11e2',                          // '토ᇢ'
    '\uf412': '\u1110\u1169\ud7e5',                          // '토ퟥ'
    '\uf413': '\u1110\u1169\u11e7',                          // '토ᇧ'
    '\uf414': '\u1110\u1169\u11f0',                          // '토ᇰ'
    '\uf415': '\u1110\u116a\u11d9',                          // '톼ᇙ'
    '\uf416': '\u1110\u116a\u11f0',                          // '톼ᇰ'
    '\uf417': '\u1110\u1182',                                // 'ᄐᆂ'
    '\uf418': '\u1110\u1182\u11af',                          // 'ᄐᆂᆯ'
    '\uf419': '\u1110\u116d\u11d9',                          // '툐ᇙ'
    '\uf41a': '\u1110\u116d\u11e2',                          // '툐ᇢ'
    '\uf41b': '\u1110\u116d\u11f0',                          // '툐ᇰ'
    '\uf41c': '\u1110\u1188',                                // 'ᄐᆈ'
    '\uf41d': '\u1110\u116e\u11d0',                          // '투ᇐ'
    '\uf41e': '\u1110\u116e\u11d9',                          // '투ᇙ'
    '\uf41f': '\u1110\u116e\u11e2',                          // '투ᇢ'
    '\uf420': '\u1110\u116e\u11f0',                          // '투ᇰ'
    '\uf421': '\u1110\u116e\u11f9',                          // '투ᇹ'
    '\uf422': '\u1110\u1189',                                // 'ᄐᆉ'
    '\uf423': '\u1110\u1189\u11ab',                          // 'ᄐᆉᆫ'
    '\uf424': '\u1110\u116f\u11f9',                          // '퉈ᇹ'
    '\uf425': '\u1110\ud7b5',                                // 'ᄐힵ'
    '\uf426': '\u1110\u118c',                                // 'ᄐᆌ'
    '\uf427': '\u1110\u118d',                                // 'ᄐᆍ'
    '\uf428': '\u1110\u1172\u11d9',                          // '튜ᇙ'
    '\uf429': '\u1110\u1172\u11e2',                          // '튜ᇢ'
    '\uf42a': '\u1110\u1172\u11f0',                          // '튜ᇰ'
    '\uf42b': '\u1110\u1194',                                // 'ᄐᆔ'
    '\uf42c': '\u1110\u1173\u11d0',                          // '트ᇐ'
    '\uf42d': '\u1110\u1173\u11d7',                          // '트ᇗ'
    '\uf42e': '\u1110\u1173\u11d9',                          // '트ᇙ'
    '\uf42f': '\u1110\u1173\u11e2',                          // '트ᇢ'
    '\uf430': '\u1110\u1173\u11f0',                          // '트ᇰ'
    '\uf431': '\u1110\u1195',                                // 'ᄐᆕ'
    '\uf432': '\u1110\u1196',                                // 'ᄐᆖ'
    '\uf433': '\u1110\u1174\u11f9',                          // '틔ᇹ'
    '\uf434': '\u1110\u1175\u11d9',                          // '티ᇙ'
    '\uf435': '\u1110\u1175\u11e6',                          // '티ᇦ'
    '\uf436': '\u1110\u1175\u11f0',                          // '티ᇰ'
    '\uf437': '\u1110\u1198',                                // 'ᄐᆘ'
    '\uf438': '\u1110\u1199',                                // 'ᄐᆙ'
    '\uf439': '\u1110\u1199\u11ab',                          // 'ᄐᆙᆫ'
    '\uf43a': '\u1110\ud7bf',                                // 'ᄐힿ'
    '\uf43b': '\u1110\ud7c0',                                // 'ᄐퟀ'
    '\uf43c': '\u1110\u119e',                                // 'ᄐᆞ'
    '\uf43d': '\u1110\u119e\u11a8',                          // 'ᄐᆞᆨ'
    '\uf43e': '\u1110\u119e\u11ab',                          // 'ᄐᆞᆫ'
    '\uf43f': '\u1110\u119e\u11ae',                          // 'ᄐᆞᆮ'
    '\uf440': '\u1110\u119e\u11af',                          // 'ᄐᆞᆯ'
    '\uf441': '\u1110\u119e\u11b0',                          // 'ᄐᆞᆰ'
    '\uf442': '\u1110\u119e\u11b3',                          // 'ᄐᆞᆳ'
    '\uf443': '\u1110\u119e\u11b7',                          // 'ᄐᆞᆷ'
    '\uf444': '\u1110\u119e\u11b8',                          // 'ᄐᆞᆸ'
    '\uf445': '\u1110\u119e\u11ba',                          // 'ᄐᆞᆺ'
    '\uf446': '\u1110\u119e\u11bc',                          // 'ᄐᆞᆼ'
    '\uf447': '\u1110\u119e\u11bd',                          // 'ᄐᆞᆽ'
    '\uf448': '\u1110\u11a1',                                // 'ᄐᆡ'
    '\uf449': '\u1110\u11a1\u11a8',                          // 'ᄐᆡᆨ'
    '\uf44a': '\u1110\u11a1\u11ab',                          // 'ᄐᆡᆫ'
    '\uf44b': '\u1110\u11a1\u11af',                          // 'ᄐᆡᆯ'
    '\uf44c': '\u1110\u11a1\u11b7',                          // 'ᄐᆡᆷ'
    '\uf44d': '\u1110\u11a1\u11ba',                          // 'ᄐᆡᆺ'
    '\uf44e': '\u1110\u11a1\u11bc',                          // 'ᄐᆡᆼ'
    '\uf44f': '\u1110\u11a1\u11f0',                          // 'ᄐᆡᇰ'
    '\uf450': '\u1110\u11a1\u11f9',                          // 'ᄐᆡᇹ'
    '\uf451': '\ua979\u116e',                                // 'ꥹᅮ'
    '\uf452': '\ua979\u119e',                                // 'ꥹᆞ'
    '\uf453': '\ua979\u119e\u11af',                          // 'ꥹᆞᆯ'
    '\uf454': '\u1111\u1161\u11d0',                          // '파ᇐ'
    '\uf455': '\u1111\u1161\u11d8',                          // '파ᇘ'
    '\uf456': '\u1111\u1161\u11d9',                          // '파ᇙ'
    '\uf457': '\u1111\u1161\u11e2',                          // '파ᇢ'
    '\uf458': '\u1111\u1161\u11e6',                          // '파ᇦ'
    '\uf459': '\u1111\u1161\u11e7',                          // '파ᇧ'
    '\uf45a': '\u1111\u1161\u11f0',                          // '파ᇰ'
    '\uf45b': '\u1111\u1161\u11f9',                          // '파ᇹ'
    '\uf45c': '\u1111\u1176',                                // 'ᄑᅶ'
    '\uf45d': '\u1111\u1162\u11f9',                          // '패ᇹ'
    '\uf45e': '\u1111\u1163\u11e2',                          // '퍄ᇢ'
    '\uf45f': '\u1111\u1178',                                // 'ᄑᅸ'
    '\uf460': '\u1111\u1165\u11d0',                          // '퍼ᇐ'
    '\uf461': '\u1111\u1165\u11f0',                          // '퍼ᇰ'
    '\uf462': '\u1111\u117a',                                // 'ᄑᅺ'
    '\uf463': '\u1111\u117c',                                // 'ᄑᅼ'
    '\uf464': '\u1111\u117c\u11af',                          // 'ᄑᅼᆯ'
    '\uf465': '\u1111\u1166\u11c7',                          // '페ᇇ'
    '\uf466': '\u1111\u1167\u11c7',                          // '펴ᇇ'
    '\uf467': '\u1111\u1167\u11d9',                          // '펴ᇙ'
    '\uf468': '\u1111\u1167\u11e2',                          // '펴ᇢ'
    '\uf469': '\u1111\u1167\u11f0',                          // '펴ᇰ'
    '\uf46a': '\u1111\u1167\u11f9',                          // '펴ᇹ'
    '\uf46b': '\u1111\u1169\u11d9',                          // '포ᇙ'
    '\uf46c': '\u1111\u1169\u11e2',                          // '포ᇢ'
    '\uf46d': '\u1111\u1169\u11f0',                          // '포ᇰ'
    '\uf46e': '\u1111\u1182',                                // 'ᄑᆂ'
    '\uf46f': '\u1111\u116d\u11e2',                          // '표ᇢ'
    '\uf470': '\u1111\u116d\u11e6',                          // '표ᇦ'
    '\uf471': '\u1111\u1188',                                // 'ᄑᆈ'
    '\uf472': '\u1111\u116e\u11d0',                          // '푸ᇐ'
    '\uf473': '\u1111\u116e\u11d9',                          // '푸ᇙ'
    '\uf474': '\u1111\u116e\u11e2',                          // '푸ᇢ'
    '\uf475': '\u1111\u116e\u11f0',                          // '푸ᇰ'
    '\uf476': '\u1111\u116e\u11f1',                          // '푸ᇱ'
    '\uf477': '\u1111\u116f\u11f9',                          // '풔ᇹ'
    '\uf478': '\u1111\ud7b5',                                // 'ᄑힵ'
    '\uf479': '\u1111\u118c',                                // 'ᄑᆌ'
    '\uf47a': '\u1111\u1171\u11f9',                          // '퓌ᇹ'
    '\uf47b': '\u1111\u1172\u11e2',                          // '퓨ᇢ'
    '\uf47c': '\u1111\u1172\u11f0',                          // '퓨ᇰ'
    '\uf47d': '\u1111\u118e',                                // 'ᄑᆎ'
    '\uf47e': '\u1111\u118e\u11ab',                          // 'ᄑᆎᆫ'
    '\uf47f': '\u1111\u1194',                                // 'ᄑᆔ'
    '\uf480': '\u1111\u1173\u11d9',                          // '프ᇙ'
    '\uf481': '\u1111\u1173\u11f0',                          // '프ᇰ'
    '\uf482': '\u1111\u1196',                                // 'ᄑᆖ'
    '\uf483': '\u1111\u1174\u11f0',                          // '픠ᇰ'
    '\uf484': '\u1111\u1174\u11f9',                          // '픠ᇹ'
    '\uf485': '\u1111\u1175\u11d9',                          // '피ᇙ'
    '\uf486': '\u1111\u1175\u11f0',                          // '피ᇰ'
    '\uf487': '\u1111\u1175\u11f9',                          // '피ᇹ'
    '\uf488': '\u1111\u1198',                                // 'ᄑᆘ'
    '\uf489': '\u1111\u1199',                                // 'ᄑᆙ'
    '\uf48a': '\u1111\u1199\u11ab',                          // 'ᄑᆙᆫ'
    '\uf48b': '\u1111\ud7bd',                                // 'ᄑힽ'
    '\uf48c': '\u1111\ud7bf',                                // 'ᄑힿ'
    '\uf48d': '\u1111\ud7c0',                                // 'ᄑퟀ'
    '\uf48e': '\u1111\u119e',                                // 'ᄑᆞ'
    '\uf48f': '\u1111\u119e\u11a8',                          // 'ᄑᆞᆨ'
    '\uf490': '\u1111\u119e\u11ab',                          // 'ᄑᆞᆫ'
    '\uf491': '\u1111\u119e\u11ae',                          // 'ᄑᆞᆮ'
    '\uf492': '\u1111\u119e\u11af',                          // 'ᄑᆞᆯ'
    '\uf493': '\u1111\u119e\u11b0',                          // 'ᄑᆞᆰ'
    '\uf494': '\u1111\u119e\u11b3',                          // 'ᄑᆞᆳ'
    '\uf495': '\u1111\u119e\u11b6',                          // 'ᄑᆞᆶ'
    '\uf496': '\u1111\u119e\u11b7',                          // 'ᄑᆞᆷ'
    '\uf497': '\u1111\u119e\u11b8',                          // 'ᄑᆞᆸ'
    '\uf498': '\u1111\u119e\u11ba',                          // 'ᄑᆞᆺ'
    '\uf499': '\u1111\u119e\u11e7',                          // 'ᄑᆞᇧ'
    '\uf49a': '\u1111\u119e\u11bc',                          // 'ᄑᆞᆼ'
    '\uf49b': '\u1111\u119e\u11bd',                          // 'ᄑᆞᆽ'
    '\uf49c': '\u1111\u119e\u11be',                          // 'ᄑᆞᆾ'
    '\uf49d': '\u1111\u119e\u11c0',                          // 'ᄑᆞᇀ'
    '\uf49e': '\u1111\u119f',                                // 'ᄑᆟ'
    '\uf49f': '\u1111\u119f\u11ab',                          // 'ᄑᆟᆫ'
    '\uf4a0': '\u1111\u11a1',                                // 'ᄑᆡ'
    '\uf4a1': '\u1111\u11a1\u11a8',                          // 'ᄑᆡᆨ'
    '\uf4a2': '\u1111\u11a1\u11ab',                          // 'ᄑᆡᆫ'
    '\uf4a3': '\u1111\u11a1\u11af',                          // 'ᄑᆡᆯ'
    '\uf4a4': '\u1111\u11a1\u11b7',                          // 'ᄑᆡᆷ'
    '\uf4a5': '\u1111\u11a1\u11b8',                          // 'ᄑᆡᆸ'
    '\uf4a6': '\u1111\u11a1\u11ba',                          // 'ᄑᆡᆺ'
    '\uf4a7': '\u1111\u11a1\u11bc',                          // 'ᄑᆡᆼ'
    '\uf4a8': '\u1111\u11a1\u11f0',                          // 'ᄑᆡᇰ'
    '\uf4a9': '\u1156\u1161',                                // 'ᅖᅡ'
    '\uf4aa': '\u1156\u1161\u11ab',                          // 'ᅖᅡᆫ'
    '\uf4ab': '\ua97a\u1175',                                // 'ꥺᅵ'
    '\uf4ac': '\ua97a\u1175\u11ab',                          // 'ꥺᅵᆫ'
    '\uf4ad': '\u1157\u116e',                                // 'ᅗᅮ'
    '\uf4ae': '\u1157\u116e\u11ab',                          // 'ᅗᅮᆫ'
    '\uf4af': '\u1112\u1161\u11ce',                          // '하ᇎ'
    '\uf4b0': '\u1112\u1161\u11cf',                          // '하ᇏ'
    '\uf4b1': '\u1112\u1161\u11d0',                          // '하ᇐ'
    '\uf4b2': '\u1112\u1161\ud7d8',                          // '하ퟘ'
    '\uf4b3': '\u1112\u1161\u11d9',                          // '하ᇙ'
    '\uf4b4': '\u1112\u1161\ud7e2',                          // '하ퟢ'
    '\uf4b5': '\u1112\u1161\u11e2',                          // '하ᇢ'
    '\uf4b6': '\u1112\u1161\u11f0',                          // '하ᇰ'
    '\uf4b7': '\u1112\u1161\u11f1',                          // '하ᇱ'
    '\uf4b8': '\u1112\u1176',                                // 'ᄒᅶ'
    '\uf4b9': '\u1112\u1177',                                // 'ᄒᅷ'
    '\uf4ba': '\u1112\u1162\u11eb',                          // '해ᇫ'
    '\uf4bb': '\u1112\u1162\u11f0',                          // '해ᇰ'
    '\uf4bc': '\u1112\u1162\ud7f6',                          // '해ퟶ'
    '\uf4bd': '\u1112\u1163\u11c7',                          // '햐ᇇ'
    '\uf4be': '\u1112\u1163\u11e2',                          // '햐ᇢ'
    '\uf4bf': '\u1112\u1163\u11f0',                          // '햐ᇰ'
    '\uf4c0': '\u1112\u1163\u11f9',                          // '햐ᇹ'
    '\uf4c1': '\u1112\u1178',                                // 'ᄒᅸ'
    '\uf4c2': '\u1112\u1164\u11f0',                          // '햬ᇰ'
    '\uf4c3': '\u1112\u1165\u11d0',                          // '허ᇐ'
    '\uf4c4': '\u1112\u1165\u11d9',                          // '허ᇙ'
    '\uf4c5': '\u1112\u1165\u11f0',                          // '허ᇰ'
    '\uf4c6': '\u1112\u1165\u11f1',                          // '허ᇱ'
    '\uf4c7': '\u1112\u117c\u11f9',                          // 'ᄒᅼᇹ'
    '\uf4c8': '\u1112\u117a',                                // 'ᄒᅺ'
    '\uf4c9': '\u1112\u117b',                                // 'ᄒᅻ'
    '\uf4ca': '\u1112\u117c',                                // 'ᄒᅼ'
    '\uf4cb': '\u1112\u117c\u11ab',                          // 'ᄒᅼᆫ'
    '\uf4cc': '\u1112\u117c\u11b7',                          // 'ᄒᅼᆷ'
    '\uf4cd': '\u1112\u1167\u11d9',                          // '혀ᇙ'
    '\uf4ce': '\u1112\u1167\u11e2',                          // '혀ᇢ'
    '\uf4cf': '\u1112\u1167\u11f0',                          // '혀ᇰ'
    '\uf4d0': '\u1112\u1167\u11f9',                          // '혀ᇹ'
    '\uf4d1': '\u1112\u1168\u11d9',                          // '혜ᇙ'
    '\uf4d2': '\u1112\u1169\u11c7',                          // '호ᇇ'
    '\uf4d3': '\u1112\u1169\u11cd',                          // '호ᇍ'
    '\uf4d4': '\u1112\u1169\u11d9',                          // '호ᇙ'
    '\uf4d5': '\u1112\u1169\u11da',                          // '호ᇚ'
    '\uf4d6': '\u1112\u1169\u11dd',                          // '호ᇝ'
    '\uf4d7': '\u1112\u1169\u11e2',                          // '호ᇢ'
    '\uf4d8': '\u1112\u1169\u11eb',                          // '호ᇫ'
    '\uf4d9': '\u1112\u1169\u11f0',                          // '호ᇰ'
    '\uf4da': '\u1112\u1169\u11f1',                          // '호ᇱ'
    '\uf4db': '\u1112\u116a\u11d9',                          // '화ᇙ'
    '\uf4dc': '\u1112\u116a\u11f0',                          // '화ᇰ'
    '\uf4dd': '\u1112\u116a\u11f1',                          // '화ᇱ'
    '\uf4de': '\u1112\u116b\u11f9',                          // '홰ᇹ'
    '\uf4df': '\u1112\u11a6',                                // 'ᄒᆦ'
    '\uf4e0': '\u1112\u117f',                                // 'ᄒᅿ'
    '\uf4e1': '\u1112\u117f\u11ab',                          // 'ᄒᅿᆫ'
    '\uf4e2': '\u1112\u117f\u11f9',                          // 'ᄒᅿᇹ'
    '\uf4e3': '\u1112\u1180',                                // 'ᄒᆀ'
    '\uf4e4': '\u1112\u1182',                                // 'ᄒᆂ'
    '\uf4e5': '\u1112\u116c\u11f0',                          // '회ᇰ'
    '\uf4e6': '\u1112\u116c\u11f9',                          // '회ᇹ'
    '\uf4e7': '\u1112\u116d\u11d9',                          // '효ᇙ'
    '\uf4e8': '\u1112\u116d\u11e2',                          // '효ᇢ'
    '\uf4e9': '\u1112\ud7b2',                                // 'ᄒힲ'
    '\uf4ea': '\u1112\ud7b2\u11ab',                          // 'ᄒힲᆫ'
    '\uf4eb': '\u1112\u1184',                                // 'ᄒᆄ'
    '\uf4ec': '\u1112\u1184\u11bc',                          // 'ᄒᆄᆼ'
    '\uf4ed': '\u1112\u1187',                                // 'ᄒᆇ'
    '\uf4ee': '\u1112\u1188',                                // 'ᄒᆈ'
    '\uf4ef': '\u1112\u1188\u11ab',                          // 'ᄒᆈᆫ'
    '\uf4f0': '\u1112\u116e\u11d9',                          // '후ᇙ'
    '\uf4f1': '\u1112\u116e\u11dd',                          // '후ᇝ'
    '\uf4f2': '\u1112\u116e\u11e2',                          // '후ᇢ'
    '\uf4f3': '\u1112\u116e\u11eb',                          // '후ᇫ'
    '\uf4f4': '\u1112\u116e\u11f0',                          // '후ᇰ'
    '\uf4f5': '\u1112\u116e\u11f9',                          // '후ᇹ'
    '\uf4f6': '\u1112\u1189',                                // 'ᄒᆉ'
    '\uf4f7': '\u1112\u1189\u11ab',                          // 'ᄒᆉᆫ'
    '\uf4f8': '\u1112\u1189\u11bc',                          // 'ᄒᆉᆼ'
    '\uf4f9': '\u1112\u118a',                                // 'ᄒᆊ'
    '\uf4fa': '\u1112\u116f\u11d9',                          // '훠ᇙ'
    '\uf4fb': '\u1112\u116f\u11f0',                          // '훠ᇰ'
    '\uf4fc': '\u1112\u116f\u11f9',                          // '훠ᇹ'
    '\uf4fd': '\u1112\u118b',                                // 'ᄒᆋ'
    '\uf4fe': '\u1112\u118b\u11ab',                          // 'ᄒᆋᆫ'
    '\uf4ff': '\u1112\u118b\u11af',                          // 'ᄒᆋᆯ'
    '\uf500': '\u1112\ud7b5',                                // 'ᄒힵ'
    '\uf501': '\u1112\u118c',                                // 'ᄒᆌ'
    '\uf502': '\u1112\u1171\u11f0',                          // '휘ᇰ'
    '\uf503': '\u1112\u1171\u11f9',                          // '휘ᇹ'
    '\uf504': '\u1112\u1172\u11d9',                          // '휴ᇙ'
    '\uf505': '\u1112\u1172\u11e2',                          // '휴ᇢ'
    '\uf506': '\u1112\u1172\u11f0',                          // '휴ᇰ'
    '\uf507': '\u1112\u118e',                                // 'ᄒᆎ'
    '\uf508': '\u1112\u118e\u11ab',                          // 'ᄒᆎᆫ'
    '\uf509': '\u1112\u1191',                                // 'ᄒᆑ'
    '\uf50a': '\u1112\u1191\u11a8',                          // 'ᄒᆑᆨ'
    '\uf50b': '\u1112\u1191\u11ab',                          // 'ᄒᆑᆫ'
    '\uf50c': '\u1112\u1191\u11af',                          // 'ᄒᆑᆯ'
    '\uf50d': '\u1112\u1191\u11d9',                          // 'ᄒᆑᇙ'
    '\uf50e': '\u1112\u1191\u11bc',                          // 'ᄒᆑᆼ'
    '\uf50f': '\u1112\u1191\u11f0',                          // 'ᄒᆑᇰ'
    '\uf510': '\u1112\u1191\u11f9',                          // 'ᄒᆑᇹ'
    '\uf511': '\u1112\u1192',                                // 'ᄒᆒ'
    '\uf512': '\u1112\u1192\u11af',                          // 'ᄒᆒᆯ'
    '\uf513': '\u1112\u1192\u11bc',                          // 'ᄒᆒᆼ'
    '\uf514': '\u1112\u1194',                                // 'ᄒᆔ'
    '\uf515': '\u1112\u1194\u11bc',                          // 'ᄒᆔᆼ'
    '\uf516': '\u1112\u1173\u11c3',                          // '흐ᇃ'
    '\uf517': '\u1112\u1173\ud7d4',                          // '흐ퟔ'
    '\uf518': '\u1112\u1173\u11d0',                          // '흐ᇐ'
    '\uf519': '\u1112\u1173\u11d9',                          // '흐ᇙ'
    '\uf51a': '\u1112\u1173\u11e2',                          // '흐ᇢ'
    '\uf51b': '\u1112\u1173\u11f0',                          // '흐ᇰ'
    '\uf51c': '\u1112\ud7b9',                                // 'ᄒힹ'
    '\uf51d': '\u1112\ud7bc',                                // 'ᄒힼ'
    '\uf51e': '\u1112\u1195',                                // 'ᄒᆕ'
    '\uf51f': '\u1112\u1196',                                // 'ᄒᆖ'
    '\uf520': '\u1112\u1174\u11eb',                          // '희ᇫ'
    '\uf521': '\u1112\u1174\u11f0',                          // '희ᇰ'
    '\uf522': '\u1112\u1174\u11f9',                          // '희ᇹ'
    '\uf523': '\u1112\u1175\u11ff',                          // '히ᇿ'
    '\uf524': '\u1112\u1175\u11d9',                          // '히ᇙ'
    '\uf525': '\u1112\u1175\u11dc',                          // '히ᇜ'
    '\uf526': '\u1112\u1175\ud7e1',                          // '히ퟡ'
    '\uf527': '\u1112\u1175\u11dd',                          // '히ᇝ'
    '\uf528': '\u1112\u1175\u11de',                          // '히ᇞ'
    '\uf529': '\u1112\u1175\u11e2',                          // '히ᇢ'
    '\uf52a': '\u1112\u1175\u11ee',                          // '히ᇮ'
    '\uf52b': '\u1112\u1175\u11f0',                          // '히ᇰ'
    '\uf52c': '\u1112\u1175\u11f1',                          // '히ᇱ'
    '\uf52d': '\u1112\u1198',                                // 'ᄒᆘ'
    '\uf52e': '\u1112\u1199',                                // 'ᄒᆙ'
    '\uf52f': '\u1112\u1199\u11ab',                          // 'ᄒᆙᆫ'
    '\uf530': '\u1112\u1199\u11bc',                          // 'ᄒᆙᆼ'
    '\uf531': '\u1112\ud7bf',                                // 'ᄒힿ'
    '\uf532': '\u1112\ud7c0',                                // 'ᄒퟀ'
    '\uf533': '\u1112\ud7c2',                                // 'ᄒퟂ'
    '\uf534': '\u1112\u119b',                                // 'ᄒᆛ'
    '\uf535': '\u1112\ud7c3',                                // 'ᄒퟃ'
    '\uf536': '\u1112\ud7c3\u11ab',                          // 'ᄒퟃᆫ'
    '\uf537': '\u1112\u119e',                                // 'ᄒᆞ'
    '\uf538': '\u1112\u119e\u11a8',                          // 'ᄒᆞᆨ'
    '\uf539': '\u1112\u119e\u11c3',                          // 'ᄒᆞᇃ'
    '\uf53a': '\u1112\u119e\u11ab',                          // 'ᄒᆞᆫ'
    '\uf53b': '\u1112\u119e\u11ae',                          // 'ᄒᆞᆮ'
    '\uf53c': '\u1112\u119e\u11af',                          // 'ᄒᆞᆯ'
    '\uf53d': '\u1112\u119e\u11b0',                          // 'ᄒᆞᆰ'
    '\uf53e': '\u1112\u119e\u11cd',                          // 'ᄒᆞᇍ'
    '\uf53f': '\u1112\u119e\u11ce',                          // 'ᄒᆞᇎ'
    '\uf540': '\u1112\u119e\u11d0',                          // 'ᄒᆞᇐ'
    '\uf541': '\u1112\u119e\u11b1',                          // 'ᄒᆞᆱ'
    '\uf542': '\u1112\u119e\u11b3',                          // 'ᄒᆞᆳ'
    '\uf543': '\u1112\u119e\u11b6',                          // 'ᄒᆞᆶ'
    '\uf544': '\u1112\u119e\u11d9',                          // 'ᄒᆞᇙ'
    '\uf545': '\u1112\u119e\u11b7',                          // 'ᄒᆞᆷ'
    '\uf546': '\u1112\u119e\u11b8',                          // 'ᄒᆞᆸ'
    '\uf547': '\u1112\u119e\u11e6',                          // 'ᄒᆞᇦ'
    '\uf548': '\u1112\u119e\u11ba',                          // 'ᄒᆞᆺ'
    '\uf549': '\u1112\u119e\u11bc',                          // 'ᄒᆞᆼ'
    '\uf54a': '\u1112\u119e\u11f0',                          // 'ᄒᆞᇰ'
    '\uf54b': '\u1112\u119e\u11c0',                          // 'ᄒᆞᇀ'
    '\uf54c': '\u1112\u119e\u11c2',                          // 'ᄒᆞᇂ'
    '\uf54d': '\u1112\u119e\u11f9',                          // 'ᄒᆞᇹ'
    '\uf54e': '\u1112\u119f',                                // 'ᄒᆟ'
    '\uf54f': '\u1112\u119f\u11ab',                          // 'ᄒᆟᆫ'
    '\uf550': '\u1112\u11a1',                                // 'ᄒᆡ'
    '\uf551': '\u1112\u11a1\u11a8',                          // 'ᄒᆡᆨ'
    '\uf552': '\u1112\u11a1\u11ab',                          // 'ᄒᆡᆫ'
    '\uf553': '\u1112\u11a1\u11ae',                          // 'ᄒᆡᆮ'
    '\uf554': '\u1112\u11a1\u11af',                          // 'ᄒᆡᆯ'
    '\uf555': '\u1112\u11a1\u11b0',                          // 'ᄒᆡᆰ'
    '\uf556': '\u1112\u11a1\u11d9',                          // 'ᄒᆡᇙ'
    '\uf557': '\u1112\u11a1\u11b7',                          // 'ᄒᆡᆷ'
    '\uf558': '\u1112\u11a1\u11b8',                          // 'ᄒᆡᆸ'
    '\uf559': '\u1112\u11a1\ud7e8',                          // 'ᄒᆡퟨ'
    '\uf55a': '\u1112\u11a1\u11ba',                          // 'ᄒᆡᆺ'
    '\uf55b': '\u1112\u11a1\u11bc',                          // 'ᄒᆡᆼ'
    '\uf55c': '\u1112\u11a1\u11f0',                          // 'ᄒᆡᇰ'
    '\uf55d': '\ua97b\u1163',                                // 'ꥻᅣ'
    '\uf55e': '\ua97b\u1163\u11bc',                          // 'ꥻᅣᆼ'
    '\uf55f': '\ua97b\u1169',                                // 'ꥻᅩ'
    '\uf560': '\ua97b\u116a',                                // 'ꥻᅪ'
    '\uf561': '\ua97b\u116a\u11ab',                          // 'ꥻᅪᆫ'
    '\uf562': '\ua97b\u116e',                                // 'ꥻᅮ'
    '\uf563': '\ua97b\u116f',                                // 'ꥻᅯ'
    '\uf564': '\ua97b\u1170',                                // 'ꥻᅰ'
    '\uf565': '\ua97b\u1171',                                // 'ꥻᅱ'
    '\uf566': '\ua97b\u1172',                                // 'ꥻᅲ'
    '\uf567': '\ua97b\u1172\u11bc',                          // 'ꥻᅲᆼ'
    '\uf568': '\ua97b\u118e',                                // 'ꥻᆎ'
    '\uf569': '\ua97b\u118e\u11ab',                          // 'ꥻᆎᆫ'
    '\uf56a': '\ua97b\u118f',                                // 'ꥻᆏ'
    '\uf56b': '\ua97b\u1190',                                // 'ꥻᆐ'
    '\uf56c': '\ua97b\u1194',                                // 'ꥻᆔ'
    '\uf56d': '\ua97b\u1194\u11ab',                          // 'ꥻᆔᆫ'
    '\uf56e': '\ua97b\u1175',                                // 'ꥻᅵ'
    '\uf56f': '\ua97b\u1175\u11ab',                          // 'ꥻᅵᆫ'
    '\uf570': '\ua97b\u1175\u11bc',                          // 'ꥻᅵᆼ'
    '\uf571': '\u1158\u1161',                                // 'ᅘᅡ'
    '\uf572': '\u1158\u1161\u11a8',                          // 'ᅘᅡᆨ'
    '\uf573': '\u1158\u1161\u11ab',                          // 'ᅘᅡᆫ'
    '\uf574': '\u1158\u1161\u11af',                          // 'ᅘᅡᆯ'
    '\uf575': '\u1158\u1161\u11d9',                          // 'ᅘᅡᇙ'
    '\uf576': '\u1158\u1161\u11b7',                          // 'ᅘᅡᆷ'
    '\uf577': '\u1158\u1161\u11e2',                          // 'ᅘᅡᇢ'
    '\uf578': '\u1158\u1161\u11b8',                          // 'ᅘᅡᆸ'
    '\uf579': '\u1158\u1161\u11e6',                          // 'ᅘᅡᇦ'
    '\uf57a': '\u1158\u1161\u11bc',                          // 'ᅘᅡᆼ'
    '\uf57b': '\u1158\u1161\u11f0',                          // 'ᅘᅡᇰ'
    '\uf57c': '\u1158\u1162',                                // 'ᅘᅢ'
    '\uf57d': '\u1158\u1162\u11bc',                          // 'ᅘᅢᆼ'
    '\uf57e': '\u1158\u1162\u11f0',                          // 'ᅘᅢᇰ'
    '\uf57f': '\u1158\u1163',                                // 'ᅘᅣ'
    '\uf580': '\u1158\u1163\u11ab',                          // 'ᅘᅣᆫ'
    '\uf581': '\u1158\u1163\u11b7',                          // 'ᅘᅣᆷ'
    '\uf582': '\u1158\u1163\u11e2',                          // 'ᅘᅣᇢ'
    '\uf583': '\u1158\u1163\u11b8',                          // 'ᅘᅣᆸ'
    '\uf584': '\u1158\u1163\u11e6',                          // 'ᅘᅣᇦ'
    '\uf585': '\u1158\u1163\u11f0',                          // 'ᅘᅣᇰ'
    '\uf586': '\u1158\u1163\u11f9',                          // 'ᅘᅣᇹ'
    '\uf587': '\u1158\u1164',                                // 'ᅘᅤ'
    '\uf588': '\u1158\u1165',                                // 'ᅘᅥ'
    '\uf589': '\u1158\u1165\u11ab',                          // 'ᅘᅥᆫ'
    '\uf58a': '\u1158\u1165\u11af',                          // 'ᅘᅥᆯ'
    '\uf58b': '\u1158\u1165\u11b7',                          // 'ᅘᅥᆷ'
    '\uf58c': '\u1158\u1165\u11e2',                          // 'ᅘᅥᇢ'
    '\uf58d': '\u1158\u1165\u11f9',                          // 'ᅘᅥᇹ'
    '\uf58e': '\u1158\u1166',                                // 'ᅘᅦ'
    '\uf58f': '\u1158\u1166\u11bc',                          // 'ᅘᅦᆼ'
    '\uf590': '\u1158\u1167',                                // 'ᅘᅧ'
    '\uf591': '\u1158\u1167\u11a8',                          // 'ᅘᅧᆨ'
    '\uf592': '\u1158\u1167\u11ab',                          // 'ᅘᅧᆫ'
    '\uf593': '\u1158\u1167\u11af',                          // 'ᅘᅧᆯ'
    '\uf594': '\u1158\u1167\u11d9',                          // 'ᅘᅧᇙ'
    '\uf595': '\u1158\u1167\u11b7',                          // 'ᅘᅧᆷ'
    '\uf596': '\u1158\u1167\u11b8',                          // 'ᅘᅧᆸ'
    '\uf597': '\u1158\u1167\u11f0',                          // 'ᅘᅧᇰ'
    '\uf598': '\u1158\u1167\u11f9',                          // 'ᅘᅧᇹ'
    '\uf599': '\u1158\u1168',                                // 'ᅘᅨ'
    '\uf59a': '\u1158\u1168\u11bc',                          // 'ᅘᅨᆼ'
    '\uf59b': '\u1158\u1169',                                // 'ᅘᅩ'
    '\uf59c': '\u1158\u1169\u11a8',                          // 'ᅘᅩᆨ'
    '\uf59d': '\u1158\u1169\u11ab',                          // 'ᅘᅩᆫ'
    '\uf59e': '\u1158\u1169\u11af',                          // 'ᅘᅩᆯ'
    '\uf59f': '\u1158\u1169\u11d9',                          // 'ᅘᅩᇙ'
    '\uf5a0': '\u1158\u1169\u11b7',                          // 'ᅘᅩᆷ'
    '\uf5a1': '\u1158\u1169\u11e2',                          // 'ᅘᅩᇢ'
    '\uf5a2': '\u1158\u1169\u11bc',                          // 'ᅘᅩᆼ'
    '\uf5a3': '\u1158\u1169\u11f0',                          // 'ᅘᅩᇰ'
    '\uf5a4': '\u1158\u116a',                                // 'ᅘᅪ'
    '\uf5a5': '\u1158\u116a\u11a8',                          // 'ᅘᅪᆨ'
    '\uf5a6': '\u1158\u116a\u11ab',                          // 'ᅘᅪᆫ'
    '\uf5a7': '\u1158\u116a\u11af',                          // 'ᅘᅪᆯ'
    '\uf5a8': '\u1158\u116a\u11d9',                          // 'ᅘᅪᇙ'
    '\uf5a9': '\u1158\u116a\u11bc',                          // 'ᅘᅪᆼ'
    '\uf5aa': '\u1158\u116a\u11f0',                          // 'ᅘᅪᇰ'
    '\uf5ab': '\u1158\u116a\u11f9',                          // 'ᅘᅪᇹ'
    '\uf5ac': '\u1158\u116b',                                // 'ᅘᅫ'
    '\uf5ad': '\u1158\u116b\u11bc',                          // 'ᅘᅫᆼ'
    '\uf5ae': '\u1158\u116c',                                // 'ᅘᅬ'
    '\uf5af': '\u1158\u116c\u11a8',                          // 'ᅘᅬᆨ'
    '\uf5b0': '\u1158\u116c\u11bc',                          // 'ᅘᅬᆼ'
    '\uf5b1': '\u1158\u116c\u11f0',                          // 'ᅘᅬᇰ'
    '\uf5b2': '\u1158\u116d',                                // 'ᅘᅭ'
    '\uf5b3': '\u1158\u116d\u11b7',                          // 'ᅘᅭᆷ'
    '\uf5b4': '\u1158\u116d\u11e2',                          // 'ᅘᅭᇢ'
    '\uf5b5': '\u1158\u116e',                                // 'ᅘᅮ'
    '\uf5b6': '\u1158\u116e\u11ab',                          // 'ᅘᅮᆫ'
    '\uf5b7': '\u1158\u116e\u11b7',                          // 'ᅘᅮᆷ'
    '\uf5b8': '\u1158\u116e\u11e2',                          // 'ᅘᅮᇢ'
    '\uf5b9': '\u1158\u116e\u11b8',                          // 'ᅘᅮᆸ'
    '\uf5ba': '\u1158\u116e\u11e6',                          // 'ᅘᅮᇦ'
    '\uf5bb': '\u1158\u116e\u11bc',                          // 'ᅘᅮᆼ'
    '\uf5bc': '\u1158\u116e\u11f0',                          // 'ᅘᅮᇰ'
    '\uf5bd': '\u1158\u116e\u11f9',                          // 'ᅘᅮᇹ'
    '\uf5be': '\u1158\u116f',                                // 'ᅘᅯ'
    '\uf5bf': '\u1158\u116f\u11ab',                          // 'ᅘᅯᆫ'
    '\uf5c0': '\u1158\u116f\u11f9',                          // 'ᅘᅯᇹ'
    '\uf5c1': '\u1158\u1171',                                // 'ᅘᅱ'
    '\uf5c2': '\u1158\u1171\u11f9',                          // 'ᅘᅱᇹ'
    '\uf5c3': '\u1158\u1172',                                // 'ᅘᅲ'
    '\uf5c4': '\u1158\u1172\u11b7',                          // 'ᅘᅲᆷ'
    '\uf5c5': '\u1158\u118f',                                // 'ᅘᆏ'
    '\uf5c6': '\u1158\u118f\u11ab',                          // 'ᅘᆏᆫ'
    '\uf5c7': '\u1158\u1191',                                // 'ᅘᆑ'
    '\uf5c8': '\u1158\u1191\u11ab',                          // 'ᅘᆑᆫ'
    '\uf5c9': '\u1158\u1191\u11af',                          // 'ᅘᆑᆯ'
    '\uf5ca': '\u1158\u1191\u11d9',                          // 'ᅘᆑᇙ'
    '\uf5cb': '\u1158\u1191\u11bc',                          // 'ᅘᆑᆼ'
    '\uf5cc': '\u1158\u1191\u11f0',                          // 'ᅘᆑᇰ'
    '\uf5cd': '\u1158\u1192',                                // 'ᅘᆒ'
    '\uf5ce': '\u1158\u1192\u11bc',                          // 'ᅘᆒᆼ'
    '\uf5cf': '\u1158\u1173',                                // 'ᅘᅳ'
    '\uf5d0': '\u1158\u1173\u11a8',                          // 'ᅘᅳᆨ'
    '\uf5d1': '\u1158\u1173\u11ab',                          // 'ᅘᅳᆫ'
    '\uf5d2': '\u1158\u1173\u11b7',                          // 'ᅘᅳᆷ'
    '\uf5d3': '\u1158\u1173\u11e2',                          // 'ᅘᅳᇢ'
    '\uf5d4': '\u1158\u1173\u11bc',                          // 'ᅘᅳᆼ'
    '\uf5d5': '\u1158\u1173\u11f0',                          // 'ᅘᅳᇰ'
    '\uf5d6': '\u1158\u1195',                                // 'ᅘᆕ'
    '\uf5d7': '\u1158\u1174',                                // 'ᅘᅴ'
    '\uf5d8': '\u1158\u1174\u11a8',                          // 'ᅘᅴᆨ'
    '\uf5d9': '\u1158\u1174\u11f0',                          // 'ᅘᅴᇰ'
    '\uf5da': '\u1158\u1174\u11c2',                          // 'ᅘᅴᇂ'
    '\uf5db': '\u1158\u1174\u11f9',                          // 'ᅘᅴᇹ'
    '\uf5dc': '\u1158\u1175',                                // 'ᅘᅵ'
    '\uf5dd': '\u1158\u1175\u11ab',                          // 'ᅘᅵᆫ'
    '\uf5de': '\u1158\u1175\u11b7',                          // 'ᅘᅵᆷ'
    '\uf5df': '\u1158\u1175\u11e2',                          // 'ᅘᅵᇢ'
    '\uf5e0': '\u1158\u1175\u11f0',                          // 'ᅘᅵᇰ'
    '\uf5e1': '\u1158\u1175\u11f9',                          // 'ᅘᅵᇹ'
    '\uf5e2': '\u1158\u119e',                                // 'ᅘᆞ'
    '\uf5e3': '\u1158\u119e\u11ab',                          // 'ᅘᆞᆫ'
    '\uf5e4': '\u1158\u11a1',                                // 'ᅘᆡ'
    '\uf5e5': '\u1158\u11a1\u11a8',                          // 'ᅘᆡᆨ'
    '\uf5e6': '\u1158\u11a1\u11bc',                          // 'ᅘᆡᆼ'
    '\uf5e7': '\u1158\u11a1\u11f0',                          // 'ᅘᆡᇰ'
    '\uf5e8': '\u1159\u1161',                                // 'ᅙᅡ'
    '\uf5e9': '\u1159\u1161\u11a8',                          // 'ᅙᅡᆨ'
    '\uf5ea': '\u1159\u1161\u11ab',                          // 'ᅙᅡᆫ'
    '\uf5eb': '\u1159\u1161\u11af',                          // 'ᅙᅡᆯ'
    '\uf5ec': '\u1159\u1161\u11d9',                          // 'ᅙᅡᇙ'
    '\uf5ed': '\u1159\u1161\u11b7',                          // 'ᅙᅡᆷ'
    '\uf5ee': '\u1159\u1161\u11e2',                          // 'ᅙᅡᇢ'
    '\uf5ef': '\u1159\u1161\u11b8',                          // 'ᅙᅡᆸ'
    '\uf5f0': '\u1159\u1161\u11e6',                          // 'ᅙᅡᇦ'
    '\uf5f1': '\u1159\u1161\u11bc',                          // 'ᅙᅡᆼ'
    '\uf5f2': '\u1159\u1161\u11f0',                          // 'ᅙᅡᇰ'
    '\uf5f3': '\u1159\u1162',                                // 'ᅙᅢ'
    '\uf5f4': '\u1159\u1162\u11bc',                          // 'ᅙᅢᆼ'
    '\uf5f5': '\u1159\u1162\u11f0',                          // 'ᅙᅢᇰ'
    '\uf5f6': '\u1159\u1163',                                // 'ᅙᅣ'
    '\uf5f7': '\u1159\u1163\u11a8',                          // 'ᅙᅣᆨ'
    '\uf5f8': '\u1159\u1163\u11ab',                          // 'ᅙᅣᆫ'
    '\uf5f9': '\u1159\u1163\u11b7',                          // 'ᅙᅣᆷ'
    '\uf5fa': '\u1159\u1163\u11e2',                          // 'ᅙᅣᇢ'
    '\uf5fb': '\u1159\u1163\u11b8',                          // 'ᅙᅣᆸ'
    '\uf5fc': '\u1159\u1163\u11e6',                          // 'ᅙᅣᇦ'
    '\uf5fd': '\u1159\u1163\u11f0',                          // 'ᅙᅣᇰ'
    '\uf5fe': '\u1159\u1163\u11f9',                          // 'ᅙᅣᇹ'
    '\uf5ff': '\u1159\u1164',                                // 'ᅙᅤ'
    '\uf600': '\u1159\u1165',                                // 'ᅙᅥ'
    '\uf601': '\u1159\u1165\u11ab',                          // 'ᅙᅥᆫ'
    '\uf602': '\u1159\u1165\u11af',                          // 'ᅙᅥᆯ'
    '\uf603': '\u1159\u1165\u11d9',                          // 'ᅙᅥᇙ'
    '\uf604': '\u1159\u1165\u11b7',                          // 'ᅙᅥᆷ'
    '\uf605': '\u1159\u1165\u11b8',                          // 'ᅙᅥᆸ'
    '\uf606': '\u1159\u1165\u11bc',                          // 'ᅙᅥᆼ'
    '\uf607': '\u1159\u1166',                                // 'ᅙᅦ'
    '\uf608': '\u1159\u1166\u11bc',                          // 'ᅙᅦᆼ'
    '\uf609': '\u1159\u1167',                                // 'ᅙᅧ'
    '\uf60a': '\u1159\u1167\u11a8',                          // 'ᅙᅧᆨ'
    '\uf60b': '\u1159\u1167\u11ab',                          // 'ᅙᅧᆫ'
    '\uf60c': '\u1159\u1167\u11af',                          // 'ᅙᅧᆯ'
    '\uf60d': '\u1159\u1167\u11d9',                          // 'ᅙᅧᇙ'
    '\uf60e': '\u1159\u1167\u11b7',                          // 'ᅙᅧᆷ'
    '\uf60f': '\u1159\u1167\u11b8',                          // 'ᅙᅧᆸ'
    '\uf610': '\u1159\u1167\u11f0',                          // 'ᅙᅧᇰ'
    '\uf611': '\u1159\u1167\u11c2',                          // 'ᅙᅧᇂ'
    '\uf612': '\u1159\u1168',                                // 'ᅙᅨ'
    '\uf613': '\u1159\u1168\u11bc',                          // 'ᅙᅨᆼ'
    '\uf614': '\u1159\u1169',                                // 'ᅙᅩ'
    '\uf615': '\u1159\u1169\u11a8',                          // 'ᅙᅩᆨ'
    '\uf616': '\u1159\u1169\u11ab',                          // 'ᅙᅩᆫ'
    '\uf617': '\u1159\u1169\u11b7',                          // 'ᅙᅩᆷ'
    '\uf618': '\u1159\u1169\u11e2',                          // 'ᅙᅩᇢ'
    '\uf619': '\u1159\u1169\u11bc',                          // 'ᅙᅩᆼ'
    '\uf61a': '\u1159\u1169\u11f0',                          // 'ᅙᅩᇰ'
    '\uf61b': '\u1159\u116a',                                // 'ᅙᅪ'
    '\uf61c': '\u1159\u116a\u11a8',                          // 'ᅙᅪᆨ'
    '\uf61d': '\u1159\u116a\u11ab',                          // 'ᅙᅪᆫ'
    '\uf61e': '\u1159\u116a\u11af',                          // 'ᅙᅪᆯ'
    '\uf61f': '\u1159\u116a\u11d9',                          // 'ᅙᅪᇙ'
    '\uf620': '\u1159\u116a\u11b8',                          // 'ᅙᅪᆸ'
    '\uf621': '\u1159\u116a\u11e6',                          // 'ᅙᅪᇦ'
    '\uf622': '\u1159\u116a\u11bc',                          // 'ᅙᅪᆼ'
    '\uf623': '\u1159\u116a\u11f0',                          // 'ᅙᅪᇰ'
    '\uf624': '\u1159\u116a\u11f9',                          // 'ᅙᅪᇹ'
    '\uf625': '\u1159\u116b',                                // 'ᅙᅫ'
    '\uf626': '\u1159\u116b\u11bc',                          // 'ᅙᅫᆼ'
    '\uf627': '\u1159\u116c',                                // 'ᅙᅬ'
    '\uf628': '\u1159\u116c\u11a8',                          // 'ᅙᅬᆨ'
    '\uf629': '\u1159\u116c\u11bc',                          // 'ᅙᅬᆼ'
    '\uf62a': '\u1159\u116c\u11f0',                          // 'ᅙᅬᇰ'
    '\uf62b': '\u1159\u116d',                                // 'ᅙᅭ'
    '\uf62c': '\u1159\u116d\u11a8',                          // 'ᅙᅭᆨ'
    '\uf62d': '\u1159\u116d\u11b7',                          // 'ᅙᅭᆷ'
    '\uf62e': '\u1159\u116d\u11e2',                          // 'ᅙᅭᇢ'
    '\uf62f': '\u1159\u116d\u11bc',                          // 'ᅙᅭᆼ'
    '\uf630': '\u1159\u116e',                                // 'ᅙᅮ'
    '\uf631': '\u1159\u116e\u11a8',                          // 'ᅙᅮᆨ'
    '\uf632': '\u1159\u116e\u11ab',                          // 'ᅙᅮᆫ'
    '\uf633': '\u1159\u116e\u11af',                          // 'ᅙᅮᆯ'
    '\uf634': '\u1159\u116e\u11d9',                          // 'ᅙᅮᇙ'
    '\uf635': '\u1159\u116e\u11b7',                          // 'ᅙᅮᆷ'
    '\uf636': '\u1159\u116e\u11e2',                          // 'ᅙᅮᇢ'
    '\uf637': '\u1159\u116e\u11bc',                          // 'ᅙᅮᆼ'
    '\uf638': '\u1159\u116e\u11f0',                          // 'ᅙᅮᇰ'
    '\uf639': '\u1159\u116e\u11f9',                          // 'ᅙᅮᇹ'
    '\uf63a': '\u1159\u116f',                                // 'ᅙᅯ'
    '\uf63b': '\u1159\u116f\u11ab',                          // 'ᅙᅯᆫ'
    '\uf63c': '\u1159\u116f\u11af',                          // 'ᅙᅯᆯ'
    '\uf63d': '\u1159\u116f\u11d9',                          // 'ᅙᅯᇙ'
    '\uf63e': '\u1159\u116f\u11bc',                          // 'ᅙᅯᆼ'
    '\uf63f': '\u1159\u1171',                                // 'ᅙᅱ'
    '\uf640': '\u1159\u1171\u11bc',                          // 'ᅙᅱᆼ'
    '\uf641': '\u1159\u1172',                                // 'ᅙᅲ'
    '\uf642': '\u1159\u1172\u11ab',                          // 'ᅙᅲᆫ'
    '\uf643': '\u1159\u1172\u11b7',                          // 'ᅙᅲᆷ'
    '\uf644': '\u1159\u1172\u11e2',                          // 'ᅙᅲᇢ'
    '\uf645': '\u1159\u1172\u11f9',                          // 'ᅙᅲᇹ'
    '\uf646': '\u1159\u1191',                                // 'ᅙᆑ'
    '\uf647': '\u1159\u1191\u11a8',                          // 'ᅙᆑᆨ'
    '\uf648': '\u1159\u1191\u11ab',                          // 'ᅙᆑᆫ'
    '\uf649': '\u1159\u1191\u11af',                          // 'ᅙᆑᆯ'
    '\uf64a': '\u1159\u1191\u11d9',                          // 'ᅙᆑᇙ'
    '\uf64b': '\u1159\u1191\u11bc',                          // 'ᅙᆑᆼ'
    '\uf64c': '\u1159\u1191\u11f0',                          // 'ᅙᆑᇰ'
    '\uf64d': '\u1159\u1192',                                // 'ᅙᆒ'
    '\uf64e': '\u1159\u1192\u11bc',                          // 'ᅙᆒᆼ'
    '\uf64f': '\u1159\u1194',                                // 'ᅙᆔ'
    '\uf650': '\u1159\u1194\u11bc',                          // 'ᅙᆔᆼ'
    '\uf651': '\u1159\u1173',                                // 'ᅙᅳ'
    '\uf652': '\u1159\u1173\u11a8',                          // 'ᅙᅳᆨ'
    '\uf653': '\u1159\u1173\u11ab',                          // 'ᅙᅳᆫ'
    '\uf654': '\u1159\u1173\u11b7',                          // 'ᅙᅳᆷ'
    '\uf655': '\u1159\u1173\u11e2',                          // 'ᅙᅳᇢ'
    '\uf656': '\u1159\u1173\u11b8',                          // 'ᅙᅳᆸ'
    '\uf657': '\u1159\u1173\u11f0',                          // 'ᅙᅳᇰ'
    '\uf658': '\u1159\u1173\u11f9',                          // 'ᅙᅳᇹ'
    '\uf659': '\u1159\u1174',                                // 'ᅙᅴ'
    '\uf65a': '\u1159\u1174\u11ab',                          // 'ᅙᅴᆫ'
    '\uf65b': '\u1159\u1174\u11bc',                          // 'ᅙᅴᆼ'
    '\uf65c': '\u1159\u1175',                                // 'ᅙᅵ'
    '\uf65d': '\u1159\u1175\u11ab',                          // 'ᅙᅵᆫ'
    '\uf65e': '\u1159\u1175\u11af',                          // 'ᅙᅵᆯ'
    '\uf65f': '\u1159\u1175\u11d9',                          // 'ᅙᅵᇙ'
    '\uf660': '\u1159\u1175\u11b7',                          // 'ᅙᅵᆷ'
    '\uf661': '\u1159\u1175\u11e2',                          // 'ᅙᅵᇢ'
    '\uf662': '\u1159\u1175\u11bc',                          // 'ᅙᅵᆼ'
    '\uf663': '\u1159\u1175\u11f0',                          // 'ᅙᅵᇰ'
    '\uf664': '\u1159\u1175\u11f9',                          // 'ᅙᅵᇹ'
    '\uf665': '\u1159\u119e',                                // 'ᅙᆞ'
    '\uf666': '\u1159\u119e\u11ab',                          // 'ᅙᆞᆫ'
    '\uf667': '\u1159\u119e\u11b7',                          // 'ᅙᆞᆷ'
    '\uf668': '\u1159\u11a1',                                // 'ᅙᆡ'
    '\uf669': '\u1159\u11a1\u11a8',                          // 'ᅙᆡᆨ'
    '\uf66a': '\u1159\u11a1\u11af',                          // 'ᅙᆡᆯ'
    '\uf66b': '\u1159\u11a1\u11cd',                          // 'ᅙᆡᇍ'
    '\uf66c': '\u1159\u11a1\u11b1',                          // 'ᅙᆡᆱ'
    '\uf66d': '\u1159\u11a1\u11bc',                          // 'ᅙᆡᆼ'
    '\uf66e': '\u1159\u11a1\u11f0',                          // 'ᅙᆡᇰ'
    '\uf784': '\u115f',                                      // 'ᅟ'
    '\uf785': '\u1100',                                      // 'ᄀ'
    '\uf786': '\u1101',                                      // 'ᄁ'
    '\uf787': '\u115a',                                      // 'ᅚ'
    '\uf788': '\u1102',                                      // 'ᄂ'
    '\uf789': '\u1113',                                      // 'ᄓ'
    '\uf78a': '\u1114',                                      // 'ᄔ'
    '\uf78b': '\u1115',                                      // 'ᄕ'
    '\uf78c': '\u1116',                                      // 'ᄖ'
    '\uf78d': '\u115b',                                      // 'ᅛ'
    '\uf78e': '\u115c',                                      // 'ᅜ'
    '\uf78f': '\u115d',                                      // 'ᅝ'
    '\uf790': '\u1103',                                      // 'ᄃ'
    '\uf791': '\u1117',                                      // 'ᄗ'
    '\uf792': '\u1104',                                      // 'ᄄ'
    '\uf793': '\u115e',                                      // 'ᅞ'
    '\uf794': '\ua960',                                      // 'ꥠ'
    '\uf795': '\ua961',                                      // 'ꥡ'
    '\uf796': '\ua962',                                      // 'ꥢ'
    '\uf797': '\ua963',                                      // 'ꥣ'
    '\uf798': '\u1105',                                      // 'ᄅ'
    '\uf799': '\ua964',                                      // 'ꥤ'
    '\uf79a': '\ua965',                                      // 'ꥥ'
    '\uf79b': '\u1118',                                      // 'ᄘ'
    '\uf79c': '\ua966',                                      // 'ꥦ'
    '\uf79d': '\ua967',                                      // 'ꥧ'
    '\uf79e': '\u1119',                                      // 'ᄙ'
    '\uf79f': '\ua968',                                      // 'ꥨ'
    '\uf7a0': '\ua969',                                      // 'ꥩ'
    '\uf7a1': '\ua96a',                                      // 'ꥪ'
    '\uf7a2': '\ua96b',                                      // 'ꥫ'
    '\uf7a3': '\ua96c',                                      // 'ꥬ'
    '\uf7a4': '\ua96d',                                      // 'ꥭ'
    '\uf7a5': '\ua96e',                                      // 'ꥮ'
    '\uf7a6': '\u111a',                                      // 'ᄚ'
    '\uf7a7': '\u111b',                                      // 'ᄛ'
    '\uf7a8': '\u1106',                                      // 'ᄆ'
    '\uf7a9': '\ua96f',                                      // 'ꥯ'
    '\uf7aa': '\ua970',                                      // 'ꥰ'
    '\uf7ab': '\u111c',                                      // 'ᄜ'
    '\uf7ac': '\ua971',                                      // 'ꥱ'
    '\uf7ad': '\u111d',                                      // 'ᄝ'
    '\uf7ae': '\u1107',                                      // 'ᄇ'
    '\uf7af': '\u111e',                                      // 'ᄞ'
    '\uf7b0': '\u111f',                                      // 'ᄟ'
    '\uf7b1': '\u1120',                                      // 'ᄠ'
    '\uf7b2': '\u1108',                                      // 'ᄈ'
    '\uf7b3': '\u1121',                                      // 'ᄡ'
    '\uf7b4': '\u1122',                                      // 'ᄢ'
    '\uf7b5': '\u1123',                                      // 'ᄣ'
    '\uf7b6': '\u1124',                                      // 'ᄤ'
    '\uf7b7': '\u1125',                                      // 'ᄥ'
    '\uf7b8': '\u1126',                                      // 'ᄦ'
    '\uf7b9': '\ua972',                                      // 'ꥲ'
    '\uf7ba': '\u1127',                                      // 'ᄧ'
    '\uf7bb': '\u1128',                                      // 'ᄨ'
    '\uf7bc': '\ua973',                                      // 'ꥳ'
    '\uf7bd': '\u1129',                                      // 'ᄩ'
    '\uf7be': '\u112a',                                      // 'ᄪ'
    '\uf7bf': '\ua974',                                      // 'ꥴ'
    '\uf7c0': '\u112b',                                      // 'ᄫ'
    '\uf7c1': '\u112c',                                      // 'ᄬ'
    '\uf7c2': '\u1109',                                      // 'ᄉ'
    '\uf7c3': '\u112d',                                      // 'ᄭ'
    '\uf7c4': '\u112e',                                      // 'ᄮ'
    '\uf7c5': '\u112f',                                      // 'ᄯ'
    '\uf7c6': '\u1130',                                      // 'ᄰ'
    '\uf7c7': '\u1131',                                      // 'ᄱ'
    '\uf7c8': '\u1132',                                      // 'ᄲ'
    '\uf7c9': '\u1133',                                      // 'ᄳ'
    '\uf7ca': '\u110a',                                      // 'ᄊ'
    '\uf7cb': '\ua975',                                      // 'ꥵ'
    '\uf7cc': '\u1134',                                      // 'ᄴ'
    '\uf7cd': '\u1135',                                      // 'ᄵ'
    '\uf7ce': '\u1136',                                      // 'ᄶ'
    '\uf7cf': '\u1137',                                      // 'ᄷ'
    '\uf7d0': '\u1138',                                      // 'ᄸ'
    '\uf7d1': '\u1139',                                      // 'ᄹ'
    '\uf7d2': '\u113a',                                      // 'ᄺ'
    '\uf7d3': '\u113b',                                      // 'ᄻ'
    '\uf7d4': '\u113c',                                      // 'ᄼ'
    '\uf7d5': '\u113d',                                      // 'ᄽ'
    '\uf7d6': '\u113e',                                      // 'ᄾ'
    '\uf7d7': '\u113f',                                      // 'ᄿ'
    '\uf7d8': '\u1140',                                      // 'ᅀ'
    '\uf7d9': '\u110b',                                      // 'ᄋ'
    '\uf7da': '\u1141',                                      // 'ᅁ'
    '\uf7db': '\u1142',                                      // 'ᅂ'
    '\uf7dc': '\ua976',                                      // 'ꥶ'
    '\uf7dd': '\u1143',                                      // 'ᅃ'
    '\uf7de': '\u1144',                                      // 'ᅄ'
    '\uf7df': '\u1145',                                      // 'ᅅ'
    '\uf7e0': '\u1146',                                      // 'ᅆ'
    '\uf7e1': '\u1147',                                      // 'ᅇ'
    '\uf7e2': '\u1148',                                      // 'ᅈ'
    '\uf7e3': '\u1149',                                      // 'ᅉ'
    '\uf7e4': '\u114a',                                      // 'ᅊ'
    '\uf7e5': '\u114b',                                      // 'ᅋ'
    '\uf7e6': '\ua977',                                      // 'ꥷ'
    '\uf7e7': '\u114c',                                      // 'ᅌ'
    '\uf7e8': '\u110c',                                      // 'ᄌ'
    '\uf7e9': '\u114d',                                      // 'ᅍ'
    '\uf7ea': '\u110d',                                      // 'ᄍ'
    '\uf7eb': '\ua978',                                      // 'ꥸ'
    '\uf7ec': '\u114e',                                      // 'ᅎ'
    '\uf7ed': '\u114f',                                      // 'ᅏ'
    '\uf7ee': '\u1150',                                      // 'ᅐ'
    '\uf7ef': '\u1151',                                      // 'ᅑ'
    '\uf7f0': '\u110e',                                      // 'ᄎ'
    '\uf7f1': '\u1152',                                      // 'ᅒ'
    '\uf7f2': '\u1153',                                      // 'ᅓ'
    '\uf7f3': '\u1154',                                      // 'ᅔ'
    '\uf7f4': '\u1155',                                      // 'ᅕ'
    '\uf7f5': '\u110f',                                      // 'ᄏ'
    '\uf7f6': '\u1110',                                      // 'ᄐ'
    '\uf7f7': '\ua979',                                      // 'ꥹ'
    '\uf7f8': '\u1111',                                      // 'ᄑ'
    '\uf7f9': '\u1156',                                      // 'ᅖ'
    '\uf7fa': '\ua97a',                                      // 'ꥺ'
    '\uf7fb': '\u1157',                                      // 'ᅗ'
    '\uf7fc': '\u1112',                                      // 'ᄒ'
    '\uf7fd': '\ua97b',                                      // 'ꥻ'
    '\uf7fe': '\u1158',                                      // 'ᅘ'
    '\uf7ff': '\u1159',                                      // 'ᅙ'
    '\uf800': '\ua97c',                                      // 'ꥼ'
    '\uf806': '\u1160',                                      // 'ᅠ'
    '\uf807': '\u1161',                                      // 'ᅡ'
    '\uf808': '\u1176',                                      // 'ᅶ'
    '\uf809': '\u1177',                                      // 'ᅷ'
    '\uf80a': '\u11a3',                                      // 'ᆣ'
    '\uf80b': '\u1162',                                      // 'ᅢ'
    '\uf80c': '\u1163',                                      // 'ᅣ'
    '\uf80d': '\u1178',                                      // 'ᅸ'
    '\uf80e': '\u1179',                                      // 'ᅹ'
    '\uf80f': '\u11a4',                                      // 'ᆤ'
    '\uf810': '\u1164',                                      // 'ᅤ'
    '\uf811': '\u1165',                                      // 'ᅥ'
    '\uf812': '\u117a',                                      // 'ᅺ'
    '\uf813': '\u117b',                                      // 'ᅻ'
    '\uf814': '\u117c',                                      // 'ᅼ'
    '\uf815': '\u1166',                                      // 'ᅦ'
    '\uf816': '\u1167',                                      // 'ᅧ'
    '\uf817': '\u11a5',                                      // 'ᆥ'
    '\uf818': '\u117d',                                      // 'ᅽ'
    '\uf819': '\u117e',                                      // 'ᅾ'
    '\uf81a': '\u1168',                                      // 'ᅨ'
    '\uf81b': '\u1169',                                      // 'ᅩ'
    '\uf81c': '\u116a',                                      // 'ᅪ'
    '\uf81d': '\u116b',                                      // 'ᅫ'
    '\uf81e': '\u11a6',                                      // 'ᆦ'
    '\uf81f': '\u11a7',                                      // 'ᆧ'
    '\uf820': '\u117f',                                      // 'ᅿ'
    '\uf821': '\u1180',                                      // 'ᆀ'
    '\uf822': '\ud7b0',                                      // 'ힰ'
    '\uf823': '\u1181',                                      // 'ᆁ'
    '\uf824': '\u1182',                                      // 'ᆂ'
    '\uf825': '\ud7b1',                                      // 'ힱ'
    '\uf826': '\u1183',                                      // 'ᆃ'
    '\uf827': '\u116c',                                      // 'ᅬ'
    '\uf828': '\u116d',                                      // 'ᅭ'
    '\uf829': '\ud7b2',                                      // 'ힲ'
    '\uf82a': '\ud7b3',                                      // 'ힳ'
    '\uf82b': '\u1184',                                      // 'ᆄ'
    '\uf82c': '\u1185',                                      // 'ᆅ'
    '\uf82d': '\ud7b4',                                      // 'ힴ'
    '\uf82e': '\u1186',                                      // 'ᆆ'
    '\uf82f': '\u1187',                                      // 'ᆇ'
    '\uf830': '\u1188',                                      // 'ᆈ'
    '\uf831': '\u116e',                                      // 'ᅮ'
    '\uf832': '\u1189',                                      // 'ᆉ'
    '\uf833': '\u118a',                                      // 'ᆊ'
    '\uf834': '\u116f',                                      // 'ᅯ'
    '\uf835': '\u118b',                                      // 'ᆋ'
    '\uf836': '\u1170',                                      // 'ᅰ'
    '\uf837': '\ud7b5',                                      // 'ힵ'
    '\uf838': '\u118c',                                      // 'ᆌ'
    '\uf839': '\u118d',                                      // 'ᆍ'
    '\uf83a': '\u1171',                                      // 'ᅱ'
    '\uf83b': '\ud7b6',                                      // 'ힶ'
    '\uf83c': '\u1172',                                      // 'ᅲ'
    '\uf83d': '\u118e',                                      // 'ᆎ'
    '\uf83e': '\ud7b7',                                      // 'ힷ'
    '\uf83f': '\u118f',                                      // 'ᆏ'
    '\uf840': '\u1190',                                      // 'ᆐ'
    '\uf841': '\u1191',                                      // 'ᆑ'
    '\uf842': '\u1192',                                      // 'ᆒ'
    '\uf843': '\ud7b8',                                      // 'ힸ'
    '\uf844': '\u1193',                                      // 'ᆓ'
    '\uf845': '\u1194',                                      // 'ᆔ'
    '\uf846': '\u1173',                                      // 'ᅳ'
    '\uf847': '\ud7b9',                                      // 'ힹ'
    '\uf848': '\ud7ba',                                      // 'ힺ'
    '\uf849': '\ud7bb',                                      // 'ힻ'
    '\uf84a': '\ud7bc',                                      // 'ힼ'
    '\uf84b': '\u1195',                                      // 'ᆕ'
    '\uf84c': '\u1196',                                      // 'ᆖ'
    '\uf84d': '\u1174',                                      // 'ᅴ'
    '\uf84e': '\u1197',                                      // 'ᆗ'
    '\uf84f': '\u1175',                                      // 'ᅵ'
    '\uf850': '\u1198',                                      // 'ᆘ'
    '\uf851': '\u1199',                                      // 'ᆙ'
    '\uf852': '\ud7bd',                                      // 'ힽ'
    '\uf853': '\ud7be',                                      // 'ힾ'
    '\uf854': '\ud7bf',                                      // 'ힿ'
    '\uf855': '\ud7c0',                                      // 'ퟀ'
    '\uf856': '\u119a',                                      // 'ᆚ'
    '\uf857': '\ud7c1',                                      // 'ퟁ'
    '\uf858': '\ud7c2',                                      // 'ퟂ'
    '\uf859': '\u119b',                                      // 'ᆛ'
    '\uf85a': '\ud7c3',                                      // 'ퟃ'
    '\uf85b': '\u119c',                                      // 'ᆜ'
    '\uf85c': '\ud7c4',                                      // 'ퟄ'
    '\uf85d': '\u119d',                                      // 'ᆝ'
    '\uf85e': '\u119e',                                      // 'ᆞ'
    '\uf85f': '\ud7c5',                                      // 'ퟅ'
    '\uf860': '\u119f',                                      // 'ᆟ'
    '\uf861': '\ud7c6',                                      // 'ퟆ'
    '\uf862': '\u11a0',                                      // 'ᆠ'
    '\uf863': '\u11a1',                                      // 'ᆡ'
    '\uf864': '\u11a2',                                      // 'ᆢ'
    '\uf86a': '',                                            // ''
    '\uf86b': '\u11a8',                                      // 'ᆨ'
    '\uf86c': '\u11a9',                                      // 'ᆩ'
    '\uf86d': '\u11fa',                                      // 'ᇺ'
    '\uf86e': '\u11c3',                                      // 'ᇃ'
    '\uf86f': '\u11fb',                                      // 'ᇻ'
    '\uf870': '\u11aa',                                      // 'ᆪ'
    '\uf871': '\u11c4',                                      // 'ᇄ'
    '\uf872': '\u11fc',                                      // 'ᇼ'
    '\uf873': '\u11fd',                                      // 'ᇽ'
    '\uf874': '\u11fe',                                      // 'ᇾ'
    '\uf875': '\u11ab',                                      // 'ᆫ'
    '\uf876': '\u11c5',                                      // 'ᇅ'
    '\uf877': '\u11ff',                                      // 'ᇿ'
    '\uf878': '\u11c6',                                      // 'ᇆ'
    '\uf879': '\ud7cb',                                      // 'ퟋ'
    '\uf87a': '\u11c7',                                      // 'ᇇ'
    '\uf87b': '\u11c8',                                      // 'ᇈ'
    '\uf87c': '\u11ac',                                      // 'ᆬ'
    '\uf87d': '\ud7cc',                                      // 'ퟌ'
    '\uf87e': '\u11c9',                                      // 'ᇉ'
    '\uf87f': '\u11ad',                                      // 'ᆭ'
    '\uf880': '\u11ae',                                      // 'ᆮ'
    '\uf881': '\u11ca',                                      // 'ᇊ'
    '\uf882': '\ud7cd',                                      // 'ퟍ'
    '\uf883': '\ud7ce',                                      // 'ퟎ'
    '\uf884': '\u11cb',                                      // 'ᇋ'
    '\uf885': '\ud7cf',                                      // 'ퟏ'
    '\uf886': '\ud7d0',                                      // 'ퟐ'
    '\uf887': '\ud7d1',                                      // 'ퟑ'
    '\uf888': '\ud7d2',                                      // 'ퟒ'
    '\uf889': '\ud7d3',                                      // 'ퟓ'
    '\uf88a': '\ud7d4',                                      // 'ퟔ'
    '\uf88b': '\u11af',                                      // 'ᆯ'
    '\uf88c': '\u11b0',                                      // 'ᆰ'
    '\uf88d': '\ud7d5',                                      // 'ퟕ'
    '\uf88e': '\u11cc',                                      // 'ᇌ'
    '\uf88f': '\ud7d6',                                      // 'ퟖ'
    '\uf890': '\u11cd',                                      // 'ᇍ'
    '\uf891': '\u11ce',                                      // 'ᇎ'
    '\uf892': '\u11cf',                                      // 'ᇏ'
    '\uf893': '\u11d0',                                      // 'ᇐ'
    '\uf894': '\ud7d7',                                      // 'ퟗ'
    '\uf895': '\u11b1',                                      // 'ᆱ'
    '\uf896': '\u11d1',                                      // 'ᇑ'
    '\uf897': '\u11d2',                                      // 'ᇒ'
    '\uf898': '\ud7d8',                                      // 'ퟘ'
    '\uf899': '\u11b2',                                      // 'ᆲ'
    '\uf89a': '\ud7d9',                                      // 'ퟙ'
    '\uf89b': '\u11d3',                                      // 'ᇓ'
    '\uf89c': '\ud7da',                                      // 'ퟚ'
    '\uf89d': '\u11d4',                                      // 'ᇔ'
    '\uf89e': '\u11d5',                                      // 'ᇕ'
    '\uf89f': '\u11b3',                                      // 'ᆳ'
    '\uf8a0': '\u11d6',                                      // 'ᇖ'
    '\uf8a1': '\u11d7',                                      // 'ᇗ'
    '\uf8a2': '\ud7db',                                      // 'ퟛ'
    '\uf8a3': '\u11d8',                                      // 'ᇘ'
    '\uf8a4': '\u11b4',                                      // 'ᆴ'
    '\uf8a5': '\u11b5',                                      // 'ᆵ'
    '\uf8a6': '\u11b6',                                      // 'ᆶ'
    '\uf8a7': '\u11d9',                                      // 'ᇙ'
    '\uf8a8': '\ud7dc',                                      // 'ퟜ'
    '\uf8a9': '\ud7dd',                                      // 'ퟝ'
    '\uf8aa': '\u11b7',                                      // 'ᆷ'
    '\uf8ab': '\u11da',                                      // 'ᇚ'
    '\uf8ac': '\ud7de',                                      // 'ퟞ'
    '\uf8ad': '\ud7df',                                      // 'ퟟ'
    '\uf8ae': '\u11db',                                      // 'ᇛ'
    '\uf8af': '\ud7e0',                                      // 'ퟠ'
    '\uf8b0': '\u11dc',                                      // 'ᇜ'
    '\uf8b1': '\ud7e1',                                      // 'ퟡ'
    '\uf8b2': '\u11dd',                                      // 'ᇝ'
    '\uf8b3': '\u11de',                                      // 'ᇞ'
    '\uf8b4': '\u11df',                                      // 'ᇟ'
    '\uf8b5': '\ud7e2',                                      // 'ퟢ'
    '\uf8b6': '\u11e0',                                      // 'ᇠ'
    '\uf8b7': '\u11e1',                                      // 'ᇡ'
    '\uf8b8': '\u11e2',                                      // 'ᇢ'
    '\uf8b9': '\u11b8',                                      // 'ᆸ'
    '\uf8ba': '\ud7e3',                                      // 'ퟣ'
    '\uf8bb': '\u11e3',                                      // 'ᇣ'
    '\uf8bc': '\ud7e4',                                      // 'ퟤ'
    '\uf8bd': '\ud7e5',                                      // 'ퟥ'
    '\uf8be': '\ud7e6',                                      // 'ퟦ'
    '\uf8bf': '\u11b9',                                      // 'ᆹ'
    '\uf8c0': '\ud7e7',                                      // 'ퟧ'
    '\uf8c1': '\ud7e8',                                      // 'ퟨ'
    '\uf8c2': '\ud7e9',                                      // 'ퟩ'
    '\uf8c3': '\u11e4',                                      // 'ᇤ'
    '\uf8c4': '\u11e5',                                      // 'ᇥ'
    '\uf8c5': '\u11e6',                                      // 'ᇦ'
    '\uf8c6': '\u11ba',                                      // 'ᆺ'
    '\uf8c7': '\u11e7',                                      // 'ᇧ'
    '\uf8c8': '\u11e8',                                      // 'ᇨ'
    '\uf8c9': '\u11e9',                                      // 'ᇩ'
    '\uf8ca': '\ud7ea',                                      // 'ퟪ'
    '\uf8cb': '\u11ea',                                      // 'ᇪ'
    '\uf8cc': '\ud7eb',                                      // 'ퟫ'
    '\uf8cd': '\u11bb',                                      // 'ᆻ'
    '\uf8ce': '\ud7ec',                                      // 'ퟬ'
    '\uf8cf': '\ud7ed',                                      // 'ퟭ'
    '\uf8d0': '\ud7ee',                                      // 'ퟮ'
    '\uf8d1': '\ud7ef',                                      // 'ퟯ'
    '\uf8d2': '\ud7f0',                                      // 'ퟰ'
    '\uf8d3': '\ud7f1',                                      // 'ퟱ'
    '\uf8d4': '\ud7f2',                                      // 'ퟲ'
    '\uf8d5': '\u11eb',                                      // 'ᇫ'
    '\uf8d6': '\ud7f3',                                      // 'ퟳ'
    '\uf8d7': '\ud7f4',                                      // 'ퟴ'
    '\uf8d8': '\u11bc',                                      // 'ᆼ'
    '\uf8d9': '\u11ec',                                      // 'ᇬ'
    '\uf8da': '\u11ed',                                      // 'ᇭ'
    '\uf8db': '\ud7f5',                                      // 'ퟵ'
    '\uf8dc': '\u11f1',                                      // 'ᇱ'
    '\uf8dd': '\u11ee',                                      // 'ᇮ'
    '\uf8de': '\u11ef',                                      // 'ᇯ'
    '\uf8df': '\ud7f6',                                      // 'ퟶ'
    '\uf8e0': '\u11f0',                                      // 'ᇰ'
    '\uf8e1': '\u11ec',                                      // 'ᇬ'
    '\uf8e2': '\u11f1',                                      // 'ᇱ'
    '\uf8e3': '\u11f2',                                      // 'ᇲ'
    '\uf8e4': '\u11ef',                                      // 'ᇯ'
    '\uf8e5': '\ud7f6',                                      // 'ퟶ'
    '\uf8e6': '\u11bd',                                      // 'ᆽ'
    '\uf8e7': '\ud7f7',                                      // 'ퟷ'
    '\uf8e8': '\ud7f8',                                      // 'ퟸ'
    '\uf8e9': '\ud7f9',                                      // 'ퟹ'
    '\uf8ea': '\u11be',                                      // 'ᆾ'
    '\uf8eb': '\u11bf',                                      // 'ᆿ'
    '\uf8ec': '\u11c0',                                      // 'ᇀ'
    '\uf8ed': '\u11c1',                                      // 'ᇁ'
    '\uf8ee': '\u11f3',                                      // 'ᇳ'
    '\uf8ef': '\ud7fa',                                      // 'ퟺ'
    '\uf8f0': '\ud7fb',                                      // 'ퟻ'
    '\uf8f1': '\u11f4',                                      // 'ᇴ'
    '\uf8f2': '\u11c2',                                      // 'ᇂ'
    '\uf8f3': '\u11f5',                                      // 'ᇵ'
    '\uf8f4': '\u11f6',                                      // 'ᇶ'
    '\uf8f5': '\u11f7',                                      // 'ᇷ'
    '\uf8f6': '\u11f8',                                      // 'ᇸ'
    '\uf8f7': '\u11f9',                                      // 'ᇹ'
};

